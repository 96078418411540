import { Reducer  } from '@cls/redux'
import { AnyAction } from 'redux'

export function undo(scope = 'content') {
  return {
    type: `${scope}/UNDO`,
  }
}

export function redo(scope = 'content') {
  return {
    type: `${scope}/REDO`,
  }
}

export function resetUndo(scope = 'content') {
  return {
    type: `${scope}/RESET_UNDO`,
  }
}

interface Options {
  includeActions: Array<any>,
  excludeActions?: Array<any>,
  delay: number,
  limit: number,
  scope: string,
}

interface UndoableState<S> {
  past: Array<S>,
  present: S,
  future: Array<S>,
}

// reducer enhancer
// http://rackt.org/redux/docs/recipes/ImplementingUndoHistory.html
export default function undoable<R extends Reducer<S, AnyAction>, S>(reducer: R, opts?: Options): Reducer<UndoableState<S>, AnyAction> {
  let updated = Date.now()
  let timeout = 0
  const config: Options = {
    includeActions: opts?.includeActions ?? [],
    excludeActions: opts?.excludeActions,
    delay:          opts?.delay || 0,
    limit:          opts?.limit || 0,
    scope:          opts?.scope || 'content',
  }

  const initialState: UndoableState<S> = {
    past:    [],
    present: reducer(),
    future:  [],
  }

  // Return a reducer that handles undo and redo
  return (prevState?: UndoableState<S>, action?: AnyAction) => {
    const state = prevState || initialState
    const { past, present, future } = state
    const now = Date.now()
    let nextState: UndoableState<S>

    if (!action) {
      return state
    }

    switch (action.type) {

      case `${config.scope}/UNDO`: {
        if (!past.length) {
          return state
        }
        const previous = past[past.length - 1]
        const newPast = past.slice(0, past.length - 1)
        nextState = {
          past:    newPast,
          present: previous,
          future:  [present, ...future],
        }
        break
      }

      case `${config.scope}/REDO`: {
        if (!future.length) {
          return state
        }
        const next = future[0]
        const newFuture = future.slice(1)
        nextState = {
          past:    [...past, present],
          present: next,
          future:  newFuture,
        }
        break
      }

      case `${config.scope}/RESET_UNDO`: {
        updated = now
        nextState = {
          past:    [],
          present: reducer(),
          future:  [],
        }
        break
      }

      default: {
        // Delegate handling the action to the passed reducer
        const newPresent = reducer(present, action)
        // These are immer objects, so will be pointers to the same object if nothing changed.
        if (present == newPresent) {
          return state
        }

        let append = true
        if (
          (action.type && (config.excludeActions && config.excludeActions.includes(action.type))) ||
          (config.includeActions && !config.includeActions.includes(action.type))
        ) {
          append = false
        }

        if (now - updated < config.delay) {
          append = false
          updated = now
        }

        if (append) {
          // append the previous state to the past and update the present state
          updated = now
          const limited = config.limit && past.length + 1 > config.limit
          nextState = {
            past:    [...past.slice(limited ? 1 : 0), present],
            present: newPresent,
            future:  [],
          }
        } else {
          // just update the present state without changing the history
          nextState = {
            past:    past,
            present: newPresent,
            future:  future,
          }
        }

        if (module.hot) {
          window.clearTimeout(timeout)
          timeout = window.setTimeout(() => {
            console.info(
              `${append ? 'add new' : 'update'} ${config.scope} undo state. Type:`,
              action.type,
              newPresent
            )
          }, 1500)
        }
      }

    }

    return nextState
  }
}
