import { Feedback } from 'cl-studio'

import * as types from './action-types'

export function showFeedbackPopup(data: { id: number, title: string}) {
  return <const>{
    type: types.SHOW_FEEDBACK_POPUP,
    data: {
      id:    data.id,
      title: data.title,
    },
  }
}

export function hideFeedbackPopup() {
  return <const>{
    type: types.HIDE_FEEDBACK_POPUP,
  }
}

export function newFeedbackSuccess(data: Feedback) {
  return <const>{
    type: types.NEW_FEEDBACK_SUCCESS,
    data: data,
  }
}

export function newFeedbackFailure(err: any) {
  return <const>{
    type: types.NEW_FEEDBACK_FAILURE,
    data: err,
  }
}

export type HideFeedbackPopup = ReturnType<typeof hideFeedbackPopup>
export type ShowFeedbackPopup = ReturnType<typeof showFeedbackPopup>
export type NewFeedbackSuccess = ReturnType<typeof newFeedbackSuccess>
export type NewFeedbackFailure = ReturnType<typeof newFeedbackFailure>

export type FeedbackActions = ShowFeedbackPopup
|HideFeedbackPopup
|NewFeedbackSuccess
|NewFeedbackFailure
