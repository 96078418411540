import React, { useState } from 'react'

interface Props {
  startValue: string | undefined,
  onUpdate?: (value: string) => void,
  field:string,
}

export const MultimediaFieldInput: React.FC<Props> = ({ startValue, onUpdate, field }) => {
  const [display, setDisplay] = useState(startValue || '')
  return <input
    className="multimedia-field--input"
    value={display}
    onChange={(e)=>setDisplay(e.target.value)}
    data-cy={`multimedia-field-input-${field}`}
    onBlur={()=> {
      onUpdate && onUpdate(display)
    }}
  />
}
