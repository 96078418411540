import { Checkbox, DraggablePanel } from '@kpv-lab/ui'
import React, { PureComponent } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { RootState } from '../../../cl-studio/src/app/state'
import MDTextEditor from '../../../md-text-editor'
import { hideRichtextPopup } from './state/richtext-popup-redux'

type State = {
  initialCheckbox: boolean,
  initialValue: string,
  checkboxVal: boolean,
  value: any,
}

const mapStateToProps = (state: RootState) => {
  const {
    title,
    dataKey,
    value,
    placeholder,
    checkbox,
    visible,
    closeHandler,
  } = state.richtextPopup
  return {
    title,
    dataKey,
    value,
    placeholder,
    checkbox,
    visible,
    closeHandler,
  }
}

const connector = connect(mapStateToProps)

type Props = ConnectedProps<typeof connector>

export class RichtextPopup extends PureComponent<Props, State> {

  constructor(props: Props) {
    super(props)
    this.state = this.initialState()
  }

  initialState = () => ({
    initialCheckbox: false,
    initialValue:    '',
    checkboxVal:     false,
    value:           '',
  })

  static getDerivedStateFromProps(props: Props, state: State) {
    const { value, checkbox } = props
    const checkboxVal = checkbox && checkbox.value

    if (value !== state.initialValue || checkboxVal !== state.initialCheckbox) {
      return {
        initialCheckbox: checkboxVal,
        initialValue:    value,
        checkboxVal,
        value,
      }
    }

    return null
  }

  richtextHandler = (value: any) => {
    this.setState({ value })
  }

  checkboxHandler = () => {
    this.setState({ checkboxVal: !this.state.checkboxVal })
  }

  closeHandler = () => {
    const { value, checkboxVal } = this.state
    const { closeHandler, dispatch } = this.props
    closeHandler && closeHandler(value, checkboxVal)
    dispatch(hideRichtextPopup())
    this.setState(this.initialState())
  }

  render() {
    const {
      visible,
      dataKey,
      title,
      placeholder,
      checkbox,
    } = this.props
    const { value, checkboxVal } = this.state

    if (!visible) {
      return false
    }

    let _checkbox: JSX.Element| null = null
    if (checkbox) {
      _checkbox = (
        <span className="richtext-popup-checkbox">
          <Checkbox
            structure={{
              label: checkbox.label,
            }}
            value={checkboxVal}
            updateHandler={this.checkboxHandler}
          />
        </span>
      )
    }

    const _title = title || `Comment for ${dataKey?.split('_').join(' ')}`
    const structure = {
      placeholder: placeholder || 'Write a comment...',
    }

    // Hide the formatting guide line when editing bibliography children
    const showingBibliographyChildren = title
      ?.toUpperCase()
      .includes('EDIT CHILDREN')
    const extraClass = showingBibliographyChildren
      ? 'bibliography-children'
      : ''

    return (
      <DraggablePanel
        className={`ui-floating-panel richtext-popup ${extraClass}`}
        name="infoEditor"
        backdrop={true}
        backdropHandler={this.closeHandler}
      >
        <h2 className="ui-title ui-comment-title draggable">
          <span>{_title}</span>
        </h2>
        <i className="material-icons ui-close-btn" onClick={this.closeHandler}>
          close
        </i>
        {_checkbox}
        <MDTextEditor
          controlled={true}
          value={value}
          structure={structure}
          theme="no-media"
          updateHandler={this.richtextHandler}
          dataKey={dataKey}
        />
      </DraggablePanel>
    )
  }

}

export default connector(RichtextPopup)
