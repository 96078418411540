import { gql } from '@apollo/client'

export const REFETCH_FEEDBACK = gql`
  query refetchFeedback($itemId: Int) {
    item(id: $itemId) {
      id
      unreadFeedbackCount
      feedback {
        id
        item_id
        parent_id
        body
        title
        created_at
        source
        user {
          name
          email
          role
          id
        }
      }
    }
  }
`

export const ADD_FEEDBACK = gql`
  mutation saveFeedback($itemId: Int, $parentId: Int, $title: String, $source: String, $body: String) {
    addFeedback(itemId: $itemId, parentId: $parentId, title: $title, source: $source, body: $body) {
      feedback {
        id
        item_id
        parent_id
        body
        title
        source
        created_at
        user {
          name
          email
          role
          id
        }
      }
      error
    }
  }
`

export const UPDATE_FEEDBACK = gql`
  mutation updateFeedback($title: String, $body: String, $id: Int) {
    updateFeedback(title: $title, body: $body, id: $id) {
      feedback {
        id
        item_id
        parent_id
        body
        title
        created_at
        user {
          name
          email
          role
          id
        }
      }
      error
    }
  }
`

export const DELETE_FEEDBACK = gql`
  mutation deleteFeedback($id: Int) {
    deleteFeedback(id: $id)
  }
`

