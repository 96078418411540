/* global VERSION */
import './app.css'
import './wdyr'
import './app/lib/polyfills'

import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer as HotAppContainer } from 'react-hot-loader'
import { Store } from 'redux'
import { Persistor } from 'redux-persist'

import { settings } from './app/config'
import { RootState } from './app/state'
import { configureStore } from './app/state/configure-store'
import { refreshSession } from './app/utils/refresh-session'
import { getAllWorkingVersions, getOverviewState } from './app/utils/working-version-storage'
import AppContainer from './app/views/app/AppContainer'

if (settings.useSentry) {
  console.info('Adding Sentry')
  Sentry.init({
    dsn:          settings.sentryDsn,
    /* @ts-expect-error Version is added in by webpack */
    release:      `cls@${VERSION}`,
    environment:  settings.sentryEnv,
    integrations: [new Integrations.Dedupe(), new Integrations.ExtraErrorData()],
  })
}

window.addEventListener('unhandledrejection', event => {
  // Prevent error output on the console
  event.preventDefault()
  console.warn('Unhandled promise rejection: ' + event.reason)
})

const shouldOpenMenu = () => {
  // If we're not deep linking then we should show the menu
  return {
    show: window.location.hash === '#/' || window.location.hash === '',
  }
}

const render = (ComponentToRender, store: Store<RootState>, persistor: Persistor) => {
  ReactDOM.render(
    <HotAppContainer>
      <ComponentToRender store={store} persistor={persistor} />
    </HotAppContainer>,
    document.getElementById('root')
  )
}

// There are a number of things we want to preload the store with.
// 1. The working versions need to be retrieved from local storage
// 2. We want to see if we have a valid cookie and set the profile if so
// 3. Do we want to start with the menu open?
//
// We can check for these things within components, but it starts to get
// complicated as to which view layer components are responsible for data.
// By starting with the store in a pre-configured state, we also save the
// initial flurry of dispatches and potential re-renders.
Promise.all([
  getAllWorkingVersions(),
  getOverviewState(),
  refreshSession(),
  shouldOpenMenu(),
]).then(([workingVersions, gridView, userProfile, menu]) => {
  const initialState: Partial<RootState> = { ...workingVersions, user: userProfile, menu }
  if (gridView) {
    initialState.gridView = gridView
  }
  const { store, persistor } = configureStore(initialState)

  // Now that we've configured the store, render the application
  render(AppContainer, store, persistor)

  if (module.hot) {
    module.hot.accept('./app/views/app/AppContainer', () => {
      const NewAppContainer = require('./app/views/app/AppContainer').default
      // @ts-ignore This is fine.
      render(NewAppContainer)
    })
  }
})

console.info(
  ' Launching...\n %cCHRONO•LOGIC STUDIO%c',
  'font-size: 18px; font-weight: 400; font-family: HelveticaNeue, Arial, sans-serif;',
  ''
)
