import '../styles/SliderGradient.css'

import React, { PureComponent } from 'react'

type Props = {
  src: string,
  row?: number,
  min?: number,
  max: number,
};

type State = {
  img: HTMLImageElement|null,
  maxRow: number,
  dy: number,
  width: number,
};

export default class SliderGradient extends PureComponent<Props, State> {

  refCanvas: any;

  constructor(props: Props) {
    super(props)
    this.loadGradientImage(props.src)

    this.state = {
      img:    null,
      maxRow: 0,
      dy:     1,
      width:  512,
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.src !== prevProps.src) {
      this.loadGradientImage(this.props.src)
    }
    if (this.state.img && prevProps.row !== this.props.row) {
      this.applyGradient(this.props.row)
    }
  }

  applyGradient(row) {
    const { img, maxRow, dy } = this.state
    const y = Math.min(row, maxRow)
    const ctx = this.refCanvas.getContext('2d')
    ctx.drawImage(img, 0, -y * dy)
  }

  loadGradientImage(path) {
    const { max } = this.props
    const src = path
    const img = new Image()
    img.crossOrigin = ''
    img.onload = () => {
      // make sure we are still mounted!
      if (this.refCanvas) {
        this.setState({
          img:    img,
          width:  img.width,
          maxRow: img.height - 1,
          dy:     img.height / (max + 1),
        })

        this.refCanvas.width = img.width
        this.applyGradient(this.props.row)
      }
    }
    img.onerror = () => {
      console.warn('Could not find gradient image:', src)
    }
    img.src = src
  }

  setRefCanvas = ref => (this.refCanvas = ref)

  render() {
    return (
      <canvas
        ref={this.setRefCanvas}
        className="slider-gradient"
        height="1"
        width={this.state.width}
      />
    )
  }

}
