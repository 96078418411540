import Logo from '@cls/Logo'
import { RootState } from '@cls/redux'
import { History } from 'history'
import queryString from 'query-string'
import React, { Fragment, PureComponent } from 'react'
import { connect } from 'react-redux'

import { newPassword, resetUserPasswordState } from './state/actions-account'

type NewPasswordViewProps = {
  dispatch: (...args: Array<any>) => any,
  location: Location,
  user: any,
  history: History,
};

type NewPasswordViewState = {
  error?: string,
  password: string,
  confirmPassword: string,
};

export class NewPasswordView extends PureComponent<NewPasswordViewProps, NewPasswordViewState> {

  state = {
    error:           undefined,
    password:        '',
    confirmPassword: '',
  }

  componentDidMount() {
    this.props.dispatch(resetUserPasswordState())
  }

  componentWillUnmount() {
    this.props.dispatch(resetUserPasswordState())
  }

  static getDerivedStateFromProps(props: any) {
    if (!props.user.error) {
      return null
    }
    return {
      error: props.user.error,
    }
  }

  formInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key: string = e.target.name
    const value: string = e.target.value

    // @ts-expect-error Unverifiable key names
    this.setState({ error: undefined, [key]: value })
  }

  onSubmit = (event: any) => {
    const { password, confirmPassword } = this.state
    const { location, dispatch } = this.props
    event.preventDefault()

    let error: string|null = null
    const urlParams = queryString.parse(location.search)
    const token = urlParams.t
    if (!token) {
      error = 'Invalid password reset link'
    } else if (!password || !confirmPassword) {
      error = 'Password not entered'
    } else if (password !== confirmPassword) {
      error = "Passwords don't match"
    } else if (password && confirmPassword && password.length < 6) {
      error = 'Password requires 6+ characters'
    }
    this.setState({ error: error ?? undefined })
    if (error) {
      return
    }

    dispatch(newPassword({ password, token, newUser: !!Number(urlParams.n) }))
  }

  render() {
    const { user, location } = this.props
    const { error } = this.state
    const urlParams = queryString.parse(location.search)
    const newUser = !!Number(urlParams.n)
    let formContent, formButton

    if (user.resetUser) {
      // check new user
      formContent = newUser ? (
        <p>{`The password for ${user.resetUser} has been created`}</p>
      ) : (
        <p>{`The password for ${user.resetUser} has been reset`}</p>
      )
      formButton = (
        <button
          onClick={() => this.props.history.replace('/login')}
          className="btn btn-primary login-button"
        >
          Back to login
        </button>
      )
    } else {
      formContent = (
        <Fragment>
          <fieldset className="fieldset">
            <p className="form-row">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                placeholder="Password"
                name="password"
                id="password"
                className="input input-email block"
                onChange={this.formInput}
                value={this.state.password}
                data-cy="password"
              />
            </p>
          </fieldset>
          <fieldset className="fieldset">
            <p className="form-row">
              <label htmlFor="password-confirm">Confirm Password</label>
              <input
                type="password"
                placeholder="Confirm Password"
                name="confirmPassword"
                id="password-confirm"
                className="input input-email block"
                onChange={this.formInput}
                value={this.state.confirmPassword}
                data-cy="password-confirm"
              />
            </p>
          </fieldset>
        </Fragment>
      )
      formButton = (
        <button type="submit" className="btn btn-primary login-button">
          {user.pending ? 'Loading...' : newUser ? 'Set password' : 'Reset password'}
        </button>
      )
    }
    return (
      <div className="app page-login">
        <form onSubmit={this.onSubmit} className="form login-form center">
          <Logo />
          {error ? <p className="form-error">{error}</p> : null}
          {formContent}
          <fieldset className="fieldset">{formButton}</fieldset>
        </form>
      </div>
    )
  }

}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(NewPasswordView)
