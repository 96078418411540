import { ActionHandler, createReducer } from '@cls/redux'
import { Explorer } from 'cl-studio'

export function showMenuWithRelatedItem(parentId: any) {
  return <const>{
    type: 'SHOW_MENU_WITH_RELATED_ITEMS',
    parentId,
  }
}

export function setQuery(query: any) {
  return <const>{
    type: 'SET_QUERY',
    query,
  }
}

export function toggleMenu() {
  return <const>{
    type: 'TOGGLE_MENU',
  }
}

export function showMenu() {
  return <const>{
    type: 'SHOW_MENU',
  }
}

export function clearParentId() {
  return <const>{
    type: 'CLEAR_PARENT_ID',
  }
}

export function setParentId(parentId: any) {
  return <const>{
    type: 'SET_PARENT_ID',
    parentId,
  }
}

export function setOverviewItems(ids: any) {
  return <const>{
    type: 'SET_OVERVIEW_ITEMS',
    ids,
  }
}

export function clearOverviewItems() {
  return {
    type: 'CLEAR_OVERVIEW_ITEMS',
  }
}

export function setExplorer(explorer: any) {
  return <const>{
    type: 'SET_EXPLORER',
    explorer,
  }
}

interface OwnState {
  show: boolean,
  parentId?: string,
  query?: string,
  overviewItemIds?: any,
  explorer?: Explorer,
}

const initialState:OwnState = {
  show:            false,
  parentId:        undefined,
  query:           undefined,
  overviewItemIds: undefined,
  explorer:        undefined,
}

const actionHandlers = new Map<string, ActionHandler<OwnState, any>>([
  ['SET_QUERY', (draftState, action) => {
    draftState.query = action.query
  }],
  ['SET_EXPLORER', (draftState, action) => {
    draftState.explorer = action.explorer
  }],
  ['TOGGLE_MENU', (draftState) => {
    draftState.show = !draftState.show
  }],
  ['SHOW_MENU', (draftState) => {
    draftState.show = true
  }],
  ['SHOW_MENU_WITH_RELATED_ITEMS', (draftState, action) => {
    draftState.show = true
    draftState.parentId = action.parentId
    draftState.query = undefined
  }],
  ['SET_PARENT_ID', (draftState, action) => {
    draftState.parentId = action.parentId
  }],
  ['CLEAR_PARENT_ID', (draftState) => {
    draftState.parentId = undefined
  }],
  ['SET_OVERVIEW_ITEMS', (draftState, action) => {
    draftState.overviewItemIds = action.ids
  }],
  ['CLEAR_OVERVIEW_ITEMS', (draftState) => {
    draftState.overviewItemIds = undefined
  }],
])
export default function reducer() {
  return createReducer(initialState, actionHandlers)
}
