type NotSelected = {isTagged: false, isPrimary: false}
type Selected = {isTagged: true, isPrimary: false}
type SelectedAndPrimary = {isTagged: true, isPrimary: true}
type TagStatesOrdered = [NotSelected, Selected, SelectedAndPrimary]
export type TagState = NotSelected | Selected | SelectedAndPrimary
type TagStateSteps = 0 | 1 | 2

export const tagStatus = (currentState: TagState, steps: TagStateSteps): TagState =>{
  if (steps === 0) {
    return currentState
  }
  const tagStatesOrdered: TagStatesOrdered = [
    { isTagged: false, isPrimary: false },
    { isTagged: true, isPrimary: false },
    { isTagged: true, isPrimary: true },
  ]
  const currentIndex = tagStatesOrdered.findIndex(s => s.isTagged === currentState.isTagged && s.isPrimary === currentState.isPrimary)
  const nextIndex = (currentIndex + steps) % tagStatesOrdered.length
  return tagStatesOrdered[nextIndex]
}