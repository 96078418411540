import { ActionHandler, createReducer } from '@cls/redux'

import * as types from './action-types'
import { processData } from './utils'

interface OwnState {
  rawData: any,
  fieldId: string,
  items: Array<any>,
  showPreview: boolean,
  visible: boolean,
  commitHandler: (items: Array<any>) => void,
}

const initialState: OwnState = {
  rawData:       '',
  fieldId:       '',
  items:         [],
  showPreview:   false,
  visible:       false,
  commitHandler: () => {
    // Null op
  },
}

const actionHandlers = new Map<string, ActionHandler<OwnState, any>>([
  [types.SHOW_IMPORT, (draftState, action) => {
    draftState.visible = true
    draftState.commitHandler = action.data
  }],
  [types.HIDE_IMPORT, (draftState) => {
    draftState.visible = false
  }],
  [types.IMPORT_INPUT, (draftState) => {
    draftState.showPreview = false
  }],
  [types.IMPORT_FIELD, (draftState, action) => {
    draftState.fieldId = action.data
  }],
  [types.IMPORT_PREVIEW, (draftState, action) => {
    draftState.rawData = action.data
    draftState.items = processData(action.data)
    draftState.showPreview = true
  }],
  [types.IMPORT_COMPLETE, (draftState) => {
    draftState.rawData = ''
    draftState.items = []
    draftState.showPreview = false
    draftState.visible = false
    draftState.commitHandler = initialState.commitHandler
  }],
])

export default function reducer() {
  return createReducer(initialState, actionHandlers)
}

