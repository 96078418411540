import { processingOptions } from '@kpv-lab/image-utils'
import { Input } from '@kpv-lab/ui'
import React from 'react'

interface OwnProps {
  id?: number,
  val: string,
  preset: string,
  onSelect: (event: React.ChangeEvent<HTMLSelectElement>) => void,
  onCustomValueChange: (data: string, val: string) => void,
}
export const ImageProcessingSelector: React.FC<OwnProps> = ({
  id = 0,
  val,
  preset,
  onSelect,
  onCustomValueChange,
}) => {

  const selectedVal = processingOptions[val] ? val : val ? 'custom' : ''
  let selectedLabel = selectedVal === 'custom' ? 'Custom' : 'None'

  const options = Object.keys(processingOptions)
    .sort()
    .map((k, i) => {
      const title = k.replace(/-/g, ' ').toTitleCase()
      if (k === val) {
        selectedLabel = title
      }
      if (processingOptions[k] === selectedVal) {
        selectedLabel = title
      }
      return (
        <option value={k} key={i}>
          {title}
        </option>
      )
    })

  const select = (
    <section className="ui-select image-editor-presets">
      <select
        onChange={onSelect}
        id={`processing-presets-${id}`}
        title="Presets"
        value={selectedVal === 'custom' ? 'custom' : preset}
        data-cy="image-processing-preset"
      >
        <option value="">None</option>
        {options}
        <option value="custom">Custom</option>
      </select>
      <label htmlFor={`processing-presets-${id}`} className="ui-label">
          Image processing: <strong>{selectedLabel}</strong>
      </label>
    </section>
  )

  let custom = <></>
  if (preset === 'custom' || selectedVal === 'custom') {
    custom = (
      <Input
        structure={{
          type:    'text',
          options: { placeholder: 'Imgix custom params' },
        }}
        value={processingOptions[val] || val || ''}
        updateHandler={onCustomValueChange}
        inline={false}
        dataKey="options"
        key={'options-' + id}
        data-cy="image-processing-custom"
      />
    )
  }

  return (
    <section className="image-editor-options">
      {select}
      {custom}
    </section>
  )
}
