import { Button, Input } from '@kpv-lab/ui'
import React, { useState } from 'react'

import { VideoFormElement } from '../styles/VideoForm-css'

interface OwnProps {
  onSubmit: (url: string) => void,
}

export const VideoUrlForm: React.FC<OwnProps> = ({ onSubmit }) => {
  const [videoUrl, setVideoUrl] = useState('')

  return <VideoFormElement>
    <Input
      structure={{
        type:    'text',
        options: {
          placeholder: 'Type or paste a Youtube URL',
          title:       'URL',
          'data-cy':   'video-url-input',
        }
        ,
      }}
      value={videoUrl}
      updateHandler={(_dataKey, url) => setVideoUrl(url)}
      inline={false}
      dataKey="video-url"
    />
    <Button
      id="video-url-submit"
      updateHandler={() => onSubmit(videoUrl)}
      structure={{
        label:     'Go',
        options: { 'data-cy': 'video-url-submit' },
      }}
    />
  </VideoFormElement>
}
