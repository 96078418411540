import PropTypes from 'prop-types'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'

interface OwnProps {
  authHandler: () => string,
  authPath: string,
  component: React.ComponentType<any>,
}

const AuthRoute = ({ component, authHandler, authPath, ...props }: OwnProps) => {
  if (authHandler) {
    const authRedirectTo = authHandler()
    if (authRedirectTo) {
      // redirect to authentication route
      const location = {
        pathname: authPath,
        state:    {
          from: authRedirectTo,
        },
      }

      if (authRedirectTo !== '/login') {
        // @ts-ignore Using the window deliberately to share data
        window._loginRedirect = authRedirectTo
      }
      return <Redirect to={location} />
    }

    // authenticated so proceed
    return <Route {...props} component={component} />
  } else {
    // work as a standard route
    return <Route {...props} component={component} />
  }
}

AuthRoute.propTypes = {
  component:   PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  authHandler: PropTypes.func,
  authPath:    PropTypes.string,
}

export default AuthRoute
