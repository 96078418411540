import './ResultBox.css'

import { RootState } from '@cls/redux'
import React from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { clearFilterTags as clearFilterTagsAction } from '../../../../../components/unitagger-popup/state/unitagger-popup-redux'
import { clearParentId as clearParentIdAction } from '../../../state/menu-redux'
import LatestItems from './LatestItems'
import RelatedItems from './RelatedItemsView'
import SearchItems from './SearchItemsView'
import TagItems from './TagsItemsView'

interface ResultBoxProps {
  toggleToolbar: () => void,
  query?: string,
  explorerId: number,
}

const mapStateToProps = (state: RootState) => {
  return {
    parentItemId:     state.menu.parentId,
    unitaggerVisible: state.unitaggerPopup.visible,
    tagIds:           state.unitaggerPopup.filterTags,
  }
}

const connector = connect(mapStateToProps,
  { clearFilterTags: clearFilterTagsAction, clearParentId: clearParentIdAction }
)

type TypesFromRedux = ConnectedProps<typeof connector>

type AllProps = TypesFromRedux & ResultBoxProps

function ResultBox({ toggleToolbar, query, parentItemId, unitaggerVisible, tagIds, clearFilterTags, clearParentId, explorerId }: AllProps) {
  if (unitaggerVisible) {
    return null
  }
  if (parentItemId) {
    return (
      <RelatedItems itemId={Number(parentItemId)} toggleToolbar={toggleToolbar} clear={clearParentId} />
    )
  }
  if (query && query.length > 2) {
    return <SearchItems query={query} toggleToolbar={toggleToolbar} explorerId={explorerId} />
  }
  if (tagIds && tagIds.length > 0) {
    return (
      <TagItems
        tagId={tagIds[0]}
        toggleToolbar={toggleToolbar}
        clear={clearFilterTags}
        explorerId={explorerId}
      />
    )
  }

  return <LatestItems toggleToolbar={toggleToolbar} explorerId={explorerId} />
}

export default connector(ResultBox)
