declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Element {
    _addEventListener<K extends keyof ElementEventMap>(type: K, listener: (this: Element, ev: ElementEventMap[K]) => any, options?: boolean | AddEventListenerOptions): void,
    _addEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void,
    eventListenerList: Record<string, any>,
  }
}

/*
  Instead of removing all event listeners and adding them all back again every
  time we add one new item, we'll extend the prototype instead so we can check
  if an element already has its listeners attached
*/
(function () {
  if (typeof Element !== 'undefined' && typeof Element.prototype.addEventListener !== 'function') {
    Element.prototype._addEventListener = Element.prototype.addEventListener
    Element.prototype.addEventListener = function (a: any, b: any, c: any) {
      this._addEventListener(a, b, c)
      if (!this.eventListenerList) {
        this.eventListenerList = {}
      }
      if (!this.eventListenerList[a]) {
        this.eventListenerList[a] = []
      }
      this.eventListenerList[a].push(b)
    }
  }
})()

export {}
