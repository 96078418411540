import { Reducer } from '@cls/redux'
import { Tag } from 'cl-studio'
import { produce } from 'immer'
import { Action } from 'redux'

interface ShowPopupOptions {
  existingTagId?: Array<number>,
  onClose?: any,
  parentTagIds?: Array<number>,
  query?: string,
  relatedTagIds?: Array<number>,
  selectTagForLink?: any,
  selectedTagIds?: Array<number>,
  tagGroupIds?: Array<number>,
  shortListFilter?: (tag: Tag) => boolean,
  action?: 'filter'|'select',
}

export function showUnitaggerPopup(options: ShowPopupOptions) {
  return <const>{
    type:    'SHOW_UNITAGGER_POPUP',
    payload: {
      ...options,
    },
  }
}

export const tagSelect = (tagId: number) => (<const>{
  type:    'TAG_SELECT',
  payload: {
    tagId,
  },
})

export const toggleTagForField = (tagId: number) => (<const>{
  type:    'TOGGLE_TAG_FOR_FIELD',
  payload: {
    tagId,
  },
})

export const clearFilterTags = () => (<const>{ type: 'CLEAR_FILTER_TAGS' })
export const hideUnitaggerPopup = () => (<const>{ type: 'HIDE_UNITAGGER_POPUP' })

export type ShowUnitaggerPopup = ReturnType<typeof showUnitaggerPopup>
export type TagSelect = ReturnType<typeof tagSelect>
export type ToggleTagForField = ReturnType<typeof toggleTagForField>

export type UnitaggerPopupActions =
| ShowUnitaggerPopup
| TagSelect
| ToggleTagForField
| Action<'CLEAR_FILTER_TAGS'>
| Action<'HIDE_UNITAGGER_POPUP'>


interface IUnitaggerPopupState {
  existingTagId:    Array<number>|null,
  filterTags:       Array<number>,
  onClose?:         (tagIds: Array<number>) => void,
  parentTagIds:     Array<number>,
  query:            string,
  relatedTagIds:    Array<number>,
  selectTagForLink: null,
  selectedTagIds:   Array<number>,
  shortListFilter?: (tag: Tag) => boolean,
  tagGroupIds:      Array<number>,
  visible:          boolean,
  action:           'filter' | 'select',
}

export type UnitaggerPopupState = Readonly<IUnitaggerPopupState>

const initialState: UnitaggerPopupState = {
  existingTagId:    null,
  filterTags:       [],
  parentTagIds:     [],
  query:            '',
  relatedTagIds:    [],
  selectTagForLink: null,
  selectedTagIds:   [],
  tagGroupIds:      [],
  visible:          false,
  action:           'select',
}

export default function reducer(): Reducer<UnitaggerPopupState, any> {
  return produce((draftState, action?: UnitaggerPopupActions): UnitaggerPopupState => {
    if (!action) {
      return draftState
    }
    if (action.type === 'SHOW_UNITAGGER_POPUP') {
      // We'll return a whole new object here as we want to reset everything and rerender.
      return {
        ...initialState,
        ...action.payload,
        visible: true,
      }
    } else if (action.type === 'HIDE_UNITAGGER_POPUP') {
      draftState.visible = false
    } else if (action.type === 'TOGGLE_TAG_FOR_FIELD') {
      const tagExists = draftState.selectedTagIds.includes(action.payload.tagId)
      if (tagExists) {
        draftState.selectedTagIds = draftState.selectedTagIds.filter(t => t !== action.payload.tagId)
      } else {
        draftState.selectedTagIds = [...draftState.selectedTagIds, action.payload.tagId]
      }
    } else if (action.type === 'CLEAR_FILTER_TAGS') {
      draftState.filterTags = []
    } else if (action.type === 'TAG_SELECT') {
      draftState.filterTags = [action.payload.tagId]
    }
    return draftState
  }, initialState)
}

