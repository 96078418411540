import { EditingUser } from 'cl-studio'
import React, { useCallback, useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { openChatWindow as openChatWindowAction } from '../../../../components/messaging/state/actions'

interface OwnProps {
  activeUsers: Array<EditingUser>,
  allUsers: Array<EditingUser>,
  itemTitles: Array<{ id: number, title: string }>,
  subscribeToUpdates?: () => () => void,
}

const mapStateToProps = state => {
  return {
    user:              state.user,
    messageWindowOpen: state.messages.windowOpen,
    unreadMessages:    state.messages.unreadMessages,
  }
}

const connector = connect(
  mapStateToProps,
  { openChatWindow: openChatWindowAction }
)

type PropsFromRedux = ConnectedProps<typeof connector>
type AllProps = OwnProps & PropsFromRedux

export const UserList:React.FC<AllProps> = (props) => {

  const [expandList, setExpandList] = useState(false)
  const [expandInactive, setExpandInactive] = useState(false)
  const toggleList = useCallback(() => {
    setExpandList((_expandList) => !_expandList)
  }, [setExpandList])

  const showInactive = useCallback(() => {
    setExpandInactive(_expandInactive => !_expandInactive)
  }, [setExpandInactive])

  useEffect(() => {
    if (props.subscribeToUpdates) {
      return props.subscribeToUpdates()
    }
  }, [props.subscribeToUpdates])

  const {
    activeUsers,
    allUsers,
    itemTitles,
    messageWindowOpen,
    openChatWindow,
    unreadMessages,
    user,
  } = props

  const formatUrl = useCallback(url => {
    const sections = url.split('/')
    if (sections[1] === 'content') {
      const itemId = sections[3]
      const item = itemTitles[itemId]
      if (!item) {
        return 'Content'
      } else {
        return item.title
      }
    }

    if (!sections.length) {
      return ''
    }
    const currentSection = sections[1][0]
      ? sections[1][0].toUpperCase() + sections[1].slice(1)
      : 'Content'

    return currentSection
  }, [itemTitles])

  const users = (activeUsers || []).filter(
    u => u.id !== user.profile.id
  )
  const seen = new Set()
  const conflictPath = /content\/\d{1,}\/\d{1,}/
  const duplicates = (activeUsers || [])
    .filter(i => conflictPath.test(i.url ?? ''))
    .some(u => seen.size === seen.add(u.url).size)

  return (
    <div className={'users' + (expandList ? ' open' : '')}>
      <div className="users-wrap" onClick={toggleList}>
        {!messageWindowOpen && !!unreadMessages.length && (
          <span className="btn add-content-btn new-chat">
            <i className="icon">chat</i>
          </span>
        )}
        {duplicates ? (
          <span className="btn add-content-btn warning">
            <i className="icon">warning</i>
          </span>
        ) : (
          <div className="active-user-count">{users.length}</div>
        )}
        <span className="label">Editors</span>
      </div>
      {expandList && (
        <div className="user-list-container">
          {users.map(u => {
            return (
              <div className="user" key={u.id}>
                <span className="editing-item-title">{formatUrl(u.url)}</span>
                <div className="user-chat">
                  {u.name}
                  <span
                    className={`btn add-content-btn ${
                      unreadMessages.includes(u.id) ? 'read-me' : ''
                    }`}
                  >
                    <a title="Chat">
                      <i
                        className="icon"
                        onClick={() => openChatWindow(u.id, u.name)}
                      >
                          chat
                      </i>
                    </a>
                  </span>
                </div>
              </div>
            )
          })}
          <div className="inactive-list">
            <span onClick={showInactive}>Inactive Users ▼</span>
            {expandInactive && (
              <div className="inactive-users">
                {allUsers.map(u => {
                  return (
                    <div className="inactive-user-chat" key={u.id}>
                      <span className="inactive-username">{u.name}</span>
                      <span
                        className={`btn add-content-btn ${
                          unreadMessages.includes(u.id) ? 'read-me' : ''
                        }`}
                      >
                        <a title="Chat">
                          <i
                            className="icon"
                            onClick={() => openChatWindow(u.id, u.name)}
                          >
                              chat
                          </i>
                        </a>
                      </span>
                      <span className="btn add-content-btn" />
                      <span className="last-active">
                        {new Date(parseInt(u.lastActive)).toLocaleDateString('en-GB', {
                          year:  'numeric',
                          month: '2-digit',
                          day:   '2-digit',
                        })}
                      </span>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default connector(UserList)
