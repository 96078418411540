import React, { ReactNode } from 'react'

import { MultimediaFieldElement } from '../styles/MultimediaField-css'


interface Props {
  className?: string,
  label?: ReactNode,
  field?: string,
}

export const MultimediaEditField: React.FC<Props> = ({ children, label, field }) => {
  const useChildrenAsLabel = children && !label
  const renderValue = children && label

  const labelClass = `multimedia-field--label ${useChildrenAsLabel ? 'multimedia-field--label-full' : ''}`

  return <MultimediaFieldElement className={'editing'} data-cy={`multimedia-field-${field}`}>
    <span className={labelClass} data-cy="multimedia-label">
      {useChildrenAsLabel ? children : label}
    </span>
    {renderValue && <span className="multimedia-field--editing" data-cy="multimedia-value">
      {children}
    </span>}
  </MultimediaFieldElement>
}
