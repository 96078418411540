import '../styles/Radio.css'

import React, { PureComponent } from 'react'

interface OwnProps {
  structure: any,
  dataKey: string,
  value: string | number,
  inline: boolean,
  updateHandler: (...args: Array<any>) => any,
  postRender: (...args: Array<any>) => any,
}

export default class Radio extends PureComponent<OwnProps> {

  onChange = event => {
    const { structure, dataKey, value, updateHandler } = this.props
    const val = event.target.value
    const key = structure.id || dataKey

    if (val !== value && updateHandler) {
      updateHandler(key, val)
    }
  }

  render() {
    const { structure, dataKey, value, inline, postRender } = this.props
    const id = ('' + structure.id || dataKey).replace(/[.~@!]/g, '-')
    const htmlFor = `input-${id}`
    let className = `ui-i ui-field ui-radio field-${id}`

    if (inline) {
      className += ' inline'
    }

    const style: React.CSSProperties = {}
    if (structure.width) {
      style.width = structure.width
    }

    const labels = structure.labels
    const radios = labels && labels.map((currentLabel: string, idx: number) => {
      const htmlForRadio = `radio-${id}-${idx}`
      const val = structure.values[idx]

      return (
        <label htmlFor={htmlForRadio} className="ui-label ui-label-option" key={idx}>
          <input
            type="radio"
            onChange={this.onChange}
            id={htmlForRadio}
            value={val}
            name={`radio-${id}`}
            checked={val === value}
          />
          {currentLabel}
        </label>
      )
    })

    let label
    if (structure.label && !inline && !structure.hideLabel) {
      label = <label className="ui-label ui-radio-title">{structure.label}</label>
    }

    if (!label) {
      className += ' no-label'
    }

    if (structure.className) {
      className += ` ${structure.className}`
    }

    const output = (
      <section className={className} style={style} key={htmlFor}>
        {label}
        {radios}
      </section>
    )

    return postRender ? postRender(structure, output) : output
  }

}
