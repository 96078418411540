import { Input } from '@kpv-lab/ui'
import React from 'react'

type Props = {
  body: string,
  title: string,
  error: string,
  active: boolean,
  acceptHandler: () => void,
  cancelHandler: () => void,
  updateHandler: (key: 'title'|'body', value: string) => any,
  newThreadMode: () => void,
};

export default function NewThread({ body, title, error, active, acceptHandler, cancelHandler, updateHandler, newThreadMode }: Props) {
  if (!active) {
    return (
      <div className="new-thread-preview">
        <button className="btn btn-primary new-thread-btn" onClick={newThreadMode}>
          Add feedback
        </button>
      </div>
    )
  }

  const buttons = (
    <div className="reply-box-btns">
      <button className="btn btn-primary reply-btn" onClick={acceptHandler}>
        Submit
      </button>
      <button onClick={cancelHandler} className="btn btn-secondary cancel-btn">
        Cancel
      </button>
    </div>
  )

  let errorMsg
  if (error) {
    errorMsg = <div className="error-msg">{error}</div>
  }

  return (
    <div className="new-thread">
      {errorMsg}
      <Input
        structure={{
          type:         'text',
          updateOnBlur: false,
          options:      { placeholder: 'Add a title... (optional)' },
        }}
        value={title}
        focus={true}
        dataKey="title"
        updateHandler={updateHandler}
        inline={false}
      />
      <Input
        structure={{
          type:         'textarea',
          updateOnBlur: false,
          options:      { placeholder: 'Add a comment...' },
        }}
        value={body}
        dataKey="body"
        updateHandler={updateHandler}
        inline={false}
      />
      {buttons}
    </div>
  )
}
