export default {
  8:   'backspace',
  9:   'tab',
  13:  'return',
  16:  'shift',
  17:  'ctrl',
  18:  'alt',
  32:  'space',
  37:  'left',
  38:  'up',
  39:  'right',
  40:  'down',
  46:  'delete',
  91:  'cmd',
  93:  'cmd',
  96:  '0',
  97:  '1',
  98:  '2',
  99:  '3',
  100: '4',
  101: '5',
  102: '6',
  103: '7',
  104: '8',
  105: '9',
  106: '*',
  107: '+',
  109: '-',
  110: '.',
  111: '/',
  186: ';',
  187: '=',
  188: ',',
  189: '-',
  190: '.',
  191: '/',
  192: '`',
  219: '[',
  220: '\\',
  221: ']',
  222: "'",
}
