/* eslint-disable max-depth */
/* eslint-disable complexity */
// https://github.com/rcowin/beemirror/blob/81b271a7f9aa8b0911c780ea8ce9f223e4630781/src/markdown-it/video.js
export function useTag(md) {
  md.inline.ruler.push('tag', tagLink)
}

function tagLink(state, silent) {
  var attrs,
    code,
    label,
    labelEnd,
    labelStart,
    pos,
    res,
    ref,
    title,
    token,
    href = '',
    oldPos = state.pos,
    max = state.posMax,
    start = state.pos,
    parseReference = true

  if (state.src.charCodeAt(state.pos) !== 0x5b /* [ */) {
    return false
  }

  labelStart = state.pos + 1
  labelEnd = state.md.helpers.parseLinkLabel(state, state.pos, true)

  // parser failed to find ']', so it's not a valid link
  if (labelEnd < 0) {
    return false
  }

  pos = labelEnd + 1
  if (pos < max && state.src.charCodeAt(pos) === 0x28 /* ( */) {
    //
    // Inline link
    //

    // might have found a valid shortcut link, disable reference parsing
    parseReference = false

    // [link](  <href>  "title"  )
    //        ^^ skipping these spaces
    pos++
    for (; pos < max; pos++) {
      code = state.src.charCodeAt(pos)
      if (!isSpace(code) && code !== 0x0a) {
        break
      }
    }
    if (pos >= max) {
      return false
    }

    // [link](  <href>  "title"  )
    //          ^^^^^^ parsing link destination
    start = pos
    res = state.md.helpers.parseLinkDestination(state.src, pos, state.posMax)
    if (res.ok) {
      href = state.md.normalizeLink(res.str)
      if (state.md.validateLink(href)) {
        pos = res.pos
      } else {
        href = ''
      }
    }

    // [link](  <href>  "title"  )
    //                ^^ skipping these spaces
    start = pos
    for (; pos < max; pos++) {
      code = state.src.charCodeAt(pos)
      if (!isSpace(code) && code !== 0x0a) {
        break
      }
    }

    // [link](  <href>  "title"  )
    //                  ^^^^^^^ parsing link title
    res = state.md.helpers.parseLinkTitle(state.src, pos, state.posMax)
    if (pos < max && start !== pos && res.ok) {
      title = res.str
      pos = res.pos

      // [link](  <href>  "title"  )
      //                         ^^ skipping these spaces
      for (; pos < max; pos++) {
        code = state.src.charCodeAt(pos)
        if (!isSpace(code) && code !== 0x0a) {
          break
        }
      }
    } else {
      title = ''
    }

    if (pos >= max || state.src.charCodeAt(pos) !== 0x29 /* ) */) {
      // parsing a valid shortcut link failed, fallback to reference
      parseReference = true
    }
    pos++
  }

  if (parseReference) {
    //
    // Link reference
    //
    if (typeof state.env.references === 'undefined') {
      return false
    }

    if (pos < max && state.src.charCodeAt(pos) === 0x5b /* [ */) {
      start = pos + 1
      pos = state.md.helpers.parseLinkLabel(state, pos)
      if (pos >= 0) {
        label = state.src.slice(start, pos++)
      } else {
        pos = labelEnd + 1
      }
    } else {
      pos = labelEnd + 1
    }

    // covers label === '' and label === undefined
    // (collapsed reference link and shortcut reference link respectively)
    if (!label) {
      label = state.src.slice(labelStart, labelEnd)
    }

    ref = state.env.references[normalizeReference(label)]
    if (!ref) {
      state.pos = oldPos
      return false
    }
    href = ref.href
    title = ref.title
  }

  //
  // We found the end of the link, and know for a fact it's a valid link;
  // so all that's left to do is to call tokenizer.
  //
  if (!silent) {
    state.pos = labelStart
    state.posMax = labelEnd

    const linkLabel = state.src.substring(labelStart, labelEnd)

    // Custom logic for parsing different kinds of tags
    const getNodeType = () => {
      if (href.startsWith('tag://')) {
        return 'tag'
      } else if (href.startsWith('info-block')) {
        return 'info'
      } else if (href.startsWith('ref-block')) {
        return 'reference'
      } else if (href.startsWith('itemlink://')) {
        return 'itemlink'
        // We don't want to render an audio player for every link that ends in an audio extension,
        // just the ones with '?' as the link title
      } else if (/^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu\.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/i.test(href) && linkLabel === '?') {
        return 'video'
      } else if (/\.(webm|mp4|mpeg)$/i.test(href)) {
        return 'video'
      } else if (/\.(mp3|mp4a|oga|ogg|wav|mid|midi|flac|aac)$/i.test(href) && linkLabel === '?') {
        return 'audio'
      } else {
        return 'link'
      }
    }

    const nodeType = getNodeType()

    if (nodeType === 'info') {
      // info items are nodes instead of marks so they don't need
      // _open and _close variants
      token = state.push('info', 'a', 0)
      token.attrs = attrs = [['href', href]]
    } else if (nodeType === 'reference') {
      token = state.push('reference', 'a', 0)
      token.attrs = attrs = [['href', href]]
    } else if (nodeType === 'audio') {
      token = state.push('audio', 'a', 0)
      token.attrs = attrs = [['src', href]]
    } else if (nodeType === 'video') {
      token = state.push('video', 'a', 0)
      token.attrs = attrs = [['src', href]]
    } else {
      const open = `${nodeType}_open`
      const close = `${nodeType}_close`

      token = state.push(open, 'a', 1)
      token.attrs = attrs = [['href', href]]

      if (href.startsWith('http')) {
        attrs.push(['target', '_blank'])
      }

      if (title) {
        attrs.push(['title', title])
      }
      state.md.inline.tokenize(state)
      token = state.push(close, 'a', -1)
    }
  }

  state.pos = pos
  state.posMax = max
  return true
}

function isSpace(code) {
  switch (code) {

    case 0x09:
    case 0x20:
      return true

  }
  return false
}

function normalizeReference(str) {
  return str
    .trim()
    .replace(/\s+/g, ' ')
    .toUpperCase()
}
