import { Flag, setFlagsAction, useFlags } from '@cls/flags'
import React, { SyntheticEvent } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { viewAccount } from '../state/actions-account'

function startCase(input: string) {
  const split = input.replace(/([A-Z])/g, ' $1')
  return split.charAt(0).toUpperCase() + split.slice(1)
}

interface Props {
  dispatch: Dispatch,
}

const FeatureFlagsComponent: React.FC<Props> = ({ dispatch }) => {
  const flags = useFlags()

  const cancelHandler = (event: SyntheticEvent) => {
    if (event.target === event.currentTarget) {
      event.preventDefault()
      event.stopPropagation()
      dispatch(viewAccount(false))
    }
  }


  return (
    <div className="overlay" onClick={cancelHandler}>
      <div className="feature-flags">
        <h1 className="account-title">Hidden Features</h1>
        <p>Messing with these may cause data loss. <br /> Do so at your own risk</p>
        <div className="flags-container">
          {
            Object.keys(flags.features).map(flagName => {
              const readableName = startCase(flagName)

              return (
                <div className="flag-row" key={flagName}>
                  <input
                    type="checkbox"
                    name={flagName}
                    checked={flags.features[flagName]}
                    onChange={() => {
                      const newFlags = Object.assign({}, flags.features)
                      newFlags[flagName] = !flags.features[flagName]
                      dispatch(setFlagsAction({ features: newFlags }))
                    }}
                  />
                  <label htmlFor={flagName}>{readableName}</label>
                </div>
              )
            })
          }
        </div>
        <div>
          <Flag
            name={['features', 'showFlagsAreWorking']}
            render={() => 'The flag is active! Woop woop!'}
            fallbackRender={() => 'The flag is not active'}
          />
        </div>
      </div>
    </div>
  )
}

const FeatureFlags = connect()(FeatureFlagsComponent)
export { FeatureFlags }
