import { ActionHandler, createReducer } from '@cls/redux'
import { Feedback } from 'cl-studio'

import * as types from './action-types'
import { FeedbackActions, HideFeedbackPopup, NewFeedbackFailure, NewFeedbackSuccess, ShowFeedbackPopup } from './actions'

interface OwnState {
  feedback: Array<Feedback>,
  title: string,
  id: number,
  visible: boolean,
  status?: string,
  error?: string,
}

export const initialState: OwnState = {
  feedback: [],
  title:    '',
  id:       -1,
  visible:  false,
  status:   undefined,
  error:    undefined,
}

const showPopupHandler: ActionHandler<OwnState, ShowFeedbackPopup> = (draftState, action) => {
  const { id, title } = action.data

  draftState.visible = true
  draftState.id = id ? id : draftState.id
  draftState.title = title ? title : draftState.title
}

const hidePopupHandler: ActionHandler<OwnState, HideFeedbackPopup> = () => initialState

const newFeedbackSuccessHandler: ActionHandler<OwnState, NewFeedbackSuccess> = (draftState, action) => {
  const { feedback } = draftState
  const newFeedback = [...feedback, action.data]

  draftState.status = ''
  draftState.feedback = newFeedback
}

const newFeedbackFailureHandler: ActionHandler<OwnState, NewFeedbackFailure> = (draftState, action) => {
  draftState.error = action.data
  draftState.status = ''
}

type FeedbackActionNames = Pick<FeedbackActions, 'type'>
const actionHandlers = new Map<FeedbackActionNames['type'], ActionHandler<OwnState, any>>([
  [types.SHOW_FEEDBACK_POPUP, showPopupHandler],
  [types.HIDE_FEEDBACK_POPUP, hidePopupHandler],
  [types.NEW_FEEDBACK_SUCCESS, newFeedbackSuccessHandler],
  [types.NEW_FEEDBACK_FAILURE, newFeedbackFailureHandler],
])

export function reducer() {
  return createReducer(initialState, actionHandlers)
}

