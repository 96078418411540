export function checkHttpStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else {
    const error = new Error(response.statusText)

    // @ts-ignore: Might as well include whatever response we get
    error.response = response
    throw error
  }
}

export function parseJSON(response: Response) {
  return response.json()
}
