/* global VERSION */
import { gql, useQuery } from '@apollo/client'
import { RootState } from '@cls/redux'
import AppLoading from '@cls/spinner'
import { DefaultExplorerQueryQuery, Explorer } from 'cl-studio'
import React, { Suspense, useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import {
  setExplorer as setExplorerAction,
  setQuery as setQueryAction,
  toggleMenu as toggleMenuAction
} from '../../state/menu-redux'
import useDebounce from './custom_hooks/UseDebounce'
import ResultBox from './result_box/ResultBox'
import Toolbar from './Toolbar'

const ReleaseNotes = React.lazy(() =>
  import(/* webpackChunkName: "releasenotes" */ '../ReleaseNotes')
)

const DEFAULT_EXPLORER_QUERY = gql`
  query DefaultExplorerQuery {
    explorers {
      id
      name
    }
  }
`

interface OwnProps {
  location: any,
  hidden: boolean,
}

const mapStateToProps = (state: RootState) => {
  return {
    query:    state.menu.query,
    explorer: state.menu.explorer,
  }
}

const connector = connect(mapStateToProps,
  {
    setQuery:    setQueryAction,
    setExplorer: setExplorerAction,
    toggleMenu:  toggleMenuAction,
  }
)

type TypesFromRedux = ConnectedProps<typeof connector>
type AllProps = TypesFromRedux & OwnProps

function Menu({ toggleMenu, location, hidden, query, setQuery, setExplorer, explorer }: AllProps) {
  const { data } = useQuery<DefaultExplorerQueryQuery>(DEFAULT_EXPLORER_QUERY)
  const [defaultExplorer, setDefaultExplorer] = useState<Explorer|undefined>(undefined)
  const debouncedQuery = useDebounce(query, 500)
  const [showingReleaseNotes, setShowingReleaseNotes] = useState(false)

  useEffect(() => {
    if (data?.explorers) {
      const retrievedDefaultExplorer = data.explorers.find((e) => e.name === 'Literature Explorer') || data.explorers[0]
      setDefaultExplorer(retrievedDefaultExplorer as Explorer)
    }
  }, [data])

  useEffect(() => {
    if (!explorer && defaultExplorer) {
      setExplorer(defaultExplorer)
    }
  }, [defaultExplorer, explorer])

  if (!explorer) {
    return null
  }

  const closeIfOverlayClicked = (event: React.MouseEvent) => {
    if (event.target === event.currentTarget) {
      toggleMenu()
    }
  }

  const hideReleaseNotesHandler = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      setShowingReleaseNotes(false)
    }
  }

  if (hidden) {
    return null
  }

  return (
    <div className="menu" onClick={closeIfOverlayClicked}>
      {/* @ts-ignore Claims to be missing props that are provided by a HOC */}
      <Toolbar
        toggleToolbar={toggleMenu}
        query={query}
        setQuery={setQuery}
        location={location}
        explorer={explorer}
        setExplorer={setExplorer}
      />
      <span className="current-version" onClick={() => setShowingReleaseNotes(true)}>
        {/* @ts-expect-error Version is added in by webpack */}
        {'v' + VERSION}
      </span>
      {showingReleaseNotes && (
        <div className="overlay" onClick={hideReleaseNotesHandler}>
          <Suspense fallback={<AppLoading />}>
            <ReleaseNotes />
          </Suspense>
        </div>
      )}
      <ResultBox
        toggleToolbar={toggleMenu}
        query={debouncedQuery}
        explorerId={Number(explorer.id)}
      />
    </div>
  )
}

export default connector(Menu)
