import { gql } from '@apollo/client'
import { ChildDataProps, graphql } from '@apollo/client/react/hoc'
import { Explorer } from 'cl-studio'
import React from 'react'

const EXPLORER_QUERY = gql`
  query ExplorerSelectQuery {
    explorers {
      id
      name
      templates {
        id
        title
      }
    }
  }
`

type Response = {
  explorers: Array<Explorer>,
}

type InputProps = {
  explorer?: Explorer,
  changeHandler: (explorer: Explorer) => void,
}

type ChildProps = ChildDataProps<InputProps, Response>

const withExplorers = graphql<InputProps, Response, unknown, ChildProps>(EXPLORER_QUERY)

const ExplorerSelect = withExplorers(({ data, explorer, changeHandler }) => {
  if (data.error) {
    console.error(data.error)
    return <div>Error</div>
  }
  if (data.loading) {
    return <div>Loading</div>
  }

  const explorers = data.explorers as Array<Explorer>
  const options = [...explorers]
  options.sort((a, b) => a.name.localeCompare(b.name))

  const selectedExplorer = explorer ? explorer : options[0]
  if (!explorer) {
    // Since we weren't given one, we should inform our parent as to the choice we've made
    changeHandler(selectedExplorer)
  }
  const explorerId = selectedExplorer.id

  let selectedLabel = 'None'
  const _options = options.map((option, idx) => {
    if (option.id === explorerId) {
      selectedLabel = option.name
    }

    return (
      <option value={option.id} key={'exp-' + idx} disabled={option.id === explorerId}>
        {option.name}
      </option>
    )
  })

  return (
    <div className="ui-select explorer-select">
      <select
        onChange={(e): void => {
          const explorerToUse = explorers.find(exp => exp.id === Number(e.target.value))
          if (explorerToUse) {
            changeHandler(explorerToUse)
          }
        }}
        id="template-explorer"
        data-cy="explorer-select"
        value={explorerId}
      >
        <optgroup label="Explorers">{_options}</optgroup>
      </select>
      <label htmlFor="template-explorer" className="ui-label">
        <strong>{selectedLabel}</strong>
      </label>
    </div>
  )
})

export { ExplorerSelect }
