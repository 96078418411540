export function transformItemVersion(obj) {
  const v = obj.body

  const item = Object.assign({}, v.body)
  item._children = v.children
  item._id = obj.type_id
  item._title = v.title
  item._parentTitle = v.parent_title
  item._parentId = v.parent_id
  item._userId = v.user_id
  item._priority = v.priority
  item._relative = v.relative
  item._range = v.range
  item._private = v.private
  item._start = v.start_point
  item._end = v.end_point
  item._published = v.published
  item._tplId = v.template_id
  item._updatedAt = v.updated_at || obj.created_at
  item._userId = obj.user_id
  item._userName = obj.name
  item.versionId = obj.id

  return item
}

