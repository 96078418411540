import { dateToString } from '@kpv-lab/time-utils'
import dateformat from 'dateformat'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { CURRENT_TIME } from '../../../../../../config/constants'
import { setEditingVersionId as setEditingVersionIdAction } from '../../../../../content/state/editor-redux'
import { setOverviewItems as setOverviewItemsAction, setParentId as setParentIdAction } from '../../../../state/menu-redux'

export const endDateToString = (endPoint: any) => {
  if (!endPoint) {
    return ''
  }

  if (endPoint === CURRENT_TIME) {
    return ' - present'
  }

  return ' - ' + dateToString(endPoint)
}

interface ItemsListProps {
  title: string,
  items: Array<any>,
  toggleToolbar: (...args: Array<any>) => any,
  setParentId: (...args: Array<any>) => any,
  setOverviewItems: (...args: Array<any>) => any,
  showType: boolean,
  setEditingVersionId: (...args: Array<any>) => any,
}

function ItemsList({ title, items, toggleToolbar, setParentId, setOverviewItems, setEditingVersionId, showType }: ItemsListProps) {
  if (items.length === 0) {
    return (
      <div className="items-box" data-cy={title.toLowerCase().replace(/ /, '-')}>
        <div className="items-box-title">
          <h2>{title}</h2>
        </div>
        <div className="items-list" data-cy="items-list">
          <div className="list-item">None found</div>
        </div>
      </div>
    )
  }

  const templateId = items[0].templateId

  const itemClick = () => {
    toggleToolbar()
    setEditingVersionId(null)
  }

  return (
    <div className="items-box" data-cy={title.toLowerCase().replace(/ /, '-')}>
      <div className="items-box-title">
        <h2>{title}</h2>
        {!showType && (
          <Link
            to={`/overview/${templateId}`}
            className={'toolbar-btn'}
            onClick={() => {
              setOverviewItems(items.map(i => i.id))
              toggleToolbar()
            }}
          >
            <i className="material-icons md-18">language</i>
          </Link>
        )}
      </div>
      <div className="items-list" data-cy="items-list">
        {items.map(item => {
          return (
            <div className="list-item" key={item.id}>
              <div>
                <div>
                  <Link
                    className="list-item-link"
                    onClick={itemClick}
                    to={`/content/${item.templateId}/${item.id}`}
                  >
                    {item.title}
                  </Link>
                </div>
                <div className="list-item-time">
                  {dateformat(item.updatedAt, 'dd/mm/yy, HH:MM')}
                </div>
              </div>
              <div>
                <div className="list-item-date">
                  {dateToString(item.startPoint)}
                  {endDateToString(item.endPoint)}
                </div>
                {showType && (
                  <div className="list-item-type">{item.template ? item.template.title : ''}</div>
                )}
                {!item.template.parentId && !showType ? (
                  <div className="list-item-subitems-link" onClick={() => setParentId(item.id)}>
                    <i className="material-icons md-18">subdirectory_arrow_right</i>
                  </div>
                ) : null}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default connect(
  null,
  { setParentId: setParentIdAction, setOverviewItems: setOverviewItemsAction, setEditingVersionId: setEditingVersionIdAction }
)(ItemsList)
