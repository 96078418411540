import React, { PureComponent } from 'react'

import Divider from './Divider'
import Input from './Input'
import Slider from './Slider'

type Props = {
  dispatch: (...args: Array<any>) => any,
  customStyling: (...args: Array<any>) => any,
  updateHandler: (...args: Array<any>) => any,
  interactionHandler: (...args: Array<any>) => any,
  keyframeEnabled: Array<boolean> | boolean,
  id: string,
  type: string,
  value: any | number,
  structure: any,
};

export default class NumberControl extends PureComponent<Props> {

  static dividerSupport = true

  render() {
    const {
      dispatch,
      id,
      type,
      value,
      structure: opts,
      customStyling,
      keyframeEnabled,
      updateHandler,
      interactionHandler,
    } = this.props

    const controls: Array<JSX.Element> = []

    if (type === 'float' || type === 'int') {
      // single component slider
      if (opts.divider === -1 || opts.divider === 2) {
        controls.push(<Divider key={`${id}-bd`} />)
      }

      const props = {
        id:                 id,
        key:                id,
        value:              value,
        min:                opts.min,
        absMin:             opts.absMin,
        max:                opts.max,
        absMax:             opts.absMax,
        step:               opts.step,
        label:              opts.label,
        style:              opts.style,
        info:               opts.info,
        keyControl:         opts.keyControl,
        vernier:            opts.vernier,
        unlimited:          opts.unlimited,
        gradient:           opts.gradient,
        exact:              opts.exact,
        unit:               opts.unit,
        keyframeActive:     opts.keyframeActive,
        keyframeEnabled:    keyframeEnabled,
        keyframeCurrent:    opts.keyframeCurrent,
        defer:              opts.constant || opts.defer,
        customStyling:      customStyling,
        updateHandler:      updateHandler,
        interactionHandler: interactionHandler,
        dispatch:           dispatch,
      }

      if ((Number.isFinite(opts.min) && Number.isFinite(opts.max)) || opts.gradient) {
        // @ts-ignore: It doesn't like some of the props, but historically seems to work
        controls.push(<Slider tick={opts.tick} wrap={opts.wrap} loop={opts.loop} {...props} />)
      } else {
        // @ts-ignore: It doesn't like some of the props, but historically seems to work
        controls.push(<Input {...props} />)
      }

      if (opts.divider > 0) {
        controls.push(<Divider key={`${id}-ad`} />)
      }
    } else {
      // multi-part slider
      const n = type.includes('2') ? 2 : type.includes('3') || type === 'rotation' ? 3 : 4
      let i = 0

      while (i < n) {
        const key = `${id}.${i}`
        const divider = Array.isArray(opts.divider) ? opts.divider[i] : opts.divider

        if (divider === -1 || divider === 2) {
          controls.push(<Divider key={`${key}-bd`} />)
        }

        const defer =
          (Array.isArray(opts.defer) ? opts.defer[i] : opts.defer) ||
          (Array.isArray(opts.constant) ? opts.constant[i] : opts.constant)

        const props: any = {
          id:             key,
          key:            key,
          min:            Array.isArray(opts.min) ? opts.min[i] : opts.min,
          absMin:         Array.isArray(opts.absMin) ? opts.absMin[i] : opts.absMin,
          max:            Array.isArray(opts.max) ? opts.max[i] : opts.max,
          absMax:         Array.isArray(opts.absMax) ? opts.absMax[i] : opts.absMax,
          step:           Array.isArray(opts.step) ? opts.step[i] : opts.step,
          label:          Array.isArray(opts.label) ? opts.label[i] : opts.label,
          style:          Array.isArray(opts.style) ? opts.style[i] : opts.style,
          info:           Array.isArray(opts.info) ? opts.info[i] : opts.info,
          exact:          Array.isArray(opts.exact) ? opts.exact[i] : opts.exact,
          vernier:        Array.isArray(opts.vernier) ? opts.vernier[i] : opts.vernier,
          keyControl:     Array.isArray(opts.keyControl) ? opts.keyControl[i] : opts.keyControl,
          unlimited:      Array.isArray(opts.unlimited) ? opts.unlimited[i] : opts.unlimited,
          unit:           Array.isArray(opts.unit) ? opts.unit[i] : opts.unit,
          keyframeActive: Array.isArray(opts.keyframeActive)
            ? opts.keyframeActive[i]
            : opts.keyframeActive,
          keyframeEnabled: Array.isArray(keyframeEnabled) ? keyframeEnabled[i] : keyframeEnabled,
          keyframeCurrent: Array.isArray(opts.keyframeCurrent)
            ? opts.keyframeCurrent[i]
            : opts.keyframeCurrent,
          defer:              defer,
          customStyling:      customStyling,
          updateHandler:      updateHandler,
          interactionHandler: interactionHandler,
        }

        if (Number.isFinite(value)) {
          props.value = value
        } else if (typeof value === 'object') {
          props.value = opts.value[i]
        } else {
          props.value = 0
        }

        if (Number.isFinite(props.min) && Number.isFinite(props.max)) {
          controls.push(
            <Slider
              tick={Array.isArray(opts.tick) ? opts.tick[i] : opts.tick}
              wrap={Array.isArray(opts.wrap) ? opts.wrap[i] : opts.wrap}
              loop={Array.isArray(opts.loop) ? opts.loop[i] : opts.loop}
              vernier={Array.isArray(opts.vernier) ? opts.vernier[i] : opts.vernier}
              {...props}
            />
          )
        } else {
          controls.push(<Input {...props} />)
        }

        if (divider > 0) {
          controls.push(<Divider key={`${key}-ad`} />)
        }
        i++
      }
    }

    return <section className="ui-i ui-group">{controls}</section>
  }
  /* eslint-enable complexity */

}
