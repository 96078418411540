export const OPEN_CHAT_WINDOW = 'messaging/OPEN_CHAT_WINDOW'
export const SET_CHAT_WINDOW_SIZE = 'messaging/SET_CHAT_WINDOW_SIZE'
export const CLOSE_CHAT_WINDOW = 'messaging/CLOSE_CHAT_WINDOW'
export const CLOSE_CHAT = 'messaging/CLOSE_CHAT'
export const SET_ACTIVE_CHAT = 'messaging/SET_ACTIVE_CHAT'
export const UNREAD_MESSAGE = 'messaging/UNREAD_MESSAGE'

interface UnreadMessage {
  type: typeof UNREAD_MESSAGE,
  data: {
    toUserId: number,
    username: string,
  },
}

interface SetActiveChat {
  type: typeof SET_ACTIVE_CHAT,
  data: {
    toUserId: number,
  },
}

interface CloseChatWindow {
  type: typeof CLOSE_CHAT_WINDOW,
}

interface OpenChatWindow {
  type: typeof OPEN_CHAT_WINDOW,
  data: {
    toUserId: number,
    username: string,
  },
}

interface CloseChat{
  type: typeof CLOSE_CHAT,
  data: {
    toUserId: number,
  },
}

interface SetChatWindowSize {
  type: typeof SET_CHAT_WINDOW_SIZE,
  data: {
    height: number,
    width: number,
  },
}

export type MessagingActions = UnreadMessage
| SetActiveChat
| OpenChatWindow
| CloseChatWindow
| CloseChat
| SetChatWindowSize

export function unreadMessage(toUserId: number, username: string): MessagingActions {
  return {
    type: UNREAD_MESSAGE,
    data: { toUserId, username },
  }
}

export function setActiveChat(toUserId: number): MessagingActions {
  return {
    type: SET_ACTIVE_CHAT,
    data: { toUserId },
  }
}

export function closeChatWindow(): MessagingActions {
  return {
    type: CLOSE_CHAT_WINDOW,
  }
}

export function closeChat(toUserId: number): MessagingActions {
  return {
    type: CLOSE_CHAT,
    data: {
      toUserId,
    },
  }
}

export function setChatWindowSize(width: number, height: number): MessagingActions {
  return {
    type: SET_CHAT_WINDOW_SIZE,
    data: {
      height,
      width,
    },
  }
}

export function openChatWindow(toUserId: number, username: string): MessagingActions {
  return {
    type: OPEN_CHAT_WINDOW,
    data: { toUserId, username },
  }
}
