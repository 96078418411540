import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

export default class ScrollBar extends PureComponent {

  static propTypes = {
    scrollTo:    PropTypes.func,
    snapTo:      PropTypes.func,
    className:   PropTypes.string,
    orientation: PropTypes.string,
    offset:      PropTypes.number,
    range:       PropTypes.number,
    length:      PropTypes.number,
    labels:      PropTypes.array,
  }

  _startPos = 0
  _trackLength = 100
  _interationType = 'mouse'

  componentDidMount() {
    this.setTrackLength()
    this.forceUpdate()
  }

  componentWillUnmount() {
    this.removeEvents()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.range !== this.props.range) {
      this.setTrackLength()
    }
  }

  setTrackLength() {
    const r = this.refTrack.getBoundingClientRect()
    this._trackLength = this.props.orientation === 'vertical' ? r.height : r.width
  }

  interactionStartHandler = event => {
    const { offset, range, scrollTo, orientation } = this.props

    if (event.target.classList.contains('label-anchor')) {
      return
    }

    event.stopPropagation()
    event.preventDefault()
    let evt
    if (event.type === 'touchstart') {
      evt = event.touches[0]
      this._interationType = 'touch'
    } else {
      evt = event
      this._interationType = 'mouse'
    }

    const r = this.refTrack.getBoundingClientRect()

    let p0, min, max
    if (orientation === 'vertical') {
      p0 = evt.clientY
      this._trackLength = r.height
      min = r.top
      max = r.bottom
    } else {
      p0 = evt.clientX
      this._trackLength = r.width
      min = r.left
      max = r.right
    }

    this._startPos = p0

    const onHandle = event.target.classList.contains('scroll-bar-handle')
    const hl = Math.floor(Math.max(range * this._trackLength, 20)) // handle length
    const p = min + (this._trackLength - hl) * offset

    if (!onHandle && p0 < min + hl * 0.5) {
      // back to very start
      scrollTo(0, { duration: 500 })
      return
    }

    if (!onHandle && p0 > max - hl * 0.5) {
      // down to very end
      scrollTo(1, { duration: 500 })
      return
    }

    if (p0 >= min && p0 < p) {
      // tapped before the scroll handle, move a page earlier
      scrollTo(-0.8, { page: true, duration: 500, direction: 1 })
      return
    }

    if (p0 > p + hl && p0 <= max) {
      // tapped after the scroll handle, move a page later
      scrollTo(0.8, { page: true, duration: 500, direction: -1 })
      return
    }

    if (this._interationType === 'mouse') {
      document.body.addEventListener('mousemove', this.interactionUpdateHandler)
      document.body.addEventListener('mouseup', this.interactionEndHandler)
      document.body.addEventListener('mouseleave', this.interactionEndHandler)
    } else {
      document.body.addEventListener('touchmove', this.interactionUpdateHandler)
      document.body.addEventListener('touchend', this.interactionEndHandler)
      document.body.addEventListener('touchcancel', this.interactionEndHandler)
      document.body.addEventListener('touchleave', this.interactionEndHandler)
    }
  }

  interactionUpdateHandler = event => {
    const { offset, range, scrollTo, orientation } = this.props
    event.stopPropagation()
    event.preventDefault()

    const evt = event.touches ? event.touches[0] : event
    const k = orientation === 'vertical' ? 'clientY' : 'clientX'
    const dp = evt[k] - this._startPos
    const pp = (1 - range) * this._trackLength

    if (scrollTo && pp && dp) {
      const p = Math.min(Math.max(offset + dp / pp, 0), 1)
      scrollTo(p)
    }
    this._startPos = evt[k]
  }

  interactionEndHandler = () => {
    const { snapTo } = this.props
    this.removeEvents()
    snapTo && snapTo()
  }

  removeEvents() {
    if (this._interationType === 'mouse') {
      document.body.removeEventListener('mousemove', this.interactionUpdateHandler)
      document.body.removeEventListener('mouseup', this.interactionEndHandler)
      document.body.removeEventListener('mouseleave', this.interactionEndHandler)
    } else {
      document.body.removeEventListener('touchmove', this.interactionUpdateHandler)
      document.body.removeEventListener('touchend', this.interactionEndHandler)
      document.body.removeEventListener('touchcancel', this.interactionEndHandler)
      document.body.removeEventListener('touchleave', this.interactionEndHandler)
    }
  }

  anchorHandler = event => {
    const { scrollTo } = this.props

    event.preventDefault()
    event.stopPropagation()
    const offset = -parseInt(event.target.dataset.offset)
    scrollTo(offset, { duration: 500 })
  }

  setRefTrack = el => (this.refTrack = el)

  render() {
    const { className, offset, range, labels, length, orientation } = this.props
    // console.log('render scrollbar:', range, offset, length);

    let labelAnchors
    if (labels) {
      const anchors = []
      let p0 = -100
      labels.forEach((l, idx) => {
        const p = l.offset / length * this._trackLength
        if (p - p0 > 10) {
          const anchorClassName = `label-anchor ${l.active ? 'active' : ''}`
          const style = orientation === 'vertical' ? { top: p } : { left: p }

          anchors.push(
            <span
              data-offset={l.offset}
              className={anchorClassName}
              key={`lbl-${idx || 'none'}`}
              style={style}
              onClick={this.anchorHandler}
            >
              {l.label}
            </span>
          )
          p0 = p
        }
      })

      labelAnchors = (
        <div className="scroll-bar-labels">
          {anchors}
        </div>
      )
    }

    const d = Math.floor(Math.max(range * this._trackLength, 20))
    const p = (this._trackLength - d) * offset
    // console.log('handle:', range, this._trackLength, range * this._trackLength);

    let trackStyles
    if (orientation === 'vertical') {
      trackStyles = {
        height: `${d}px`,
        top:    `${p}px`,
      }
    } else {
      trackStyles = {
        width: `${d}px`,
        left:  `${p}px`,
      }
    }

    return (
      <div
        className={`scroll-bar ${className || ''}`}
        onMouseDown={this.interactionStartHandler}
        onTouchStart={this.interactionStartHandler}
      >
        {labelAnchors}
        <div className="scroll-bar-track" ref={this.setRefTrack}>
          <div className="scroll-bar-handle" style={trackStyles} />
        </div>
      </div>
    )
  }

}
