import { RootState } from '@cls/redux'
import AppLoading from '@cls/spinner'
import React, { PureComponent, Suspense } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { flashMessage as flashMessageAction } from '../flash/state/flash-redux'
import { getRoadRoute, prepareLng } from './lib/route-helpers'
import { hideMapEventsEditor as hideMapEventsEditorAction } from './state/actions'

const Map = React.lazy(() => import(/* webpackChunkName: "maps" */ './components/Map'))

type OwnMapEventsEditorProps = {
  getRoadRoute?: (...args: Array<any>) => any,
};

type MapEventsEditorState = any;

type MapEventsEditorProps = OwnMapEventsEditorProps & typeof MapEventsEditor.defaultProps;

const mapStateToProps = ({
  mapEventsEditor,
}: RootState) => ({
  visible:       mapEventsEditor.visible,
  updateHandler: mapEventsEditor.updateHandler,
  features:      mapEventsEditor.features,
  prevFeatures:  mapEventsEditor.prevFeatures,
  start:         mapEventsEditor.start,
  end:           mapEventsEditor.end,
})

const connector = connect(mapStateToProps, {
  flashMessage:        flashMessageAction,
  hideMapEventsEditor: hideMapEventsEditorAction,
})

type TypesFromRedux = ConnectedProps<typeof connector>

type AllProps = MapEventsEditorProps & TypesFromRedux

export class MapEventsEditor extends PureComponent<AllProps, MapEventsEditorState> {

  static defaultProps = {
    getRoadRoute,
  }

  constructor(props: AllProps) {
    super(props)
    this.state = this.setupState()
  }

  componentDidUpdate(prevProps: AllProps) {
    const { visible } = this.props
    if (!prevProps.visible && visible) {
      this.setState(this.setupState())
    }
  }

  setupState = () => {
    const features = this.props.features
    const prevFeatures = this.props.prevFeatures

    return { features, prevFeatures }
  }

  closeHandler = () => {
    const { updateHandler, hideMapEventsEditor } = this.props
    const { features } = this.state
    updateHandler({ features })
    hideMapEventsEditor()
    this.setState({ features: [] })
  }

  createWaypoint = ({
    idx,
    coordinates,
    properties = {},
  }: any) => {
    this.setState(({
      features,
    }: any) => {
      const feature = features[idx]
      if (feature.properties.type !== 'route') {
        return
      }
      const [backwardLng] = features[idx - 1].geometry.coordinates
      const [forwardLng] = features[idx + 1].geometry.coordinates
      coordinates[0] = prepareLng(coordinates[0], backwardLng, forwardLng)

      const routeCoords = feature.geometry.coordinates
      const firstPoint = routeCoords[0]
      const lastPoint = routeCoords[routeCoords.length - 1]
      const backwardRoute = {
        geometry: {
          type:        'LineString',
          coordinates: [firstPoint, coordinates],
        },
        properties: { type: 'route' },
      }
      const newWaypoint = {
        geometry: {
          type: 'Point',
          coordinates,
        },
        properties: {
          type: 'waypoint',
          ...properties,
        },
      }
      const forwardRoute = {
        geometry: {
          type:        'LineString',
          coordinates: [coordinates, lastPoint],
        },
        properties: { type: 'route' },
      }
      const newFeatures = [
        ...features.slice(0, idx),
        backwardRoute,
        newWaypoint,
        forwardRoute,
        ...features.slice(idx + 1),
      ]
      return { features: newFeatures }
    })
  }

  updateWaypoint = ({
    idx,
    coordinates,
    properties,
  }: any) => {
    if (coordinates) {
      this.updateWaypointCoordinates({ idx, coordinates })
    } else if (properties) {
      this.updateWaypointMetadata({ idx, properties })
    }
  }

  updateWaypointCoordinates = ({
    idx,
    coordinates,
  }: any) => {
    this.setState(({
      features,
    }: any) => {
      const feature = features[idx]
      if (feature.properties.type !== 'waypoint') {
        return
      }
      const [backwardLng] = features[idx - 2].geometry.coordinates
      const [forwardLng] = features[idx + 2].geometry.coordinates
      coordinates[0] = prepareLng(coordinates[0], backwardLng, forwardLng)

      const featureCoords = coordinates || feature.geometry.coordinates
      const prevFeature = features[idx - 1]
      const prevFeatureCoords = prevFeature.geometry.coordinates[0]
      const backwardRoute = {
        ...prevFeature,
        geometry: {
          ...prevFeature.geometry,
          coordinates: [prevFeatureCoords, featureCoords],
        },
      }
      const newWaypoint = {
        ...feature,
        geometry: {
          ...feature.geometry,
          coordinates,
        },
      }
      const nextFeature = features[idx + 1]
      const nextCoords = nextFeature.geometry.coordinates
      const nextFeatureCoords = nextCoords[nextCoords.length - 1]
      const forwardRoute = {
        ...nextFeature,
        geometry: {
          ...nextFeature.geometry,
          coordinates: [featureCoords, nextFeatureCoords],
        },
      }
      const newFeatures = [
        ...features.slice(0, idx - 1),
        backwardRoute,
        newWaypoint,
        forwardRoute,
        ...features.slice(idx + 2),
      ]
      return { features: newFeatures }
    })
  }

  updateWaypointMetadata = ({
    idx,
    properties,
  }: any) => {
    this.setState(({
      features,
    }: any) => {
      const feature = features[idx]
      if (feature.properties.type !== 'waypoint') {
        return
      }
      const newWaypoint = {
        ...feature,
        properties: {
          ...feature.properties,
          ...properties,
        },
      }
      const newFeatures = [...features.slice(0, idx), newWaypoint, ...features.slice(idx + 1)]
      return { features: newFeatures }
    })
  }

  removeWaypointMetadata = ({
    idx,
  }: any) => {
    this.setState(({
      features,
    }: any) => {
      const feature = features[idx]
      if (feature.properties.type !== 'waypoint') {
        return
      }
      const { type } = feature.properties
      const newWaypoint = {
        ...feature,
        properties: { type },
      }
      const newFeatures = [...features.slice(0, idx), newWaypoint, ...features.slice(idx + 1)]
      return { features: newFeatures }
    })
  }

  destroyWaypoint = ({
    idx,
  }: any) => {
    this.setState(({
      features,
    }: any) => {
      const feature = features[idx]
      if (feature.properties.type !== 'waypoint') {
        return
      }
      const prevFeature = features[idx - 1]
      const nextFeature = features[idx + 1]
      const lastCoordIdx = nextFeature.geometry.coordinates.length - 1
      const coordinates = [
        prevFeature.geometry.coordinates[0],
        nextFeature.geometry.coordinates[lastCoordIdx],
      ]
      const newRoute = {
        properties: { type: 'route' },
        geometry:   { type: 'LineString', coordinates },
      }
      const newFeatures = [...features.slice(0, idx - 1), newRoute, ...features.slice(idx + 2)]
      return { features: newFeatures }
    })
  }

  updateRoute = ({
    idx,
  }: any) => {
    // We use an injected prop here as a means of mocking an import
    // eslint-disable-next-line no-shadow
    const { flashMessage, getRoadRoute } = this.props // NOSONAR
    const { features } = this.state
    const feature = features[idx]
    if (feature.properties.type !== 'route') {
      return
    }
    const coords = feature.geometry.coordinates

    if (coords.length > 2) {
      const newRoute = {
        ...feature,
        geometry: {
          ...feature.geometry,
          coordinates: [coords[0], coords[coords.length - 1]],
        },
      }
      const newFeatures = [...features.slice(0, idx), newRoute, ...features.slice(idx + 1)]
      this.setState({ features: newFeatures })
      return
    }
    getRoadRoute(coords)
      .then((roadRoute = []) => {
        const newRoute = {
          ...feature,
          geometry: {
            ...feature.geometry,
            coordinates: [coords[0], ...roadRoute, coords[coords.length - 1]],
          },
        }
        const newFeatures = [...features.slice(0, idx), newRoute, ...features.slice(idx + 1)]
        this.setState({ features: newFeatures })
      })
      .catch(() => {
        flashMessage('Could not retrieve directions for that journey')
      })
  }

  render() {
    const { visible, start, end } = this.props
    const { features, prevFeatures } = this.state
    if (!visible) {
      return null
    }
    return (
      <div className="overlay">
        <div className="map-editor">
          <button onClick={this.closeHandler} className="map-close-btn" />
          {!!features.length && (
            <Suspense fallback={<AppLoading />}>
              <Map
                features={features}
                prevFeatures={prevFeatures}
                start={start}
                end={end}
                createWaypoint={this.createWaypoint}
                updateWaypoint={this.updateWaypoint}
                removeWaypointMetadata={this.removeWaypointMetadata}
                destroyWaypoint={this.destroyWaypoint}
                updateRoute={this.updateRoute}
              />
            </Suspense>
          )}
        </div>
      </div>
    )
  }

}

export default connector(MapEventsEditor)
