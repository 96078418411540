import EventEmitter from 'eventemitter3'

import keyCodes from './key-codes'

function KeyController() {
  const emitter = new EventEmitter()
  let keys: any = {}

  init()

  function init() {
    release()
    window.document.addEventListener('keydown', keyDown, false)
    window.document.addEventListener('keyup', keyUp, false)
  }

  function keyDown(event: KeyboardEvent) {
    if (event.target instanceof Element) {
      if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
        return
      }

      if (keyState(event, true)) {
        emitter.emit('keydown', keys)
      }

    }
  }

  function keyUp(event: KeyboardEvent) {
    if (event.target instanceof Element) {
      if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
        return
      }

      if (keyState(event, false)) {
        emitter.emit('keyup', keys)
      }
    }
  }

  function keyState(event: KeyboardEvent, down: boolean) {
    const code = fromCharCode(event.which)
    let changed = false
    if (
      keys[code] !== down ||
      keys.shift !== event.shiftKey ||
      keys.alt !== event.altKey ||
      keys.ctrl !== event.ctrlKey ||
      keys.meta !== event.metaKey
    ) {
      changed = true
      keys[code] = down
      keys.shift = event.shiftKey
      keys.alt = event.altKey
      keys.ctrl = event.ctrlKey
      keys.meta = event.metaKey
    }
    keys.event = event
    return changed
  }

  function fromCharCode(n: number) {
    return keyCodes[n] || String.fromCharCode(n).toLowerCase()
  }

  function reset() {
    keys = {}
  }

  function on(event, callback) {
    return emitter.on(event, callback)
  }

  function once(event, callback) {
    return emitter.once(event, callback)
  }

  function off(event, callback) {
    if (callback) {
      emitter.removeListener(event, callback)
    } else {
      emitter.removeAllListeners(event)
    }
  }

  function release() {
    window.document.removeEventListener('keydown', keyDown)
    window.document.removeEventListener('keyup', keyUp)
    emitter.removeAllListeners('keydown')
    emitter.removeAllListeners('keyup')
  }

  return Object.freeze({
    init,
    keys,
    reset,
    on,
    off,
    once,
    release,
  })
}

const keyController = KeyController()

export default keyController
