import { getIn } from '@cls/deep'
import isPlainObject from 'lodash/isPlainObject'
import { AnyAction } from 'redux'

import { discardWorkingVersion, updateOverview, updateWorkingVersion } from '../utils/working-version-storage'

const updateActionTypes = {
  ITEM_UPDATED:         'checkDiff',
  ITEM_ARRAY_MOVE:      'noCheck',
  ITEM_ARRAY_ADD:       'noCheck',
  ITEM_ARRAY_DELETE:    'noCheck',
  ITEM_ARRAY_ADD_BATCH: 'noCheck',
}

const deleteActions = {
  REMOVE_LOCAL_VERSION: true,
}

const overviewActionTypes = {
  ADD_ITEM_TAG_TO_QUEUE: 'noCheck',
  CLEAR_ITEM_TAG_QUEUE:  'noCheck',
  UPDATE_TAG_COMMENT:    'noCheck',
}

const middleware = store => next => (action: AnyAction) => {
  const updateAction = updateActionTypes[action.type]
  const isDeleteAction = deleteActions[action.type]
  const isOverviewAction = overviewActionTypes[action.type]

  if (!updateAction && !isDeleteAction && !isOverviewAction) {
    return next(action)
  }

  if (isOverviewAction) {
    const result = next(action)
    const gridViewState = store.getState().gridView
    updateOverview(gridViewState)
    return result
  }

  if (updateAction) {
    const previousItem = getIn(store.getState().versions, ['workingVersions', action.payload.itemId])
    const result = next(action)
    const updatedItem = getIn(store.getState().versions, ['workingVersions', action.payload.itemId])

    const shouldUpdate =
      updateAction === 'noCheck' ||
      (action.payload.data &&
        shouldUpdateWorkingVersion(previousItem, updatedItem, action.payload.data))

    if (shouldUpdate) {
      updateWorkingVersion(updatedItem)
    }
    return result
  }

  if (isDeleteAction) {
    discardWorkingVersion(action.id)
    return next(action)
  }
}

export default middleware

function valueChanged(newValue, oldValue) {
  const bothInvalid = !newValue && !oldValue
  const diffValues = newValue !== oldValue
  const newEmptyArray = !oldValue && Array.isArray(newValue) && !newValue.length
  const newEmptyMap = newValue && !oldValue && !Array.isArray(newValue) && typeof newValue === 'object' && Object.keys(newValue).every(v => !v || !v.length)
  return !bothInvalid && diffValues && !newEmptyMap && !newEmptyArray
}


export function shouldUpdateWorkingVersion(previousItem, updatedItem, payload) {
  const { key } = payload
  const path = key && (Array.isArray(key) ? key : key.split('.'))
  const oldValue: any = path && getIn(previousItem, key)
  const newValue: any = path && getIn(updatedItem, key)

  let updateRequired = false
  if (isPlainObject(newValue) && isPlainObject(oldValue)) {
    Object.keys(newValue).forEach(k => {
      if (valueChanged(newValue[k], oldValue[k])) {
        updateRequired = true
      }
    })
  } else if (!key) {
    updateRequired = Object.keys(payload).some(k => {
      let _newValue, _oldValue
      if (payload[k] instanceof Object) {
        return Object.keys(payload[k]).some(_k => {
          _newValue = JSON.stringify(payload[k][_k])
          _oldValue = JSON.stringify(getIn(previousItem, [k, _k]))
          return _newValue !== _oldValue
        })
      } else {
        _newValue = JSON.stringify(payload[k])
        _oldValue = JSON.stringify(previousItem[k])
        return _newValue !== _oldValue
      }
    })
  } else {
    if (valueChanged(newValue, oldValue)) {
      updateRequired = true
    }
  }

  return updateRequired
}
