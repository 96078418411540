import { RootState } from '@cls/redux'
import React, { Fragment, PureComponent } from 'react'
import { connect, ConnectedProps } from 'react-redux'

interface OwnProps {
  roles: string|Array<string>,
  children: Array<React.ReactElement> | React.ReactElement,
}

const mapStateToProps = (state: RootState) => ({
  role: state.user.profile.role,
})

const connector = connect(mapStateToProps)

type TypesFromRedux = ConnectedProps<typeof connector>

export class Authorize extends PureComponent<OwnProps & TypesFromRedux> {

  constructor(props: OwnProps & TypesFromRedux) {
    super(props)
  }

  render() {
    const { children, roles, role } = this.props

    if (roles.indexOf(role) === -1) {
      return false
    }

    return (
      <Fragment>
        {children}
      </Fragment>
    )
  }

}


export default connector(Authorize)
