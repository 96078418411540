import { RootState } from '@cls/redux'
import React, { PureComponent } from 'react'
import { Helmet } from 'react-helmet'
import { connect, ConnectedProps } from 'react-redux'
import { Link } from 'react-router-dom'

import SignupForm from './components/SignupForm'

const mapStateToProps = (state: RootState) => {
  return {
    signupPending: state.user.pending,
    signupError:   state.user.error,
  }
}

const connector = connect(mapStateToProps)

type TypesFromRedux = ConnectedProps<typeof connector>

export class SignupView extends PureComponent<TypesFromRedux> {

  render() {
    return (
      <div className="app page-login">
        <Helmet title="Signup" />
        <SignupForm {...this.props} />
        <div className="signup-text">
          <p>Have an account?</p>
          <Link to="/login" className="signup-link">
            Login
          </Link>
        </div>
      </div>
    )
  }

}

export default connector(SignupView)
