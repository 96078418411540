import AppLoading from '@cls/spinner'
import { DraggablePanel } from '@kpv-lab/ui'
import React, { PureComponent, Suspense } from 'react'
import { connect } from 'react-redux'

import { setQuery as setQueryAction } from '../../views/app/state/menu-redux'
import { showMapEditor as showMapEditorAction } from '../map-editor/state/actions'
import {
  hideUnitaggerPopup as hideUnitaggerPopupAction,
  tagSelect as tagSelectAction,
  toggleTagForField as toggleTagForFieldAction,
  UnitaggerPopupState
} from './state/unitagger-popup-redux'

const Unitagger = React.lazy(
  () => import(/* webpackChunkName: "unitagger" */ '@kpv-lab/unitagger')
)

const ESCAPE_KEY = 27

interface IUnitaggerPopupStateProps extends UnitaggerPopupState {
  explorerId:         number|null,
  visible:            boolean,
}

interface IUnitaggerPopupDispatchProps {
  hideUnitaggerPopup: () => void,
  setQuery:           (q: string) => void,
  showMapEditor:      (data: unknown) => void,
  tagSelect:          (id: number) => void,
  toggleTagForField:  (tagId: number) => void,
}

type StateProps = Readonly<IUnitaggerPopupStateProps>
type DispatchProps = Readonly<IUnitaggerPopupDispatchProps>

export class UnitaggerPopup extends PureComponent<StateProps & DispatchProps> {

  componentDidMount() {
    document.addEventListener('keydown', this.handleEscapeKey)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEscapeKey)
  }

  handleEscapeKey = e => {
    if (e.keyCode === ESCAPE_KEY) {
      this.onCloseHandler(false)
    }
  }

  onCloseHandler = (save = true) => {
    const { hideUnitaggerPopup, onClose, selectedTagIds } = this.props
    onClose && save && onClose(selectedTagIds)
    hideUnitaggerPopup()
  }

  onShowMapEditor = (coords, search, onSelect) => {
    const entryCoords = coords || []
    const value = { coords: entryCoords }
    this.props.showMapEditor({
      value,
      updateHandler:    onSelect,
      prefillSearch:    search,
      customLabelInput: false,
    })
  }

  render() {
    const {
      action,
      existingTagId,
      explorerId,
      hideUnitaggerPopup,
      parentTagIds,
      query,
      relatedTagIds,
      selectTagForLink,
      selectedTagIds,
      setQuery,
      shortListFilter,
      tagGroupIds,
      tagSelect,
      toggleTagForField,
      visible,
    } = this.props
    const key = query
      ? query
      : (tagGroupIds?.length) > 0
        ? 'tag-field-' + tagGroupIds.join('-') + parentTagIds?.join('-')
        : 'tag-manager'
    const isTagManager = !query && !selectedTagIds

    const selectTagForFilter = (tagId: number) => {
      tagSelect(tagId)
      hideUnitaggerPopup()
      setQuery('')
    }

    if (!explorerId) {
      return null
    }

    return (
      <div className={`panel-wrapper  ${visible ? '' : 'hidden'}`}>
        <DraggablePanel
          className={'ui-floating-panel unitagger-popup-view'}
          backdrop={true}
          backdropHandler={this.onCloseHandler}
        >
          <span className="draggable" />
          <Suspense fallback={<AppLoading />}>
            <Unitagger
              key={key}
              action={action}
              existingTagId={existingTagId}
              explorerId={explorerId}
              isTagManager={isTagManager}
              parentTagIds={parentTagIds}
              query={selectedTagIds.length ? '' : query}
              relatedTagIds={relatedTagIds}
              selectTagForFilter={selectTagForFilter}
              selectTagForLink={selectTagForLink}
              selectedTagIds={selectedTagIds}
              shortListFilter={shortListFilter}
              showMapEditor={this.onShowMapEditor}
              tagGroupIds={tagGroupIds && tagGroupIds.length > 0 ? tagGroupIds : null}
              toggleTagForField={toggleTagForField}
            />
          </Suspense>
        </DraggablePanel>
      </div>
    )
  }

}

const mapStateToProps = (state): StateProps => {
  return {
    existingTagId:    state.unitaggerPopup.existingTagId,
    explorerId:       state.menu.explorer ? Number(state.menu.explorer.id) : null,
    filterTags:       state.unitaggerPopup.filterTags,
    onClose:          state.unitaggerPopup.onClose,
    parentTagIds:     state.unitaggerPopup.parentTagIds,
    query:            state.unitaggerPopup.query,
    relatedTagIds:    state.unitaggerPopup.relatedTagIds,
    selectTagForLink: state.unitaggerPopup.selectTagForLink,
    selectedTagIds:   state.unitaggerPopup.selectedTagIds,
    shortListFilter:  state.unitaggerPopup.shortListFilter,
    tagGroupIds:      state.unitaggerPopup.tagGroupIds,
    visible:          state.unitaggerPopup.visible,
    action:           state.unitaggerPopup.action,
  }
}

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  {
    hideUnitaggerPopup: hideUnitaggerPopupAction,
    tagSelect:          tagSelectAction,
    showMapEditor:      showMapEditorAction,
    toggleTagForField:  toggleTagForFieldAction,
    setQuery:           setQueryAction,
  }
)(UnitaggerPopup)
