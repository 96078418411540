import createFlags from 'flag'
import { createReduxBindings } from 'flag/redux'

export type MyFlags = {
  features: {
    showFlagsAreWorking: boolean,
    showMapsEditor: boolean,
  },
};

export const flags: MyFlags = {
  features: {
    showFlagsAreWorking:  false,
    showMapsEditor:      false,
  },
}

const { FlagsProvider, Flag, useFlag, useFlags } = createFlags<MyFlags>()

const {
  setFlagsAction,
  getFlagsSelector,
  createFlagsReducer,
  ConnectedFlagsProvider,
} = createReduxBindings(FlagsProvider)

const reducer = createFlagsReducer(flags)

export {
  FlagsProvider,
  Flag,
  useFlag,
  useFlags,
  setFlagsAction,
  getFlagsSelector,
  ConnectedFlagsProvider,
  reducer,
}
