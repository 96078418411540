import { ActionHandler, createReducer } from '@cls/redux'
import { Feature } from 'geojson'

import * as types from './action-types'
import { ShowMapEventsEditor } from './actions'

interface OwnState {
  visible: boolean,
  features: Array<Feature>,
  prevFeatures: Array<Feature>,
  updateHandler: ({ features }: { features: any}) => void,
  dataKey: string,
  start: string,
  end: string,
}

const initialState: OwnState = {
  visible:       false,
  features:      [],
  prevFeatures:  [],
  updateHandler: () => { /* no op */ },
  dataKey:       '',
  start:         '',
  end:           '',
}

const showEditor: ActionHandler<OwnState, ShowMapEventsEditor> = (draftState, action) => {
  const { updateHandler, dataKey, features, prevFeatures, start, end } = action.data

  draftState.visible = true
  draftState.updateHandler = updateHandler
  draftState.dataKey = dataKey
  draftState.features = features
  draftState.prevFeatures = prevFeatures
  draftState.start = start
  draftState.end = end
}

const actionHandlers = new Map<string, ActionHandler<OwnState, any>>([
  [types.SHOW_MAPEVENTS_EDITOR, showEditor],
  [types.HIDE_MAPEVENTS_EDITOR, (draftState) => {
    draftState.visible = false
  }],
])

export default function reducer() {
  return createReducer(initialState, actionHandlers)
}
