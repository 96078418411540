import { Journey } from '../../../components/journey-control/JourneyControl'

export function getEventJourneyStatus(events: Array<any>|undefined, journeys: Array<Journey>) {
  if (!events || !journeys) {
    return events
  }

  const newEvents: Array<any> = []

  // All events were deleted
  if (events.length && !journeys.length) {
    events.forEach((e, idx) => {
      newEvents[idx] = deleteAllJourneyKeys(e)
    })
    return newEvents
  }

  const journeyEventCount = {}

  journeys.forEach((j, idx) => {
    const jStart = j._start
    const jEnd = j._end

    events.forEach(e => {
      const start = e._start
      const inRange = start >= jStart && start <= jEnd
      if (inRange) {
        journeyEventCount[idx] = journeyEventCount[idx] + 1 || 1
      }
    })
  })

  const journeyEventTally = {}
  events.forEach((e, eIdx) => {
    const start = e._start

    journeys.forEach((j, jIdx) => {
      const jStart = j._start
      const jEnd = j._end

      const inRange = start >= jStart && start <= jEnd
      let newEvent = deleteAllJourneyKeys(e)

      if (inRange) {
        const tally = journeyEventTally[jIdx]
        const eventCount = journeyEventCount[jIdx]
        const isFirst = !tally
        const isLast = tally === eventCount - 1

        newEvent._journeyId = j._journeyId

        if (isFirst) {
          newEvent = {
            ...newEvent,
            _stage:        0,
            _journeyTitle: j.title,
            _journeyStart: j.start,
          }
        } else if (isLast) {
          newEvent = {
            ...newEvent,
            _stage:      1,
            _journeyEnd: j.end,
          }
        } else {
          newEvent = {
            ...newEvent,
            _stage: tally / eventCount,
          }
        }

        journeyEventTally[jIdx] = journeyEventTally[jIdx] + 1 || 1
        newEvents[eIdx] = newEvent
      } else {
        if (!newEvents[eIdx]) {
          newEvent = {
            ...newEvent,
            _partOfJourney: false,
          }
          newEvents[eIdx] = newEvent
        }
      }
    })
  })

  return newEvents
}


function deleteAllJourneyKeys(event: any): any {
  const newEvent = { ...event }

  delete newEvent._journeyTitle
  delete newEvent._journeyStart
  delete newEvent._journeyEnd
  delete newEvent._partOfJourney
  delete newEvent._journeyId
  delete newEvent._stage

  return newEvent
}
