import { Message } from 'cl-studio'
import { History } from 'history'
import React, { Fragment, PureComponent } from 'react'

import { formatMessage } from './formatMessage'

interface OwnProps {
  chatMessages: Array<Message>,
  chatId: number,
  history: History,
}
export default class MessageList extends PureComponent<OwnProps> {

  render() {
    const { chatMessages, chatId, history } = this.props
    return chatMessages.map((message, index) => {
      const showTimestamp = chatMessages[index + 1]
        ? Math.abs(Number(chatMessages[index + 1].timestamp) - Number(chatMessages[index].timestamp)) > 60000 * 15
        : true

      const showDate = Math.abs(Number(message.timestamp) - Date.now()) > 60000 * 1440
      const incoming = chatId === message.from_user_id
      const incomingChat = incoming ? 'incoming' : ''

      return (
        <Fragment key={`${message.id}-${message.timestamp}`}>
          <div className={`chat-message ${incomingChat}`}>
            {formatMessage(message.text, history)}
          </div>

          {showTimestamp && (
            <div className="message-timestamp">
              <span className="message-user">{`${message.name}`}</span>
              {showDate
                ? new Date(Number(message.timestamp)).toLocaleDateString('en-GB', {
                  year:  'numeric',
                  month: '2-digit',
                  day:   '2-digit',
                })
                : new Date(Number(message.timestamp)).toLocaleTimeString('en-GB', {
                  hour:   '2-digit',
                  minute: '2-digit',
                })}
            </div>
          )}
        </Fragment>
      )
    })
  }

}
