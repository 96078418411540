import { extractMediaIdsFromAuthorField } from './author-media-finder'
import { extractMediaIdsFromCuriosityField } from './curiosity-media-finder'
import { extractMediaIdsFromQuizField, findMediaItemsInQuestion } from './quiz-media-finder'
import { AUTHOR_TEMPLATE_ID, CURIOSITY_TEMPLATE_ID, MediaId, QUIZ_TEMPLATE_ID, Version, WORK_TEMPLATE_ID } from './types'
import { extractMediaIdsFromWorkField } from './work-media-finder'

/**
 * Find all _mediaItems fields in the version object and return a Map with the mediaId as keys
 * and their positions (such as chronology.0 or biography) as values
 */
export const findMediaItems = (version: Version) => {
  const { template_id, body: versionBody } = version
  return Object.entries(versionBody).reduce((mediaItemPositions, [fieldName, field]) => {
    if (Array.isArray(field)) {
      field.forEach((arrayItem, index) => {
        if (template_id === QUIZ_TEMPLATE_ID && fieldName === 'questions') {
          findMediaItemsInQuestion(`${fieldName}.${index}`, arrayItem)
            .forEach((questionMediaPositions, questionMediaKey) => {
              mediaItemPositions.set(questionMediaKey, questionMediaPositions)
            })
        } else {
          addPositions(mediaItemPositions, arrayItem, template_id, fieldName, `${fieldName}.${index}`)
        }
      })
    } else {
      addPositions(mediaItemPositions, field, template_id, fieldName, fieldName)
    }
    return mediaItemPositions
  }, new Map<string, Array<string>>())
}

/**
 * Add position to each mediaItem linked to an item
 */
export const addPositions = (
  mediaItemPositions: Map<MediaId, Array<string>>,
  field: any,
  templateId: number,
  fieldName: string,
  position: string
) => {
  const mediaIds = extractMediaIds(templateId, fieldName, field)

  mediaIds?.forEach(mediaId => {
    const positions = mediaItemPositions.get(mediaId) || []
    mediaItemPositions.set(mediaId, [...positions, position])
  })
}

/**
 * Get mediaIds if present, or extract them from item fields depending on item type
 */
function extractMediaIds(templateId: number, fieldName: string, field: any) {
  const mediaIds: Array<string> = []

  switch (templateId) {

    case AUTHOR_TEMPLATE_ID:
      return extractMediaIdsFromAuthorField(fieldName, field)

    case QUIZ_TEMPLATE_ID:
      return extractMediaIdsFromQuizField(fieldName, field)

    case WORK_TEMPLATE_ID:
      return extractMediaIdsFromWorkField(fieldName, field)

    case CURIOSITY_TEMPLATE_ID:
      return extractMediaIdsFromCuriosityField(fieldName, field)

  }

  return mediaIds
}

export const extractMediaIdFromUrl = (mediaPath?: string) => {
  const regex = /(images|audio)\/\w+\/(?<mediaId>\w+)\./
  const found = mediaPath?.match(regex)
  return found?.groups?.mediaId || undefined
}

export const extractMediaIdsFromRichText = (richText?: string) => {
  const regex = /(images|audio)\/\w+\/\w+\./g
  const mediaPaths = richText?.match(regex)
  return mediaPaths?.map(mediaPath => extractMediaIdFromUrl(mediaPath))
    .filter(mediaId => mediaId !== undefined) as Array<string> | undefined || []
}
