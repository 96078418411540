import '../styles/Toggle.css'

import React, { PureComponent } from 'react'

type Props = {
  id: number | string,
  style?: any,
  label: string,
  icon?: string,
  place?: boolean,
  linked?: boolean,
  extraLabel?: number | string,
  extraClassName?: string,
  selectObject: any,
  value: boolean,
  updateHandler: (...args: Array<any>) => any,
};

type State = any;

export default class Toggle extends PureComponent<Props, State> {

  constructor(props: Props) {
    super(props)
    this.state = {
      value: props.value,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.value) {
      return { value: nextProps.value }
    }

    return null
  }

  clickHandler = e => {
    const { label, updateHandler, selectObject } = this.props
    if (e.altKey) {
      const url = `http://www.google.com/search?q=${label}`
      window.open(url, '_search')
      return
    }
    updateHandler(selectObject, e.shiftKey)
  }

  render() {
    const { id, label, extraLabel, extraClassName, style, icon, place, linked } = this.props
    const { value } = this.state

    let className = 'ui-i ui-toggle'
    value ? (className += ' toggled') : (className += ' untoggled')
    extraClassName ? (className += ` ${extraClassName}`) : ''
    let extraInfo
    if (typeof extraLabel !== 'undefined') {
      extraInfo = <span className="ui-toggle-extra-label">{extraLabel}</span>
    }

    let extraIcon
    if (icon) {
      extraIcon = <i className="material-icons md-16 ui-toggle-icon">{icon}</i>
    }

    let placeIcon
    if (place) {
      placeIcon = (
        <i className="material-icons md-16 ui-toggle-icon" title="Has a location">
          place
        </i>
      )
    }

    let linkedIcon
    if (linked) {
      linkedIcon = (
        <i className="material-icons md-16 ui-toggle-icon" title="Linked to item">
          link
        </i>
      )
    }

    return (
      <div
        className={className}
        onClick={this.clickHandler}
        key={label}
        style={style}
        title={`${label} (${id})`}
      >
        <span className="ui-toggle-label">{label}</span>
        <span className="ui-toggle-label info-container">
          {linkedIcon}
          {placeIcon}
          {extraInfo}
          {extraIcon}
        </span>
      </div>
    )
  }

}
