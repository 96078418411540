import styled from '@emotion/styled'

export const MultimediaFieldElement = styled.div`
  label: multimedia-field;
  display: flex;
  font-family: var(--form-field-font-family);
  padding: 0.4em;

  .multimedia-field--label {
    flex-grow: 1;
    align-self: center;
    font-size: var(--font-size-label);
    color: var(--label-color);
    font-weight: bold;
    :not(.multimedia-field--label-full) {
      max-width: 10em;
      text-align: right;
      padding-right: 1em;
    }
    &.multimedia-field--label-full {
      text-align: center;
      margin: 0.8em 0;
    }
  }

  .multimedia-field--value,
  .multimedia-field--positions,
  .multimedia-field--editing {
    flex: 1 0;
    color: #222;
    letter-spacing: normal;
    font-weight: var(--content-font-weight);
    line-height: 1.4;
    border-radius: var(--ui-field-border-radius);
    font-size: var(--form-field-font-size);
    user-select: text;
    padding: var(--form-field-padding-x) var(--form-field-padding-y);
    border: 1px solid rgba(0, 0, 0, 0.0);
  }

  .multimedia-field--value:hover {
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: white;
  }

  .multimedia-field--input {
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.0);
    padding: var(--form-field-padding-x) var(--form-field-padding-y);
  }

  .multimedia-field--input:hover {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .multimedia-field--input:focus {
    border: 1px solid var(--ui-active-color);
  }
`
