import { reducer as flagsReducer } from '@cls/flags'
import { reducer as dateControlReducer } from '@kpv-lab/date-control'
import { reducer as linkEditorReducer } from '@kpv-lab/link-editor'
import { referencePopupReducer, richTextPopupReducer } from '@kpv-lab/md-text-editor'
import { modalReducer } from '@kpv-lab/modal'
import { AnyAction, combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'

import { reducer as feedbackPopupReducer } from '../components/feedback-popup/state/reducer'
import flashReducer from '../components/flash/state/flash-redux'
import gridViewReducer from '../components/grid-view/state/reducer'
import imageEditorReducer from '../components/image-editor/state/reducer'
import mapEditorReducer from '../components/map-editor/state/reducer'
import mapEventsEditorReducer from '../components/mapevents-editor/state/reducer'
import mediaManagerPopupReducer from '../components/media-manager/state/media-manager-popup-redux'
import messageReducer from '../components/messaging/state/reducer'
import { reducer as multimediaPopupReducer } from '../components/multimedia-popup/state/reducer'
import taggingControlReducer from '../components/tagging-control/state/reducer'
import unitaggerPopupReducer from '../components/unitagger-popup/state/unitagger-popup-redux'
import wikidataPopupReducer from '../components/wikidata-popup/state/wikidata-popup-redux'
import menuReducer from '../views/app/state/menu-redux'
import importReducer from '../views/content/components/import-editor/state/reducer'
import { reducer as editorReducer } from '../views/content/state/editor-redux'
import { reducer as versionsReducer } from '../views/content/state/versions-redux'
import { reducer as wikidataReducer } from '../views/content/state/wikidata-redux'
import templatesReducer from '../views/templates/state/reducer'
import userReducer from '../views/user/state/reducer'
import { featuresTransform } from './flags-filter'

// Only load those keys which are still part of the flags collection
export const persistConfig = {
  key:             'flags',
  storage,
  stateReconciler: autoMergeLevel2,
  transforms:      [featuresTransform],
  debug:           true,
  whitelist:       ['features'],
}

const rootReducer = combineReducers({
  dateControl:       dateControlReducer(),
  editor:            editorReducer(),
  feedbackPopup:     feedbackPopupReducer(),
  multimediaPopup:   multimediaPopupReducer(),
  flags:             persistReducer<any, AnyAction>(persistConfig, flagsReducer),
  flash:             flashReducer(),
  gridView:          gridViewReducer(),
  imageEditor:       imageEditorReducer(),
  import:            importReducer(),
  linkEditor:        linkEditorReducer(),
  mapEditor:         mapEditorReducer(),
  mapEventsEditor:   mapEventsEditorReducer(),
  mediaManagerPopup: mediaManagerPopupReducer(),
  menu:              menuReducer(),
  messages:          messageReducer(),
  modal:             modalReducer(),
  richtextPopup:     richTextPopupReducer(),
  referencePopup:    referencePopupReducer(),
  taggingControl:    taggingControlReducer(),
  // This one is different as it's wrapped in an undoable function
  templates:         templatesReducer,
  unitaggerPopup:    unitaggerPopupReducer(),
  user:              userReducer(),
  versions:          versionsReducer(),
  wikidata:          wikidataReducer(),
  wikidataPopup:     wikidataPopupReducer(),
})

export type RootState = ReturnType<typeof rootReducer>

export { rootReducer }
