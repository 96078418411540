import '../styles/ImageSwatch.css'

import React, { PureComponent } from 'react'

type OwnProps = {
  structure: any,
  id: string,
  value: string,
  urlParams: string,
  assetHost: string,
  updateHandler: (...args: Array<any>) => any,
};

type Props = OwnProps & typeof ImageSwatch.defaultProps;

export default class ImageSwatch extends PureComponent<Props> {

  static defaultProps = {
    assetHost: '',
    urlParams: '',
  }

  onClick = () => {
    const { updateHandler, id, value, structure } = this.props
    const [val] = value.split('|')
    updateHandler({ id: id, image: val, repeat: structure.repeat || '' })
  }

  render() {
    const { structure, id, value, urlParams, assetHost } = this.props

    const host = structure.assetHost || assetHost || ''
    const _urlParams = structure.urlParams || urlParams || ''
    const _id = ('' + id).replace(/[.]/g, '-')
    const htmlFor = `input-${_id}`
    const className = `ui-i image-swatch field-${_id}`
    const label = <label className="ui-label">{structure.label}</label>

    let img
    if (value) {
      const [val] = value.split('|')
      const src = `${!/^http/i.test(val) ? host : ''}${val}${_urlParams}`
      img = <img className="image-swatch-preview" src={src} alt="" />
    }

    return (
      <section className={className} key={htmlFor}>
        {label}
        <div className="image-swatch-container" onClick={this.onClick}>
          {img}
        </div>
      </section>
    )
  }

}
