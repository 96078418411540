const priorityOpts: ReadonlyArray<{ value: number, label: string}> = [
  { value: 100, label: '100 - Always show' },
  { value: 90, label: '90 - Global importance' },
  { value: 75, label: '75 - Major in field' },
  { value: 60, label: '60 - Notable in field' },
  { value: 50, label: '50 - Major in life' },
  { value: 25, label: '25 - Notable in life' },
  { value: 0, label: '0 - Unset' },
]

export { priorityOpts }
