import React from 'react'

import EditMessage from './EditMessage'
import Message from './Message'
import ReplyBox from './ReplyBox'

type Props = {
  item: any,
  error: string,
  active: boolean,
  replies: Array<any>,
  replyBody: string,
  replyHandler: (dataKey: string, replyValue: string) => any,
  acceptHandler: () => void,
  cancelHandler: () => void,
  enterReplyMode: (threadId: number, title: string) => void,
  handleEditThread: (threadId: number) => void,
  handleSetEditReplyMode?: (replyId: number) => void,
  handleEditReply?: (key: string, body: string) => void,
  handleSaveReplyEdit?: () => void,
  handleCancelReplyEdit?: () => void,
  handleDeleteFeedback: (id: number) => void,
  editReply: any,
  allowManipulation: boolean,
  userId?: number,
};

export default function Thread({
  item,
  active,
  error,
  replyBody,
  replies,
  replyHandler,
  acceptHandler,
  cancelHandler,
  enterReplyMode,
  handleEditThread,
  handleSetEditReplyMode,
  handleEditReply,
  handleSaveReplyEdit,
  handleCancelReplyEdit,
  handleDeleteFeedback,
  editReply,
  allowManipulation,
  userId,
}: Props) {
  const id = item.id
  const title = item.title

  let replySection
  if (active) {
    replySection = (
      <ReplyBox
        error={error}
        value={replyBody}
        replyHandler={replyHandler}
        acceptHandler={acceptHandler}
        cancelHandler={cancelHandler}
      />
    )
  }

  return (
    <div className="feedback-thread" key={`f-thread-${id}`}>
      <div className="feedback-thread-replies">
        <div className="feedback-thread-header"></div>
        <Message
          allowManipulation={allowManipulation}
          item={item}
          handleEditThread={() => handleEditThread(id)}
          handleDeleteThread={() => handleDeleteFeedback(id)}
          handleReply={() => enterReplyMode(id, title)}
        />
        {replies.map((r, idx) => {
          const isInEditMode = r.id === editReply?.id

          if (isInEditMode) {
            return (
              <EditMessage
                editModeActive={r.id === editReply?.id}
                key={`msg-${id}-${idx}`}
                item={r}
                handleEditReply={handleEditReply}
                handleSaveReplyEdit={handleSaveReplyEdit}
                handleCancelReplyEdit={handleCancelReplyEdit}
              />
            )
          }

          return (
            <Message
              key={`msg-${id}-${idx}`}
              item={r}
              allowManipulation={r.user.id === userId}
              handleEditThread={() =>
                handleSetEditReplyMode &&
                               handleSetEditReplyMode(r.id)
              }
              handleDeleteThread={() =>
                handleDeleteFeedback(r.id)
              }
            />
          )
        })}
      </div>
      <div className="controls-row">{replySection}</div>
    </div>
  )
}
