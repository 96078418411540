import { gql } from '@apollo/client'

const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    email
    name
    role
    disabled
    status
  }
`

export const USER_LIST = gql`
  query users {
    users {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`

export const GET_USER = gql`
  query user($userId: Int!) {
    user(userId: $userId) {
      id
      email
      name
      role
      disabled
      created_at
      updated_at
      lastCLSActivity
      lastLEActivity
    }
  }
`

export const ADD_USER = gql`
  mutation addAccount($user: NewUserInput!, $location: String) {
    addAccount(user: $user, location: $location) {
      id
      email
      name
      role
      updated_at
      created_at
    }
  }
`

export const UPDATE_USER = gql`
  mutation updateUser($user: UserUpdateInput!) {
    updateUser(user: $user) {
      id
      body
      email
      name
      role
      created_at
      updated_at
      feedback {
        id
        item_id
        parent_id
        body
        title
        created_at
      }
    }
  }
`
