import { col2rgba } from '@kpv-lab/color-utils'
import { produce } from 'immer'

export const SWATCH_SAVE = 'swatches/SAVE'
export const SWATCH_CLEAR = 'swatches/CLEAR'

export function saveSwatch(idx, color) {
  return {
    type: SWATCH_SAVE,
    data: { idx, color },
  }
}

export function clearSwatch(idx) {
  return {
    type: SWATCH_CLEAR,
    data: idx,
  }
}

let localStorePrefix = 'cls'

// initial state
export function initialState(prefix = localStorePrefix) {
  localStorePrefix = prefix
  let swatches = {}
  try {
    swatches = JSON.parse(
      (window.localStorage && window.localStorage.getItem(`${prefix}:swatches`)) || '{}'
    )
  } catch (err) {
    swatches = {}
  }

  return swatches
}

export function saveState(data, prefix = localStorePrefix) {
  localStorePrefix = prefix
  try {
    window.localStorage &&
      window.localStorage.setItem(`${prefix}:swatches`, JSON.stringify(data))
  } catch (err) {
    console.error('could not save swatches!', err)
  }
}

const reducer = () => {
  return produce((draftState, action = {}) => {
    // if initialised with a prevState string value then that sets the local storage key prefix
    const state = typeof draftState === 'string' ? initialState(draftState) : draftState || initialState()

    switch (action.type) {

      case SWATCH_SAVE: {
        // color is an RGBA array
        const { color, idx } = action.data
        const col = col2rgba(color)
        state[idx.toString()] = col
        saveState(state)
        return state
      }

      case SWATCH_CLEAR: {
        state[action.data.toString()] = null
        saveState(state)
        return state
      }

      default:
        return state

    }
  }, initialState())
}

export default reducer
