import { Input } from '@kpv-lab/ui'
import React from 'react'

type Props = {
  error: string,
  value: string,
  replyHandler: (dataKey: string, replyValue: string) => any,
  acceptHandler: () => void,
  cancelHandler: () => void,
};

export default function ReplyBox({ error, value, replyHandler, acceptHandler, cancelHandler }: Props) {
  return (
    <div className="reply-box" data-cy="feedback-reply-container">
      {error &&
        <div className="error-msg">
          {error}
        </div>
      }
      <Input
        dataKey="feedback"
        structure={{
          type:         'textarea',
          updateOnBlur: false,
          options:      { placeholder: 'Write a reply...' },
        }}
        value={value}
        focus={true}
        updateHandler={replyHandler}
        inline={false}
      />
      <div className="reply-box-btns">
        <button
          className="btn btn-primary reply-btn"
          data-cy="submit-feedback-reply-btn"
          onClick={acceptHandler}
        >
          Reply
        </button>
        <button onClick={cancelHandler} className="btn btn-secondary cancel-btn">
          Cancel
        </button>
      </div>
    </div>
  )
}
