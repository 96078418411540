import { addPositions, extractMediaIdFromUrl, extractMediaIdsFromRichText } from './common-media-finder'
import { Answer, Question, QUIZ_TEMPLATE_ID } from './types'

export const findMediaItemsInQuestion = (
  questionPosition: string,
  question: Question
) => {
  return Object.entries(question).reduce((questionMediaPositions, [fieldName, field]) => {
    if (Array.isArray(field)) {
      field.forEach((arrayItem, index) => {
        const fieldPosition = [questionPosition, fieldName, index].join('.')
        addPositions(questionMediaPositions, arrayItem, QUIZ_TEMPLATE_ID, `questions.${fieldName}`, fieldPosition)
        if (fieldName === 'answers') {
          findMediaItemsInAnswer(fieldPosition, arrayItem)
            .forEach((answerMediaPositions, answerMediaKey) => {
              questionMediaPositions.set(answerMediaKey, answerMediaPositions)
            })
        }
      })
    } else {
      addPositions(questionMediaPositions, field, QUIZ_TEMPLATE_ID, `questions.${fieldName}`, `${questionPosition}.${fieldName}`)
    }
    return questionMediaPositions
  }, new Map<string, Array<string>>())
}

const findMediaItemsInAnswer = (
  answerPosition: string,
  answer: Answer
) => {
  return Object.entries(answer).reduce((answerMediaPositions, [fieldName, field]) => {
    if (!Array.isArray(field)) {
      addPositions(answerMediaPositions, field, QUIZ_TEMPLATE_ID, `questions.answers.${fieldName}`, `${answerPosition}.${fieldName}`)
    }
    return answerMediaPositions
  }, new Map<string, Array<string>>())
}

export const extractMediaIdsFromQuizField = (fieldName: string, field: any) => {
  let mediaIds: Array<string> = []

  switch (fieldName) {

    case 'description':
    case 'summary':
    case 'teaser':
      mediaIds = extractMediaIdsFromRichText(field.value)
      break

    case 'image':
      if (field.path) {
        const extractedMediaId = extractMediaIdFromUrl(field.path)
        mediaIds = extractedMediaId ? [extractedMediaId] : []
      }
      break

    case 'questions.content':
      mediaIds = extractMediaIdsFromRichText(field)
      break

    case 'questions.answers.value':
      mediaIds = extractMediaIdsFromRichText(field)
      break

  }

  return mediaIds
}
