import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { hideModal as hideModalAction, OwnState } from './modal-redux'

type ModalProps = {
  message: string,
  acceptLabel: string,
  cancelLabel: string,
  acceptHandler?: () => void,
  cancelHandler?: () => void,
  isVisible: boolean,
  hideModal: () => void,
};

export class Modal extends PureComponent<ModalProps> {

  refOverlay: React.RefObject<HTMLElement>|undefined;

  acceptHandler = () => {
    const { acceptHandler, hideModal } = this.props
    acceptHandler && acceptHandler()
    hideModal()
  }

  cancelHandler = () => {
    const { cancelHandler, hideModal } = this.props
    cancelHandler && cancelHandler()
    hideModal()
  }

  overlayHandler = event => {
    if (event.target === this.refOverlay) {
      this.cancelHandler()
    }
  }

  setRefOverlay = el => (this.refOverlay = el)

  render() {
    const { message, acceptLabel, cancelLabel, isVisible } = this.props

    if (!isVisible) {
      return null
    }

    return (
      <div
        className={`modal-overlay ${isVisible ? 'show' : ''}`}
        ref={this.setRefOverlay}
        onClick={this.overlayHandler}
      >
        <div className="modal-content">
          <div className="modal-message">{message || ''}</div>
          <fieldset className="fieldset">
            <button className="btn btn-secondary cancel-button" onClick={this.cancelHandler}>
              {cancelLabel}
            </button>
            <button className="btn btn-primary save-button" onClick={this.acceptHandler}>
              {acceptLabel}
            </button>
          </fieldset>
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state: { modal: OwnState }) => ({
  message:       state.modal.message,
  acceptLabel:   state.modal.acceptLabel,
  cancelLabel:   state.modal.cancelLabel,
  acceptHandler: state.modal.acceptHandler,
  cancelHandler: state.modal.cancelHandler,
  isVisible:     state.modal.isVisible,
})

export default connect(mapStateToProps, { hideModal: hideModalAction })(Modal)
