import { objectPath, Path } from '.'

/**
 * Deep getIn
 */
export function getIn<T>(obj: Record<string, any>, path: Path, notFoundValue?: T): T {
  const keys = objectPath(path)
  if (!obj || !keys.length) {
    // @ts-ignore: For the most part, the calling code is generally happy that it's going to get a
    // result and doesn't check for undefined return values. However, if we type this as `T|undefined`
    // then everything needs to check return values.
    return notFoundValue
  }

  let v = obj
  for (const k of keys) {
    v = v[k]
    if (v === undefined) {
      break
    }
  }

  return (v ? v : notFoundValue) as T
}

