import { ActionHandler, createReducer } from '@cls/redux'

const SHOW_WIKIDATA_POPUP = 'SHOW_WIKIDATA_POPUP'
const HIDE_WIKIDATA_POPUP = 'HIDE_WIKIDATA_POPUP'

export function showWikidataPopup(data: { query: string, results: Array<any>}) {
  return <const>{
    type: SHOW_WIKIDATA_POPUP,
    data: {
      query:   data.query,
      results: data.results,
    },
  }
}

export function hideWikidataPopup() {
  return <const>{
    type: HIDE_WIKIDATA_POPUP,
  }
}
interface OwnState {
  visible: boolean,
  query: string,
  results: Array<any>,
}

const initialState: OwnState = {
  visible: false,
  query:   '',
  results: [],
}

const actionHandlers = new Map<string, ActionHandler<OwnState, any>>([
  [SHOW_WIKIDATA_POPUP, (draftState, action) => {
    draftState.visible = true
    draftState.query = action.data.query
    draftState.results = action.data.results
  }],
  [HIDE_WIKIDATA_POPUP, () => initialState],
])

export default function reducer() {
  return createReducer(initialState, actionHandlers)
}

