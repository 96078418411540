import {
  alignBlock,
  blockQuote,
  clearTags,
  headingBlock,
  icons,
  infoBlock,
  link,
  linkItem,
  markItem,
  mediaItem,
  mediaSize,
  reference,
  tagItem,
  wrapListItem } from './utils'

/*
- https://github.com/ProseMirror/prosemirror-example-setup/blob/master/src/menu.js
*/

export function buildMenuItems(schema, props) {
  const r = {}
  let type

  r.clearTags = clearTags(schema)

  // Set media manager to only work in the below fields
  // Enable across all RTE when we've simplified ItemEditor structure
  // If we need to add media uploading to another field then the RTE for that component
  // needs to supply the itemUpdate prop.
  // const mediaFriendlyFields = [
  //   'chronology',
  //   'biography',
  //   'body', // For images in person articles
  //   'description',
  //   'summary',
  //   'question',
  // ]

  if ((type = schema.nodes.image)) {
    r.media = mediaItem(schema, type, props)
    r.mediaSize = mediaSize(schema)
  }

  if ((type = schema.nodes.paragraph)) {
    r.alignBlockLeft = alignBlock(schema, type, {
      align: 'left',
    })
    r.alignBlockCenter = alignBlock(schema, type, {
      align: 'center',
    })
    r.alignBlockRight = alignBlock(schema, type, {
      align: 'right',
    })
    r.alignBlockJustify = alignBlock(schema, type, {
      align: 'justify',
    })
  }
  if ((type = schema.marks.strong)) {
    r.toggleStrong = markItem(type, { title: 'Toggle bold', icon: icons.strong })
  }
  if ((type = schema.nodes.heading)) {
    r.makeHead1 = headingBlock(schema, type, {
      label: 'H1',
      attrs: {
        level: 1,
      },
    })
    r.makeHead2 = headingBlock(schema, type, {
      label: 'H2',
      attrs: {
        level: 2,
      },
    })
    r.makeHead3 = headingBlock(schema, type, {
      label: 'H3',
      attrs: {
        level: 3,
      },
    })
  }
  if ((type = schema.marks.em)) {
    r.toggleEm = markItem(type, { title: 'Toggle emphasis', icon: icons.em })
  }
  if ((type = schema.nodes.blockquote)) {
    r.wrapBlockQuote = blockQuote(schema, type, {
      title: 'Wrap in block quote',
      icon:  icons.blockquote,
    })
  }
  if ((type = schema.nodes.info)) {
    r.infoBlock = infoBlock(schema, props)
  }
  if ((type = schema.nodes.reference)) {
    r.toggleReference = reference(schema, props)
  }
  if ((type = schema.marks.tag)) {
    r.toggleTag = tagItem(schema, type, props)
  }
  if ((type = schema.marks.itemlink)) {
    r.linkItem = linkItem(schema, type, props)
  }
  if ((type = schema.marks.link)) {
    r.toggleLink = link(type)
  }
  if ((type = schema.nodes.bullet_list)) {
    r.wrapBulletList = wrapListItem(schema, type, {
      title: 'Wrap in bullet list',
      icon:  icons.bulletList,
    })
  }
  if ((type = schema.nodes.ordered_list)) {
    r.wrapOrderedList = wrapListItem(schema, type, {
      title: 'Wrap in ordered list',
      icon:  icons.orderedList,
    })
  }

  const cut = arr => arr.filter(x => x)

  const getFullMenu = () => {
    return {
      inlineMenu: [
        cut([
          r.toggleStrong,
          r.toggleEm,
          r.toggleLink,
          r.toggleTag,
          r.linkItem,
          r.infoBlock,
          r.toggleReference,
          r.clearFormatting,
          r.clearTags,
        ]),
      ],
      blockMenu: [
        cut([
          r.wrapBlockQuote,
          r.wrapBulletList,
          r.wrapOrderedList,
          r.media,
          r.mediaSize,
          r.videoItem,
          r.alignBlockLeft,
          r.alignBlockCenter,
          r.alignBlockRight,
          r.alignBlockJustify,
        ]),
      ],
    }
  }

  const { inlineMenu: im, blockMenu: bm } = getFullMenu()

  switch (props.theme) {

    case 'full':
      r.inlineMenu = im
      r.blockMenu = bm
      r.fullMenu = r.blockMenu.concat([[r.makeHead1, r.makeHead2, r.makeHead3]], r.inlineMenu)
      break

    case 'no-media':
      r.inlineMenu = im
      r.blockMenu =  [bm[0].filter(x => x?.spec?.title !== 'Media')]
      r.fullMenu = r.blockMenu.concat([[r.makeHead1, r.makeHead2, r.makeHead3]], r.inlineMenu)
      break

    default:
      r.inlineMenu = [cut([r.toggleStrong, r.toggleEm, r.toggleLink])]
      r.blockMenu = [cut([r.wrapBlockQuote, r.wrapBulletList, r.wrapOrderedList])]
      r.fullMenu = r.blockMenu.concat(r.inlineMenu)

  }


  return r
}
