// action constants
export const LOGIN_REQUEST = 'user/LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'user/LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'user/LOGIN_FAILURE'

export const LOGOUT = 'user/LOGOUT'
export const VIEW_ACCOUNT = 'user/VIEW_ACCOUNT'

export const EDIT_ACCOUNT_SUCCESS = 'user/EDIT_ACCOUNT_SUCCESS'
export const EDIT_ACCOUNT_FAILURE = 'user/EDIT_ACCOUNT_FAILURE'

export const SIGNUP_REQUEST = 'user/SIGNUP_REQUEST'
export const SIGNUP_SUCCESS = 'user/SIGNUP_SUCCESS'
export const SIGNUP_FAILURE = 'user/SIGNUP_FAILURE'

export const RESET_PASSWORD_REQUEST = 'user/RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'user/RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'user/RESET_PASSWORD_FAILURE'
export const RESET_PASSWORD_STATE = 'user/RESET_PASSWORD_STATE'
