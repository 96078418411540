import '../styles/Header.css'

import React from 'react'

type Props = {
  structure?: any,
  postRender?: (...args: Array<any>) => any,
};

export default function Header({ structure, postRender }: Props) {
  const className = `ui-i ui-header ${structure.className || ''}`

  const output = <h3 className={className}>{structure.value}</h3>

  return postRender ? postRender(structure, output) : output
}
