export const allowedImageFilesTypes = [
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'image/webp',
  'image/gif',
  'image/tif',
]

export const allowedAudioFilesTypes = [
  'audio/aac',
  'audio/ogg',
  'audio/wav',
  'audio/x-wav',
  'audio/flac',
  'audio/x-flac',
  'audio/mpeg',
]

export const allowedVideoFilesTypes = [
  'video/mp4',
  'video/mpeg',
  'video/webm',
]
