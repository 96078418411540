import React from 'react'

import ItemsList from './ItemsList'

interface Props {
  items: ReadonlyArray<any>,
  templates: ReadonlyArray<{ id: number, title: string}>,
  toggleToolbar: () => any,
  mustShowTemplateIds?: Array<any>,
}

export default function TemplateItems({ items, templates, toggleToolbar, mustShowTemplateIds = [] }: Props) {
  const sortedTemplates = Array.from(templates)
  sortedTemplates.sort((a, b) => Number(a.id) - Number(b.id))

  const templatesWithItems = sortedTemplates
    .map(t => ({
      id:    t.id,
      title: t.title,
      items: items.filter(i => Number(i.templateId) === Number(t.id)),
    }))
    .filter(template => template.items.length > 0 || mustShowTemplateIds.includes(template.id))

  return (
    <div className="items-container">
      {templatesWithItems.map(t => (
        <ItemsList key={t.id} title={t.title} items={t.items} toggleToolbar={toggleToolbar} showType={false} />
      ))}
    </div>
  )
}
