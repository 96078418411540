import dateformat from 'dateformat'
import React from 'react'

const adminRoles = ['admin', 'editor', 'writer']

type Props = {
  item: any,
  handleDeleteThread: () => void,
  handleEditThread: () => void,
  handleReply?: () => void,
  allowManipulation: boolean,

};

export default function Message({
  item,
  handleDeleteThread,
  handleEditThread,
  handleReply,
  allowManipulation,
}: Props) {
  const id = item.id
  const body = item.body
  const hour = dateformat(item.created_at, 'HH:MM')
  const day = dateformat(item.created_at, 'd mmm yyyy')
  const user = item.user.name
  const title = item.title
  const userRole = item.user.role
  const isAdmin = adminRoles.includes(userRole)

  let className = 'feedback-msg-username',
    starIcon
  if (isAdmin) {
    starIcon = (
      <i className="material-icons user-admin-icon">star</i>
    )
    className += ' user-admin'
  }

  const author = (
    <div className="feedback-msg-by">
      {starIcon}
      <span className={className}>{user}</span>
    </div>
  )

  const time = (
    <span>
      {' '}at {hour} on {day}
    </span>
  )
  const msgTitle = <span>{title}&nbsp;by&nbsp;</span>

  return (
    <div key={`f-msg-${id}`} className="feedback-msg">
      <div className="feedback-msg-header">
        {title ? msgTitle : ''}
        {author}&nbsp;{time}
        <div className="feedback-msg-controls">
          {handleReply && (
            <i
              onClick={handleReply}
              className="material-icons md-15"
              title="reply"
              data-cy="reply-icon"
            >
              reply
            </i>
          )}
          {allowManipulation ? (
            <span>
              <i
                onClick={handleEditThread}
                className="material-icons md-15"
                title="edit"
                data-cy="edit-icon"
              >
                edit
              </i>
              <i
                onClick={handleDeleteThread}
                className="material-icons md-15"
                title="delete"
                data-cy="delete-icon"
              >
                delete
              </i>
            </span>
          ) : null}
        </div>
      </div>
      <div className="feedback-msg-body">{body}</div>
    </div>
  )
}
