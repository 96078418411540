import { useEffect, useState } from 'react'

export function useKeyPress(targetKey: string) {
  const [keyPressed, setKeyPressed] = useState(false)

  function downHandler({ key }: KeyboardEvent) {
    if (key === targetKey) {
      setKeyPressed(true)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
    }
  }, [])
  return keyPressed
}
