import { gql, useQuery } from '@apollo/client'
import {
  Template,
  TemplateSelectorItemsQuery,
  TemplateSelectorItemsQueryVariables
} from 'cl-studio'
import React from 'react'

export const TEMPLATE_ITEMS_QUERY = gql`
  query TemplateSelectorItems($explorerId: Int!) {
    explorer(id: $explorerId) {
      templates {
        id
        title
        start
        parentId
      }
    }
  }
`

interface OwnProps {
  explorerId: number,
  setTemplate: (t: Partial<Template>|undefined) => void,
}

export function TemplateSelector({ explorerId, setTemplate }: OwnProps) {
  const { loading, data, error } = useQuery<TemplateSelectorItemsQuery, TemplateSelectorItemsQueryVariables>(TEMPLATE_ITEMS_QUERY, {
    variables: {
      explorerId,
    },
  })

  if (loading) {
    return <div className="template-selector">Loading...</div>
  } else if (error) {
    return <div className="template-selector"></div>
  }

  const templates = Array.from(data?.explorer?.templates || [])
  templates.sort((a, b) => a.id - b.id)

  return (
    <div className="template-selector">
      <select
        data-cy="new-content-template"
        onChange={e => setTemplate(templates.find(t => t.id === Number(e.target.value)))}
      >
        <option value="null">Select template</option>
        {templates.map(t => {
          return (
            <option key={t.id} value={t.id}>
              {t.title}
            </option>
          )
        })}
      </select>
    </div>
  )
}
