import DateControl from '@kpv-lab/date-control'
import { Input } from '@kpv-lab/ui'
import React, { FC, Suspense, useState } from 'react'

import { VideoFormElement } from '../styles/VideoForm-css'

const LazyYoutubePlayer = React.lazy(() => import('react-player/youtube'))
const LazyFilePlayer = React.lazy(() => import('react-player/file'))

interface VideoData {
  alt?: string,
  attribution: string,
  credit?: string,
  description?: string,
  start?: string,
  _start?: string,
  isApproximateDate: boolean,
  src: string,
}

interface VideoFormProps {
  dateAcceptHandler: (
    val: Record<string, string | number>,
    isApproximateDate: boolean
  ) => void,
  handleInputChange: (data: string, val: string) => void,
  handleOnLoad: () => void,
  videoData: VideoData,
}

export const VideoForm: FC<VideoFormProps> = ({
  dateAcceptHandler,
  handleInputChange,
  handleOnLoad,
  videoData,
}) => {
  const [error, setError] = useState<string | null>(null)

  const link = videoData.attribution || videoData.src
  const Player = videoData.attribution ? LazyYoutubePlayer : LazyFilePlayer

  const handleError = (e: any) => {
    console.error('Video Error:', e)
    let errorMessage = 'There was an error loading the video.'

    if (e.target.error) {
      switch (e.target.error.code) {

        case e.target.error.MEDIA_ERR_ABORTED:
          errorMessage = 'The video playback was aborted.'
          break
        case e.target.error.MEDIA_ERR_NETWORK:
          errorMessage = 'A network error caused the video download to fail.'
          break
        case e.target.error.MEDIA_ERR_DECODE:
          errorMessage =
            'The video playback was aborted due to a corruption problem or because the video used features your browser did not support.'
          break
        case e.target.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
          errorMessage =
            'The video could not be loaded, either because the server or network failed or because the format is not supported.'
          break
        default:
          errorMessage = 'An unknown error occurred.'
          break

      }
    }

    setError(errorMessage)
  }

  return (
    <VideoFormElement data-cy="video-form">
      {link ? (
        <Suspense fallback={<div>Loading video...</div>}>
          <Player
            url={link}
            className="video-form--preview"
            data-cy="video-form-preview"
            controls={true}
            width="50%"
            height="100%"
            onReady={handleOnLoad}
            onError={handleError}
          />
        </Suspense>
      ) : (
        <div>Insert a YouTube url</div>
      )}
      {error && <div className="video-error">{error}</div>}
      <div className="item-field media-manager-controls">
        <Input
          structure={{
            type:    'text',
            options: {
              placeholder: 'Link',
              title:       'Link',
              disabled:    true,
              'data-cy':   'video-form-link',
            },
          }}
          value={videoData.attribution || videoData.src}
          updateHandler={handleInputChange}
          inline={false}
          dataKey="src"
        />
        <Input
          structure={{
            type:    'text',
            options: {
              placeholder: 'Title',
              title:       'Title',
              'data-cy':   'video-form-title',
            },
          }}
          value={videoData.alt}
          updateHandler={handleInputChange}
          inline={false}
          dataKey="alt"
        />
        <Input
          structure={{
            type:    'textarea',
            options: { placeholder: 'Description', title: 'Description' },
          }}
          value={videoData.description}
          updateHandler={handleInputChange}
          inline={false}
          dataKey="description"
        />
        <Input
          structure={{
            type:    'text',
            options: { placeholder: 'Attribution', title: 'Attribution' },
          }}
          value={videoData.credit}
          updateHandler={handleInputChange}
          inline={false}
          dataKey="credit"
        />

        <DateControl
          structure={{
            showHMS:                 undefined,
            type:                    'startDate',
            title:                   'Date',
            showApproximateCheckbox: true,
            isApproximateDate:       videoData.isApproximateDate,
          }}
          dateAcceptHandler={dateAcceptHandler}
          value={videoData.start || ''}
          dataKey="start"
          placeholder="Date"
        />
      </div>
    </VideoFormElement>
  )
}
