export const TEMPLATES_REQUEST = 'templates/TEMPLATES_REQUEST'
export const TEMPLATES_SUCCESS = 'templates/TEMPLATES_SUCCESS'
export const TEMPLATES_FAILURE = 'templates/TEMPLATES_FAILURE'

export const TEMPLATE_NEW = 'templates/TEMPLATE_NEW'
export const TEMPLATE_EDIT = 'templates/TEMPLATE_EDIT'
export const TEMPLATE_UPDATE = 'templates/TEMPLATE_UPDATE'
export const TEMPLATE_DELETE = 'templates/TEMPLATE_DELETE'
export const TEMPLATE_CANCEL = 'templates/TEMPLATE_CANCEL'
export const TEMPLATE_SAVE_REQUEST = 'templates/TEMPLATE_SAVE_REQUEST'
export const TEMPLATE_SAVE_SUCCESS = 'templates/TEMPLATE_SAVE_SUCCESS'
export const TEMPLATE_SAVE_FAILURE = 'templates/TEMPLATE_SAVE_FAILURE'
export const TEMPLATE_DELETE_REQUEST = 'templates/TEMPLATE_DELETE_REQUEST'
export const TEMPLATE_DELETE_SUCCESS = 'templates/TEMPLATE_DELETE_SUCCESS'
export const TEMPLATE_DELETE_FAILURE = 'templates/TEMPLATE_DELETE_FAILURE'
export const TEMPLATE_CHANGE_COUNT = 'templates/TEMPLATE_CHANGE_COUNT'
