import DateControl from '@kpv-lab/date-control'
import { Input } from '@kpv-lab/ui'
import React, { FC } from 'react'

import { UploadedMedia } from '../MediaManager'

interface AudioFormProps {
  uploadedMedia: UploadedMedia,
  handleInputChange: (data: string, val: string) => void,
  handleOnCanPlayThrough: () => void,
  handleOnError: () => void,
  dateAcceptHandler: (val: Record<string, string | number>, isApproximateDate: boolean) => void,
}

export const AudioForm: FC<AudioFormProps> = ({
  uploadedMedia,
  handleInputChange,
  handleOnError,
  handleOnCanPlayThrough,
  dateAcceptHandler,
}) => {


  return (
    <>
      <audio
        data-cy="audio-preview"
        src={uploadedMedia.src}
        controls
        onCanPlayThrough={handleOnCanPlayThrough}
        onError={handleOnError}
        controlsList="nodownload"
      />
      <div className="item-field media-manager-controls">
        <Input
          structure={{
            type:    'text',
            options: {
              placeholder: 'Title',
              title:       'Title',
              'data-cy':   'audio-alt-input',
            },
          }}
          value={uploadedMedia.alt}
          updateHandler={handleInputChange}
          inline={false}
          dataKey="alt"
        />
        <Input
          structure={{
            type:    'textarea',
            options: { placeholder: 'Description', title: 'Description' },
          }}
          value={uploadedMedia.description}
          updateHandler={handleInputChange}
          inline={false}
          dataKey="description"
        />
        <Input
          structure={{
            type:    'text',
            options: { placeholder: 'Attribution', title: 'Attribution' },
          }}
          value={uploadedMedia.credit}
          updateHandler={handleInputChange}
          inline={false}
          dataKey="credit"
        />
        <Input
          structure={{
            type:    'text',
            options: { placeholder: 'Link', title: 'Link' },
          }}
          value={uploadedMedia.attribution}
          updateHandler={handleInputChange}
          inline={false}
          dataKey="attribution"
        />
        <DateControl
          structure={{
            showHMS:                 undefined,
            type:                    'startDate',
            title:                   'Date',
            showApproximateCheckbox: true,
            isApproximateDate:       uploadedMedia.isApproximateDate,
          }}
          dateAcceptHandler={dateAcceptHandler}
          value={uploadedMedia.start}
          dataKey="start"
          placeholder="Date"
        />
      </div>
    </>
  )
}