import '../styles/SliderScale.css'

import React, { PureComponent } from 'react'

type Props = {
  min: number,
  max: number,
  tick: number,
};

export default class SliderScale extends PureComponent<Props> {

  render() {
    const { min, max, tick } = this.props
    const ticks = generateTicks(min, max, tick)

    return (
      <svg className="ui-slider-scale">
        <g className="minor-ticks">{ticks.minor}</g>
        <g className="major-ticks">{ticks.major}</g>
      </svg>
    )
  }

}

export function generateTicks(min: number, max: number, tick: number) {
  const range = max - min
  const tickStep = setTickStep(min, max, tick)
  const majorTicks: Array<JSX.Element> = []
  const minorTicks: Array<JSX.Element> = []
  let x = 0
  let i = 1
  let p: number

  while (x < 100) {
    p = (i * tickStep) / range
    x = Number((p * 100 + 0.5 - p).toFixed(1)) // 0.5 - p is the stroke offset

    if (x >= 99.5) {
      break
    }

    const tickMark = <line x1={x + '%'} x2={x + '%'} y1="0" y2="100%" key={i} />

    if (Number(p.toFixed(3)) === 0.5) {
      majorTicks.push(tickMark)
    } else {
      minorTicks.push(tickMark)
    }

    i++
  }

  return {
    minor: minorTicks,
    major: majorTicks,
  }
}

export function setTickStep(min: number, max: number, tick: number) {
  const range = max - min
  let tickStep = tick

  if (range <= 2) {
    tickStep = tick > 0.1 ? tick : 0.1
  } else if (range <= 10) {
    tickStep = tick > 0.5 ? tick : 0.5
  } else if (range <= 20) {
    tickStep = tick > 1 ? tick : 1
  } else if (range <= 100) {
    tickStep = tick > 5 ? tick : 5
  } else if (range === 255) {
    tickStep = tick > 32 ? tick : 32
  } else if (range <= 360) {
    tickStep = tick > 30 ? tick : 30
  } else {
    // set automatically
    let count = Math.ceil(range / tickStep)

    while (count > 40) {
      tickStep *= 10
      count = Math.ceil(range / tickStep)
    }
  }

  return tickStep
}
/* eslint-enable complexity */
