import { Reducer } from '@cls/redux'
import { produce } from 'immer'
import { AnyAction } from 'redux'

import * as types from './action-types'

interface OwnState {
  editing: boolean,
  error: string,
  loggedIn: boolean,
  pending: boolean,
  profile: any,
  resetUser: any,
}

const initialState: OwnState = {
  profile:   null,
  pending:   false,
  loggedIn:  false,
  error:     '',
  editing:   false,
  resetUser: null,
}

export default function reducer(): Reducer<OwnState, AnyAction> {
  return produce((draftState: OwnState, action = {}) => {
    switch (action.type) {

      case types.LOGIN_REQUEST:
        draftState.pending = true
        draftState.loggedIn = false
        draftState.profile = null
        draftState.error = ''
        draftState.editing = false
        break

      case types.RESET_PASSWORD_SUCCESS:
        draftState.pending = false
        draftState.resetUser = action.data
        break

      case types.RESET_PASSWORD_STATE:
        draftState.pending = false
        draftState.resetUser = null
        draftState.error = ''
        break

      case types.RESET_PASSWORD_FAILURE:
        draftState.pending = false
        draftState.error = action.data
        draftState.resetUser = null
        break

      case types.RESET_PASSWORD_REQUEST:
        draftState.pending = true
        draftState.resetUser = null
        break

      case types.LOGIN_SUCCESS:
      case types.EDIT_ACCOUNT_SUCCESS:
        draftState.pending = false
        draftState.loggedIn = true
        draftState.profile = action.data.profile
        draftState.error = ''
        draftState.editing = false
        break

      case types.LOGIN_FAILURE:
        draftState.pending = false
        draftState.loggedIn = false
        draftState.profile = null
        draftState.error = action.data
        draftState.editing = false
        break

      case types.EDIT_ACCOUNT_FAILURE:
        draftState.pending = false
        draftState.error = action.data
        draftState.editing = true
        break

      case types.LOGOUT: {
        draftState.pending = false
        draftState.loggedIn = false
        draftState.profile = null
        draftState.error = ''
        draftState.editing = false
        break
      }

      case types.VIEW_ACCOUNT:
        draftState.pending = false
        draftState.error = ''
        draftState.editing = action.data
        break

      case types.SIGNUP_REQUEST:
        draftState.pending = true
        break

      case types.SIGNUP_SUCCESS:
        draftState.pending = false
        break

      case types.SIGNUP_FAILURE:
        draftState.pending = false
        draftState.error = action.data
        break

    }
  }, initialState)

}

