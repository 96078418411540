import { MediaItem } from './types'

/**
 * Add path, alt and positions properties to mediaItem extracting information from RichText
 */
export const migrateUsingRichText = (mediaItem: MediaItem, richText: string) => {
  const allFound = richText.match(globalImageRegex)

  const found = allFound.findIndex(i => i.includes(mediaItem.mediaId))
  if (found < 0) {
    return mediaItem
  }

  const { alt, filename, title } = allFound[found].match(imageRegex).groups
  if (!mediaItem.path && filename) {
    mediaItem.path = extractPathFromImageUrl(filename, mediaItem.mediaId)
  }
  if (!mediaItem.alt && (alt || title)) {
    mediaItem.alt = alt || title
  }
  if (!mediaItem.type) {
    mediaItem.type = 'image'
  }


  return mediaItem
}

export const migrateUsingImageItem = (mediaItem: MediaItem, imageItem: any) => {
  const map: Record<keyof MediaItem, string> = {
    align:       'align',
    alt:         'title',
    attribution: 'link',
    credit:      'credit',
    description: 'description',
    mediaId:     'mediaId',
    options:     'options',
    path:        'path',
    preset:      'preset',
    positions:   '',
    start:       'start',
    type:        'type',
    _start:      'start',
  }

  const entries = Object.entries(map) as Array<[keyof MediaItem, string]>
  entries.forEach(([mediaItemProp, imageProp]) => {
    if (!mediaItem[mediaItemProp] && imageProp && imageItem[imageProp]) {
      mediaItem[mediaItemProp] = imageItem[imageProp]
    }
  })

  return mediaItem
}

/**
 * https://regex101.com/r/jBlgxj/3
 */
const imageRegex = /!\[(?<alt>[^\]]+)?\]\((?<filename>.*?)(?= "|\))( "(?<title>.*)")?\)/
const globalImageRegex = /!\[(?<alt>[^\]]+)?\]\((?<filename>.*?)(?= "|\))( "(?<title>.*)")?\)/g

export const extractPathFromImageUrl = (imageUrl: string, mediaId = '\\w+') => {
  // Extract a string of "images/<directory name>/<file name>.<extension>". Leave anything
  // either side of that behind
  const regex = new RegExp(`.*(images/\\w+/${mediaId}\\.\\w{1,4})\\??.*`)
  const found = imageUrl.match(regex)
  return found?.[1] || undefined
}
