import React, { ReactNode } from 'react'

import { MultimediaFieldElement } from '../styles/MultimediaField-css'

interface Props {
  className?: string,
  label?: ReactNode,
  field?: string,
}

export const MultimediaField: React.FC<Props> = ({ className, children, label, field }) => {
  const useChildrenAsLabel = children && !label

  const labelClass = `multimedia-field--label ${useChildrenAsLabel ? 'multimedia-field--label-full' : ''}`

  return <MultimediaFieldElement className={className} data-cy={`multimedia-field-${field}`}>
    <span className={labelClass} data-cy="multimedia-label">
      {useChildrenAsLabel ? children : label}
    </span>
    {<span className="multimedia-field--value" data-cy="multimedia-value">
      {children && !useChildrenAsLabel ? children : <i>No value</i>}
    </span>}
  </MultimediaFieldElement>
}
