import styled from '@emotion/styled'

export const MultimediaItemElement = styled.div`
  label: multimedia-item;
  margin: 0 0 var(--form-spacing);
  background-color: rgba(255, 255, 255, 0.4);

  .multimedia-item--title {
    font-size: var(--font-size-normal);
    margin: 1.5em 0 1.5em;
    text-align: center;
  }

  .multimedia-item--content {
    display: flex;
    flex-direction: row;
    padding: var(--form-spacing);

    &.editing {
      border: 1px solid var(--ui-active-color);
      background-color: var(--field-active-color);
    }

    &:hover {
      background-color: var(--field-active-color);
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
  }

  .multimedia-item--image {
    display: flex;
    align-items: center;
    width: 15em;
    padding: 0.5em;
    box-sizing: content-box;
    justify-content: center;
    height: calc(100% - 1em);

    img {
      max-width: 15em;
    }
  }

  .multimedia-item--audio {
    display: grid;
    place-items: center;
    padding: 0.5em;
  }

  .multimedia-item--fields {
    flex-grow: 1;
    margin-left: 0.5em;
    padding: 0 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .multimedia-field--input {
    background-color: #EFEFEF;
  }
`
