import * as types from './action-types'

export function showMultimediaPopup(data: { itemId: number, title: string, templateId: number }) {
  return <const>{
    type:       types.SHOW_MULTIMEDIA_POPUP,
    itemId:     data.itemId,
    templateId: data.templateId,
    title:      data.title,
  }
}

export function hideMultimediaPopup() {
  return <const>{
    type: types.HIDE_MULTIMEDIA_POPUP,
  }
}

export type HideMultimediaPopup = ReturnType<typeof hideMultimediaPopup>
export type ShowMultimediaPopup = ReturnType<typeof showMultimediaPopup>

export type MultimediaActions = ShowMultimediaPopup
| HideMultimediaPopup
