/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { gql, useQuery } from '@apollo/client'
import { RelatedItemsQueryQuery, RelatedItemsQueryQueryVariables, Template } from 'cl-studio'
import React from 'react'

import { itemFragment } from './apollo_fragments/items'
import TemplateItems from './components/TemplateItems'

const RELATED_ITEMS_QUERY = gql`
  query RelatedItemsQuery($itemId: Int) {
    templates {
      id
      title
      parentId
    }
    item(id: $itemId) {
      ...itemFields
    }
    childItems(id: $itemId) {
      ...itemFields
    }
    relatedItems(id: $itemId) {
      ...itemFields
    }
  }
  ${itemFragment}
`

interface OwnProps {
  clear: () => void,
  itemId: number,
  toggleToolbar: () => void,
}

function RelatedItemsView({ toggleToolbar, clear, itemId }: OwnProps) {
  const { data } = useQuery<RelatedItemsQueryQuery, RelatedItemsQueryQueryVariables>(RELATED_ITEMS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables:   {
      itemId: itemId,
    },
  })

  if (!data || !data.relatedItems || !data.templates) {
    return <div className="result-box" data-cy="related-items" />
  }

  {/* @ts-expect-error Not sure why it complains about childItems, but not relatedItems here */}
  const items = [...data.childItems, ...data.relatedItems, data.item]

  const childTemplateIds = data.templates
    .filter((t: any) => t.parentId === data.item!.templateId)
    .map((t: any) => t.id)

  return (
    <div className="result-box" data-cy="related-items">
      <div className="result-box-tag-info">
        Items related to <strong>{data.item!.title}</strong>{' '}
        <i className="material-icons md-16" onClick={() => clear()}>
          close
        </i>
      </div>
      <TemplateItems
        items={items}
        templates={data!.templates as Array<Template>}
        toggleToolbar={toggleToolbar}
        mustShowTemplateIds={childTemplateIds}
      />
    </div>
  )
}

export default RelatedItemsView
