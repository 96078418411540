import '../styles/ColorEditor.css'

import * as ColorUtils from '@kpv-lab/color-utils'
import React, { PureComponent } from 'react'

import { hideColorEditor, setColor, setColorChannel } from '../color-editor-redux'
import { saveSwatch } from '../swatches-redux'
import ColorSwatches from './ColorSwatches'
import Divider from './Divider'
import DraggablePanel from './DraggablePanel'
import Slider from './Slider'

export interface Color {
  hue: number,
  sat: number,
  lgt: number,
  red: number,
  green: number,
  blue: number,
}

export function hslStyle(color: Color, id: string, bodyStyle, barStyle, handleStyle, labelStyle, valueStyle) {
  let c1: string, c2: string, l0: number, l1: number, l2: number
  const h = color.hue || 0
  const s = color.sat || 0
  const l = color.lgt || 0

  if (id === 'sat') {
    c1 = `hsl(${h}, 0%, ${l}%)`
    c2 = `hsl(${h}, 100%, ${l * 0.5}%)`
    l0 = ColorUtils.hsl2lum(h, s, l)
    l1 = ColorUtils.hsl2lum(h, 0, l)
    l2 = ColorUtils.hsl2lum(h, 100, l * 0.5)
  } else {
    c1 = `hsl(${h}, ${s}%, 0%)`
    c2 = `hsl(${h}, ${s}%, ${100 - s * 0.5}%)`
    l0 = l / 100
    l1 = 0
    l2 = ColorUtils.hsl2lum(h, s, 100 - s * 0.5)
  }

  barStyle.backgroundColor = 'transparent'
  barStyle.boxShadow = 'none'
  bodyStyle.backgroundImage = `linear-gradient(to right, ${c1}, ${c2})`
  handleStyle.backgroundColor = l0 > 0.7 ? '#000' : '#FFF'
  handleStyle.boxShadow = l0 > 0.7 ? '0 0 1px #FFF' : '0 0 1px #000'
  labelStyle.color = l1 > 0.7 ? '#000' : '#FFF'
  valueStyle.color = l2 > 0.7 ? '#000' : '#FFF'
}

export function rgbStyle(color: Color, id: string, bodyStyle, barStyle, handleStyle, labelStyle, valueStyle) {
  const red = Math.round(color.red)
  const green = Math.round(color.green)
  const blue = Math.round(color.blue)

  const idx = { red: 0, green: 1, blue: 2 }
  const rgb1 = [red, green, blue]
  const rgb2 = [red, green, blue]

  rgb1[idx[id]] = 0
  rgb2[idx[id]] = 255

  const c1 = `rgb(${rgb1[0]}, ${rgb1[1]}, ${rgb1[2]})`
  const c2 = `rgb(${rgb2[0]}, ${rgb2[1]}, ${rgb2[2]})`
  const l0 = ColorUtils.rgb2lum(red, green, blue)
  const l1 = ColorUtils.rgb2lum(rgb1[0], rgb1[1], rgb1[2])
  const l2 = ColorUtils.rgb2lum(rgb2[0], rgb2[1], rgb2[2])

  barStyle.backgroundColor = 'transparent'
  barStyle.boxShadow = 'none'
  bodyStyle.backgroundImage = `linear-gradient(to right, ${c1}, ${c2})`
  handleStyle.backgroundColor = l0 > 0.7 ? '#000' : '#FFF'
  handleStyle.boxShadow = l0 > 0.7 ? '0 0 1px #FFF' : '0 0 1px #000'
  labelStyle.color = l1 > 0.7 ? '#000' : '#FFF'
  valueStyle.color = l2 > 0.7 ? '#000' : '#FFF'
}

type Props = {
  dispatch: (...args: Array<any>) => any,
  color: any,
  swatches: Array<any>,
  swatchCount: number,
  inline: boolean,
};

type State = {
  hex: string,
  active: boolean,
};

export default class ColorEditor extends PureComponent<Props, State> {

  state = { hex: '', active: false }
  _timeout = 0
  _interacting = false

  sliderHandler = (id, val) => {
    this.props.dispatch(setColorChannel(id, val))
  }

  interactionHandler = interacting => {
    this.setState({ active: interacting })
  }

  closeHandler = () => {
    this.props.dispatch(hideColorEditor())
  }

  resetHandler = () => {
    const { dispatch, color } = this.props
    dispatch(setColor(color.originalHex, color.originalAlpha))
  }

  acceptHandler = () => {
    this.props.dispatch(hideColorEditor())
  }

  hexFocusHandler = () => {
    const { color } = this.props
    this._interacting = true
    const hex = ColorUtils.rgba2hex(color.red, color.green, color.blue)
    this.setState({ hex: hex })
  }

  hexChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { hex } = this.state
    this._interacting = true
    const val = '#' + event.target.value.replace(/[^\da-f]+/i, '').toUpperCase()

    if (val !== hex) {
      this.setState({ hex: val })
    }
  }

  hexKeyHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13 || event.keyCode === 27) {
      (event.target as HTMLInputElement).blur()
    }
  }

  hexBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    const { color, dispatch } = this.props
    const val = '#' + event.target.value.replace(/[^\da-f]+/i, '').toUpperCase()
    this.setState({ hex: val })
    this._interacting = false
    dispatch(setColor(val, color.alpha))
  }

  clearColorHandler = () => {
    this.props.dispatch(setColor(''))
  }

  saveSwatchHandler = idx => {
    const { color, dispatch } = this.props
    dispatch(
      saveSwatch(idx, [
        parseInt(color.red, 10),
        parseInt(color.green, 10),
        parseInt(color.blue, 10),
        color.alpha.toFixed(3) * 1,
      ])
    )
  }

  _hslStyle = (...args) => {
    // @ts-ignore: We're spreading props, so argument counting doesn't work
    hslStyle(this.props.color, ...args)
  }

  _rgbStyle = (...args) => {
    // @ts-ignore: We're spreading props, so argument counting doesn't work
    rgbStyle(this.props.color, ...args)
  }

  render() {
    const { dispatch, color, swatches, swatchCount, inline } = this.props
    const { hex, active } = this.state


    let hexCol = ColorUtils.rgba2hex(color.red, color.green, color.blue)

    if (this._interacting) {
      hexCol = hex
    }

    const originalStyle = {
      backgroundColor: color.originalHex,
    }

    const newStyle = {
      backgroundColor: hexCol,
    }

    let newColorClass = 'color-editor-swatch new-color'

    if (color.blank) {
      newColorClass += ' blank-color'
    }

    let divider, alphaSlider

    if (color.showAlpha) {
      divider = <Divider />
      alphaSlider = (
        <Slider
          id="alpha"
          className="alpha-slider"
          label="Opacity"
          min={0}
          max={1}
          step={0.01}
          exact={false}
          value={color.alpha}
          updateHandler={this.sliderHandler}
          interactionHandler={this.interactionHandler}
        />
      )
    }

    const ctrls = (
      <section className="color-editor-ctrls">
        <div className="color-editor-swatches">
          <span
            className="color-editor-swatch original-color"
            style={originalStyle}
            onClick={this.resetHandler}
          >
            <i className="material-icons color-editor-reset">cached</i>
          </span>
          <span className={newColorClass} style={newStyle} onClick={this.acceptHandler}>
            <i className="material-icons color-editor-accept">done</i>
          </span>
        </div>

        <section className="ui-field ui-input ui-input-text color-hexes">
          <label htmlFor="colorHex" className="hex-label">
            {color.originalHex}
          </label>
          <span className="color-editor-clear-btn blank-color" onClick={this.clearColorHandler} />
          <input
            type="text"
            value={hexCol}
            onFocus={this.hexFocusHandler}
            onChange={this.hexChangeHandler}
            onKeyUp={this.hexKeyHandler}
            onBlur={this.hexBlurHandler}
            id="colorHex"
            maxLength={7}
            className="hex-input"
          />
        </section>

        <Slider
          id="hue"
          className="hue-slider"
          label="Hue"
          min={0}
          max={360}
          step={1}
          tick={30}
          exact={true}
          value={color.hue}
          wrap={true}
          updateHandler={this.sliderHandler}
          interactionHandler={this.interactionHandler}
        />

        <Slider
          id="sat"
          className="sat-slider"
          label="Sat."
          min={0}
          max={100}
          step={0.2}
          tick={10}
          exact={true}
          value={color.sat}
          updateHandler={this.sliderHandler}
          interactionHandler={this.interactionHandler}
          customStyling={this._hslStyle}
        />

        <Slider
          id="lgt"
          className="lgt-slider"
          label="Light."
          min={0}
          max={100}
          step={0.2}
          tick={10}
          exact={true}
          value={color.lgt}
          updateHandler={this.sliderHandler}
          interactionHandler={this.interactionHandler}
          customStyling={this._hslStyle}
        />

        <Divider />

        <Slider
          id="red"
          className="red-slider"
          label="Red"
          min={0}
          max={255}
          step={1}
          tick={32}
          exact={true}
          value={color.red}
          updateHandler={this.sliderHandler}
          interactionHandler={this.interactionHandler}
          customStyling={this._rgbStyle}
        />

        <Slider
          id="green"
          className="green-slider"
          label="Green"
          min={0}
          max={255}
          step={1}
          tick={32}
          exact={true}
          value={color.green}
          updateHandler={this.sliderHandler}
          interactionHandler={this.interactionHandler}
          customStyling={this._rgbStyle}
        />

        <Slider
          id="blue"
          className="blue-slider"
          label="Blue"
          min={0}
          max={255}
          step={1}
          tick={32}
          exact={true}
          value={color.blue}
          updateHandler={this.sliderHandler}
          interactionHandler={this.interactionHandler}
          customStyling={this._rgbStyle}
        />

        {divider}
        {alphaSlider}

        <Divider />
        <ColorSwatches
          dispatch={dispatch}
          swatches={swatches}
          saveSwatch={this.saveSwatchHandler}
          swatchCount={swatchCount}
        />
      </section>
    )

    let className = `${inline ? 'ui-panel' : 'ui-floating-panel'} color-editor`
    if (active) {
      className += ' active'
    }

    const close = (
      <i className="material-icons ui-close-btn" onClick={this.closeHandler}>
        close
      </i>
    )

    const l = color.label
    const label = (l && l.length ? l[0] : l) || 'Color Editor'

    if (inline) {
      return (
        <div className={className}>
          <h2 className="ui-label color-editor-title">{label}</h2>
          {close}
          {ctrls}
        </div>
      )
    } else {
      return (
        <DraggablePanel className={className} name="colorEditor">
          <h2 className="ui-title color-editor-title draggable">{label}</h2>
          {close}
          {ctrls}
        </DraggablePanel>
      )
    }
  }

}
