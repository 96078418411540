import * as types from './action-types'

interface ShowProps {
  dataKey: string,
  start: string,
  end: string,
  features: Array<any>,
  prevFeatures: Array<any>,
  updateHandler: ({ features }: { features: any}) => void,
}

export function showMapEventsEditor(data: ShowProps) {
  return <const>{
    type: types.SHOW_MAPEVENTS_EDITOR,
    data: data,
  }
}

export function hideMapEventsEditor() {
  return <const>{
    type: types.HIDE_MAPEVENTS_EDITOR,
  }
}

export type ShowMapEventsEditor = ReturnType<typeof showMapEventsEditor>
export type HideMapEventsEditor = ReturnType<typeof hideMapEventsEditor>
