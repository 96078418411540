import { useMutation } from '@apollo/client'
import { updateUserMutation, updateUserMutationVariables } from 'cl-studio'
import React, { SyntheticEvent, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Dispatch } from 'redux'

import { UPDATE_USER } from '../../user-manager/graphql/users'
import { editAccountFailure, editAccountSuccess, viewAccount } from '../state/actions-account'
import { FeatureFlags } from './FeatureFlags'

interface AccountProps {
  dispatch: Dispatch,
  user: any,
}

export const AccountComponent: React.FC<AccountProps> = ({ user, dispatch }) => {
  const refEmail = useRef<HTMLInputElement>(null)
  const refName = useRef<HTMLInputElement>(null)
  const refPassword = useRef<HTMLInputElement>(null)

  const [email, setEmail] = useState(user.profile.email)
  const [name, setName] = useState(user.profile.name)
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')

  const [error, setError] = useState<string|null>(null)
  const [hiddenClickCount, setHiddenClickCount] = useState(0)
  const [showFlags, setShowFlags] = useState(false)

  const [updateUser, { loading } ] = useMutation<updateUserMutation, updateUserMutationVariables>(UPDATE_USER, {
    variables: {
      user: {
        id:       user.profile.id,
        role:     user.profile.role,
        email,
        name,
        password: password1 && password2 && password1.length >= 6 ? password1 : undefined,
      },
    },
  })

  const focus = (el: React.RefObject<HTMLElement>|null) => el && el.current && el.current.focus()
  const cancelHandler = (event: any) => {
    if (event.target === event.currentTarget) {
      event.preventDefault()
      event.stopPropagation()
      dispatch(viewAccount(false))
    }
  }
  const submitHandler = (event: SyntheticEvent) => {
    event && event.preventDefault()

    let errorMessage = ''

    if (!email || email.length < 7) {
      errorMessage = 'A valid email is required'
      focus(refEmail)
    } else if (!name || name.length < 2) {
      errorMessage = 'A name of 2+ letters is required'
      focus(refName)
    } else if ((password1 || password2) && password1 !== password2) {
      errorMessage = "Passwords don't match"
      focus(refPassword)
    } else if (password1 && password2 && password1.length < 6) {
      errorMessage = 'Password requires 6+ characters'
      focus(refPassword)
    }

    if (errorMessage.length > 0) {
      setError(errorMessage)
    } else {
      updateUser()
        .then(({ data }) => {
          if (data) {
            const updatedUser = data.updateUser
            if (updatedUser) {
              const profile = {
                ...updatedUser,
                id:   Number(updatedUser.id),
                body: {
                  ...updatedUser.body,
                  feedback: updatedUser.feedback,
                },
              }
              delete profile.feedback

              dispatch(editAccountSuccess({ profile }))
            }
          }
        })
        .catch((e) => {
          console.error({ e })
          dispatch(editAccountFailure({ response: { status: 403, statusText: 'Invalid token' } }))
        })
    }
  }

  if (showFlags) {
    return <FeatureFlags />
  }

  return (
    <div className="overlay" onClick={cancelHandler}>
      <form
        method="post"
        className="form account-form center"
        onSubmit={submitHandler}
        autoComplete="off"
      >
        <h1
          className="account-title"
          onClick={() => hiddenClickCount >= 4 ? setShowFlags(true) : setHiddenClickCount(hiddenClickCount + 1)}
        >Your Details</h1>
        {error && <p className="form-error">{error}</p>}
        <fieldset className="fieldset">
          <p className="form-row">
            <label htmlFor="user-email">Email</label>
            <input
              type="email"
              placeholder="Email"
              name="email"
              id="user-email"
              data-id="email"
              className="input input-email block"
              ref={refEmail}
              title="Email"
              onChange={e => setEmail(e.target.value)}
              value={email}
            />
          </p>

          <p className="form-row">
            <label htmlFor="user-name">Name</label>
            <input
              type="text"
              placeholder="Name"
              name="name"
              id="user-name"
              data-id="name"
              className="input input-name block"
              ref={refName}
              title="Name"
              onChange={e => setName(e.target.value)}
              value={name}
            />
          </p>

          <p className="form-row">
            <span className="help">Only enter a new password to change it</span>
            <input
              type="password"
              placeholder="Password"
              name="password1"
              id="user-password1"
              data-id="password1"
              className="input input-password block"
              ref={refPassword}
              onChange={(e) => setPassword1(e.target.value)}
              value={password1}
            />
            <input
              type="password"
              placeholder="Password confirmation"
              name="password2"
              id="user-password2"
              data-id="password2"
              className="input input-password block"
              onChange={(e) => setPassword2(e.target.value)}
              value={password2}
            />
          </p>
        </fieldset>

        <fieldset className="fieldset">
          <button
            type="button"
            className="btn btn-secondary cancel-button"
            onClick={cancelHandler}
          >
              Cancel
          </button>
          <button type="submit" className="btn btn-primary save-button" disabled={loading}>
            {loading ? 'Updating...' : 'Save' }
          </button>
        </fieldset>

        <fieldset className="fieldset">
          <Link to="/logout" className="logout-link">
              LOGOUT
          </Link>
        </fieldset>
      </form>
    </div>
  )
}
const Account = connect()(AccountComponent)
export { Account }
