import { RootState } from '@cls/redux'
import React, { PureComponent } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { flashReset as flashResetAction } from './state/flash-redux'

const flashDuration = 4 * 1000

const mapStateToProps = (state: RootState)=> ({
  message: state.flash.message,
  kind:    state.flash.kind,
})

const connector = connect(mapStateToProps, { flashReset: flashResetAction })

type TypesFromRedux = ConnectedProps<typeof connector>

export class Flash extends PureComponent<TypesFromRedux> {

  _message: string;
  _timeout: number;

  constructor(props: TypesFromRedux) {
    super(props)
    this._timeout = 0
    this._message = ''
  }

  hideFlash = () => {
    window.clearTimeout(this._timeout)
    this.props.flashReset()
  }

  render() {
    const { message, kind } = this.props
    let className = `flash flash-${kind || 'notice'}`

    if (message) {
      className += ' active'
      window.clearTimeout(this._timeout)
      this._timeout = window.setTimeout(this.hideFlash, flashDuration)
      // we use a local state so that the message hides gracefully when
      // the active css class is removed
      this._message = message
    }

    return (
      <div className={className} onClick={this.hideFlash}>
        <div data-cy="flash-message">
          {this._message}
        </div>
        <i className="icon md-16 flash-close-icon" data-cy="close-flash">close</i>
      </div>
    )
  }

}


export default connect(mapStateToProps, { flashReset: flashResetAction })(Flash)
