import '../styles/HTMLPanel.css'

import React, { PureComponent } from 'react'

type Props = {
  structure?: any,
  postRender?: (...args: Array<any>) => any,
};

export default class HTMLPanel extends PureComponent<Props> {

  render() {
    const { structure, postRender } = this.props

    const className = `ui-i ui-html ${structure.className || ''}`

    const output = (
      <section className={className} dangerouslySetInnerHTML={{ __html: structure.value }} />
    )

    return postRender ? postRender(structure, output) : output
  }

}
