import { ActionHandler, createReducer } from '@cls/redux'
import { AnyAction } from 'redux'

import undoable from '../../content/state/undoable'
import * as types from './action-types'

export interface OwnState {
  editTemplate?: typeof starterTemplate,
  templateStatus: string,
}

export const starterTemplate = {
  id:       -1,
  title:    'New template',
  fields: [
    {
      id:          'title',
      type:        'title',
      timelineVis: true,
      label:       'Title',
      group:       'Content',
    },
  ],
}

const initialState: OwnState = {
  editTemplate:   undefined,
  templateStatus: '',
}

const setTemplateToData: ActionHandler<OwnState, AnyAction> = (draftState, { data }) => {
  draftState.editTemplate = data
}

const setTemplateStatusToData: ActionHandler<OwnState, AnyAction> = (draftState, { data }) => {
  draftState.templateStatus = data
}

const actionHandlers = new Map<string, ActionHandler<OwnState, AnyAction>>([
  [types.TEMPLATE_EDIT, setTemplateToData],
  [types.TEMPLATE_UPDATE, setTemplateToData],
  [types.TEMPLATE_SAVE_FAILURE, setTemplateStatusToData],
  [types.TEMPLATE_DELETE_FAILURE, setTemplateStatusToData],
  [types.TEMPLATE_NEW, (draftState) => {
    draftState.editTemplate = starterTemplate
  }],
  [types.TEMPLATE_CANCEL, (draftState) => {
    draftState.editTemplate = undefined
  }],
  [types.TEMPLATE_SAVE_SUCCESS, (draftState, { data }) => {
    draftState.templateStatus = ''
    if (draftState.editTemplate) {
      draftState.editTemplate.id = data
    }
  }],
  [types.TEMPLATE_DELETE_SUCCESS, (draftState) => {
    draftState.templateStatus = ''
    draftState.editTemplate = undefined
  }],
])

export function reducer() {
  return createReducer(initialState, actionHandlers)
}

export default undoable<ReturnType<typeof reducer>, OwnState>(reducer(), {
  scope:          'templates',
  limit:          30,
  delay:          1000,
  includeActions: [types.TEMPLATE_UPDATE],
})
