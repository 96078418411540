import './styles/UI.css'

import React, { PureComponent } from 'react'

import Panel from './components/Panel'

type OwnProps = {
  dispatch?: (...args: Array<any>) => any,
  dataPath?: string,
  uiPath?: string,
  keyPath?: string,
  className?: string,
  structure?: any,
  data?: any,
  components?: any,
  scope?: string,
  updateHandler?: (...args: Array<any>) => any,
  postRender?: (...args: Array<any>) => any,
  categories?: any,
  s3Upload?: any,
};

type Props = OwnProps & typeof UI.defaultProps;

export default class UI extends PureComponent<Props> {

  static defaultProps = {
    uiPath:    '',
    keyPath:   '',
    className: 'ui',
  }

  render() {
    const { className, structure } = this.props

    if (!structure) {
      return false
    }

    return (
      <div className={className}>
        <Panel {...this.props} active={true} depth={0} />
      </div>
    )
  }

}
