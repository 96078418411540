import { gql, useQuery } from '@apollo/client'
import { RootState } from '@cls/redux'
import { LatestItemsQuery, LatestItemsQueryVariables } from 'cl-studio'
import React from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { itemFragment } from './apollo_fragments/items'
import ItemsList from './components/ItemsList'

const LATEST_ITEMS_QUERY = gql`
  query LatestItems($workingIds: [Int!]!, $explorerId: Int!) {
    itemsList(ids: $workingIds, explorerId: $explorerId) {
      ...itemFields
    }
    latestEditedItems(explorerId: $explorerId) {
      ...itemFields
    }
    latestFeedbackItems(explorerId: $explorerId) {
      ...itemFields
    }
  }
  ${itemFragment}
`

const mapStateToProps = (state: RootState) => {
  const workingIds = Object.keys(state.versions.workingVersions).map(Number).filter(id => id > 0)
  return {
    workingIds: workingIds,
  }
}

interface LatestItemsViewProps {
  explorerId: number,
  toggleToolbar: () => void,
}

const connector = connect(mapStateToProps)

type TypesFromRedux = ConnectedProps<typeof connector>
type AllProps = TypesFromRedux & LatestItemsViewProps

const LatestItemsView: React.FC<AllProps> = ({ toggleToolbar, workingIds, explorerId }) => {
  const { loading, data, error } = useQuery<LatestItemsQuery, LatestItemsQueryVariables>(LATEST_ITEMS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables:   {
      workingIds,
      explorerId,
    },
    skip: !explorerId,
  })

  if (error) {
    console.error(error)
  }

  if (loading || !data) {
    return null
  }
  const workingItems = data.itemsList || []
  const latestEditedItems = data.latestEditedItems || []
  const latestFeedbackItems = data.latestFeedbackItems || []

  return (
    <div className="result-box" data-cy="latest-items">
      <div className="result-box-tag-info" />
      <div className="items-container">
        <ItemsList
          title={'Working Versions'}
          items={workingItems as Array<any>}
          toggleToolbar={toggleToolbar}
          showType={true}
        />
        <ItemsList
          title={'Latest Edits'}
          items={latestEditedItems as Array<any>}
          toggleToolbar={toggleToolbar}
          showType={true}
        />
        <ItemsList
          title={'Latest Feedback'}
          items={latestFeedbackItems as Array<any>}
          toggleToolbar={toggleToolbar}
          showType={true}
        />
      </div>
    </div>
  )
}

export default connector(LatestItemsView)
