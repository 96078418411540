import { produce } from 'immer'

import {
  HIDE_REFERENCE_POPUP,
  ReferencePopupAction,
  ReferencePopupData,
  SHOW_REFERENCE_POPUP } from './reference-popup-types'

const initialState: ReferencePopupData = {
  visible:      false,
  title:        '',
  dataKey:      '',
  id:           0,
  page:         '',
}

export function reducer() {
  return produce((draftState, action: ReferencePopupAction) => {
    switch (action.type) {

      case SHOW_REFERENCE_POPUP: {
        const {
          id,
          page,
          dataKey,
          title,
          closeHandler,
          additionalInfo,
        } = action.data
        draftState.visible = true
        draftState.id = id
        draftState.page = page
        draftState.additionalInfo = additionalInfo
        draftState.dataKey = dataKey
        draftState.title = title || ''
        draftState.closeHandler = closeHandler
        break
      }

      case HIDE_REFERENCE_POPUP:
        return initialState

    }
  }, initialState)

}
