/**
 * Unsafe deep setIn functions. Mutates source object.
 */
export function setIn(source: Record<string, unknown>, path: string|Array<string|number>, newValue: unknown) {
  const keys = (typeof path === 'string' ? path.split('.') : path).filter(k => k)

  if (!keys.length) {
    return newValue
  }

  const lastKey = keys.slice(-1)[0]

  // @ts-ignore: We can't be specific with types here as we're dealing with the `unknown`
  const lastObj = keys.slice(0, -1).reduce((objArg, key) => (objArg[key] = objArg[key] || {}), source)

  lastObj[lastKey] = newValue

  return source
}

/**
 * Safe deep getIn
 */
export function getIn(obj: Record<string, unknown>, path: string|Array<string|number>): unknown {
  const keys = (typeof path === 'string' ? path.split('.') : path)
    .filter(k => k !== '' && k !== undefined)
    .map(k => (isFinite(k as unknown as number) ? parseInt(k as any) : k))

  if (!obj || !keys.length) {
    return
  }

  let v = obj
  for (const k of keys) {
    // @ts-ignore: We can't be specific with types here as we're dealing with the `unknown`
    v = v[k]
    if (v === undefined) {
      break
    }
  }

  return v
}

export function hasIn(source: Record<string, unknown>, path: string|Array<string>) {
  return getIn(source, path) !== undefined
}
