import { getIn } from '@cls/deep'

export function canDeleteGenealogyEntry(item, idx) {
  const entryId = getIn(item, ['genealogy', idx, 'id'])
  const entries = (item.genealogy || []).filter(e => e.id !== entryId)

  return entries.every(e => {
    const isMother = e.motherId === entryId
    const isFather = e.fatherId === entryId
    const hasRelationship = e.connections.some(c => {
      return c.entryId === entryId
    })

    return !isMother && !isFather && !hasRelationship
  })
}
