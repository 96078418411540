import { ActionHandler, createReducer } from '@cls/redux'

const FLASH_MESSAGE = 'flash/MESSAGE'
const FLASH_RESET = 'flash/RESET'

export type FlashKind = 'notice'|'error'

export function flashMessage(message: any, kind: FlashKind = 'notice') {
  return <const>{
    type:    FLASH_MESSAGE,
    message: message,
    kind:    kind,
  }
}

export function flashReset() {
  return <const>{
    type: FLASH_RESET,
  }
}

export function flashError(message: string) {
  return <const>{
    type: FLASH_MESSAGE,
    kind: 'error',
    message,
  }
}
interface OwnState {
  message: string,
  kind: FlashKind,
}

type FlashResetAction = ReturnType<typeof flashReset>
type FlashMessageAction = ReturnType<typeof flashMessage>

export type FlashActions = FlashMessageAction | FlashResetAction


const initialState: OwnState = {
  message: '',
  kind:    'notice',
}

const flashMessageHandler: ActionHandler<OwnState, FlashMessageAction> = (draftState, action) => {
  draftState.message = action.message
  draftState.kind = action.kind
}

type FlashActionNames = Pick<FlashActions, 'type'>
const actionHandlers = new Map<FlashActionNames['type'], ActionHandler<OwnState, any>>([
  [FLASH_RESET, () => initialState],
  [FLASH_MESSAGE, flashMessageHandler],
])


export default function reducer() {
  return createReducer(initialState, actionHandlers)
}
