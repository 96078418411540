import { graphql } from '@apollo/client/react/hoc'
import { RootState } from '@cls/redux'
import { DraggablePanel } from '@kpv-lab/ui'
import { Feedback } from 'cl-studio'
import React, { PureComponent } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import { flashError, flashMessage } from '../flash/state/flash-redux'
import NewThread from './components/NewThread'
import Thread from './components/Thread'
import * as queries from './gql/queries'
import {
  hideFeedbackPopup as hideFeedbackPopupAction,
  newFeedbackFailure,
  newFeedbackSuccess
} from './state/actions'

type FeedbackPopupState = {
  showNewThread: boolean,
  newThread:     {
    title: string,
    body:  string,
  },
  showEditThread: boolean,
  editThread: {
    title: string,
    body:  string,
    id: number,
  },
  showEditReply: boolean,
  editReply: {
    title: string,
    body: string,
    id: number,
  },
  replyBody:     string,
  replyParentId: number,
  replyTitle:    string,
  error:         string,
}

const mapStateToProps = (state: RootState) => {
  const { feedbackPopup, user } = state

  return {
    error:     feedbackPopup.error,
    itemId:    feedbackPopup.id,
    itemTitle: feedbackPopup.title,
    userId:    user.profile.id,
  }
}

const connector = connect(mapStateToProps, {
  hideFeedbackPopup: hideFeedbackPopupAction,
})

type PropsFromRedux = ConnectedProps<typeof connector>
type PropsFromServer = {
  loadingFeedback: boolean,
  feedback: Array<Feedback>,
  saveFeedback(itemId: number, parentId: number|null, title: string, source: string, body: string): void,
  updateFeedback(title: string, body: string, id: number, itemId: number): void,
  deleteFeedback(id: number, itemId: number): void,
}

export class FeedbackPopup extends PureComponent<
PropsFromRedux & PropsFromServer,
FeedbackPopupState
> {

  state = {
    showNewThread: false,
    newThread:     {
      title: '',
      body:  '',
    },
    showEditReply:  false,
    editReply:      {
      title: '',
      body:  '',
      id:    0,
    },
    showEditThread: false,
    editThread:     {
      title: '',
      body:  '',
      id:    0,
    },
    replyBody:      '',
    replyParentId:  0,
    replyTitle:     '',
    error:          '',
  };

  componentDidUpdate(prevProps) {
    if (prevProps.feedback !== this.props.feedback) {
      this.cancelNewThread()
      this.cancelReply()
    }
  }

  replyHandler = (_dataKey: any, val: string) => {
    this.setState({ replyBody: val, error: '' })
  };

  replyMode = (threadId: number, title: string) => {
    this.cancelNewThread()
    this.setState({
      replyParentId: threadId,
      replyTitle:    `Re: ${title}`,
    })
  };

  cancelReply = () => {
    this.setState({
      replyParentId: 0,
      replyBody:     '',
    })
  };

  submitReply = () => {
    const { saveFeedback, itemId } = this.props
    const { replyBody, replyParentId, replyTitle } = this.state
    if (replyBody.length < 3) {
      return
    }
    saveFeedback(itemId, replyParentId, replyTitle, 'backend', replyBody)
  };

  newThread = () => {
    this.cancelReply()
    this.setState({
      showNewThread: true,
      newThread:     {
        title: '',
        body:  '',
      },
    })
  };

  editThread = (id: number) => {
    const { feedback } = this.props
    const thread = feedback.find((t) => t.id === id)

    if (!thread) {
      return
    }

    this.setState({
      showEditThread: true,
      editThread:     {
        title: thread.title,
        body:  thread.body,
        id:    thread.id,
      },
    })
  };

  cancelEditThread = () => {
    this.setState({
      showEditThread: false,
      editThread:      {
        title: '',
        body:  '',
        id:    0,
      },
    })
  };

  editThreadUpdateHandler = (key: 'title' | 'body', value: string) => {
    const { editThread } = this.state

    // eslint-disable-next-line
    // @ts-ignore
    editThread[key] = value
    this.setState({ editThread })
  };

  submitEditedThread = () => {
    const { updateFeedback } = this.props
    const { editThread } = this.state

    if (!editThread) {
      return
    }

    // eslint-disable-next-line
           // @ts-ignore
    if (editThread['body'].length < 3) {
      window.alert('Thread is not long enough')
      return
    }

    this.setState({
      showEditThread: false,
      editThread:     {
        title: '',
        body:  '',
        id:    0,
      },
    })

    updateFeedback(
      editThread['title'],
      editThread['body'],
      editThread['id'],
      this.props.itemId
    )
  };

  handleSetEditReplyMode = (id: number) => {
    const { feedback } = this.props
    const thread = feedback.find((t) => t.id === id)

    if (!thread) {
      return
    }

    this.setState({
      editReply: {
        title: thread.title,
        body:  thread.body,
        id:    thread.id,
      },
    })
  };

  handleEditReply = (key: string, value: string) => {
    const { editReply } = this.state

    if (!editReply) {
      return
    }
    // eslint-disable-next-line
           // @ts-ignore
    editReply[key] = value
    this.setState({ editReply })
  };

  handleSaveReplyEdit = () => {
    const { updateFeedback } = this.props
    const { editReply } = this.state

    if (!editReply) {
      return
    }

    // eslint-disable-next-line
           // @ts-ignore
    if (editReply['body'].length < 3) {
      window.alert('Thread is not long enough')
      return
    }

    this.setState({
      showEditReply: false,
      editReply:     {
        title: '',
        body:  '',
        id:    0,
      },
    })

    updateFeedback(
      editReply['title'],
      editReply['body'],
      editReply['id'],
      this.props.itemId
    )
  };

  handleCancelReplyEdit = () => {
    this.setState({
      showEditReply: false,
      editReply:     {
        title: '',
        body:  '',
        id:    0,
      },
    })
  };

  newThreadUpdateHandler = (key: 'title' | 'body', value: string) => {
    const { newThread } = this.state
    newThread[key] = value
    this.setState({ newThread })
  };

  submitNewThread = () => {
    const { itemId, saveFeedback } = this.props
    const { newThread } = this.state
    if (newThread.body.length < 3) {
      window.alert('Thread is not long enough')
      return
    }
    saveFeedback(itemId, null, newThread.title, 'backend', newThread.body)
  };

  cancelNewThread = () => {
    this.setState({
      showNewThread: false,
      newThread:     {
        title: '',
        body:  '',
      },
    })
  };

  handleDeleteFeedback = (id: number) => {
    this.props.deleteFeedback(id, this.props.itemId)
  };

  render() {
    const {
      itemTitle,
      feedback,
      hideFeedbackPopup,
      userId,
    } = this.props
    const {
      replyParentId,
      replyBody,
      error,
      showNewThread,
      newThread,
      editThread,
      editReply,
      showEditThread,
    } = this.state

    const threads = feedback
      ?.filter((f) => !f.parent_id)
      .reverse()
      .map((t, idx) => {
        const feedbackUserId = t.user.id

        const id = t.id
        const replies = feedback.filter((i) => i.parent_id === id)

        if (showEditThread && editThread['id'] === id) {
          return (
            <NewThread
              body={editThread['body']}
              title={editThread['title']}
              error={error}
              active={true}
              acceptHandler={this.submitEditedThread}
              cancelHandler={this.cancelEditThread}
              updateHandler={this.editThreadUpdateHandler}
              newThreadMode={this.newThread}
            />
          )
        }

        return (
          <Thread
            handleEditThread={this.editThread}
            key={idx}
            item={t}
            error={error}
            active={id === replyParentId}
            replies={replies}
            allowManipulation={feedbackUserId === userId}
            userId={userId}
            editReply={editReply}
            handleSetEditReplyMode={this.handleSetEditReplyMode}
            handleEditReply={this.handleEditReply}
            handleSaveReplyEdit={this.handleSaveReplyEdit}
            handleCancelReplyEdit={this.handleCancelReplyEdit}
            handleDeleteFeedback={this.handleDeleteFeedback}
            replyBody={replyBody}
            replyHandler={this.replyHandler}
            acceptHandler={this.submitReply}
            cancelHandler={this.cancelReply}
            enterReplyMode={this.replyMode}
          />
        )
      })

    const headerMsg = <p>Feedback for {itemTitle}</p>

    let noThreadsMsg: any
    let className = 'feedback-popup'
    if (!threads || threads.length === 0) {
      className += ' no-feedback'
      noThreadsMsg = (
        <div className="no-feedback-msg">
                 No feedback has been submitted
        </div>
      )
    }

    return (
      <DraggablePanel
        className="ui-floating-panel feedback-popup-container"
        name="feedback-panel"
      >
        <div className="ui-title draggable">{headerMsg}</div>
        <i
          className="material-icons ui-close-btn"
          onClick={hideFeedbackPopup}
        >
                 close
        </i>
        <div className={className}>
          {this.props.loadingFeedback ? (
            ''
          ) : (
            <>
              {noThreadsMsg}
              <NewThread
                body={newThread.body}
                title={newThread.title}
                error={error}
                active={showNewThread}
                acceptHandler={this.submitNewThread}
                cancelHandler={this.cancelNewThread}
                updateHandler={this.newThreadUpdateHandler}
                newThreadMode={this.newThread}
              />
              {threads}
            </>
          )}
        </div>
      </DraggablePanel>
    )
  }

}

const processFeedback = ({
  data,
  ownProps,
}: any) => {
  if (!data.item) {
    return {
      ...ownProps,
      loadingFeedback: true,
    }
  }
  return {
    ...ownProps,
    loadingFeedback: false,
    feedback:        data.item.feedback,
  }
}

export default compose(
  connector,
  graphql(queries.REFETCH_FEEDBACK, {
    options: (props: PropsFromRedux) => ({
      variables: {
        itemId: props.itemId,
      },
      fetchPolicy: 'cache-and-network',
    }),
    props: processFeedback,
  }),
  graphql(queries.DELETE_FEEDBACK, {
    props: ({ mutate }) => ({
      deleteFeedback(id: number, itemId: number) {
        if (!mutate) {
          return
        }
        return mutate({
          variables:      { id },
          refetchQueries: [
            {
              query:     queries.REFETCH_FEEDBACK,
              variables: {
                itemId,
              },
            },
          ],
          awaitRefetchQueries: true,
        })
      },
    }),
  }),
  graphql(queries.ADD_FEEDBACK, {
    props: ({ mutate, ownProps }) => ({
      saveFeedback(itemId: number, parentId: number|null, title: string, source: string, body: string) {
        if (!mutate) {
          return
        }
        return mutate({
          variables:      { itemId, parentId, title, body },
          refetchQueries: [
            {
              query:     queries.REFETCH_FEEDBACK,
              variables: {
                itemId,
              },
            },
          ],
          awaitRefetchQueries: true,
        // @ts-expect-error The Apollo HOCs are deprecated, and a bit of a pain to add types to.
        // The TODO here is that we should refactor the component to use the hooks instead, and get
        // rid of these extra redux actions
        }).then(({ data: { addFeedback: { feedback, error } } }) => {
          if (error) {
            // @ts-ignore ...
            ownProps.dispatch(newFeedbackFailure(error))
            // @ts-ignore ...
            ownProps.dispatch(flashError(error))
          } else {
            // @ts-ignore ...
            ownProps.dispatch(flashMessage('Feedback successfully added'))
            // @ts-ignore ...
            ownProps.dispatch(newFeedbackSuccess(feedback))
          }
        })
      },
    }),
  }),
  graphql(queries.UPDATE_FEEDBACK, {
    props: ({ mutate, ownProps }) => ({
      updateFeedback(title: string, body: string, id: number, itemId: number) {
        if (!mutate) {
          return
        }
        return mutate({
          variables:      { title, body, id },
          refetchQueries: [
            {
              query:     queries.REFETCH_FEEDBACK,
              variables: {
                itemId,
              },
            },
          ],
          awaitRefetchQueries: true,
          // @ts-expect-error The Apollo HOCs are deprecated, and a bit of a pain to add types to.
          // The TODO here is that we should refactor the component to use the hooks instead, and get
          // rid of these extra redux actions
        }).then(({ data: { addFeedback: { feedback, error } } }) => {
          if (error) {
            // @ts-ignore ...
            ownProps.dispatch(newFeedbackFailure(error))
            // @ts-ignore ...
            ownProps.dispatch(flashError(error))
          } else {
            // @ts-ignore ...
            ownProps.dispatch(flashMessage('Feedback successfully added'))
            // @ts-ignore ...
            ownProps.dispatch(newFeedbackSuccess(feedback))
          }
        }
        )
      },
    }),
  })
)(FeedbackPopup)
