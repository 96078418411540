import { ActionHandler, createReducer } from '@cls/redux'

import * as types from './action-types'
import { AddFilesToQueue, FileProcessSuccess, UpdateUploadItem, UploadFileFailure, UploadFileProgress, UploadFileRequest, UploadFileSuccess } from './actions'

export interface UploadQueueItem {
  id: string,
  dataPath: string,
  status: string,
  progress: number,
  file: any,
  itemId: number,
  title: string,
  cache?: number,
  signedData?: {
    url: string,
    signedRequest: string,
  },
}

export interface OwnState {
  queue: Array<UploadQueueItem>,
}

const initialState: OwnState = {
  queue: [],
}

const addFilesToQueue: ActionHandler<OwnState, AddFilesToQueue> = (draftState, action) => {
  const newFiles = Array.isArray(action.data) ? action.data : [action.data]
  const queue = draftState.queue.concat(newFiles)
  draftState.queue = queue
}

const uploadRequest: ActionHandler<OwnState, UploadFileRequest> = (draftState, action) => {
  const id = action.data
  const itemIdx = draftState.queue.findIndex(i => i.id === id)
  draftState.queue[itemIdx]['status'] = 'uploadRequest'
}

const updateUploadItem: ActionHandler<OwnState, UpdateUploadItem> = (draftState, action) => {
  const { id, signedData } = action.data
  const itemIdx = draftState.queue.findIndex(i => i.id === id)

  draftState.queue[itemIdx]['signedData'] = signedData
  draftState.queue[itemIdx]['status'] = 'dataSigned'
}

const uploadProgress: ActionHandler<OwnState, UploadFileProgress> = (draftState, action) => {
  const { id, progress } = action.data
  const itemIdx = draftState.queue.findIndex(i => i.id === id)
  draftState.queue[itemIdx]['status'] = 'uploading'
  draftState.queue[itemIdx]['progress'] = progress
}

const uploadSuccess: ActionHandler<OwnState, UploadFileSuccess> = (draftState, action) => {
  const id = action.data
  const itemIdx = draftState.queue.findIndex(i => i.id === id)
  draftState.queue[itemIdx]['status'] = 'processing...'
}

const uploadFailure: ActionHandler<OwnState, UploadFileFailure> = (draftState, action) => {
  const { id, errorText } = action.data
  const itemIdx = draftState.queue.findIndex(i => i.id === id)
  const errorMessage = `error: ${errorText}`
  draftState.queue[itemIdx]['status'] = errorMessage
}

const fileProcessSuccess: ActionHandler<OwnState, FileProcessSuccess> = (draftState, action) => {
  const id = action.data
  const newQueue = draftState.queue.filter(i => i.id !== id)
  draftState.queue = newQueue
}

const actionHandlers = new Map<string, ActionHandler<OwnState, any>>([
  [types.ADD_FILES_TO_QUEUE, addFilesToQueue],
  [types.UPLOAD_REQUEST, uploadRequest],
  [types.UPDATE_UPLOAD_ITEM, updateUploadItem],
  [types.UPLOAD_PROGRESS, uploadProgress],
  [types.UPLOAD_SUCCESS, uploadSuccess],
  [types.UPLOAD_FAILURE, uploadFailure],
  [types.FILE_PROCESS_SUCCESS, fileProcessSuccess],
  [types.CLEAR_QUEUE, () => initialState],
])

export default function reducer() {
  return createReducer(initialState, actionHandlers)
}
