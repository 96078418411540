import { checkHttpStatus, parseJSON } from '@cls/utils/fetch'
import { dateToString } from '@kpv-lab/time-utils'
import { produce } from 'immer'
import fetch from 'isomorphic-fetch'

import { flashError, flashMessage } from '../../../components/flash/state/flash-redux'
import {
  hideWikidataPopup,
  showWikidataPopup
} from '../../../components/wikidata-popup/state/wikidata-popup-redux'
import { itemUpdate } from '../state/versions-redux'

const WIKIDATA_SEARCH_REQUEST = 'content/WIKIDATA_SEARCH_REQUEST'
const WIKIDATA_SEARCH_SUCCESS = 'content/WIKIDATA_SEARCH_SUCCESS'
const WIKIDATA_SEARCH_FAILURE = 'content/WIKIDATA_SEARCH_FAILURE'
const WIKIDATA_PROPERTIES_REQUEST = 'content/WIKIDATA_PROPERTIES_REQUEST'
const WIKIDATA_PROPERTIES_SUCCESS = 'content/WIKIDATA_PROPERTIES_SUCCESS'
const WIKIDATA_PROPERTIES_FAILURE = 'content/WIKIDATA_PROPERTIES_FAILURE'
const WIKIDATA_VALUES_REQUEST = 'content/WIKIDATA_VALUES_REQUEST'
const WIKIDATA_VALUES_SUCCESS = 'content/WIKIDATA_VALUES_SUCCESS'
const WIKIDATA_VALUES_FAILURE = 'content/WIKIDATA_VALUES_FAILURE'
const SHOW_WIKIDATA_RESULTS = 'content/SHOW_WIKIDATA_RESULTS'
const WIKIDATA_VALUES_CLEAR = 'content/WIKIDATA_VALUES_CLEAR'

export function wikidataSearch(itemId: any, title: any, wikidataFields: any) {
  return (dispatch: any, getState: any) => {
    dispatch(wikidataSearchRequest(title))

    const lang = getState().editor.lang
    const url = `https://www.wikidata.org/w/api.php?action=wbsearchentities&search=${title}&language=${lang}&limit=5&format=json&origin=*`

    const options = {
      method:  'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const updateFlow = (wikidataId: string) => {
      dispatch(wikidataSearchSuccess(wikidataId))
      dispatch(wikidataGetProperties(wikidataId, wikidataFields))
      dispatch(
        itemUpdate({ key: ['wikidata_id', 'value'], value: wikidataId })
      )
      dispatch(hideWikidataPopup())
    }

    return fetch(url, options)
      .then(checkHttpStatus)
      .then(parseJSON)
      .catch(err => dispatch(wikidataSearchFailure(err)))
      .then(res => {
        if (res && !res.error) {
          if (res.search.length === 1) {
            updateFlow(res.search[0].id)
          } else {
            dispatch(
              showWikidataPopup({
                results: res.search,
                query:   title,
              })
            )
          }
        } else {
          dispatch(wikidataSearchFailure(res.error.info))
          dispatch(flashError(`Error searching wikidata for ${title}`))
          console.warn('Wikidata search error', res.error.info)
        }
      })
  }
}

export function wikidataSearchRequest(title: string) {
  return {
    type: WIKIDATA_SEARCH_REQUEST,
    data: title,
  }
}

export function wikidataSearchSuccess(id: string) {
  return {
    type: WIKIDATA_SEARCH_SUCCESS,
    data: id,
  }
}

export function wikidataSearchFailure(error: any) {
  return {
    type: WIKIDATA_SEARCH_FAILURE,
    data: error || 'Error searching wikipedia...',
  }
}

export function showWikidataResults(options: any) {
  return {
    type: SHOW_WIKIDATA_RESULTS,
    data: options,
  }
}

/**
 *
 * @param {*} wikidataId ID of item to be fetched
 * @param {[string]} wikidataProps Field names to be extracted
 */
export function wikidataGetProperties(wikidataId: any, wikidataProps: any) {
  return (dispatch: any) => {
    dispatch(wikidataGetPropertiesRequest(wikidataId))

    const url = `https://www.wikidata.org/w/api.php?action=wbgetclaims&format=json&origin=*&entity=${wikidataId}`

    const options = {
      method:  'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return fetch(url, options)
      .then(checkHttpStatus)
      .then(parseJSON)
      .catch(err => ({ err }))
      .then(res => {
        if (res && !res.error) {
          dispatch(wikidataGetPropertiesSuccess(res))
          dispatch(wikidataGetValues(res, wikidataId, wikidataProps))
        } else {
          dispatch(wikidataGetPropertiesFailure(res.error.info))
          dispatch(flashError('Error retrieving wikidata item properties'))
          console.warn('Wikidata properties error', res.error.info)
        }
      })
  }
}

export function wikidataGetPropertiesRequest(id: any) {
  return {
    type: WIKIDATA_PROPERTIES_REQUEST,
    data: id,
  }
}

export function wikidataGetPropertiesSuccess(data: any) {
  return {
    type: WIKIDATA_PROPERTIES_SUCCESS,
    data: data,
  }
}

export function wikidataGetPropertiesFailure(error: any) {
  return {
    type: WIKIDATA_PROPERTIES_FAILURE,
    data: error || 'Error retrieving wikidata entry...',
  }
}

function wikidataGetValues(data: any, id: any, wikidataProps: any) {
  return (dispatch: any, getState: any) => {
    const lang = getState().editor.lang
    const entityIds = [id]
    const finalData: any = {}
    const propIdsToEntities: any = {}

    wikidataProps.map((propId: any) => {
      if (propId === 'description') {
        propIdsToEntities[propId] = [id]
        return
      }

      data.claims[propId] &&
        data.claims[propId].map((prop: any) => {
          const datatype = prop.mainsnak.datatype
          const datavalue = prop.mainsnak.datavalue
          if (datavalue) {
            if (datatype === 'wikibase-item') {
              const entityId = datavalue.value.id
              entityIds.push(entityId)
              if (propIdsToEntities[propId]) {
                propIdsToEntities[propId].push(entityId)
              } else {
                propIdsToEntities[propId] = [entityId]
              }
            } else if (datatype === 'time') {
              finalData[propId] = [dateToString(datavalue.value.time, lang)]
            } else if (datatype === 'monolingualtext') {
              finalData[propId] = [datavalue.value.text]
            } else if (datatype === 'string') {
              finalData[propId]
                ? finalData[propId].push(datavalue.value)
                : (finalData[propId] = [datavalue.value])
            }
          }
        })
    })

    dispatch(wikidataGetValuesRequest(entityIds))

    const url = `https://www.wikidata.org/w/api.php?action=wbgetentities&props=labels|descriptions&languages=${lang}&ids=${entityIds.join(
      '|'
    )}&format=json&origin=*`

    const options = {
      method:  'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return fetch(url, options)
      .then(checkHttpStatus)
      .then(parseJSON)
      .catch(err => ({ err }))
      .then(res => {
        if (res && !res.error) {
          const propIds = Object.keys(propIdsToEntities)

          try {
            propIds.forEach(propId => {
              if (propId === 'description') {
                const k = propIdsToEntities.description[0]
                finalData.description = [res.entities[k].descriptions[lang].value]
                return
              }

              const values: any = []

              propIdsToEntities[propId].map((entityId: any) => {
                res.entities[entityId] &&
                  res.entities[entityId].labels[lang] &&
                  values.push(res.entities[entityId].labels[lang].value)
              })
              finalData[propId] = values
            })
          } catch (e) {
            console.warn('Problems reading values in wikimedia response', e)
          }

          dispatch(wikidataGetValuesSuccess(finalData))
          dispatch(flashMessage('Wikidata request successful', 'notice'))
        } else {
          dispatch(wikidataGetValuesFailure(res.error.info))
          dispatch(flashError('Wikidata request unsuccessful'))
        }
      })
  }
}

export function wikidataGetValuesRequest(entityIds: any) {
  return {
    type: WIKIDATA_VALUES_REQUEST,
    data: entityIds,
  }
}

export function wikidataGetValuesSuccess(data: any) {
  return {
    type: WIKIDATA_VALUES_SUCCESS,
    data: data,
  }
}

export function wikidataValuesClear() {
  return {
    type: WIKIDATA_VALUES_CLEAR,
  }
}

export function wikidataGetValuesFailure(error: any) {
  return {
    type: WIKIDATA_VALUES_FAILURE,
    data: error || 'Error retrieving wikidata values...',
  }
}

const initialState = {
  wikidata: {
    status:      '',
    data:        {},
    searchError: '',
    propsError:  '',
    valuesError: '',
    fetching:    false,
    done:        false,
  },
}

export function reducer() {
  return produce((draftState, action) => {
    if (!action) {
      return draftState
    }
    switch (action.type) {

      case WIKIDATA_SEARCH_REQUEST:
        draftState.wikidata.status = `searching wikidata data for ${action.data}`
        draftState.wikidata.done = false
        break

      case WIKIDATA_SEARCH_SUCCESS:
        draftState.wikidata.status = ''
        draftState.wikidata.searchError = ''
        break

      case WIKIDATA_SEARCH_FAILURE:
        draftState.wikidata.searchError = action.data
        break

      case WIKIDATA_PROPERTIES_REQUEST:
        draftState.wikidata.status = `properties for wikidata id ${action.data}`
        draftState.wikidata.fetching = true
        break

      case WIKIDATA_PROPERTIES_SUCCESS:
        draftState.wikidata.status = ''
        draftState.wikidata.propsError = ''
        break

      case WIKIDATA_PROPERTIES_FAILURE:
        draftState.wikidata.propsError = action.data
        draftState.wikidata.fetching = false
        break

      case WIKIDATA_VALUES_REQUEST:
        draftState.wikidata.status = `requesting data for entity ids ${action.data}`
        break

      case WIKIDATA_VALUES_SUCCESS:
        draftState.wikidata.status = ''
        draftState.wikidata.data = action.data
        draftState.wikidata.valuesError = ''
        draftState.wikidata.fetching = false
        draftState.wikidata.done = true
        break

      case WIKIDATA_VALUES_CLEAR:
        draftState.wikidata.status = ''
        draftState.wikidata.data = {}
        draftState.wikidata.valuesError = ''
        draftState.wikidata.fetching = false
        draftState.wikidata.done = false
        break

      case WIKIDATA_VALUES_FAILURE:
        draftState.wikidata.valuesError = action.data
        draftState.wikidata.fetching = false
        break

    }
  }, initialState)

}
