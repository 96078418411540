import fetch from 'isomorphic-fetch'
import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'

import settings from '../settings'
import * as types from './action-types'

export interface ShowLinkEditorAction extends Action {
  type: typeof types.SHOW_LINK_EDITOR,
  data: any,
}

export interface HideLinkEditorAction extends Action {
  type: typeof types.HIDE_LINK_EDITOR,
}

export interface LinkSearchRequestAction extends Action {
  type: typeof types.LINK_SEARCH_REQUEST,
  data: {
    id: number,
  },
}

export interface LinkSearchSuccessAction extends Action {
  type: typeof types.LINK_SEARCH_SUCCESS,
  data: any,
}

export interface LinkSearchFailureAction extends Action {
  type: typeof types.LINK_SEARCH_FAILURE,
  errorText: string,
}

export type LinkEditorActions = ShowLinkEditorAction
| HideLinkEditorAction
| LinkSearchRequestAction
| LinkSearchSuccessAction
| LinkSearchFailureAction

export function showLinkEditor(data: any): LinkEditorActions {
  return {
    type: types.SHOW_LINK_EDITOR,
    data: data,
  }
}

export function hideLinkEditor(): ThunkAction<unknown, any, unknown, LinkEditorActions> {
  return (dispatch, getState) => {
    window.setTimeout(() => {
      const { closeHandler } = getState().linkEditor
      if (typeof closeHandler === 'function') {
        closeHandler()
      }
    }, 1)

    return dispatch({
      type: types.HIDE_LINK_EDITOR,
    })
  }
}

function checkHttpStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else {
    const error = new Error(response.statusText)
    // @ts-ignore: We have code expecting to see the response as part of the error
    error.response = response
    throw error
  }
}

export function searchLinks(itemId = 0, str = '', templateId = 0): ThunkAction<unknown, any, unknown, LinkEditorActions> {
  return dispatch => {
    // @ts-ignore: We have some JS consumers of the code that pass in Strings
    const id = parseInt(itemId)
    const query = `${str}`.trim()

    dispatch(searchLinksRequest(id))

    const options: RequestInit = {
      credentials: 'same-origin',
      method:      'get',
      headers:     {
        Accept:         'application/json',
        'Content-Type': 'application/json',
      },
    }

    const limit = 50
    let url = `${settings.apiServer}/items/search?`
    if (templateId) {
      url += `templateId=${templateId}&`
    }
    url += `id=${id}&query=${encodeURIComponent(query)}&limit=${limit}`

    return fetch(url, options)
      .then(checkHttpStatus)
      .then(response => response.json())
      .catch(err => {
        dispatch(searchLinksFailure(err))
      })
      .then(res => {
        if (!res) {
          return
        }
        dispatch(searchLinksSuccess(res))
      })
  }
}

export function searchLinksRequest(id): LinkEditorActions {
  return {
    type: types.LINK_SEARCH_REQUEST,
    data: { id },
  }
}

export function searchLinksSuccess(data: any): LinkEditorActions {
  return {
    type: types.LINK_SEARCH_SUCCESS,
    data: data,
  }
}

export function searchLinksFailure(error?: any): LinkEditorActions {
  const errorText = (error && error.response && error.response.statusText) || 'Server error...'

  return {
    type:      types.LINK_SEARCH_FAILURE,
    errorText: errorText,
  }
}
