import '../styles/ColorSwatch.css'

import { col2rgba, hex2rgb } from '@kpv-lab/color-utils'
import React, { PureComponent } from 'react'

import { showColorEditor } from '../color-editor-redux'
import { getIn } from '../utils/get-set-in'
import Slider from './Slider'

const history = {}
export function clearControlHistory() {
  Object.keys(history).forEach(k => delete history[k])
}

type OwnProps = {
  dispatch: (...args: Array<any>) => any,
  structure: any,
  dataKey: string,
  value: string | any | Array<any>,
  alphaValue: number,
  inline: boolean,
  updateHandler: (...args: Array<any>) => any,
  postRender: (...args: Array<any>) => any,
};

type Props = OwnProps & typeof ColorSwatch.defaultProps;

export default class ColorSwatch extends PureComponent<Props> {

  static defaultProps = {
    alphaValue: 1,
    inline:     false,
  }

  history: any;

  timeout = 0

  constructor(props: Props) {
    super(props)

    this.history = []
    const { dataKey } = props
    if (dataKey) {
      if (!history[dataKey]) {
        history[dataKey] = []
      }
      this.history = history[dataKey]
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeout)
  }

  menuHandler = event => {
    event.preventDefault()
  }

  clickHandler = event => {
    const { dispatch, structure, dataKey, value, alphaValue } = this.props

    if (event.metaKey || (event.buttons || event.button) === 2) {
      // undo to the item before the last state
      const v = this.history.pop()
      v && this.onChange(dataKey, v, false)
      event.preventDefault()
      event.stopPropagation()
      return
    }

    if (value !== this.history[this.history.length]) {
      this.history.push(value)
    }

    dispatch(
      showColorEditor({
        color:         this.normalizeColor(value),
        colorKey:      dataKey,
        updateHandler: this.onChange,
        alpha:         alphaValue,
        alphaKey:      getIn(structure, ['options', 'alphaKey']),
        showAlpha:     getIn(structure, ['options', 'showAlpha']) || structure.showAlpha,
        label:         structure.label || '',
      })
    )
  }

  onChange = (dataKey, color, storeUndo = true) => {
    const { structure, value, updateHandler, alphaValue } = this.props
    let val = color

    if (structure.encoder) {
      const srcValue = value || structure.value || ''
      val = this.encodeValue(
        structure.encoder,
        structure.decoder,
        srcValue,
        structure.valueIndex,
        val
      )
    }

    // store current value before changing it
    if (storeUndo) {
      window.clearTimeout(this.timeout)
      this.timeout = window.setTimeout(() => {
        if (value !== this.history[this.history.length]) {
          this.history.push(value)
        }
      }, 1000)
    }

    if (!updateHandler) {
      return
    }

    let col = val
    if (structure.format === 'array') {
      col = col2rgba(val)
      if (getIn(structure, ['label', 1]) || alphaValue !== undefined) {
        // custom alpha control
        col[3] = value[3]
      }
    }
    updateHandler(dataKey, col)
  }

  onChangeSlider = (_id, v) => {
    const { dataKey, updateHandler, value } = this.props
    const val = value
    val[3] = v

    if (updateHandler) {
      updateHandler(dataKey, val)
    }
  }

  normalizeColor(col) {
    const { structure, alphaValue } = this.props
    let rgba = col || [0, 0, 0, alphaValue]

    if (structure.decoder) {
      rgba = this.decodeValue(structure.decoder, rgba, structure.valueIndex)
    } else if (typeof rgba === 'string') {
      rgba = [...hex2rgb(rgba), alphaValue]
    }

    return rgba
  }

  encodeValue(encode, decode, value, idx, val) {
    const vals = decode(value)
    vals[idx] = val
    return encode(vals)
  }

  decodeValue(decode, value, idx) {
    const vals = decode(value)
    return vals[idx]
  }

  render() {
    const { structure, dataKey, value, alphaValue, inline, postRender } = this.props


    let id = ('' + dataKey).replace(/[.]/g, '-')
    id += structure.valueIndex || ''

    const htmlFor = `input-${id}`
    let className = `ui-i ui-field ui-input ui-color-swatch field-${id}`

    if (this.history.length) {
      className += ' edited'
    }

    if (inline) {
      className += ' inline'
    }

    const style: React.CSSProperties = {}
    if (structure.width) {
      style.width = structure.width
    }

    const rgba = this.normalizeColor(value)
    const [r, g, b, a] = col2rgba(rgba, alphaValue)
    const colorStyle = {
      backgroundColor: `rgba(${r},${g},${b},${a})`,
    }

    let colorSampleClass = 'color-sample'
    if (!value) {
      colorSampleClass += ' blank-color'
    }

    let label, label1, label2
    if (structure.label && !structure.hideLabel) {
      if (Array.isArray(structure.label)) {
        label1 = structure.label[0]
        label2 = structure.label[1]
      } else {
        label1 = structure.label
      }

      label = (
        <label htmlFor={htmlFor} className="ui-label">
          {label1}
        </label>
      )
    }

    if (!label) {
      className += ' no-label'
    }

    let slider
    if (label2 && !structure.showAlpha) {
      slider = (
        <Slider
          id={id + '.3'}
          label={label2}
          min={structure.min}
          max={structure.max}
          step={structure.step}
          tick={structure.tick}
          exact={structure.exact}
          value={a}
          updateHandler={this.onChangeSlider}
        />
      )
    }

    if (structure.className) {
      className += ` ${structure.className}`
    }

    const output = (
      <section className={className} style={style} key={htmlFor} onContextMenu={this.menuHandler}>
        {label}
        <div className="color-swatch">
          <span className={colorSampleClass} style={colorStyle} onClick={this.clickHandler} />
        </div>
        {slider}
      </section>
    )

    return postRender ? postRender(structure, output) : output
  }
  /* eslint-enable complexity */

}
