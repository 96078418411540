/* eslint no-param-reassign:0 */

// http://robertpenner.com/easing/
const Easing = {
  // t: current time, b: beginning value, c: change in value, d: duration
  linear: function (t, b, c, d) {
    return c * t / d + b
  },

  linearIn: function (t, b, c, d) {
    return Easing.linear(t, b, c, d)
  },

  linearOut: function (t, b, c, d) {
    return Easing.linear(t, b, c, d)
  },

  linearInOut: function (t, b, c, d) {
    return Easing.linear(t, b, c, d)
  },

  step: function (t, b, c, d) {
    return c * Math.floor(t / d) + b
  },

  quadIn: function (t, b, c, d) {
    return c * (t /= d) * t + b
  },

  quadOut: function (t, b, c, d) {
    return -c * (t /= d) * (t - 2) + b
  },

  quadInOut: function (t, b, c, d) {
    if ((t /= d / 2) < 1) {
      return c / 2 * t * t + b
    }
    return -c / 2 * (--t * (t - 2) - 1) + b
  },

  cubicIn: function (t, b, c, d) {
    return c * (t /= d) * t * t + b
  },

  cubicOut: function (t, b, c, d) {
    return c * ((t = t / d - 1) * t * t + 1) + b
  },

  cubicInOut: function (t, b, c, d) {
    if ((t /= d / 2) < 1) {
      return c / 2 * t * t * t + b
    }
    return c / 2 * ((t -= 2) * t * t + 2) + b
  },

  quartIn: function (t, b, c, d) {
    return c * (t /= d) * t * t * t + b
  },

  quartOut: function (t, b, c, d) {
    return -c * ((t = t / d - 1) * t * t * t - 1) + b
  },

  quartInOut: function (t, b, c, d) {
    if ((t /= d / 2) < 1) {
      return c / 2 * t * t * t * t + b
    }
    return -c / 2 * ((t -= 2) * t * t * t - 2) + b
  },

  quintIn: function (t, b, c, d) {
    return c * (t /= d) * t * t * t * t + b
  },

  quintOut: function (t, b, c, d) {
    return c * ((t = t / d - 1) * t * t * t * t + 1) + b
  },

  quintInOut: function (t, b, c, d) {
    if ((t /= d / 2) < 1) {
      return c / 2 * t * t * t * t * t + b
    }
    return c / 2 * ((t -= 2) * t * t * t * t + 2) + b
  },

  sineIn: function (t, b, c, d) {
    return -c * Math.cos(t / d * (Math.PI / 2)) + c + b
  },

  sinOut: function (t, b, c, d) {
    return c * Math.sin(t / d * (Math.PI / 2)) + b
  },

  sineInOut: function (t, b, c, d) {
    return -c / 2 * (Math.cos(Math.PI * t / d) - 1) + b
  },

  expoIn: function (t, b, c, d) {
    return t === 0 ? b : c * Math.pow(2, 10 * (t / d - 1)) + b
  },

  expoOut: function (t, b, c, d) {
    return t === d ? b + c : c * (-Math.pow(2, -10 * t / d) + 1) + b
  },

  expoInOut: function (t, b, c, d) {
    if (t === 0) {
      return b
    }
    if (t === d) {
      return b + c
    }
    if ((t /= d / 2) < 1) {
      return c / 2 * Math.pow(2, 10 * (t - 1)) + b
    }
    return c / 2 * (-Math.pow(2, -10 * --t) + 2) + b
  },

  circIn: function (t, b, c, d) {
    return -c * (Math.sqrt(1 - (t /= d) * t) - 1) + b
  },

  circOut: function (t, b, c, d) {
    return c * Math.sqrt(1 - (t = t / d - 1) * t) + b
  },

  circInOut: function (t, b, c, d) {
    if ((t /= d / 2) < 1) {
      return -c / 2 * (Math.sqrt(1 - t * t) - 1) + b
    }
    return c / 2 * (Math.sqrt(1 - (t -= 2) * t) + 1) + b
  },

  elasticIn: function (t, b, c, d) {
    let s = 1.70158
    let p = 0
    let a = c
    if (t === 0) {
      return b
    }
    if ((t /= d) === 1) {
      return b + c
    }
    if (!p) {
      p = d * 0.3
    }
    if (a < Math.abs(c)) {
      a = c
      s = p / 4
    } else {
      s = p / (2 * Math.PI) * Math.asin(c / a)
    }
    return -(a * Math.pow(2, 10 * (t -= 1)) * Math.sin((t * d - s) * (2 * Math.PI) / p)) + b
  },

  elasticOut: function (t, b, c, d) {
    let s = 1.70158
    let p = 0
    let a = c
    if (t === 0) {
      return b
    }
    if ((t /= d) === 1) {
      return b + c
    }
    if (!p) {
      p = d * 0.3
    }
    if (a < Math.abs(c)) {
      a = c
      s = p / 4
    } else {
      s = p / (2 * Math.PI) * Math.asin(c / a)
    }
    return a * Math.pow(2, -10 * t) * Math.sin((t * d - s) * (2 * Math.PI) / p) + c + b
  },

  elasticInOut: function (t, b, c, d) {
    let s = 1.70158
    let p = 0
    let a = c
    if (t === 0) {
      return b
    }
    if ((t /= d / 2) === 2) {
      return b + c
    }
    if (!p) {
      p = d * (0.3 * 1.5)
    }
    if (a < Math.abs(c)) {
      a = c
      s = p / 4
    } else {
      s = p / (2 * Math.PI) * Math.asin(c / a)
    }
    if (t < 1) {
      return -0.5 * (a * Math.pow(2, 10 * (t -= 1)) * Math.sin((t * d - s) * (2 * Math.PI) / p)) + b
    }
    return a * Math.pow(2, -10 * (t -= 1)) * Math.sin((t * d - s) * (2 * Math.PI) / p) * 0.5 + c + b
  },

  backIn: function (t, b, c, d, s) {
    s = s || 1.70158
    return c * (t /= d) * t * ((s + 1) * t - s) + b
  },

  backOut: function (t, b, c, d, s) {
    s = s || 1.70158
    return c * ((t = t / d - 1) * t * ((s + 1) * t + s) + 1) + b
  },

  backInOut: function (t, b, c, d, s) {
    s = s || 1.70158
    if ((t /= d / 2) < 1) {
      return c / 2 * (t * t * (((s *= 1.525) + 1) * t - s)) + b
    }
    return c / 2 * ((t -= 2) * t * (((s *= 1.525) + 1) * t + s) + 2) + b
  },

  bounceIn: function (t, b, c, d) {
    return c - Easing.bounceOut(d - t, 0, c, d) + b
  },

  bounceOut: function (t, b, c, d) {
    if ((t /= d) < 1 / 2.75) {
      return c * (7.5625 * t * t) + b
    } else if (t < 2 / 2.75) {
      return c * (7.5625 * (t -= 1.5 / 2.75) * t + 0.75) + b
    } else if (t < 2.5 / 2.75) {
      return c * (7.5625 * (t -= 2.25 / 2.75) * t + 0.9375) + b
    } else {
      return c * (7.5625 * (t -= 2.625 / 2.75) * t + 0.984375) + b
    }
  },

  bounceInOut: function (t, b, c, d) {
    if (t < d / 2) {
      return Easing.bounceIn(t * 2, 0, c, d) * 0.5 + b
    }
    return Easing.bounceOut(t * 2 - d, 0, c, d) * 0.5 + c * 0.5 + b
  },
}

Easing.easeIn = Easing.cubicIn
Easing.easeOut = Easing.cubicOut
Easing.easeInOut = Easing.cubicInOut
Easing.ease = Easing.cubicInOut

export default Easing
