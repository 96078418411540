import { EditorState } from 'prosemirror-state'

import { ReferenceBlock } from '../reference-popup/ReferencePopup'

export function getInfoBlocks(infoBlockElements: Array<HTMLElement>, infoBlocks: Array<any>) {
  if (!infoBlocks?.length || !infoBlockElements?.length) {
    return []
  }

  return infoBlockElements.map((infoBlockElement) => {
    const id = parseInt(infoBlockElement.dataset.infoId ?? '')
    return infoBlocks.find((infoBlock) => infoBlock && infoBlock.id === id)
  })
}

export function getReferenceBlocks(referenceBlockElements: Array<HTMLElement>, referenceBlocks: Array<ReferenceBlock>) {

  if (!referenceBlockElements?.length || !referenceBlocks?.length) {
    return []
  }

  return referenceBlockElements.map((referenceBlockElement: HTMLElement) => {
    // Using the blockId instead of reference ID because that may not be unique
    const blockId = parseInt(referenceBlockElement.dataset.blockId ?? '')
    return referenceBlocks.find((refBlock) => refBlock?.blockId === blockId)
  })
}

export function getMediaItems(editorState: EditorState) {
  /**
   * We want to keep a reference, local to the field of the media items being used in it
   * Since items can be deleted in a whole variety of ways, it makes the most sense to find
   * them on RTE blur
   */
  const mediaIds: Array<string> = []
  editorState.doc.nodesBetween(0, editorState.doc.content.size, (node) => {
    if (node.type.name === 'image' || node.type.name === 'audio' || node.type.name === 'video') {
      mediaIds.push(node.attrs.mediaId)
    }
  })
  return Array.from(new Set(mediaIds))
}


export function cleanNewLines(str: string) {
  return str
    .replace(/\r/g, '')
    .trim()
    .replace(
      /([^\s])[ \f\r\t\v\u00a0\u1680\u2000-\u200a\u2028\u2029\u202f\u205f\u3000\ufeff]*\n[ \f\r\t\v\u00a0\u1680\u2000-\u200a\u2028\u2029\u202f\u205f\u3000\ufeff]*([^\s])/g,
      '$1 $2'
    )
    .replace(
      /([^\s])([ \f\r\t\v\u00a0\u1680\u2000-\u200a\u2028\u2029\u202f\u205f\u3000\ufeff]*\n[ \f\r\t\v\u00a0\u1680\u2000-\u200a\u2028\u2029\u202f\u205f\u3000\ufeff]*){2,}([^\s])/g,
      '$1\n$3'
    )
}

export function fixHTML(html: string) {
  return (html || '')
    .replace(/<p>\s*<br>\s*<\/p>/gi, '') // remove empty paragraphs with breaks
    .replace(/<br>\s*<\/p>/gi, '</p>') // remove breaks at end of paragraph
    .replace(/<em>\s*<\/em>/gi, '') // remove empty italic tags
    .replace(/(<[^>/]+>)<br>/gi, '<br>$1') // swap opening tag followed by a break
    .replace(/<br>(<\/[^>]+>)/gi, '$1<br>') // swap closing tag preceeded by a break
}
