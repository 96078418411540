import { produce } from 'immer'
import merge from 'lodash.merge'

import { getIdPaths } from './action-helpers'
import { getIn, hasIn, setIn } from './utils/get-set-in'

export const SWITCH_PANEL = 'ui/SWITCH_PANEL'
export const UPDATE = 'ui/UPDATE'
export const UPDATE_ALL = 'ui/UPDATE_ALL'
export const CHANGE_SCALE = 'ui/CHANGE_SCALE'
export const CHANGE_PANEL = 'ui/CHANGE_PANEL'

// action creators
export function switchPanel(scope = '', path, parent = false) {
  return {
    type: scope + SWITCH_PANEL,
    data: { path, parent },
  }
}

export function updateAll(data, scope = '', path?: string) {
  return {
    type: scope + UPDATE_ALL,
    data: { data, scope, path },
  }
}

export function update(data, scope = '', path) {
  return {
    type: scope + UPDATE,
    data: { data, scope, path },
  }
}

// reducer enhancer
// http://rackt.org/redux/docs/recipes/ImplementingUndoHistory.html
export default function uiReducer(reducer, opts: any = {}) {
  const initialState = reducer()
  const scope = opts.scope || ''

  // Return a reducer with the extra actions
  return produce((draftState, action = {}) => {

    switch (action.type) {

      case scope + SWITCH_PANEL: {
        const parts = action.data.path.split('.')
        let panelId = parseInt(parts.pop())
        parts.pop()
        setIn(draftState, [...parts, 'activeChild'], panelId)

        if (action.data.parent) {
          panelId = parseInt(parts.pop())
          parts.pop()
          setIn(draftState, [...parts, 'activeChild'], panelId)
        }
        return draftState
      }

      case scope + UPDATE:
        setIn(draftState, action.data.path, action.data.data)
        return draftState

      case scope + UPDATE_ALL: {
        const { data, scope: actionScope, path } = action.data
        const ids = getIdPaths(actionScope ? draftState[actionScope] : draftState, actionScope, path)

        ids.map(i => { // NOSONAR
          const idx = i.id.split('.')
          if (hasIn(data, idx)) {
            const p = i.key.split('.')
            const oldVal = getIn(draftState, p)
            const newVal = getIn(data, idx)

            if (p.indexOf('data') > -1) {
              // merge with the current value and then update at the original key
              const o = getIn(draftState, p)
              const merged = merge(o, newVal)
              setIn(draftState, p, merged)
            } else if (oldVal !== newVal) {
              setIn(draftState, p, newVal)
            }
          }
        })

        return draftState
      }

      default:
        // Delegate handling the action to the passed reducer
        return reducer(draftState, action)

    }
  }, initialState)
}

export function generateStructure(controls, opts: any = {}) {
  const ui = {
    tabs:        'column',
    type:        'panel',
    scrollable:  true,
    activeChild: 0,
    children:    [] as Array<any>,
    controlIds:  [] as Array<string>,
  }
  const groups: any = {}
  let parent = ui

  controls.forEach(control => {
    const groupPath = (control.group || opts.defaultGroup || 'Other').split('|')
    groupPath.forEach((name, depth) => {
      const key = `${name}-${depth}`
      if (depth === 0) {
        parent = ui
      }

      if (!groups[key]) {
        // create new panel group
        groups[key] = {
          tabs:        'column',
          type:        'panel',
          label:       name,
          activeChild: 0,
          scrollable:  true,
          children:    [],
        }
        parent.children.push(groups[key])
      }
      parent = groups[key]
    })

    // create controls
    if (control.id && control.id.indexOf('.') === -1 && opts.prefix) {
      control.id = `${opts.prefix}.${control.id}`
      ui.controlIds.push(control.id)
    }

    parent.children.push(control)
  })

  return ui
}
