import { ActionHandler, createReducer } from '@cls/redux'

import * as types from './action-types'
import { QueryGroupTags, ResetTagControlState } from './actions'

interface OwnState {
  query: string,
}

const initialState: OwnState = {
  query: '',
}

const resetHandler: ActionHandler<OwnState, ResetTagControlState> = (draftState) => {
  draftState.query = ''
}

const queryHandler: ActionHandler<OwnState, QueryGroupTags> = (draftState, action) => {
  draftState.query = action.data
}

const actionHandlers = new Map<string, ActionHandler<OwnState, any>>([
  [types.QUERY_GROUP_TAGS, queryHandler],
  [types.RESET_TAG_CONTROL_STATE, resetHandler],
])

export default function reducer() {
  return createReducer(initialState, actionHandlers)
}
