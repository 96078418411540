import { settings } from '@cls/config'

import { getCookie, setCookie } from './cookies'
import { checkHttpStatus, parseJSON } from './fetch'

// This exists to allow the store to be initialised with a valid user
// object if a cookie exists and it's still good. This saves some redirect
// flashing.
const refreshSession = async () => {
  const token = getCookie(settings.jwtKey)
  if (!token) {
    return {
      loggedIn: false,
    }
  }
  const url = `${settings.apiServer}/session`
  const options:RequestInit = {
    method:      'get',
    headers:     { Accept: 'application/json' },
    credentials: 'same-origin',
  }

  return fetch(url, options)
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(data => {
      if (data && data.profile) {
        setCookie(settings.jwtKey, data.token, data.expires)
        return {
          ...data,
          loggedIn: true,
        }
      }
    })
    .catch(() => {
      console.warn('Invalid token')
      return {
        loggedIn: false,
      }
    })

}

export {
  refreshSession,
}
