import { produce } from 'immer'

import { MultimediaActions } from './actions'

interface OwnState {
  itemId: number,
  templateId: number,
  title: string,
  visible: boolean,
}

export const initialState: OwnState = {
  itemId:     -1,
  templateId: -1,
  title:      '',
  visible:    false,
}

export function reducer() {
  return produce((draftState: OwnState, action?: MultimediaActions) => {
    if (!action) {
      return draftState
    }

    switch (action.type) {

      case 'SHOW_MULTIMEDIA_POPUP':
        const { itemId, templateId, title } = action
        draftState.itemId = itemId ?? draftState.itemId
        draftState.templateId = templateId ?? draftState.templateId
        draftState.title = title ?? draftState.title
        draftState.visible = true
        break
      case 'HIDE_MULTIMEDIA_POPUP':
        return initialState

    }
  }, initialState)
}

