import isPlainObject from 'lodash/isPlainObject'


export function objectIsFalse(obj) {
  if (typeof obj !== 'object' || Array.isArray(obj)) {
    return false
  }

  return Object.keys(obj).every(k => obj[k] === undefined)
}

export function getTagIds(item) {
  const tagIds: Array<number> = []
  Object.keys(item).forEach(fieldKey => {
    const fieldContents = item[fieldKey]

    if (Array.isArray(fieldContents)) {
      if (fieldKey === 'tagIds') {
        tagIds.push(...fieldContents)
      } else {
        fieldContents.forEach(subfield => {
          if (isPlainObject(subfield)) {
            const ids = getTagIds(subfield)
            if (ids.length) {
              tagIds.push(...ids)
            }
          }
        })
      }
    } else if (isPlainObject(fieldContents) && Object.keys(fieldContents).includes('tagIds')) {
      const localTagIds = fieldContents.tagIds
      if (Array.isArray(localTagIds)) {
        tagIds.push(...localTagIds)
      }
    }
  })

  return Array.from(new Set(tagIds))
}

export function getLocalTagIds(value) {
  if (typeof value !== 'string') {
    return
  }

  let match
  const ids: Array<number> = []
  // Looking for tags as described in html-to-md.js
  const regex = /(tag:\/\/)(\w+)\/(\d+)/gi
  while ((match = regex.exec(value)) !== null) {
    ids.push(parseInt(match[3]))
  }
  return ids
}
