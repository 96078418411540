import { dateToHMS, dateToString } from '@kpv-lab/time-utils'
import React, {  useCallback, useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { showDatePopup as showDatePopupAction } from './state/date-control-redux'
import { CURRENT_TIME } from './utils'

interface DateControlProps {
  dataKey: string,
  setAsActiveField?: () => void,
  dateAcceptHandler: (date: Record<string, string|number>, isApproximateDate: boolean) => void,
  structure: Record<string, unknown>,
  value: string,
  lang?: string,
  placeholder?: string,
  age?: number,
}

const connector = connect(undefined, {
  showDatePopup: showDatePopupAction,
})

type TypesFromRedux = ConnectedProps<typeof connector>

const DateControl = (props: DateControlProps & TypesFromRedux) => {
  const {
    showDatePopup,
    value,
    structure,
    lang,
    age,
    placeholder,
    dataKey,
    setAsActiveField,
    dateAcceptHandler,
  } = props

  const [dateValue, setDateValue] = useState(value)

  useEffect(() => {
    setDateValue(value)
  }, [value])


  const handleClick = useCallback(() => {
    if (setAsActiveField) {
      setAsActiveField()
    }

    showDatePopup({
      dataKey,
      structure,
      value:        dateValue,
      closeHandler: dateAcceptHandler,
    })
  }, [
    dataKey,
    structure,
    dateValue,
    showDatePopup,
    setAsActiveField,
    dateAcceptHandler,
  ])

  const current = value === '' + CURRENT_TIME

  const style: React.CSSProperties = {}
  let label, type, itemField
  if (structure) {
    type = structure.type
    itemField = structure.itemField

    if (structure.width) {
      style.width = structure.width as string
    }

    if (structure.label) {
      label = (
        <label className="ui-label ui-date-label">
          {structure.label as string}
        </label>
      )
    }
  }

  const date = dateToString(value, lang)
  const dateHMS = value ? dateToHMS(value) : ''

  let aged
  if (type === 'endDate' && age) {
    aged = (
      <span className="item-age">
        {date ? `Aged ${age}` : `Current age ${age}`}
      </span>
    )
  }

  let className = `date-control${itemField ? ' item-field-value' : ''}`

  let previewText
  if (date) {
    previewText = `${date}${dateHMS ? ` - ${dateHMS}` : ''}`
  } else if (!current) {
    previewText = placeholder || ''
    className += ' placeholder'
  }

  const { isApproximateDate } = structure

  return (
    <section
      className={className}
      title={(structure.title ?? '') as string}
      style={style}
      onClick={handleClick}
      data-cy={dataKey}
    >
      {label}
      <div className="date-control-preview" data-cy="date-preview">
        {isApproximateDate ? 'ca. ' : ''}{previewText} {aged}
      </div>
    </section>
  )
}

export default connector(DateControl)
