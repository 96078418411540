import '../styles/DraggablePanel.css'

import React, { PureComponent } from 'react'

import {
  getItem as getItemFromLocalStorage,
  initStore,
  setItem as setItemInLocalStorage
} from '../utils/local-storage'
initStore(null, 'draggable')

let startZindex = 2000

type OwnProps = {
  name: string,
  className: string,
  margin?: number,
  width?: string | number,
  height?: string | number,
  backdrop?: boolean,
  backdropHandler?: (...args: Array<any>) => any,
  getItem?: (...args: Array<any>) => any,
  setItem?: (...args: Array<any>) => any,
};

type State = {
  x: number,
  y: number,
  x0: number,
  y0: number,
  xMax: number,
  yMax: number,
  moving: boolean,
  touch: boolean,
  zIndex: number,
};

type Props = OwnProps & typeof DraggablePanel.defaultProps;

export default class DraggablePanel extends PureComponent<Props, State> {

  static defaultProps = {
    name:      'draggablePanel',
    className: 'draggable-panel',
    margin:    5,
    backdrop:  false,
    getItem:   getItemFromLocalStorage,
    setItem:   setItemInLocalStorage,
  }

  _touchSupport: any;
  refOverlay: any;
  refPanel: any;

  constructor(props: Props) {
    super(props)

    const pos = props.getItem(props.name)
    this.state = {
      x:      pos ? pos.x : window.innerWidth - 550,
      y:      pos ? pos.y : 50,
      x0:     0,
      y0:     0,
      xMax:   window.innerWidth - 50,
      yMax:   window.innerHeight - 200,
      moving: false,
      touch:  false,
      zIndex: startZindex++,
    }
  }

  // componentWillMount() {
  //   const { name, getItem } = this.props

  //   // init session
  //   const pos = getItem(name)
  //   if (pos) {
  //     this._setPosition(pos.x, pos.y)
  //   } else {
  //     this._setPosition(window.innerWidth - 550, 50)
  //   }
  // }

  componentDidMount() {
    window.addEventListener('resize', this._resize, false)
    window.setTimeout(this._resize, 0)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._resize)
  }

  _resize = () => {
    if (!this.refPanel) {
      return
    }

    const { x, y } = this.state
    // let r = el.getBoundingClientRect();
    const p = this.refPanel.parentNode.getBoundingClientRect()

    this.setState({
      // xMax: p.width - r.width - this.props.margin,
      xMax: (p.width || window.innerWidth) - 50,
      yMax: (p.height || window.innerHeight) - 40,
    })

    this._setPosition(x, y)
  }

  _setPosition(x, y) {
    const { xMax, yMax } = this.state
    const { name, margin, setItem } = this.props
    const p = {
      x:      Math.floor(Math.min(Math.max(margin, x), xMax)),
      y:      Math.floor(Math.min(Math.max(margin, y), yMax)),
      zIndex: startZindex++,
    }


    this.setState(p)
    if (!this.state.moving) {
      setItem(name, p)
    }
  }

  onMoveStart = event => {

    if (event.target.closest('.draggable')) {
      event.preventDefault()
      event.stopPropagation()
      const pos = this._pointerPosition(event)
      this._addMoveListeners()

      // only set touch support if available and not already set
      const touch = (!this.state.touch && event.type === 'touchstart') || this.state.touch
      this.setState({
        moving: true,
        touch:  touch,
        x0:     pos[0],
        y0:     pos[1],
        zIndex: ++startZindex,
      })
    }
  }

  _pointerPosition(event) {
    const evt = (event.touches && event.touches[0]) || event

    return [evt.clientX, evt.clientY]
  }

  _addMoveListeners() {
    const eventMap = this._eventHandlers(this._touchSupport)
    for (const key in eventMap) {
      document.body.addEventListener(key, eventMap[key], false)
    }
  }

  _eventHandlers(touchEvents) {
    if (touchEvents) {
      return {
        touchmove:   this.onMoveUpdate,
        touchend:    this.onMoveEnd,
        touchcancel: this.onMoveEnd,
      }
    } else {
      return {
        mousemove: this.onMoveUpdate,
        mouseup:   this.onMoveEnd,
      }
    }
  }

  onMoveUpdate = event => {
    const { touch, x0, y0 } = this.state
    if (!touch && event.buttons === 0) {
      // special case where the mouse button was released outside the browser
      this.onMoveEnd()
      return
    }

    const { name, getItem } = this.props
    const { x, y } = getItem(name)
    const p = this._pointerPosition(event)
    const x1 = x + (p[0] - x0)
    const y1 = y + (p[1] - y0)
    this._setPosition(x1, y1)
  }

  onMoveEnd = () => {
    const { touch, x, y } = this.state
    const eventMap = this._eventHandlers(touch)
    for (const key in eventMap) {
      document.body.removeEventListener(key, eventMap[key])
    }
    this.setState({ moving: false })
    this._setPosition(x, y)
  }

  onBackdropClick = event => {
    const { backdropHandler } = this.props

    if (backdropHandler && event.target === this.refOverlay) {
      event.preventDefault()
      backdropHandler()
    }
  }

  setRefPanel = el => (this.refPanel = el)
  setRefOverlay = el => (this.refOverlay = el)

  render() {
    const { x, y, zIndex, moving } = this.state
    const { className, children, width, height, backdrop } = this.props


    const style: React.CSSProperties = { left: x, top: y, zIndex: zIndex }

    if (width) {
      style.width = width
    }

    if (height) {
      style.height = height
    }

    let classNames = className
    if (moving) {
      classNames += ' moving'
    }

    const panel = (
      <div
        className={classNames}
        onMouseDown={this.onMoveStart}
        onTouchStart={this.onMoveStart}
        style={style}
        ref={this.setRefPanel}
      >
        {children}
      </div>
    )

    if (backdrop) {
      return (
        <div
          className="draggable-panel-backdrop"
          onMouseDown={this.onBackdropClick}
          ref={this.setRefOverlay}
        >
          {panel}
        </div>
      )
    } else {
      return panel
    }
  }

}
