import React, { Component } from 'react'

type OwnProps = {
  value: number,
  min: number,
  max: number,
  wrap: boolean,
  loop: boolean,
  decimalPlaces?: number,
  style: React.CSSProperties,
  className: string,
  unit: string,
  updateHandler: (...args: Array<any>) => any,
};

type State = {
  value: number,
  focus: boolean,
};

export default class SliderInput extends Component<OwnProps, State> {

  static defaultProps = {
    value:         0.0,
    className:     'ui-slider-value',
    updateHandler: null,
    unit:          '',
  }

  refInput: any;

  constructor(props: OwnProps) {
    super(props)

    this.state = {
      value: props.value,
      focus: false,
    }
  }

  clickHandler = event => {
    const { value, decimalPlaces } = this.props
    if (event.metaKey || event.button === 2) {
      event.stopPropagation()
      event.preventDefault()

      this.setState({
        focus: true,
        value: Number(value.toFixed(decimalPlaces)),
      })
      window.setTimeout(() => {
        this.refInput.select()
      }, 1)
      return false
    }
  }

  changeHandler = event => {
    this.setState({ value: event.target.value })
  }

  blurHandler = event => {
    const val = `${event.target.value}`.trim()
    if (val !== '') {
      const parsedVal = parseFloat(val)
      if (!Number.isNaN(parsedVal)) {
        this.props.updateHandler(parsedVal)
      }
    }
    this.setState({ focus: false })
  }

  mouseHandler(event) {
    event.stopPropagation()
  }

  keyHandler = event => {
    if (event.keyCode === 27) {
      // esc - reset and blur
      this.refInput.value = this.props.value
      this.refInput.blur()
    } else if (event.keyCode === 13) {
      // return - accept current value and blur
      this.refInput.blur()
    }
  }

  displayValue(val) {
    const { min, max, loop, wrap, decimalPlaces, unit } = this.props
    const range = max - min
    let v = val

    if (loop) {
      let loops = 0

      if (v > max) {
        loops = Math.floor((v - min) / range)
        v -= range * loops
        return `${loops}x ${v.toFixed(decimalPlaces)}${unit}`
      } else if (v < min) {
        loops = -Math.floor((Math.abs(v) + max) / range)
        v -= range * loops
        return `${loops}x ${v.toFixed(decimalPlaces)}${unit}`
      }
    } else if (wrap) {
      if (v > max) {
        v = v % range
      } else if (v < min) {
        v = max - (Math.abs(v) % range)
      }
    }

    return `${v.toFixed(decimalPlaces)}${unit}`
  }

  setRefInput = ref => (this.refInput = ref)

  render() {
    const { className, style, value } = this.props

    let input
    if (this.state.focus) {
      input = (
        <input
          ref={this.setRefInput}
          className={className + '-input'}
          type="text"
          value={this.state.value}
          onChange={this.changeHandler}
          onBlur={this.blurHandler}
          onMouseDown={this.mouseHandler}
          onKeyUp={this.keyHandler}
        />
      )
    } else {
      input = (
        <span className={className} style={style} onMouseDown={this.clickHandler}>
          {this.displayValue(value)}
        </span>
      )
    }

    return input
  }

}
