/* eslint complexity: 0 */

import Transitionable from '@kpv-lab/transitionable'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import ScrollBar from './components/ScrollBar'

const offsetHistory = {}

const arrows = {
  left: (
    <svg className="scroll-hint-arrow" height="24" viewBox="0 0 24 24" width="24">
      <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z" />
    </svg>
  ),
  right: (
    <svg className="scroll-hint-arrow" height="24" viewBox="0 0 24 24" width="24">
      <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" />
    </svg>
  ),
  up: (
    <svg className="scroll-hint-arrow" height="24" viewBox="0 0 24 24" width="24">
      <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
    </svg>
  ),
  down: (
    <svg className="scroll-hint-arrow" height="24" viewBox="0 0 24 24" width="24">
      <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
    </svg>
  ),
}

const initWidth = 13
const initHeight = 15

export default class ScrollPanel extends Component {

  static propTypes = {
    dispatch:         PropTypes.func,
    scrollRender:     PropTypes.func,
    positionsHandler: PropTypes.func,
    children:         PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    id:               PropTypes.string,
    panelClassName:   PropTypes.string,
    bodyClassName:    PropTypes.string,
    flashClassName:   PropTypes.string,
    offset:           PropTypes.number,
    scrollToPercent:  PropTypes.number,
    friction:         PropTypes.number,
    marginStart:      PropTypes.number,
    marginEnd:        PropTypes.number,
    overshoot:        PropTypes.number,
    hintSize:         PropTypes.number,
    hintOpacity:      PropTypes.number,
    snap:             PropTypes.number,
    flashTimeout:     PropTypes.number,
    dragScroll:       PropTypes.bool,
    wheelScroll:      PropTypes.bool,
    fixedLabels:      PropTypes.bool,
    orientation:      PropTypes.string,
    startArrow:       PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    endArrow:         PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }

  static defaultProps = {
    offset:          0,
    friction:        0.9,
    overshoot:       75,
    marginStart:     0,
    marginEnd:       0,
    snap:            0,
    hintSize:        15,
    hintOpacity:     1,
    flashTimeout:    0,
    dragScroll:      false,
    wheelScroll:     true,
    fixedLabels:     false,
    orientation:     'vertical',
    flashClassName:  'flash',
    scrollToPercent: 0,
  }

  _interaction = ''
  _interationType = 'mouse'
  _delta = 0
  _deltaSum = 0
  _overshoot = 0
  _overshootEnd = 0
  _wheelLock = 0
  _momentum = 0
  _wheelTimeout = 0
  _sizeTimeout = 0
  _resizeTimeout = 0
  _flashTimeout = 0
  _range = 1
  _percent = 0
  _direction = -1
  _raf = 0
  _resized = false
  _columns = 1
  _columnWidth = 100
  _columnGap = 0
  _positions = []
  _labels = []
  _pageSize = 0
  _panelSize = { width: 0, height: 0 }
  _bodySize = { width: 0, height: 0 }
  _panelLength = 0
  _bodyLength = 0
  _prevPos = 0

  state = {
    offset: this.restoreOffset(this.props.id) || this.props.offset || 0,
    range:  1,
    width:  initWidth,
    height: initHeight,
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeHandler, false)
    if (window.MutationObserver) {
      this.observer = new MutationObserver(this.mutationHandler)
      this.observer.observe(this.refBody, { subtree: true, childList: true })
    }
    this._mounted = true
    this.resize()
    this.bodyFlash()
    this.monitorImages()
  }

  componentDidUpdate(oldProps) {
    const { id, children, panelClassName, scrollToPercent } = this.props

    if (oldProps.id !== id) {
      this._percent = 0
    }

    if (
      oldProps.children !== children ||
      oldProps.panelClassName !== panelClassName ||
      oldProps.id !== id
    ) {
      // console.log('changed children so resize', this.props.id);
      this.resizeHandler()
      this.bodyFlash()
      this.monitorImages()
      this.getItemPositions()
    }

    if (oldProps.scrollToPercent !== scrollToPercent) {
      this.scrollTo(scrollToPercent, { duration: 500 })
    }
  }

  componentWillUnmount() {
    this._mounted = false
    this.removeEvents()
    this.observer && this.observer.disconnect()
    window.removeEventListener('resize', this.resizeHandler)
    Array.from(this.refPanel.querySelectorAll('img')).forEach(img => {
      img.removeEventListener('load', this.imageHandler)
    })
    window.clearTimeout(this._wheelTimeout)
    window.clearTimeout(this._sizeTimeout)
    window.clearTimeout(this._resizeTimeout)
    window.cancelAnimationFrame(this._raf)
  }

  monitorImages() {
    // capture load events for all images so we can trigger a resize after they have loaded
    Array.from(this.refPanel.querySelectorAll('img')).forEach(img => {
      img.removeEventListener('load', this.imageHandler)
      img.addEventListener('load', this.imageHandler)
    })
  }

  bodyFlash() {
    const { flashTimeout, flashClassName } = this.props
    if (!this.refBody || !flashTimeout) {
      return
    }

    this.refBody.classList.add(flashClassName)
    window.clearTimeout(this._flashTimeout)
    this._flashTimeout = window.setTimeout(() => {
      this.refBody && this.refBody.classList.remove(flashClassName)
    }, flashTimeout)
  }

  imageHandler = event => {
    event.target.removeEventListener('load', this.imageHandler)
    this.resizeHandler()
  }

  mutationHandler = () => {
    // console.log('mutation list', list)
    this.resizeHandler()
  }

  resizeHandler = () => {
    window.clearTimeout(this._resizeTimeout)
    this._resizeTimeout = window.setTimeout(this.resize, 100)
  }

  resize = () => {
    const { marginEnd, id, orientation, snap, fixedLabels } = this.props

    // console.log('resize scroll panel:', id)
    const p = this.refPanel
    const b = this.refBody
    if (!p || !b) {
      return
    }

    const rp = p.getBoundingClientRect()
    const rb = b.getBoundingClientRect()

    // console.log('resize scroll panel:', id, rp.height, rb.height);
    this._panelSize = {
      width:  rp.width,
      height: rp.height,
    }
    this._bodySize = {
      width:  rb.width,
      height: rb.height,
    }

    // console.log('resize', id, this.refBody, this._bodySize);
    if (orientation === 'vertical') {
      this._panelLength = this._panelSize.height
      this._pageSize = this._panelLength
      this.setSize(this._bodySize.height + marginEnd)
    } else if (orientation === 'horizontal') {
      this._panelLength = this._panelSize.height
      this._pageSize = this._panelLength
      this.setSize(this._bodySize.width + marginEnd)
    } else {
      // hack for getting the total body width when using horizontally scrolling css columns!
      this._panelLength = this._bodySize.width
      const e = this.refBody.querySelector('.scroll-panel-end')
      if (e) {
        this._bodySize.width = e.offsetLeft + e.offsetWidth
      }

      const s = this.refBody.querySelector('.scroll-panel-start')
      if (s) {
        this._columnWidth = s.offsetWidth
        this._columns = Math.floor(this._panelLength / this._columnWidth)

        if (this._columns === 1) {
          const computedStyle = window.getComputedStyle(this.refBody.children[0])
          this._columnGap = parseInt(computedStyle.columnGap)
        } else {
          const gaps = this._panelLength - this._columns * this._columnWidth
          this._columnGap = gaps / (this._columns - 1)
        }
        this._pageSize = (this._columnWidth + this._columnGap) * this._columns
      }

      this.setSize(this._bodySize.width)
    }

    this._resized = true
    this.restoreOffset(id)

    if (snap) {
      const snapOffset = snap <= 1 ? this._pageSize / this._columns : snap
      const snapPages = Math.floor(Math.abs(this._offset) / snapOffset)
      this._offset = -Math.floor(snapPages * snapOffset)
    }

    this.setState({
      range:  this._range,
      width:  this._panelSize.width,
      height: this._panelSize.height,
      offset: this._offset,
    }, () => {
      this.getItemPositions()

      if (fixedLabels) {
        this.getLabelPositions()
      }
    })
  }

  setSize(length) {
    const { marginStart } = this.props
    const { offset } = this.state
    const d = Math.floor(length)

    if (d !== this._bodyLength) {
      // console.log('set size', d)
      this._interaction = ''
      this._overshoot = 0
      this._offset = offset || 0 // reset this._offset to the state version in case the scroll wheel has fired since the last render
      this._minOffset = 0
      this._maxOffset = Math.min(Math.floor(this._panelLength - (marginStart + d)), 0)
      this._range = Math.min(Math.max(this._panelLength / (marginStart + d), 0), 1)

      // note that the offsets are negative
      this._offset = Math.min(Math.max(this._offset, this._maxOffset), this._minOffset)
      window.clearTimeout(this._sizeTimeout)
      this._sizeTimeout = window.setTimeout(() => {
        this.setState(
          {
            offset: this._offset,
            range:  this._range,
          },
          this.update
        )
      }, 0)
    }

    this._bodyLength = d
  }

  restoreOffset(id) {
    // const d = (this._maxOffset - this._minOffset) || 0;
    // this._offset = Math.min((offsetHistory[id] || 0) * d, 0);
    this._offset = offsetHistory[id] || 0
    // console.log('restore offset', id, offsetHistory[id], this._offset);
  }

  interactionStartHandler = event => {
    if (!this._bodySize.height || !this._bodySize.width) {
      // console.warn('resize for wheel');
      this.resize()
    }

    let evt
    if (event.type === 'touchstart') {
      evt = event.touches[0]
      this._interationType = 'touch'
    } else {
      evt = event
      this._interationType = 'mouse'
    }

    this._deltaSum = 0
    this._momentum = 0
    this._interaction = 'drag'
    this._startTime = Date.now()
    this._startPos = this.props.orientation === 'vertical' ? evt.clientY : evt.clientX
    this._offset = this.state.offset || 0

    if (this._interationType === 'mouse') {
      document.body.addEventListener('mousemove', this.interactionUpdateHandler)
      document.body.addEventListener('mouseup', this.interactionEndHandler)
      document.body.addEventListener('mouseleave', this.interactionEndHandler)
    } else {
      document.body.addEventListener('touchmove', this.interactionUpdateHandler)
      document.body.addEventListener('touchend', this.interactionEndHandler)
      document.body.addEventListener('touchcancel', this.interactionEndHandler)
      document.body.addEventListener('touchleave', this.interactionEndHandler)
    }

    this.update()
  }

  interactionUpdateHandler = event => {
    // required to prevent some janky behaviour in Chrome!
    event.stopPropagation()
    event.preventDefault()

    const evt = event.touches ? event.touches[0] : event
    const p = this.props.orientation === 'vertical' ? evt.clientY : evt.clientX
    const dp = p - this._startPos
    this._momentum = (this._delta + 2 * dp) / 3 // weighted average
    this._delta = dp
    this._startPos = p
    this._deltaSum += dp
  }

  interactionEndHandler = event => {
    event.stopPropagation()
    event.preventDefault()

    if (this._interaction === 'drag' && Math.abs(this._deltaSum) > 0) {
      this.snapTo()
    }

    this._interaction = ''
    this._delta = 0
    this.removeEvents()
  }

  removeEvents() {
    if (this._interationType === 'mouse') {
      document.body.removeEventListener('mousemove', this.interactionUpdateHandler)
      document.body.removeEventListener('mouseup', this.interactionEndHandler)
      document.body.removeEventListener('mouseleave', this.interactionEndHandler)
    } else {
      document.body.removeEventListener('touchmove', this.interactionUpdateHandler)
      document.body.removeEventListener('touchend', this.interactionEndHandler)
      document.body.removeEventListener('touchcancel', this.interactionEndHandler)
      document.body.removeEventListener('touchleave', this.interactionEndHandler)
    }
  }

  wheelHandler = event => {
    if (!this._bodySize.height || !this._bodySize.width) {
      // console.warn('resize for wheel');
      this.resize()
    }

    if (
      !this._interaction &&
      (event.target.closest('.prevent-custom-scroll') ||
        /textarea|select/i.test(event.target.tagName))
    ) {
      return false
    }

    event.stopPropagation()

    if (this._interaction === 'drag' || event.buttons) {
      return
    }

    const delta = 'deltaY' // this.props.orientation === 'vertical' ? 'deltaY' : 'deltaX';
    if (this._wheelLock && Math.sign(this._delta - event[delta]) === this._wheelLock) {
      if (!this._overshoot && this._maxOffset < this._offset && this._offset < this._minOffset) {
        this._wheelLock = 0
      } else {
        // console.log('wheel lock!')
        return
      }
    }

    let timeout
    if (Math.abs(this._overshoot) > this.props.overshoot) {
      // past overshoot limit so pause the interaction to enable it to spring back
      this._interaction = 'paused'
      this._wheelLock = this._overshootEnd
      timeout = 1000
    } else {
      // normal wheel scroll. OSX mouse fires this many times per tick so we accumulate the delta
      this._delta -= event[delta]
      this._deltaSum += this._delta
      timeout = 200
    }

    if (!this._interaction) {
      // first wheel event
      this._interaction = 'wheel'
      this._offset = this.state.offset || 0
      this._startTime = Date.now()
      this._momentum = 0
      this._deltaSum = 0
      this.update()
    }

    // we don't know when the wheel action has really finished, so a timeout is needed
    window.clearTimeout(this._wheelTimeout)
    this._wheelTimeout = window.setTimeout(() => {
      this._interaction = ''
      this._wheelLock = 0
      this.snapTo()
    }, timeout)
  }

  hintHandler = event => {
    if (event.target.classList.contains('scroll-hint-start')) {
      event.preventDefault()
      event.stopPropagation()
      this.scrollTo(-0.8, { page: true, duration: 500, direction: 1 })
    } else if (event.target.classList.contains('scroll-hint-end')) {
      event.preventDefault()
      event.stopPropagation()
      this.scrollTo(-0.8, { page: true, duration: 500, direction: -1 })
    }
  }

  update = () => {
    const { id, friction, positionsHandler } = this.props
    if (this._interaction || this._delta !== 0 || this._momentum !== 0 || this._overshoot !== 0) {
      window.cancelAnimationFrame(this._raf)
      this._raf = window.requestAnimationFrame(this.update)
    }

    let mom = this._momentum
    if (this._interaction && this._interaction !== 'paused') {
      // still scrolling or dragging
      mom = 0
    } else {
      // momentum slow down
      mom *= friction
      if (this._overshoot) {
        mom *= friction
      }
      if (Math.abs(mom) < 1) {
        mom = 0
      }
    }

    const d = this._offset + this._delta + mom
    let [pos, osh] = this.scrollLimit(d)

    const dp = pos - this._prevPos
    if (dp) {
      this._direction = dp < 0 ? -1 : 1
    }

    if (osh) {
      this._direction = 0
    }

    if (this._interaction && this._interaction !== 'paused') {
      // whilst interacting we keep the full distance offset
      this._offset = d
      pos += osh
    } else {
      // when not interacting the overshoot snaps back
      osh *= 0.85
      if (Math.abs(osh) < 1.5) {
        osh = 0
      }
      pos += osh
      this._offset = pos
      this._momentum = mom
    }

    if (this._interaction === 'wheel') {
      this._momentum = this._delta
      this._delta = 0
    }

    if (this._interaction === 'paused') {
      this._momentum = 0
      this._delta = 0
      this._direction = 0
    }

    this._overshoot = osh
    this._percent = pos / (this._maxOffset - this._minOffset)
    if (id) {
      // offsetHistory[id] = this._percent;
      offsetHistory[id] = this._offset
    }
    pos = Math.ceil(pos)
    if (this.state.offset !== pos) {
      this.setState({ offset: pos })
      positionsHandler && positionsHandler(pos, this._positions, this._percent)
    }
  }

  scrollLimit(v) {
    const { marginStart, overshoot, orientation } = this.props
    const k = orientation === 'vertical' ? 'height' : 'width'
    const d = marginStart + this._bodySize[k]
    let pos = v
    let osh = 0

    if (d <= this._panelSize[k]) {
      // body shorter than container so no scrolling
      pos = 0
    } else if (v > this._minOffset) {
      // overshoot start
      pos = this._minOffset
      osh = Math.atan(v / overshoot) * overshoot
      this._overshootEnd = 1
    } else if (v < this._maxOffset) {
      // overshoot end
      pos = this._maxOffset
      osh = -Math.atan((this._maxOffset - v) / overshoot) * overshoot
      this._overshootEnd = -1
    } else if (this._maxOffset < v && v < 0) {
      // normal scroll area
      this._overshootEnd = 0
    }
    // console.log('pos', pos, osh, this._bodySize, this._panelSize);
    return [pos, osh]
  }

  snapTo = (direction = this._direction) => {
    const { snap } = this.props
    const { offset } = this.state
    const snapOffset = snap <= 1 ? this._pageSize / this._columns : snap
    if (!snap || !snapOffset || !direction) {
      return
    }

    let snapPages = Math.floor(Math.abs(offset) / snapOffset)
    if (direction < 0) {
      snapPages += 1
    }

    let targetOffset = -Math.floor(snapPages * snapOffset)
    if (offset === targetOffset) {
      targetOffset += direction < 0 ? -snapOffset : snapOffset
    }

    targetOffset = Math.min(Math.max(targetOffset, this._maxOffset), this._minOffset)
    this.scrollTo(targetOffset, { duration: 500, easeFn: 'easeOut' })
  }

  scrollTo = (p, opts = {}) => {
    const { id, positionsHandler, snap } = this.props
    const { offset } = this.state

    let targetOffset
    if (opts.page) {
      // scroll by pages
      if (snap) {
        this.snapTo(opts.direction)
        return
      }

      targetOffset = offset - p * this._panelLength
    } else if (p >= 0 && p <= 1) {
      // scroll by percentage
      targetOffset = p * this._maxOffset
    } else {
      // scroll by pixels
      targetOffset = p
    }

    // offsets are negative, so min/max the opposite way to usual
    targetOffset = Math.min(Math.max(targetOffset, this._maxOffset), this._minOffset)

    if (offset === targetOffset) {
      return
    }

    const dp = targetOffset - this._prevPos
    if (dp) {
      this._direction = dp < 0 ? -1 : 1
    }

    if (!opts.duration) {
      this.setState({ offset: targetOffset })
      positionsHandler && positionsHandler(targetOffset, this._positions, this._percent)
      return
    }

    const t = Transitionable({
      startVal: offset,
      finalVal: targetOffset,
      duration: opts.duration,
      easeFn:   opts.easeFn || 'ease',
    })

    const animate = () => {
      const v = t.get()
      this._offset = v
      this.setState({ offset: v })
      positionsHandler && positionsHandler(v, this._positions, this._percent)

      if (t.complete()) {
        this._percent = v / (this._maxOffset - this._minOffset)
        if (id) {
          offsetHistory[id] = this._offset
        }
        window.cancelAnimationFrame(this._raf)
      } else {
        this._raf = window.requestAnimationFrame(animate)
      }
    }

    window.cancelAnimationFrame(this._raf)
    animate()
  }

  getItemPositions() {
    const { offset } = this.state
    const { positionsHandler, marginStart } = this.props

    if (!positionsHandler) {
      return
    }

    const items = this.refBody.querySelectorAll('*[data-id]')
    const height = this.refBody.offsetHeight
    this._positions = []
    Array.from(items).forEach(item => {
      const t = parseFloat(item.dataset.time || 0)
      if (t) {
        const y = item.offsetTop
        this._positions.push({
          x: 0, // item.offsetLeft,
          y: y + marginStart,
          p: y / height,
          k: item.dataset && item.dataset.id,
          t: parseFloat(item.dataset.time || 0),
          a: item.classList.contains('active'),
          z: item.classList.contains('disabled'),
        })
      }
    })
    // console.log('calculate positions', this._positions);
    positionsHandler(offset, this._positions, this._percent)
  }

  getLabelPositions() {
    // const { marginStart } = this.props;

    const items = this.refBody.querySelectorAll('*[data-label]')
    this._labels = Array.from(items).map(item => {
      return {
        offset: item.offsetTop,
        label:  item.dataset && item.dataset.label,
      }
    })
    // console.log('label positions:', this._labels);
  }

  // not we have to do this due to a double assignment issue when using inline functions
  // see Caveats at the end of https://facebook.github.io/react/docs/refs-and-the-dom.html
  setRefPanel = el => (this.refPanel = el)
  setRefBody = el => (this.refBody = el)

  render() {
    const {
      panelClassName,
      bodyClassName,
      children,
      marginStart,
      marginEnd,
      scrollRender,
      hintSize,
      dragScroll,
      wheelScroll,
      orientation,
      startArrow,
      endArrow,
      hintOpacity,
      id,
      fixedLabels,
    } = this.props

    // console.log('render scroll panel', id);

    const { offset, range, width, height } = this.state
    const _panelClassName = `scroll-panel scroll-panel-${orientation} ${panelClassName || ''}`
    const _bodyClassName = `scroll-panel-body ${bodyClassName || ''}`
    const pos = Math.floor(marginStart + offset)

    let body,
      style = {},
      labels
    if (scrollRender) {
      if (width !== initWidth || height !== initHeight) {
        const sr = scrollRender(pos, width, height, id)
        this.setSize(sr.height + marginEnd)
        body = sr.content
        labels = sr.labels
        style = {
          height: `${Math.ceil(sr.height)}px`,
        }
      }
    } else {
      body = children
    }

    if (fixedLabels) {
      labels = this._labels
    }

    if (orientation === 'vertical') {
      style.transform = `translate3d(0, ${pos}px, 0)`
    } else {
      style.transform = `translate3d(${pos}px, 0, 0)`
    }
    this._prevPos = pos

    let startHint, endHint

    if (hintSize) {
      const a1 = Math.min(Math.max(-offset / hintSize, 0), 1) * hintOpacity || 0
      const hintProps1 = {
        className: `scroll-hint scroll-hint-start ${!a1 ? 'scroll-hint-disabled' : ''}`,
        style:     { opacity: a1 },
      }
      if (startArrow) {
        hintProps1.onMouseDown = this.hintHandler
        hintProps1.onTouchEnd = this.hintHandler
      }
      startHint = <div {...hintProps1}>{arrows[startArrow] || startArrow || ''}</div>

      const a2 = Math.min(Math.max(-(this._maxOffset - offset) / hintSize, 0), 1) * hintOpacity || 0
      const hintProps2 = {
        className: `scroll-hint scroll-hint-end ${!a2 ? 'scroll-hint-disabled' : ''}`,
        style:     { opacity: a2 },
      }
      if (endArrow) {
        hintProps2.onMouseDown = this.hintHandler
        hintProps2.onTouchEnd = this.hintHandler
      }
      endHint = <div {...hintProps2}>{arrows[endArrow] || endArrow || ''}</div>
    }

    let scrollbar
    this._percent = 0
    if (range < 1) {
      this._percent = offset / (this._maxOffset - this._minOffset)
      // console.log('render scrollbar:', offset, range, this._bodyLength);
      scrollbar = (
        <ScrollBar
          offset={this._percent}
          orientation={orientation}
          range={range}
          length={this._bodyLength}
          marginStart={marginStart}
          marginEnd={marginEnd}
          scrollTo={this.scrollTo}
          snapTo={this.snapTo}
          labels={labels}
        />
      )
    }

    const props = {
      className: _panelClassName,
    }

    if (dragScroll) {
      props.onMouseDown = this.interactionStartHandler
      props.onTouchStart = this.interactionStartHandler
    }

    if (wheelScroll) {
      props.onWheel = this.wheelHandler
    }

    return (
      <div {...props} ref={this.setRefPanel}>
        {startHint}
        <div className={_bodyClassName} ref={this.setRefBody} style={style} key={id}>
          {body}
        </div>
        {endHint}
        {scrollbar}
      </div>
    )
  }

}
