import { RootState } from '@cls/redux'
import AppLoading from '@cls/spinner'
import { castDraft } from 'immer'
import Mousetrap from 'mousetrap'
import React, { PureComponent, Suspense } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import MapEditorControls from './components/MapEditorControls'
import {
  getMapResults as getMapResultsAction,
  hideMapEditor as hideMapEditorAction,
  lastMapLocation as lastMapLocationAction
} from './state/actions'
import { MapLocation } from './state/reducer'

const Map = React.lazy(() => import(/* webpackChunkName: "maps" */ './components/Map'))

type MapEditorState = MapLocation

const mapStateToProps = (state: RootState) => {
  const { mapEditor } = state

  const { location } = mapEditor
  const { search, updateHandler, prefillSearch, customLabelInput } = mapEditor

  return {
    location,
    search,
    updateHandler,
    prefillSearch,
    customLabelInput,
  }
}

const connector = connect(
  mapStateToProps,
  {
    hideMapEditor:      hideMapEditorAction,
    setLastMapLocation: lastMapLocationAction,
    getMapResults:      getMapResultsAction,
  }
)

type MapEditorProps = ConnectedProps<typeof connector>

export class MapEditor extends PureComponent<MapEditorProps, MapEditorState> {

  refOverlay: React.Ref<HTMLElement>|undefined;

  constructor(props: MapEditorProps) {
    super(props)
    this.state = { ...castDraft(props.location) }
  }

  componentDidMount() {
    Mousetrap.bind('esc', this.closeHandler)
  }

  componentWillUnmount() {
    Mousetrap.unbind('esc')
  }

  closeHandler = () => {
    this.props.hideMapEditor()
  }

  acceptHandler = () => {
    const { updateHandler, setLastMapLocation } = this.props
    const { coords, placeLabel, zoom, viewOnly, country } = this.state

    if (placeLabel || viewOnly) {
      updateHandler({ coords, placeLabel, zoom, country, viewOnly })
      setLastMapLocation(coords, zoom, placeLabel)
      this.closeHandler()
    }
  }

  overlayHandler = (event: any) => {
    if (event.target === this.refOverlay) {
      this.acceptHandler()
    }
  }

  updateHandler = (newLocation: Partial<MapLocation>) => {
    const { coords, zoom, placeLabel, country, viewOnly } = newLocation
    const newState: any = {}

    if (coords) {
      newState.coords = coords
    }

    if (zoom) {
      newState.zoom = Number(zoom.toFixed(2))
    }

    if (placeLabel || placeLabel === '') {
      newState.placeLabel = placeLabel
    }

    if (country) {
      newState.country = country
    }

    if (viewOnly !== undefined) {
      newState.viewOnly = viewOnly
    }

    this.setState(newState)
  }

  setRefOverlay = (el: any) => {
    this.refOverlay = el
  }

  render() {
    const { getMapResults, search, prefillSearch, customLabelInput } = this.props
    const { coords, placeLabel, zoom, viewOnly } = this.state

    return (
      <div className="overlay" ref={this.setRefOverlay} onClick={this.overlayHandler}>
        <div className="map-editor">
          <Suspense fallback={<AppLoading />}>
            <Map
              zoom={zoom}
              coords={coords}
              updateHandler={this.updateHandler}
              showMarker={!viewOnly}
            />
            <MapEditorControls
              getMapResults={getMapResults}
              placeLabel={placeLabel}
              viewOnly={viewOnly}
              acceptHandler={this.acceptHandler}
              closeHandler={this.closeHandler}
              prefillSearch={prefillSearch}
              searchResults={search.result}
              updateHandler={this.updateHandler}
              customLabelInput={customLabelInput}
            />
          </Suspense>
        </div>
      </div>
    )
  }

}

export default connector(MapEditor)
