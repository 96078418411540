import { EditorView } from 'prosemirror-view'
import React, { Component } from 'react'

import { markdownSerializer } from '../pm/markdown/serializer'

type OwnProps = {
  editorState?: any,
  onEditorState: (...args: Array<any>) => any,
  onChange: (value: any) => void,
  onBlur?: (...args: Array<any>) => any,
  handleAddInfo: (...args: Array<any>) => any,
  handleAddReference: (...args: Array<any>) => any,
  handleAddMedia: (...args: Array<any>) => any,
  testId?: string,
  readOnly?: boolean,
}

type State = {
  editorView: EditorView | null,
  editorState: any,
}

type Props = OwnProps

export default class EditingView extends Component<Props, State> {

  static defaultProps = {
    onChange: () => {
      /* no-op */
    },
  }

  createEditorView: (instance: HTMLDivElement | null) => void
  editorView: EditorView | null = null

  state: State = {
    editorView:  null,
    editorState: null,
  }

  editorDOM: HTMLDivElement | null = null

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const { editorView, editorState } = prevState
    if (editorView && editorState) {
      // No need to update readonly as we'll get a
      // fresh new component due to keying on value
      if (nextProps.editorState !== editorState && !nextProps.readOnly) {
        editorView.updateState(nextProps.editorState)
        // Disabled for now, does weird focus stealing on click handlers
        // editorView.setProps({ editable: () => !nextProps.readOnly })
      }
      return null
    } else {
      return { editorState: nextProps.editorState }
    }
  }

  constructor(props: Props) {
    super(props)
    this.createEditorView = (element: HTMLDivElement | null) => {
      if (element == null) {
        this.setState({ editorState: null })
      }
      if (element != null) {
        const editorView = new EditorView(element, {
          state:               this.state.editorState,
          dispatchTransaction: this.dispatchTransaction,
        })
        editorView.dom.addEventListener('blur', () => {
          // return the markdown and plain text
          this.props.onBlur &&
            this.props.onBlur({
              markdown: markdownSerializer.serialize(
                this.props.editorState.doc
              ),
              text: this.props.editorState.doc.textContent,
            })
        })
        this.setState({ editorView })
        this.editorDOM = element
      }
    }
  }

  shouldComponentUpdate() {
    return false
  }

  componentWillUnmount() {
    if (this.editorView) {
      this.state.editorView?.destroy()
    }
  }

  dispatchTransaction = (tx: any) => {
    if (this.props.readOnly) {
      return null
    }
    const editorState = this.props.editorState.apply(tx)
    if (this.editorView != null) {
      this.editorView.updateState(editorState)
    }
    this.props.onEditorState(editorState)
    setTimeout(() => {
      const attributes = tx.getMeta('attributes')
      if (!attributes) {
        return
      }

      // Transactions can categorise themselves with
      // additional metadata
      // tr.setMeta('attribute', {extraDeets: 'here'})
      switch (attributes.type) {

        case 'insertInfoBlock': {
          const { id, value, isRef } = attributes
          this.props.handleAddInfo(id, value, isRef)
          this.state.editorView?.focus()
          break
        }
        case 'insertReferenceBlock': {
          const { refId, blockId, page, additionalInfo } = attributes
          this.props.handleAddReference({
            refId,
            blockId,
            page,
            additionalInfo,
          })
          this.state.editorView?.focus()
          break
        }
        case 'insertMediaItem': {
          this.props.handleAddMedia()
          break
        }

      }
    }, 0)
    this.props.onChange({
      markdown: markdownSerializer.serialize(editorState.doc),
      text:     editorState.doc.textContent,
    })
  }

  render() {
    return (
      <div
        className="pm-editor"
        data-cy={this.props.testId}
        ref={this.createEditorView}
      />
    )
  }

}
