import { gql, useQuery } from '@apollo/client'
import { TagItemsQuery, TagItemsQueryVariables } from 'cl-studio'
import React from 'react'

import { itemFragment } from './apollo_fragments/items'
import TemplateItems from './components/TemplateItems'

const TAG_ITEMS_QUERY = gql`
  query TagItems($tagId: Int, $explorerId: Int) {
    templates {
      id
      title
    }
    itemsForTag(tagId: $tagId, explorerId: $explorerId) {
      ...itemFields
    }
    tag(id: $tagId) {
      id
      name
    }
  }
  ${itemFragment}
`

interface Props {
  toggleToolbar: () => void,
  clear: () => void,
  tagId: number,
  explorerId: number,
}

function TagsItemsView({ tagId, explorerId, toggleToolbar, clear }: Props) {
  const { data, loading } = useQuery<TagItemsQuery, TagItemsQueryVariables>(TAG_ITEMS_QUERY, {
    variables: {
      tagId,
      explorerId,
    },
  })
  if (loading || !data || !data.itemsForTag || !data.templates) {
    return null
  }

  const { tag } = data

  return (
    <div className="result-box" data-cy="related-items">
      {tag && (
        <div className="result-box-tag-info" data-cy="result-box-tag-info">
          Items tagged with <strong>{tag.name}</strong>{' '}
          <i className="material-icons md-16" onClick={() => clear()}>
            close
          </i>
        </div>
      )}
      <TemplateItems
        items={data.itemsForTag}
        templates={data.templates}
        toggleToolbar={toggleToolbar}
      />
    </div>
  )
}
export default TagsItemsView
