import '../styles/Button.css'

import React, { PureComponent } from 'react'

type OwnProps = {
  structure: {
    label: string,
    [key: string]: any,
  },
  className?: string,
  id?: string,
  value?: string | boolean,
  inline?: boolean,
  updateHandler?: (id?: any, val?: any) => any,
  postRender?: (...args: Array<any>) => any,
};

export default class Button extends PureComponent<OwnProps> {

  static defaultProps = {
    className: 'ui-i ui-btn',
  }

  onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { id, updateHandler } = this.props
    // @ts-ignore: Although checked doesn't appear on a button element, this code is old and assumed
    // to work for now
    const val = event.target.checked

    if (updateHandler) {
      updateHandler(id, val)
    }
  }

  render() {
    const { className, structure, id, inline, postRender } = this.props

    const options = structure?.options ?? {}

    const _id = ('' + id).replace(/[.~@!]/g, '-')
    let _className = `${className} field-${_id}`

    if (inline) {
      _className += ' inline'
    }

    const style: React.CSSProperties = {}
    if (structure.width) {
      style.width = structure.width
    }


    if (structure.className) {
      _className += ` ${structure.className}`
    }

    const output = (
      <button className={_className} onClick={this.onClick} style={style} {...options}>
        {structure.label}
      </button>
    )

    return postRender ? postRender(structure, output) : output
  }

}
