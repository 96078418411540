import { TemplateField } from 'cl-studio'
import React from 'react'

import { MultimediaItemEdit } from './MultimediaItemEdit'
import { MultimediaItemView } from './MultimediaItemView'


export interface MediaItem {
  align?: string,
  alt?: string,
  attribution?: string,
  credit?: string,
  description?: string,
  mediaId: string,
  options?: string,
  path?: string,
  preset?: string,
  start?: string,
  type?: 'audio' | 'image',
  _start?: string,
  isApproximateDate?: boolean,
}

interface Props {
  editId?: string,
  itemId: number,
  itemUpdate: (updateObject: {value: any, key: string}) => void,
  lang: string,
  mediaItem: MediaItem,
  onItemClick: (mediaId?: string) => void,
  onLinkClick?: () => void,
  positions: Array<string>,
  templateFields: Array<TemplateField>,
  templateId: number,
}

export const MultimediaItem: React.FC<Props> = ({
  editId,
  itemId,
  itemUpdate,
  lang,
  mediaItem,
  onItemClick,
  onLinkClick,
  positions,
  templateFields,
  templateId,
}) => {

  return <div onClick={(e) => {
    if (!editId) {
      onItemClick(mediaItem.mediaId)
    } else if (editId && editId !== mediaItem.mediaId) {
      onItemClick(undefined)
    }
    e.stopPropagation()
  }
  }>
    {editId === mediaItem.mediaId
      ? <MultimediaItemEdit
        itemId={itemId}
        itemUpdate={itemUpdate}
        lang={lang}
        mediaItem={mediaItem}
        onLinkClick={onLinkClick}
        positions={positions}
        templateId={templateId}
      />
      : <MultimediaItemView
        itemId={itemId}
        lang={lang}
        mediaItem={mediaItem}
        onLinkClick={onLinkClick}
        positions={positions}
        templateFields={templateFields}
        templateId={templateId}
      /> }
  </div>
}
