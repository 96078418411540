export function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj))
}

export { canDeleteGenealogyEntry } from './can-delete-genealogy-entry'
export { getEventJourneyStatus } from './event-journey-status'
export { itemLoadPostProcessing } from './item-load-post-processing'
export { processItem } from './process-item'
export { sortItems } from './sort-items'
export { titleFields } from './title-fields'
export { transformItemVersion } from './transform-item-version'
export { userHasPublishRights } from './user-has-publish-rights'
export { validTag } from './valid-tag'
