/* eslint-disable simple-import-sort/sort */
import { hot } from 'react-hot-loader'

import { ConnectedFlagsProvider } from '@cls/flags'
import LoginView from '../../views/user/LoginView'
import NewPasswordView from '../../views/user/NewPasswordView'
import ResetPasswordView from '../../views/user/ResetPasswordView'
import SignupView from '../..//views/user/SignupView'
import React, { Component } from 'react'
import { ApolloProvider } from '@apollo/client'
import { Provider } from 'react-redux'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import client from '../../state/client'
import AppBody from './components/AppBody'
import AuthRoute from './components/AuthRoute'
import { Store } from 'redux'
import { RootState } from '@cls/redux'
import { Persistor } from 'redux-persist'

interface OwnProps {
  store: Store<RootState>,
  persistor: Persistor,
}

class AppContainer extends Component<OwnProps> {

  authHandler = () => {
    const { store } = this.props
    const state = store.getState()

    let redirectTo = ''
    if (!state.user.loggedIn) {
      redirectTo = window.location.hash
        ? `${window.location.hash}`.replace(/#/, '')
        : window.location.pathname
    }

    return redirectTo
  }

  render() {
    const { store, persistor } = this.props

    return (
      <ApolloProvider client={client}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ConnectedFlagsProvider>
              <Router>
                <Switch>
                  <Route path="/secretsignup" component={SignupView} />
                  <Route path="/login" component={LoginView} />
                  <Route exact path="/resetpassword" component={ResetPasswordView} />
                  <Route exact path="/new-password" component={NewPasswordView} />
                  <AuthRoute authPath="/login" authHandler={this.authHandler} component={AppBody} />
                </Switch>
              </Router>
            </ConnectedFlagsProvider>
          </PersistGate>
        </Provider>
      </ApolloProvider>
    )
  }

}

export default hot(module)(AppContainer)
