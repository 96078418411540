const leClient = 'http://localhost:3002'

// eslint-disable-next-line
// @ts-ignore
const windowConfig = window.__CLS__ || {}
const location = window.location

const settings = {
  jwtKey:          'lejwt',
  localStoreKey:   'cls',
  apiServer:       `${location.protocol}//${location.host}/api`,
  websocketHost:   windowConfig.websocketHost || 'localhost:9000',
  previewUrl:      windowConfig.previewUrl || leClient,
  textureHost:     '/',
  autoSaveSteps:   5,
  assetHost:       windowConfig.assetHost || 'https://cls-assets.imgix.net/',
  mapboxToken:     'pk.eyJ1Ijoia3B2bWIiLCJhIjoiY2xydGVnYXk0MDEzdDJqczVhOWFoODc4MSJ9.yQDyRhPNuWEsL7KjF6M21A',
  mapboxStyle:     'mapbox://styles/kpvmb/clsvs0h29005v01medktg9onr',
  mapboxImageUrl:  'https://api.mapbox.com/styles/v1/mapbox/light-v10/static',
  useSentry:       windowConfig.useSentry && windowConfig.useSentry === 'true',
  sentryDsn:       windowConfig.sentryDsn,
  sentryEnv:       windowConfig.sentryEnv || 'development',
  defaultLang:     windowConfig.defaultLang || 'en',
  showHostWarning:
    windowConfig.showHostWarning && windowConfig.showHostWarning === 'true',
  featureFlags: {
    ...windowConfig.featureFlags,
  },
  categoryTagIds:        [14401], // Lit. Categories
  genreTagIds:           [14363, 15], // Lit. Genres
}

export default settings
