import {
  HIDE_REFERENCE_POPUP,
  ReferencePopupAction,
  ReferencePopupData,
  SHOW_REFERENCE_POPUP } from './reference-popup-types'

export function showReferencePopup(data: Omit<ReferencePopupData, 'visible'>): ReferencePopupAction {
  return {
    type: SHOW_REFERENCE_POPUP,
    data,
  }
}

export function hideReferencePopup(): ReferencePopupAction {
  return {
    type: HIDE_REFERENCE_POPUP,
  }
}
