import localforage from 'localforage'
import { extendPrototype } from 'localforage-startswith'
extendPrototype(localforage)
import { produce } from 'immer'

import { RootState } from '../state'

export async function getLocalWorkingVersion(id) {
  const versionId = `item-${id}`
  return localforage.getItem(versionId).catch(err => {
    console.warn('get working version error:', err)
  })
}

export function updateOverview(overviewData) {
  return localforage
    .setItem('overview', overviewData)
    .catch(err => {
      console.warn('set working version error:', err)
    })
}

export function updateWorkingVersion(itemData: any) {
  const item = produce(itemData, draftItem => {
    draftItem._lastUpdated = new Date().toISOString()
  })

  // @ts-ignore Immer doesn't like this
  const itemId = item._id
  const key = `item-${itemId == '-1' ? 0 : itemId}`
  return localforage
    .setItem(key, item)
    .then(() => {
      console.info('Local version update id:', itemId)
    })
    .catch(err => {
      console.warn('set working version error:', err)
    })
}

export function discardWorkingVersion(id) {
  const key = `item-${id == '-1' ? 0 : id}`
  return localforage.removeItem(key).catch(err => {
    console.warn('remove working version error:', err)
  })
}

export function getAllWorkingVersions() {
  return localforage.startsWith('item').then(data => {
    const workingVersions = {}
    const keyValuePairs = Object.entries(data)
    keyValuePairs.forEach(pair => {
      let id = parseInt(pair[0].split('-')[1])
      if (id === 0) {
        id = -1
      }
      workingVersions[id] = pair[1]
    })
    return {
      versions: {
        workingVersions,
      },
    }
  })
}

export function getOverviewState(): Promise<RootState['gridView']|null> {
  return localforage.getItem<RootState['gridView']>('overview').catch(err => {
    console.warn('retrieving overview error:', err)
    return null
  })
}
