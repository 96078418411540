// This is an invisible component that's always on screen to send editing data back to server
import { gql } from '@apollo/client'
import React from 'react'
import { connect, ConnectedProps } from 'react-redux'

import client from '../../../state/client'

const USER_UPDATE_MUTATION = gql`
  mutation userUpdate($editing: [Int], $url: String!) {
    userUpdate(editing: $editing, url: $url) {
      id
      url
      lastActive
    }
  }
`

interface OwnProps {
  location: Location,
}

const mapStateToProps = state => {
  const gridTagItemIds = Object.keys(state.gridView.tagsQueue).map(Number)
  const gridCommentItemIds: Array<number> = state.gridView.commentsQueue.map(queueItem => Number(queueItem.itemId))
  return {
    gridItemIds:       [...gridTagItemIds, ...gridCommentItemIds],
    workingVersionIds: Array.from(Object.keys(state.versions.workingVersions)),
  }
}

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export class UserUpdate extends React.Component<OwnProps & PropsFromRedux> {

  componentDidMount() {
    this.sendUserUpdate()
  }

  componentDidUpdate() {
    this.sendUserUpdate()
  }

  sendUserUpdate = () => {
    const { location, workingVersionIds, gridItemIds } = this.props

    if (!location.pathname.includes('logout')) {
      client.mutate({
        mutation:  USER_UPDATE_MUTATION,
        variables: {
          editing: [...workingVersionIds.map(Number), ...gridItemIds],
          url:     location.pathname,
        },
      })
    }
  }

  render() {
    return null
  }

}

export default connector(UserUpdate)
