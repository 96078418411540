import '../styles/TabSelect.css'

import React, { PureComponent } from 'react'

import { switchPanel } from '../ui-redux'

type Props = {
  dispatch: (...args: Array<any>) => any,
  structure: any,
  depth: number,
  activeTab: number,
  uiPath: string,
  scope: string,
  changePanel: (...args: Array<any>) => any,
};

export default class TabSelect extends PureComponent<Props> {

  changePanels = event => {
    const { dispatch, changePanel, scope } = this.props
    const uiPath = event.target.value

    if (changePanel) {
      changePanel(scope, uiPath)
    } else {
      dispatch(switchPanel(scope, uiPath, true))
    }
  }

  render() {
    const { structure, uiPath, depth, activeTab } = this.props
    const opts: Array<JSX.Element> = []
    let label = ''
    let value = ''

    structure.children.forEach((child, idx) => {
      if (child.type === 'panel') {
        const _uiPath = uiPath ? `${uiPath}.children.${idx}` : `children.${idx}`
        if (activeTab === idx) {
          value = _uiPath
          label = child.label
        }

        opts.push(
          <option value={_uiPath} key={idx}>
            {child.label || idx}
          </option>
        )
      }
    })

    if (opts.length < 2) {
      return false
    }

    const htmlFor = `tab-select-${depth}-${uiPath}`
    const className = `ui-tabs ui-select ui-tabs-${structure.tabs} ui-tabs-depth-${depth}`

    return (
      <section className={className}>
        <select onChange={this.changePanels} id={htmlFor} value={value}>
          {opts}
        </select>
        <label htmlFor={htmlFor} className="ui-label">
          <b>{label}</b>
        </label>
      </section>
    )
  }

}
