interface State {
  versions: Record<string, any>,
  editor: Record<string, any>,
}

export function selectEditingVersion(state: State): Record<string, any> | null {
  const activeVersion = state.versions.activeVersion
  if (!activeVersion) {
    return null
  }
  const itemId = activeVersion._id
  const workingVersion = state.versions.workingVersions[itemId]
  const { editingVersionId } = state.editor
  const version = workingVersion && !editingVersionId ? workingVersion : activeVersion
  return version
}
