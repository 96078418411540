// FIXME: Most of these types should be extracted from DB/graphql like it happens for CLS and LE

export type Image = {
  readonly __typename?: 'Image',
  readonly _id: string,
  readonly title?: string,
  readonly path: string,
  readonly height: number,
  readonly width: number,
  readonly credit?: string,
  readonly description?: string,
  readonly _start?: number,
};

export type RichTextField = {
  readonly __typename?: 'RichTextField',
  readonly info: ReadonlyArray<any|undefined>,
  readonly value?: string,
  readonly tagIds?: ReadonlyArray<number|undefined>,
  readonly referenceBlocks: ReadonlyArray<any>,
};

export type Question = {
  readonly __typename?: 'Question',
  readonly title: string,
  readonly content: string,
  readonly answers: ReadonlyArray<Answer>,
  readonly detail?: string,
};

export type Answer = {
  readonly __typename?: 'Answer',
  readonly value: string,
  readonly isCorrect: boolean,
};

export type Quiz = {
  readonly __typename?: 'Quiz',
  readonly title: string,
  readonly teaser: RichTextField,
  readonly description: RichTextField,
  readonly questions: ReadonlyArray<Question>,
  readonly id: number,
  readonly summary: RichTextField,
  readonly image: Image,
  readonly relatedItems: ReadonlyArray<any>,
  readonly mediaItems: Record<MediaId, MediaItem>,
  readonly updatedAt: string,
}

export type MediaId = string

export interface MediaItem {
  align?: string,
  alt?: string,
  attribution?: string,
  credit?: string,
  description?: string,
  mediaId: MediaId,
  options?: string,
  path?: string,
  preset?: string,
  start?: string,
  positions?: Array<string>,
  type?: 'image' | 'audio',
  _start?: string,
}

export const AUTHOR_TEMPLATE_ID = 1001
export const QUIZ_TEMPLATE_ID = 1018
export const WORK_TEMPLATE_ID = 1017
export const CURIOSITY_TEMPLATE_ID = 1009

interface IVersion {
  template_id: number,
  body: {
    mediaItems?: Record<MediaId, MediaItem>,
  },
}

export interface AuthorVersion extends IVersion {
  template_id: typeof AUTHOR_TEMPLATE_ID,
  body: {
    mediaItems: Record<MediaId, MediaItem>,
    bibliography: Array<any>,
    biography: any,
    chronology: Array<any>,
    images: Array<any>,
  },
}

export interface QuizVersion extends IVersion {
  template_id: typeof QUIZ_TEMPLATE_ID,
  body: Quiz,
}

export interface WorkVersion extends IVersion {
  template_id: typeof WORK_TEMPLATE_ID,
  body: {
    cover: {
      path: string,
      title?: string,
    },
    illustrations: Array<{
      credit?: string,
      path: string,
      start?: string,
      title?: string,
    }>,
    introduction: {
      value: string,
      _mediaItems?: Array<string>,
    },
    mediaItems?: Record<MediaId, MediaItem>,
  },
}

export interface CuriosityVersion extends IVersion {
  template_id: typeof CURIOSITY_TEMPLATE_ID,
  body: {
    body: any,
    mediaItems?: Record<MediaId, MediaItem>,
  },
}

export type Version = AuthorVersion | QuizVersion | WorkVersion

export function isAuthorVersion(version:IVersion): version is AuthorVersion {
  return version.template_id === AUTHOR_TEMPLATE_ID
}

export function isQuizVersion(version:IVersion): version is QuizVersion {
  return version.template_id === QUIZ_TEMPLATE_ID
}

export function isWorkVersion(version:IVersion): version is WorkVersion {
  return version.template_id === WORK_TEMPLATE_ID
}

export function isCuriositiesVersion(version: IVersion): version is CuriosityVersion {
  return version.template_id === CURIOSITY_TEMPLATE_ID
}
