import { settings } from '@cls/config'
import { dateToString } from '@kpv-lab/time-utils'
import { TemplateField } from 'cl-studio'
import React from 'react'
import { Link } from 'react-router-dom'

import { MultimediaFieldElement } from '../styles/MultimediaField-css'
import { MultimediaItemElement } from '../styles/MultimediaItem-css'
import { MultimediaField } from './MultimediaField'
import { MediaItem } from './MultimediaItem'

interface Props {
  itemId: number,
  mediaItem: MediaItem,
  onLinkClick?: () => void,
  templateId: number,
  positions: Array<string>,
  lang: string,
  templateFields: Array<TemplateField>,
}

export const MultimediaItemView: React.FC<Props> = ({
  itemId,
  lang,
  mediaItem,
  onLinkClick,
  positions,
  templateFields,
  templateId,
}) => {
  const {
    alt,
    attribution,
    credit,
    description,
    mediaId,
    path,
    start,
    type,
  } = mediaItem
  return <MultimediaItemElement>
    <div className="multimedia-item--content">
      {type === 'audio' ?
        <div className="multimedia-item--audio">
          <audio controls src={path} data-cy="multimedia-audio" preload="metadata" controlsList="nodownload" />
        </div>
        :
        <div
          className="multimedia-item--image-container"
          data-cy="multimedia-image"
        >
          <div className="multimedia-item--image">
            {path
              ? <img src={`${settings.assetHost}${path}`} loading="lazy" />
              : <div className="multimedia-item--image-placeholder">Image path is missing</div>
            }
          </div>
        </div>
      }
      <div className="multimedia-item--fields">
        {(!mediaId && !description && !credit && !attribution && !positions && !start)
          ? <MultimediaField field="no-data">No information attached</MultimediaField>
          : <>
            <MultimediaField label="Title" field="alt">
              {alt}
            </MultimediaField>
            <MultimediaField label="Description" field="description">
              {description}
            </MultimediaField>
            <MultimediaField
              label="Credit"
              field="credit"
            >
              {credit}
            </MultimediaField>
            <MultimediaFieldElement>
              <span className={'multimedia-field--label'} data-cy="multimedia-label">
                Attribution
              </span>
              {
                attribution && attribution.match(/https?:\/\//)
                  ? <span className="multimedia-field--attribution">
                    <a href={attribution} target="_blank" rel="noopener noreferrer"><i className="material-icons md-18">open_in_new</i></a>
                  </span>
                  : <span className="multimedia-field--attribution multimedia-field--value">
                    {attribution ? attribution : <i>No value</i>}
                  </span>
              }
            </MultimediaFieldElement>
            <MultimediaField
              label="Date"
              field="date"
            >
              {dateToString(start, lang)}
            </MultimediaField>
            <MultimediaFieldElement>
              <span className={'multimedia-field--label'} data-cy="multimedia-label">
                Used in
              </span>
              <span className="multimedia-field--positions">
                {positions?.length
                  ? positions.map((position, index, arr) => {
                    const fieldId = position.split('.')[0]
                    const field = templateFields.find(({ id }) => id === fieldId)
                    const group = field?.group?.toLowerCase()
                    const computedGroup = group && group !== fieldId
                      ? group
                      : position.split('.').join('/')

                    return <>
                      <Link
                        key={index}
                        to={`/content/${templateId}/${itemId}/${computedGroup}`}
                        onClick={onLinkClick}
                      >
                        {position}
                      </Link>
                      { index !== arr.length - 1 ? ', ' : ''}
                    </>
                  })
                  : 'Unused'}
              </span>
            </MultimediaFieldElement>
          </>
        }
      </div>
    </div>
  </MultimediaItemElement>
}
