import { settings } from '@cls/config'
import { dateToString } from '@kpv-lab/time-utils'
import React from 'react'

import { MultimediaItemElement } from '../styles/MultimediaItem-css'
import { MultimediaEditField } from './MultimediaEditField'
import { MultimediaFieldInput } from './MultimediaFieldInput'
import { MediaItem } from './MultimediaItem'

interface Props {
  itemId: number,
  mediaItem: MediaItem,
  onLinkClick?: () => void,
  templateId: number,
  lang: string,
  positions: Array<string>,
  itemUpdate: (updateObject: {value: any, key: string}) => void,
}

export const MultimediaItemEdit: React.FC<Props> = ({
  lang,
  mediaItem,
  itemUpdate,
  positions,
}) => {

  const {
    alt,
    attribution,
    credit,
    description,
    mediaId,
    path,
    start,
    type,
  } = mediaItem

  const onUpdateField = (fieldId: string) => (value: string) => {
    const updatedMediaItem = { ...mediaItem, [fieldId]: value }
    itemUpdate({ value: { [mediaId]: updatedMediaItem }, key: 'mediaItems' })
  }

  return <MultimediaItemElement>
    <div className="multimedia-item--content editing">
      {type === 'audio' ?
        <div className="multimedia-item--audio">
          <audio controls src={path} data-cy="multimedia-audio" preload="metadata" controlsList="nodownload" />
        </div>
        :
        <div
          className="multimedia-item--image-container"
          data-cy="multimedia-image"
        >
          <div className="multimedia-item--image">
            {path
              ? <img src={`${settings.assetHost}${path}`} loading="lazy"/>
              : <div className="multimedia-item--image-placeholder">Image path is missing</div>
            }
          </div>
        </div>
      }
      <div className="multimedia-item--fields">
        {(!mediaId && !description && !credit && !attribution && !positions && !start)
          ? <MultimediaEditField field="no-data">No information attached</MultimediaEditField>
          : <>
            <MultimediaEditField label="Title" field="alt">
              <MultimediaFieldInput field="alt" startValue={alt} onUpdate={onUpdateField('alt')}/>
            </MultimediaEditField>
            <MultimediaEditField label="Description" field="description">
              <MultimediaFieldInput field="description" startValue={description} onUpdate={onUpdateField('description')}/>
            </MultimediaEditField>
            <MultimediaEditField
              label="Credit"
              field="credit-edit"
            >
              <MultimediaFieldInput field="credit" startValue={credit} onUpdate={onUpdateField('credit')}/>
            </MultimediaEditField>
            <MultimediaEditField
              label="Attribution"
              field="attribution"
            >
              <MultimediaFieldInput field="attribution" startValue={attribution} onUpdate={onUpdateField('attribution')}/>
            </MultimediaEditField>
            {start && <MultimediaEditField
              label="Date"
              field="date"
            >
              {dateToString(start, lang)}
            </MultimediaEditField>}
          </>
        }
      </div>
    </div>
  </MultimediaItemElement>
}
