import { DraggablePanel } from '@kpv-lab/ui'
import React, { FC, useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { MDToHTML } from '../../pm/markdown/md-to-html'
import { selectEditingVersion } from '../utils/redux-helper'
import { hideReferencePopup } from './state/reference-popup-actions'

interface StateProps {
  referencePopup: {
    title:        string,
    dataKey:      string,
    id:           number,
    page:         string,
    visible:      boolean,
    closeHandler: (referenceBlock: ReferenceBlock) => void,
    additionalInfo: string,
  },
  versions: Record<string, any>,
  editor: Record<string, any>,
}

interface PopupReference {
  id:             number,
  title:          string,
  html:           string,
  fullReference:  string,
}

export interface ReferenceBlock {
  blockId?: number,
  page?:    string,
  refId:    number,
  additionalInfo?: string,
}

const parseReferences = (references?: Array<Record<string, any>>): Array<PopupReference> =>

  references
    ? references.map(({ id, title, fullReference }) => ({
      id,
      title,
      fullReference,
      html:          MDToHTML(fullReference),
    }))
    : []

const mapStateToProps = (state: StateProps) => {
  const {
    title,
    dataKey,
    page,
    id,
    visible,
    closeHandler,
    additionalInfo,
  } = state.referencePopup

  const version = selectEditingVersion(state)

  return {
    title,
    dataKey,
    page,
    id,
    visible,
    references: parseReferences(version?.references),
    closeHandler,
    additionalInfo,
  }
}

const windowConfig = (window as any).__CLS__ || {}

const connector = connect(mapStateToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux

export const ReferencePopupComponent: FC<Props> = ({
  title,
  dataKey,
  visible,
  references,
  page,
  id,
  additionalInfo,
  ...props
}) => {
  const [selectedId, setSelectedId] = useState(0)
  const [selectedRef, setSelectedRef] = useState<PopupReference>()
  const [pageValue, setPageValue] = useState('')
  const [additionalInfoValue, setAdditionalInfo] = useState('')

  useEffect(() => {
    setSelectedId(id)
  }, [id])

  useEffect(() => {
    setSelectedRef(references.find((ref) => ref.id === selectedId))
  }, [selectedId])

  useEffect(() => {
    page && setPageValue(page)
  }, [page])

  useEffect(() => {
    additionalInfo && setAdditionalInfo(additionalInfo)
  }, [additionalInfo])

  const onClose = () => {
    const { closeHandler, dispatch } = props

    if (selectedRef) {
      const { id: refId } = selectedRef
      closeHandler && closeHandler({ refId, page: pageValue, additionalInfo: additionalInfoValue })
    }

    setSelectedId(0)
    setPageValue('')
    setAdditionalInfo('')
    dispatch(hideReferencePopup())
  }

  if (!visible) {
    return null
  }

  const _title =
           title || `Reference for ${dataKey.split('_').join(' ')}`
  const pageAbbreveation =
           windowConfig.defaultLang === 'de' ? ', S. ' : ''

  return (
    <DraggablePanel
      className="ui-floating-panel reference-popup"
      name="referenceSelector"
      backdrop={true}
      backdropHandler={onClose}
    >
      <div className="references-container">
        <div className="reference-popup-header">
          <span className="draggable" />
          <h2 className="ui-title ui-comment-title">
            <span>{_title}</span>
          </h2>
          <button
            className="btn btn-primary"
            onClick={onClose}
            data-cy="reference-apply-btn"
          >
            Apply
          </button>
        </div>
        {!selectedRef && <ReferenceAlert />}
        <div className="references-list">
          {references.length > 0 ? (
            references.map(({ html, id: refId, title: refTitle }, idx) => {
              const isSelected = refId === selectedId
              return (
                <div
                  key={`reference-${refId}`}
                  className={`reference-item ${isSelected ? 'selected' : ''}`}
                  onClick={() => setSelectedId(refId)}
                  data-cy={`reference-${idx}`}
                >
                  <div className="reference-item-icon">
                    {isSelected && (
                      <i className="material-icons md-18">library_books</i>
                    )}
                  </div>
                  <div className="reference-item-body">
                    <p className="reference-item-title">{refTitle}</p>
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                  </div>
                </div>
              )
            })
          ) : (
            <p className="references-empty-message">
              No references found. Please create references on the References
              page.
            </p>
          )}
        </div>
        <div
          className="references-footer"
          style={{ opacity: selectedRef ? 1 : 0 }}
        >
          <div className="ui-field">
            <label htmlFor="reference-page" className="ui-label">
              Additional Info
            </label>
            <section className="ui-input">
              <input
                id="reference-page"
                data-cy="reference-info-input"
                type="text"
                value={additionalInfoValue}
                onChange={(event) => setAdditionalInfo(event.target.value)}
                title="Additional Info"
              />
              <span className="reference-title-preview">
                {`${selectedRef?.title ?? ''}${
                  pageValue ? `${pageAbbreveation}${pageValue}` : ''
                }`}
              </span>
            </section>
          </div>
          <div className="ui-field">
            <label htmlFor="reference-page" className="ui-label">
              Page
            </label>
            <section className="ui-input">
              <input
                id="reference-page"
                data-cy="reference-page-input"
                type="text"
                value={pageValue}
                onChange={(event) => setPageValue(event.target.value)}
                title="Page"
              />
              <span className="reference-title-preview">
                {`${additionalInfoValue + ' ' ?? ''}${selectedRef?.title ?? ''}${
                  pageValue ? `${pageAbbreveation}${pageValue}` : ''
                }`}
              </span>
            </section>
          </div>
        </div>
      </div>
    </DraggablePanel>
  )
}

const ReferenceAlert: FC = () => {
  return (
    <div className="reference-alert" data-cy="reference-alert">
      <i className="material-icons md-18">report_problem</i>
      <p>No matching reference found. Please choose one or delete this block.</p>
    </div>
  )
}

const ConnectedComponent = connector(ReferencePopupComponent)

export {
  ConnectedComponent as ReferencePopup,
}
