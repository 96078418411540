import { Flag } from '@cls/flags'
import { RootState } from '@cls/redux'
import { Explorer, Template } from 'cl-studio'
import React, { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Link, RouteComponentProps } from 'react-router-dom'

import { showUnitaggerPopup as showUnitaggerPopupAction } from '../../../../components/unitagger-popup/state/unitagger-popup-redux'
import { createNewItem as createNewItemAction } from '../../../content/state/versions-redux'
import MessageSubscription from '../../../dashboard/components/Messages/MessageSubscription'
import CurrentlyEditing from '../../../dashboard/components/UsersEditing/CurrentlyEditing'
import { ExplorerSelect } from '../../../overview/components/ExplorerSelect'
import { Account } from '../../../user/components/Account'
import { viewAccount as viewAccountAction } from '../../../user/state/actions-account'
import Authorize from '../Authorize'
import { TemplateSelector } from './TemplateSelector'

interface OwnProps extends RouteComponentProps {
  toggleToolbar: () => void,
  query?: string,
  setQuery: (a: string) => void,
  explorer: Explorer,
  setExplorer: (explorer: Explorer) => void,
}

const connector = connect(
  (state: RootState) => ({ user: state.user } as { user: any }),
  {
    viewAccount:        viewAccountAction,
    showUnitaggerPopup: showUnitaggerPopupAction,
    createNewItem:      createNewItemAction,
  }
)

type TypesFromRedux = ConnectedProps<typeof connector>

type CombinedProps = OwnProps & TypesFromRedux

function Toolbar({
  toggleToolbar,
  location,
  user,
  viewAccount,
  query,
  setQuery,
  showUnitaggerPopup,
  createNewItem,
  explorer,
  setExplorer,
}: CombinedProps) {
  const [isCreatingNewItem, setIsCreatingNewItem] = useState(false)
  const [template, setTemplate] = useState<Partial<Template>|undefined>(undefined)

  const [section] = location.pathname.slice(1).split('/')
  const userName = user?.profile?.name
  const editingUser = Boolean(user && user.editing)
  const hide = isCreatingNewItem ? 'hide' : ''
  const show = isCreatingNewItem ? 'show' : ''
  const searchRef = React.createRef<HTMLInputElement>()

  // focus the search input only when first mounted
  useEffect(() => searchRef.current?.focus(), [])

  return (
    <div className="toolbar">
      <div className={`item-tags-block ${hide}`}>
        <ExplorerSelect explorer={explorer} changeHandler={setExplorer} />
        <button
          className="toolbar-btn add-item"
          data-cy="add-new-content"
          onClick={() => setIsCreatingNewItem(!isCreatingNewItem)}
        >
          <i className="material-icons md-18">add_box</i>
          <span className="label">New</span>
        </button>

        <button
          className="toolbar-btn add-tag"
          data-cy="tags-editor-icon"
          onClick={() => showUnitaggerPopup({ query: '', action: 'filter' })}
        >
          <i className="material-icons md-18">local_offer</i>
          <span className="label">TAGS</span>
        </button>
      </div>

      <div className={`create-template-block ${show}`}>
        <TemplateSelector setTemplate={setTemplate} explorerId={explorer.id} />
        <div className="create-item">
          {template && (
            <Link
              className="toolbar-btn add-item"
              to={`/content/${template.id}/-1`}
              data-cy="create-new-content"
              data-id={template.id}
              onClick={() => {
                createNewItem(template)
                toggleToolbar()
              }}
            >
              <i className="material-icons md-18">add_box</i>
              <span className="label">Create</span>
            </Link>
          )}
        </div>

        <div className="cancel" onClick={() => setIsCreatingNewItem(!isCreatingNewItem)}>
          <i className="material-icons md-18">close</i>
          <span className="label">Cancel</span>
        </div>
      </div>

      <div className={`toolbar-search-container ${hide}`}>
        <input
          className="menu-search"
          data-cy="menu-search"
          type="text"
          value={query || ''}
          onChange={e => setQuery(e.target.value)}
          ref={searchRef}
        />
        {query ? (
          <i
            onClick={() => setQuery('')}
            className="menu-search-icon menu-search-icon--close material-icons md-18"
          >
            close
          </i>
        ) : (
          <i className="menu-search-icon material-icons md-18">search</i>
        )}
      </div>

      <div className={`page-links ${hide}`}>
        <Link
          to="/overview"
          className={`toolbar-btn ${section === 'content' ? 'active' : ''}`}
          data-cy="overview-link"
          onClick={toggleToolbar}
        >
          <i className="material-icons md-18">language</i>
          <span className="label">Overview</span>
        </Link>
        <Flag
          name={['features', 'showMapsEditor']}
          render={() => (
            <Link
              to="/maps"
              className={`toolbar-btn ${section === 'maps' ? 'active' : ''}`}
              data-cy="maps-manager-link"
              onClick={toggleToolbar}
            >
              <i className="material-icons md-18">map</i>
              <span className="label">Maps</span>
            </Link>)
          }
          fallbackRender={() => null}
        />
        <Authorize roles="admin">
          <Link
            to="/templates"
            className={`toolbar-btn ${section === 'templates' ? 'active' : ''}`}
            data-cy="templates-editor-link"
            onClick={toggleToolbar}
          >
            <i className="material-icons md-18">settings</i>
            <span className="label">Templates</span>
          </Link>
          <Link
            to="/users"
            className={`toolbar-btn ${section === 'users' ? 'active' : ''}`}
            data-cy="user-manager-link"
            onClick={toggleToolbar}
          >
            <i className="material-icons md-18">people</i>
            <span className="label">Users</span>
          </Link>
        </Authorize>
      </div>

      <div className="users-container">
        <MessageSubscription>
          <CurrentlyEditing />
        </MessageSubscription>

        <button className="toolbar-btn user-info" onClick={() => viewAccount(true)}>
          <i className="material-icons md-18">person_pin</i>
          <span className="label">{userName}</span>
        </button>
      </div>

      {editingUser && <Account user={user} />}
    </div>
  )
}

export default connector(Toolbar)
