import { toFloatTime } from '@kpv-lab/time-utils'

import { templateIds } from '../../../config/constants'

export function itemLoadPostProcessing(data, template) {
  template.fields.forEach(f => {
    // make sure all events have a proper start date
    // console.log('data in itemLoadPostProcessing', data);
    if (f.type === 'event' && data.item[f.id]) {
      data.item[f.id].forEach(e => {
        if (!e._start && e.start) {
          e._start = toFloatTime(e.start)
        }
      })
    } else if (/date/i.test(f.type) && typeof data.item[f.id] !== 'object') {
      data.item[f.id] = { value: data.item[f.id] }
    }

    const field = data.item[f.id]
    if (f.itemArray && field) {
      if (!Array.isArray(field) && typeof field === 'object') {
        data.item[f.id] = Object.keys(field).map(k => field[k])
      }
    }
  })

  // catch for invalid legacy tagId data
  if (!data.item._tagIds && data.item.tagIds) {
    data.item._tagIds = data.item.tagIds
  }

  if (data.item._tagIds) {
    data.item._tagIds = data.item._tagIds.filter(Number)
  }

  const i = data.item

  const genealogy = i.genealogy
  if (i._tplId === templateIds.author && (!genealogy?.length)) {
    const defaultGenealogyEntry = {
      id:          Math.floor(1000 + Math.random() * 9000),
      end:         i.date_of_death?.value,
      name:        i._title,
      _end:        i._end,
      start:       i.date_of_birth?.value,
      notes:       '',
      _start:      i._start,
      fatherId:    '',
      motherId:    '',
      highlight:   true,
      connections: [],
    }

    data.item.genealogy = [defaultGenealogyEntry]
  }
  // console.log('item post processing:', {data, template, tagsData})
  return data
}
