import { gql, useQuery } from '@apollo/client'
import { useLatestUsers } from '@cls/hooks'
import { EditingUser, itemTitlesQuery } from 'cl-studio'
import React, { useEffect } from 'react'

import UserList from './UserList'

// Message updates prop comes from MessageSubscription component.
// Has to be optional as this component is rendered as a child of that one (see Toolbar).
interface OwnProps {
  subscribeToMessageUpdates?: () => () => void,
}

const CurrentlyEditing: React.FC<OwnProps> = ({ subscribeToMessageUpdates }) => {

  useEffect(() => {
    if (subscribeToMessageUpdates) {
      return subscribeToMessageUpdates()
    }
  }, [subscribeToMessageUpdates])

  const { loading, data, subscribeToUpdates } = useLatestUsers()
  const { data: titlesData } = useQuery<itemTitlesQuery>(ITEM_TITLES)

  if (loading || !data || !titlesData) {
    return (
      <div className="users">
        <div className="users-wrap">
          <div className="active-user-count">-</div>
          <span className="label">Editors</span>
        </div>
      </div>
    )
  }

  return (
    <UserList
      activeUsers={data.activeUsers as Array<EditingUser>}
      allUsers={data.allUsers as Array<EditingUser>}
      itemTitles={titlesData.itemTitles}
      subscribeToUpdates={subscribeToUpdates}
    />
  )
}

export const ITEM_TITLES = gql`
  query itemTitles {
    itemTitles
  }
`

export const USERS = gql`
  query activeUsers {
    activeUsers {
      id
      url
      name
      editing
      lastActive
    }
    allUsers {
      id
      name
      lastActive
    }
  }
`

export const EDIT_SUBSCRIPTION = gql`
  subscription onNewUserUpdate {
    userUpdate {
      id
      url
      name
      editing
      lastActive
    }
  }
`

export default React.memo(CurrentlyEditing)
