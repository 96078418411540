import {
  defaultMarkdownSerializer,
  MarkdownSerializer
} from 'prosemirror-markdown'

export const markdownSerializer = new MarkdownSerializer(
  {
    ...defaultMarkdownSerializer.nodes,
    image: (state, node) => {
      const { align } = node.attrs
      let alignClass = ''

      if (align !== 'img-align-left') {
        alignClass = align
      }

      state.write(
        '![' +
        state.esc(node.attrs.alt || '') +
        '](' +
        // Need to remove any existing alignment
        state.esc(node.attrs.src.replace(/##.*$/gm, '') + `##${alignClass}`) +
        (node.attrs.title ? ' ' + state.quote(node.attrs.title) : '') +
        ')'
      )
    },
    audio: (state, node) => {
      const { insertEscape, src } = node.attrs
      state.write(`${insertEscape ? '\u200B' : ''}[?]`)
      state.write(`(${src})`)
    },
    video: (state, node) => {
      const { insertEscape, src } = node.attrs
      state.write(`${insertEscape ? '\u200B' : ''}[?]`)
      state.write(`(${src})`)
    },
    paragraph: (state, node) => {
      const { align } = node.attrs
      if (align && !align.endsWith('left')) {
        state.write(`~~ ${align} ~~`)
        state.renderInline(node)
        state.closeBlock(node)
      } else {
        state.renderInline(node)
        state.closeBlock(node)
      }
    },
    info: (state, node) => {
      const { href, insertEscape } = node.attrs
      // See align scheme for why we can't have just white space
      state.write(`${insertEscape ? '\u200B' : ''}[?]`)
      state.write(`(${href})`)
    },
    reference: (state, node) => {
      const { href, insertEscape } = node.attrs
      // See align scheme for why we can't have just white space
      state.write(`${insertEscape ? '\u200B' : ''}[?]`)
      state.write(`(${href})`)
    },
  },
  {
    ...defaultMarkdownSerializer.marks,
    itemlink: {
      open: () => {
        return '['
      },
      mixable:                  true,
      expelEnclosingWhitespace: false,
      close:                    (node, mark) => {
        const { href } = mark.attrs
        return `](${href})`
      },
    },
    tag: {
      open: () => {
        return '['
      },
      mixable:                  true,
      expelEnclosingWhitespace: false,
      close:                    (node, mark) => {
        const { href } = mark.attrs
        return `](${href})`
      },
    },
    link: {
      ...defaultMarkdownSerializer.marks.link,
      mixable: true,
    },
  }
)
