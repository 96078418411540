import React from 'react'
import { Link } from 'react-router-dom'

export default () => {
  return (
    <div className="page not-found">
      <h1>Page not found!</h1>
      <Link to="/overview">Back to the overview</Link>
    </div>
  )
}
