import { settings } from '@cls/config'
import { checkHttpStatus, parseJSON } from '@cls/utils/fetch'
import fetch from 'isomorphic-fetch'

import * as types from './action-types'

export function getMapResults(query: any) {
  return (dispatch: any, getState: any) => {
    dispatch(getMapSearchResultsRequest())
    const { lang } = getState().editor

    const options = {
      method:  'get',
      headers: {
        'cache-control': 'max-age=604800',
        'content-type':  'application/vnd.geo+json; charset=utf-8',
      },
    }

    const url = [
      'https://api.mapbox.com/geocoding/v5/mapbox.places/',
      `${query}.json?autocomplete=true&language=${lang}&limit=10&access_token=${settings.mapboxToken}`,
    ].join('')

    return fetch(url, options)
      .then(checkHttpStatus)
      .then(parseJSON)
      .catch(err => {
        dispatch(getMapSearchResultsFailure(err))
      })
      .then(res => {
        if (!res) {
          return
        }
        dispatch(getMapSearchResultsSuccess(res))
      })
  }
}

export function getMapSearchResultsRequest() {
  return <const>{
    type: types.MAP_SEARCH_REQUEST,
  }
}

export function getMapSearchResultsSuccess(data: any) {
  return <const>{
    type: types.MAP_SEARCH_SUCCESS,
    data: data,
  }
}

export function getMapSearchResultsFailure(error: any) {
  const errorText = (error && error.response && error.response.statusText) || 'Request failed...'

  return <const>{
    type: types.MAP_SEARCH_FAILURE,
    data: errorText,
  }
}

export function showMapEditor(data?: any) {
  return <const>{
    type: types.SHOW_MAP_EDITOR,
    data: data ?? {},
  }
}

export function hideMapEditor() {
  return <const>{
    type: types.HIDE_MAP_EDITOR,
  }
}

export function lastMapLocation(coords: any, zoom: any, placeLabel: any) {
  return <const>{
    type: types.LAST_MAP_LOCATION,
    data: { coords, zoom, placeLabel },
  }
}

export type LastMapLocation = ReturnType<typeof lastMapLocation>
export type HideMapEditor = ReturnType<typeof hideMapEditor>
export type ShowMapEditor = ReturnType<typeof showMapEditor>
export type GetSearchResultsFailure = ReturnType<typeof getMapSearchResultsFailure>
export type GetSearchResultsSuccess = ReturnType<typeof getMapSearchResultsSuccess>
export type GetSearchResultsRequest = ReturnType<typeof getMapSearchResultsRequest>
