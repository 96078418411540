import { produce } from 'immer'
import { Action } from 'redux'

export const SHOW_DATE_POPUP = 'SHOW_DATE_POPUP'
export const HIDE_DATE_POPUP = 'HIDE_DATE_POPUP'

interface ShowDatePopup extends Action<'SHOW_DATE_POPUP'> {
  data: any,
}

export type DatePopupActions = ShowDatePopup | Action<'HIDE_DATE_POPUP'>

export function showDatePopup(data: any): DatePopupActions {
  return {
    type: SHOW_DATE_POPUP,
    data: data,
  }
}

export function hideDatePopup(): DatePopupActions {
  return {
    type: HIDE_DATE_POPUP,
  }
}

const initialState = {
  value:             '',
  visible:           false,
  dataKey:           '',
  structure:         {},
  closeHandler:      () => { /* default no-op */ },
}

const reducer = () => {
  return produce((draftState, action) => {
    if (!action) {
      return draftState
    }

    switch (action.type) {

      case SHOW_DATE_POPUP:
        draftState.visible = true
        draftState.value = action.data.value
        draftState.dataKey = action.data.dataKey
        draftState.structure = action.data.structure
        draftState.closeHandler = action.data.closeHandler
        break

      case HIDE_DATE_POPUP:
        return initialState

    }

  }, initialState)
}

export default reducer
