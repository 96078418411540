import '../styles/TabRow.css'

import React, { PureComponent } from 'react'

import { switchPanel } from '../ui-redux'

type Props = {
  dispatch: (...args: Array<any>) => any,
  structure: any,
  depth: number,
  activeTab: number,
  uiPath: string,
  scope: string,
  changePanel: (...args: Array<any>) => any,
};

export default class TabRow extends PureComponent<Props> {

  changePanels = event => {
    const { dispatch, changePanel, scope } = this.props
    const uiPath = event.target.dataset.uiPath

    if (changePanel) {
      changePanel(scope, uiPath)
    } else {
      dispatch(switchPanel(scope, uiPath))
    }
  }

  render() {
    const { structure, uiPath, depth, activeTab } = this.props


    const btns: Array<JSX.Element> = []
    structure.children.forEach((child, idx) => {
      if (child.type === 'panel') {
        const _uiPath = uiPath ? `${uiPath}.children.${idx}` : `children.${idx}`
        const label = `${child.label || idx}`

        let className = `ui-tab-row-btn tab-${label.toLowerCase().replace(/\s+/g, '-')}`
        if (activeTab === idx) {
          className += ' active'
        }

        btns.push(
          <a className={className} key={idx} onClick={this.changePanels} data-ui-path={_uiPath}>
            {label}
          </a>
        )
      }
    })

    if (btns.length < 2) {
      return false
    }

    const className = `ui-tabs ui-tabs-${structure.tabs} ui-tabs-depth-${depth}`
    return <nav className={className}>{btns}</nav>
  }

}
