import '../styles/ColorDot.css'

import { col2rgba } from '@kpv-lab/color-utils'
import React, { PureComponent } from 'react'

import { setColor } from '../color-editor-redux'
import { clearSwatch } from '../swatches-redux'

type OwnProps = {
  dispatch: (...args: Array<any>) => any,
  saveSwatch: (...args: Array<any>) => any,
  className: string,
  idx: string,
  value: string,
  alpha: number,
  hint: string,
};

type State = {
  time: number,
  interacting: boolean,
};

export default class ColorDot extends PureComponent<OwnProps, State> {

  static defaultProps = {
    className: 'color-dot',
  }

  state = { time: 0, interacting: false }

  mouseDownHandler = () => {
    this.setState({
      time:        Date.now(),
      interacting: true,
    })
  }

  mouseUpHander = event => {
    const { dispatch, saveSwatch, idx, value, alpha } = this.props
    const dt = Date.now() - this.state.time

    if (event.altKey || event.shiftKey) {
      dispatch(clearSwatch(idx))
    } else if (!value || dt > 800) {
      saveSwatch(idx)
    } else if (value) {
      const col = col2rgba(value)

      dispatch(setColor(col.slice(0, 3), alpha))
    }

    this.setState({ interacting: false })
  }

  render() {
    const { className, value, hint, idx } = this.props
    let _className = `${className} swatch-${idx}`

    if (this.state.interacting) {
      _className += ' interacting'
    }

    const style: React.CSSProperties = {}
    if (value) {
      style.backgroundColor = value
    }

    return (
      <span
        className={_className}
        style={style}
        onMouseDown={this.mouseDownHandler}
        onMouseUp={this.mouseUpHander}
        title={`Click to save or load existing color
Long press to replace
alt+click to clear
${hint || 'No color'}`}
      />
    )
  }

}
