import styled from '@emotion/styled'

export const VideoFormElement = styled.form`
  label: video-form;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5em;

  .field-video-url {
    flex-grow: 1;
    padding-left:0;
    input {
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: none;
    }
  }
  .field-video-url-submit {
    margin: 2px 0;
  }
`
