import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore } from 'redux-persist'
import thunk from 'redux-thunk'

import { RootState } from './index'
import persistMiddleware from './persist-middleware'
import { rootReducer } from './reducer'

export function configureStore(initialState: Partial<RootState>) {

  const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(thunk, persistMiddleware))
  )
  const persistor = persistStore(store)

  return {
    store,
    persistor,
  }
}
