import { flags } from '@cls/flags'
import { createTransform } from 'redux-persist'


type FeatureFlags = typeof flags.features

/**
 * This ensures that we only persist and rehydrate current feature flags. Without this, older keys
 * that are removed from the list will continue to be persisted.
 */
const featuresTransform = createTransform<FeatureFlags, FeatureFlags, any>(
  (inboundState) => {
    return Object.keys(flags.features).reduce((acc, curr) => {
      return { ...acc, [curr]: inboundState?.[curr] ?? false }
    }, {} as FeatureFlags)
  },
  (outboundState) => {
    return Object.keys(flags.features).reduce((acc, curr) => {
      return { ...acc, [curr]: outboundState[curr] }
    }, {} as FeatureFlags)
  },
  { whitelist: ['features'] }
)

export { featuresTransform }
