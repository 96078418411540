import './styles/MultimediaPopup.css'

import { RootState } from '@cls/redux'
import { findMediaItems } from '@kpv-lab/media-migrator'
import { DraggablePanel } from '@kpv-lab/ui'
import React, { useMemo, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { itemUpdate as itemUpdateAction, selectEditingVersion } from '../../views/content/state/versions-redux'
import { MediaItem, MultimediaItem } from './components/MultimediaItem'
import { hideMultimediaPopup as hideMultimediaPopupAction } from './state/actions'

const mapStateToProps = (state: RootState) => {
  const { multimediaPopup, editor } = state

  const version: {mediaItems: Record<string, MediaItem>} = selectEditingVersion(state)

  return {
    itemId:     multimediaPopup.itemId,
    itemTitle:  multimediaPopup.title,
    lang:       editor.lang,
    template:   editor.templates.find(({ id }) => id === multimediaPopup.templateId),
    templateId: multimediaPopup.templateId,
    version,
  }
}

const connector = connect(mapStateToProps, {
  hideMultimediaPopup: hideMultimediaPopupAction,
  itemUpdate:          itemUpdateAction,
})

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux

export const MultimediaPopup: React.FC<Props> = ({
  itemId,
  itemTitle,
  lang,
  version,
  template,
  templateId,
  hideMultimediaPopup,
  itemUpdate,
}) => {
  const { mediaItems } = version as {mediaItems: Record<string, MediaItem>}
  const positions: Map<string, Array<string>> = useMemo(() => {
    return findMediaItems({
      // @ts-ignore We don't have good types for the version objects
      template_id: version._tplId,
      // @ts-ignore We don't have good types for the version objects
      body:        version,
    })
  }, [version])

  const entries = useMemo(() => {
    return mediaItems ? Object.entries(mediaItems).filter(mediaItem => {
      const item = mediaItem[1]
      const itemPositions = positions[item.mediaId] ?? []
      const isInImages = itemPositions.some(p => p.includes('images'))
      return !isInImages
    }) : []
  }, [positions])

  const [editId, setEditId] = useState<undefined | string>(undefined)
  const onItemClick = (mediaId?: string) => {
    if (!mediaId || mediaId === editId) {
      setEditId(undefined)
    } else {
      setEditId(mediaId)
    }
  }


  return (
    <DraggablePanel
      className="ui-floating-panel multimedia-popup-container"
      name="multimedia-panel"
      width="60%"
    >
      <div className="ui-title draggable"><p>Media for {itemTitle || 'Author name'}</p></div>
      <i className="material-icons ui-close-btn" onClick={hideMultimediaPopup} data-cy="multimedia-close">
        close
      </i>
      <div className="multimedia-popup" onClick={() => setEditId(undefined)}>
        {entries.length
          ? <div className="multimedia-items">
            {entries.map(([mediaId, item]) =>
              <MultimediaItem
                key={mediaId}
                mediaItem={item}
                itemId={itemId}
                lang={lang}
                templateFields={template.fields}
                templateId={templateId}
                onLinkClick={hideMultimediaPopup}
                itemUpdate={itemUpdate}
                onItemClick={onItemClick}
                editId={editId}
                positions={positions.get(mediaId) || []}
              />
            )}
          </div>
          : <div className="no-multimedia">
            No media have been uploaded for this item
          </div>
        }
      </div>
    </DraggablePanel>
  )
}


export default connector(MultimediaPopup)
