import '../styles/ActionButtons.css'

import React, { PureComponent } from 'react'

type Props = {
  acceptDisabled?: boolean,
  cancelDisabled?: boolean,
  loading?: boolean,
  cancelHandler?: (...args: Array<any>) => any,
  acceptHandler?: (...args: Array<any>) => any,
  loadingLabel?: string,
  className?: string,
  acceptLabel?: string,
  cancelLabel?: string,
};

export default class ActionButtons extends PureComponent<Props> {

  render() {
    const {
      acceptDisabled,
      cancelDisabled,
      cancelHandler,
      acceptHandler,
      loading,
      acceptLabel,
      cancelLabel,
      className,
      loadingLabel,
    } = this.props

    return (
      <div className={`action-buttons ${className || ''}`}>
        <button
          onClick={cancelHandler}
          className="btn btn-secondary action-btn-cancel"
          disabled={cancelDisabled}
        >
          {cancelLabel}
        </button>
        <button
          onClick={acceptHandler}
          className={`btn btn-primary action-btn-accept ${loading && 'loading'}`}
          disabled={acceptDisabled}
        >
          {(loading && loadingLabel) || acceptLabel}
        </button>
      </div>
    )
  }

}
