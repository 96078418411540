export const SHOW_MAP_EDITOR = 'SHOW_MAP_EDITOR'
export const HIDE_MAP_EDITOR = 'HIDE_MAP_EDITOR'

export const MAP_SEARCH_REQUEST = 'MAP_SEARCH_REQUEST'
export const MAP_SEARCH_SUCCESS = 'MAP_SEARCH_SUCCESS'
export const MAP_SEARCH_FAILURE = 'MAP_SEARCH_FAILURE'

export const LAST_MAP_LOCATION = 'LAST_MAP_LOCATION'

export const GET_COUNTRY_REQUEST = 'GET_COUNTRY_REQUEST'
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS'
export const GET_COUNTRY_FAILURE = 'GET_COUNTRY_FAILURE'
