import React, { PureComponent, SyntheticEvent } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { DatePopupDialog } from './DatePopupDialog'
import { hideDatePopup as hideDatePopupAction } from './state/date-control-redux'

interface DatePopupWrapperProps {
  value: string,
  dataKey: string,
  structure: Record<string, unknown>,
  template: Record<string, string>,
  dispatch: Dispatch,
  hideDatePopup: () => void,
  closeHandler: (date: Record<string, string|number>, isApproximateDate: boolean) => void,
}

class DatePopupWrapper extends PureComponent<DatePopupWrapperProps> {

  private refOverlay: React.RefObject<HTMLElement>;

  constructor(props: DatePopupWrapperProps) {
    super(props)

    this.refOverlay = React.createRef()
  }

  overlayHandler = (e: SyntheticEvent) => {
    if (e.target === this.refOverlay.current) {
      this.props.hideDatePopup()
    }
  };


  dateAcceptHandler = (
    date: Record<string, string|number>,
    isApproximateDate = false
  ) => {


    this.props.closeHandler(date, isApproximateDate)
    this.props.hideDatePopup()
  };

  render() {
    return (
      <section
        className="overlay"
        ref={this.refOverlay}
        onClick={this.overlayHandler}
      >
        <DatePopupDialog
          value={this.props.value}
          dataKey={this.props.dataKey}
          structure={this.props.structure}
          dateAcceptHandler={this.dateAcceptHandler}
          cancelHandler={() => this.props.hideDatePopup()}
        />
      </section>
    )
  }

}

const mapStateToProps = (state: any) => {
  const { dateControl } = state

  return {
    value:        dateControl.value,
    dataKey:      dateControl.dataKey,
    structure:    dateControl.structure,
    closeHandler: dateControl.closeHandler,
  }
}

export default connect(mapStateToProps, {
  hideDatePopup: hideDatePopupAction,
})(DatePopupWrapper)
