import { extractMediaIdFromUrl, extractMediaIdsFromRichText } from './common-media-finder'

export const extractMediaIdsFromWorkField = (fieldName: string, field: any) => {
  let mediaIds: Array<string> = []

  switch (fieldName) {

    case 'cover':
    case 'illustrations':
      if (field.path) {
        const extractedMediaId = extractMediaIdFromUrl(field.path)
        mediaIds = extractedMediaId ? [extractedMediaId] : []
      }
      break

    case 'introduction':
      mediaIds = extractMediaIdsFromRichText(field.value)
      break

  }

  return mediaIds
}
