import { ActionHandler, createReducer } from '@cls/redux'

import * as types from './action-types'
import { ShowMapEditor } from './actions'

export interface MapLocation {
  coords: Array<number>,
  placeLabel: string,
  zoom: number,
  viewOnly: boolean,
  country?: string,
}

interface OwnState {
  visible: boolean,
  updateHandler: (...args: any) => void,
  prefillSearch: string,
  customLabelInput: boolean,
  country: string,
  search: {
    status: string,
    result: Array<any>,
  },
  location: MapLocation,
  lastMapLocation: MapLocation,
  dataKey: string,
}

const initialState: OwnState = {
  visible:          false,
  updateHandler:    () => { /* no-op */ },
  prefillSearch:    '',
  customLabelInput: true,
  country:          '',
  search:           {
    status: '',
    result: [],
  },
  dataKey:  '',
  location: {
    coords:     [],
    placeLabel: '',
    zoom:       3,
    viewOnly:   false,
  },
  lastMapLocation: {
    coords:     [],
    placeLabel: '',
    zoom:       3,
    viewOnly:   false,
  },
}

const defaultLocation: MapLocation = {
  coords:     [3.8728, 36.5464],
  placeLabel: '',
  zoom:       6,
  viewOnly:   false,
}

const showMapEditor: ActionHandler<OwnState, ShowMapEditor> = (draftState, action) => {
  const { value, updateHandler, dataKey, customLabelInput, prefillSearch } = action.data
  draftState.customLabelInput = customLabelInput
  draftState.prefillSearch = prefillSearch
  draftState.visible = true

  // The location is determined from either the location that's been passed in,
  // the last location saved, or the default location
  const { lastMapLocation } = draftState
  const coords = value?.coords?.length
    ? value.coords
    : lastMapLocation.coords.length ? lastMapLocation.coords : defaultLocation.coords

  const placeLabel = value?.placeLabel
    ? value.placeLabel
    : lastMapLocation.placeLabel.length ? lastMapLocation.placeLabel : defaultLocation.placeLabel

  const zoom = value?.zoom
    ? value.zoom
    : lastMapLocation.coords.length ? lastMapLocation.zoom : defaultLocation.zoom

  const viewOnly = value?.viewOnly
    ? value.viewOnly
    : lastMapLocation.viewOnly ? lastMapLocation.viewOnly : defaultLocation.viewOnly

  draftState.location = {
    coords,
    placeLabel,
    zoom,
    viewOnly,
  }

  draftState.updateHandler = updateHandler
  draftState.dataKey = dataKey
}

const actionHandlers = new Map<string, ActionHandler<OwnState, any>>([
  [types.SHOW_MAP_EDITOR, showMapEditor],
  [types.HIDE_MAP_EDITOR, (draftState) => {
    draftState.visible = false
  }],
  [types.MAP_SEARCH_REQUEST, (draftState) => {
    draftState.search.status = 'in progress'
  }],
  [types.MAP_SEARCH_SUCCESS, (draftState, action) => {
    draftState.search.status = 'success'
    draftState.search.result = action.data.features
  }],
  [types.MAP_SEARCH_FAILURE, (draftState, action) => {
    draftState.search.status = action.data
  }],
  [types.LAST_MAP_LOCATION, (draftState, action) => {
    const { coords, zoom, placeLabel } = action.data
    draftState.lastMapLocation = { coords, zoom, placeLabel, viewOnly: false }
  }],
  [types.GET_COUNTRY_REQUEST, (draftState) => {
    draftState.search.status = 'requesting country'
  }],
  [types.GET_COUNTRY_SUCCESS, (draftState, action) => {
    draftState.search.status = ''
    draftState.country = action.data
  }],
  [types.GET_COUNTRY_FAILURE, (draftState, action) => {
    draftState.search.status = action.data
  }],
])

export default function reducer() {
  return createReducer(initialState, actionHandlers)
}
