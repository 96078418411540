// ref: https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
export function getCookie(key: string): string|null {
  try {
    const data =
      decodeURIComponent(
        document.cookie.replace(
          new RegExp(
            '(?:(?:^|.*;)\\s*' +
              encodeURIComponent(key).replace(/[.+*-]/g, '\\$&;') +
              '\\s*\\=\\s*([^;]*).*$)|^.*$'
          ),
          '$1'
        )
      ) || null
    return data
  } catch (e) {
    console.warn('could not get cookie', e)
    return null
  }
}

export function setCookie(key: string, val = '', expires = 0) {
  try {
    if (expires === 0 || val === '') {
      throw new Error('Cannot set empty cookie or with expiry in the past')
    }
    const expiryDate = new Date(1970, 0, 1)
    expiryDate.setSeconds(expires)

    const cookie: Array<string> = []
    cookie.push(`${key}=${encodeURIComponent(val)}`)
    cookie.push(`expires=${expiryDate.toUTCString()}`)

    if (location.protocol === 'https:') {
      cookie.push('secure')
    }

    document.cookie = cookie.join(';')
  } catch (e) {
    console.warn('could not set cookie!', e)
  }
}

export function purgeCookie(key: string) {
  document.cookie = key + '=; Max-Age=-99999999;'
}
