import { produce } from 'immer'

const SHOW_RICHTEXT_POPUP = 'SHOW_RICHTEXT_POPUP'
const HIDE_RICHTEXT_POPUP = 'HIDE_RICHTEXT_POPUP'

export interface ShowRichtextPopupData {
  checkbox?: {value: boolean, label: string},
  closeHandler: (value: string, checkboxVal?: boolean) => void,
  dataKey?: string,
  placeholder?: string,
  title: string,
  value: string,
}

type State = Pick<ShowRichtextPopupData, 'checkbox' | 'dataKey' | 'placeholder' | 'title' | 'value'>
& Partial<Pick<ShowRichtextPopupData, 'closeHandler'>>
& {
  visible: boolean,
}

export const showRichtextPopup = (data: ShowRichtextPopupData) => ({
  type: SHOW_RICHTEXT_POPUP,
  data,
}) as const

export const hideRichtextPopup = () => ({
  type: HIDE_RICHTEXT_POPUP,
}) as const

type Actions = ReturnType<typeof showRichtextPopup> | ReturnType<typeof hideRichtextPopup>

const initialState: State = {
  dataKey:      '',
  placeholder:  '',
  title:        '',
  value:        '',
  visible:      false,
}

export default function reducer() {
  return produce((draftState: State, action: Actions) => {
    if (!action) {
      return draftState
    }
    switch (action.type) {

      case SHOW_RICHTEXT_POPUP: {

        const { value, dataKey, closeHandler, title, placeholder, checkbox } = action.data
        draftState.visible = true
        draftState.checkbox = checkbox
        draftState.value = value
        draftState.dataKey = dataKey
        draftState.title = title || ''
        draftState.closeHandler = closeHandler
        draftState.placeholder = placeholder

        break
      }

      case HIDE_RICHTEXT_POPUP:
        return initialState

    }
  }, initialState)

}
