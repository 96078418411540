import '../styles/Divider.css'

import React, { PureComponent } from 'react'

type OwnProps = {
  structure?: any,
  postRender?: (...args: Array<any>) => any,
  className?: string,
};

type Props = OwnProps & typeof Divider.defaultProps;

export default class Divider extends PureComponent<Props> {

  static defaultProps = {
    className: 'ui-divider',
  }

  render() {
    const { className, structure, postRender } = this.props
    const _className = `${className} ${structure?.className ?? ''}`
    const output = <hr className={_className} />

    return postRender ? postRender(structure, output) : output
  }

}
