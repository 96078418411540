import { produce } from 'immer'

import * as types from './action-types'
import { LinkEditorActions, LinkSearchFailureAction, LinkSearchRequestAction, LinkSearchSuccessAction, ShowLinkEditorAction } from './actions'

const initialState: OwnState = {
  query:         '',
  itemId:        0,
  templateId:    0,
  visible:       false,
  link:          {},
  links:         [],
  pending:       false,
  tagStatus:     '',
  error:         '',
}

export interface OwnState {
  query: string,
  itemId: number,
  templateId: number,
  visible: boolean,
  link: Record<string, any>,
  links: Array<Record<string, any>>,
  pending: boolean,
  tagStatus: string,
  error: string,
  updateHandler?: (updates: any) => void,
  clearHandler?: (itemId: number) => void,
  closeHandler?: () => void,
}

type LinkEditorActionHandler<T extends LinkEditorActions> = (state: OwnState, action: T) => OwnState
type LinkEditorActionNames = Pick<LinkEditorActions, 'type'>

const showLinkEditor = (draftState: OwnState, action: ShowLinkEditorAction) => {
  const {
    query,
    itemId,
    templateId,
    updateHandler,
    clearHandler,
    closeHandler,
  } = action.data

  draftState.visible = true
  draftState.query = query

  // We sometimes get NaN coming through, so let's validate it. Doing so here is the point at
  // which calling code has signified that it wants an item selector to appear, and gives us the
  // required info. If it's bad, revert to defaults.
  draftState.itemId = Number.isFinite(itemId) ? itemId : 0
  draftState.templateId = templateId ?? 0
  draftState.updateHandler = updateHandler
  draftState.clearHandler = clearHandler
  draftState.closeHandler = closeHandler

  return draftState
}

const hideLinkEditor = (draftState: OwnState) => {
  draftState.visible = false
  draftState.updateHandler = undefined
  draftState.clearHandler = undefined
  draftState.closeHandler = undefined

  return draftState
}

const linkSearchRequest = (draftState: OwnState, action: LinkSearchRequestAction) => {
  const { id } = action.data

  // clear the current link if we're requesting a new one
  if (!id || id !== draftState.itemId) {
    draftState.link = new Map()
  }

  draftState.pending = true
  draftState.error = ''
  draftState.itemId = id

  return draftState
}

const linkSearchSuccess = (draftState: OwnState, action: LinkSearchSuccessAction) => {
  const itemId = draftState.itemId
  const link = action.data.find(l => (l.id || l._id) === itemId)

  draftState.error = ''
  draftState.pending = false
  draftState.links = action.data.filter(l => (l.id || l._id) !== itemId)

  if (link) {
    draftState.link = link
  }

  return draftState
}

const linkSearchFailure = (draftState: OwnState, action: LinkSearchFailureAction) => {
  draftState.pending = false
  draftState.error = action.errorText

  return draftState
}

const actionHandlers = new Map <LinkEditorActionNames['type'], LinkEditorActionHandler<any>>([
  [types.SHOW_LINK_EDITOR, showLinkEditor],
  [types.HIDE_LINK_EDITOR, hideLinkEditor],
  [types.LINK_SEARCH_REQUEST, linkSearchRequest],
  [types.LINK_SEARCH_SUCCESS, linkSearchSuccess],
  [types.LINK_SEARCH_FAILURE, linkSearchFailure],
])

const reducer = () => {
  return produce((draftState: OwnState, action: LinkEditorActions|null) => {
    if (!action) {
      return draftState
    }
    const handler = actionHandlers.get(action.type)
    return handler ? handler(draftState, action) : draftState
  }, initialState)
}

export default reducer
