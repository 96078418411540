import '../styles/Slider.css'

import classNames from 'classnames'
import React, { PureComponent } from 'react'

import keyController from '../utils/key-controller'
import KeyframeBtn from './KeyframeBtn'
import SliderGradient from './SliderGradient'
import SliderInput from './SliderInput'
import SliderScale from './SliderScale'

const history = {}
export function clearControlHistory() {
  Object.keys(history).forEach(k => delete history[k])
}

export function setValueState(val, props, state) {
  const { absMin, absMax, step, wrap, loop, unlimited } = props
  const { min, max, decimalPlaces } = state

  let v = val
  let dv = v
  let _min = min
  let _max = max
  const range = max - min

  if (unlimited) {
    if (typeof absMax === 'number' && v > absMax) {
      v = absMax
    } else if (v > max) {
      _min += range
      _max += range
    } else if (typeof absMin === 'number' && v < absMin) {
      v = absMin
    } else if (v < min) {
      _min -= range
      _max -= range
    }
  } else if (wrap || loop) {
    if (v > max) {
      // const loops = Math.floor((v - min) / range);
      // v -= range * loops;
      v = min + ((v - min) % range)

    } else if (v < min) {
      v = max + ((v - min) % range)

    }

    if (loop) {
      [dv, v] = [v, dv]
    } else {
      dv = v
    }
  }

  v = Number(roundTo(v, step).toFixed(decimalPlaces))

  if (!unlimited && !wrap && !loop) {
    v = Math.min(Math.max(v, min), max)
    dv = v
  }

  return { value: v, displayVal: dv, min: _min, max: _max }
}
/* eslint-enable complexity */

export function roundTo(val, t) {
  return t * Math.round(val / t)
}

type OwnProps = {
  id: string,
  className: string,
  label: string,
  style: string,
  gradient: string,
  unit: string,
  min: number,
  absMin?: number,
  max: number,
  absMax?: number,
  step: number,
  tick: number,
  exact: boolean,
  loop: boolean,
  wrap: boolean,
  unlimited: boolean,
  defer: boolean,
  vernier: boolean,
  keyControl: boolean,
  value: number,
  keyframeActive: boolean,
  keyframeEnabled: boolean,
  keyframeCurrent: boolean,
  updateHandler: (...args: Array<any>) => any,
  interactionHandler?: (...args: Array<any>) => any,
  customStyling?: (...args: Array<any>) => any,
  dispatch?: (...args: Array<any>) => any,
};

type State = {
  decimalPlaces?: number,
  interacting?:   boolean,
  focusInput?:    boolean,
  tick?:          number,
  gradient?:      string,
  min: number,
  max: number,
  value?: number,
  deltaPx?: number,
  displayVal?: any,
};

export default class Slider extends PureComponent<OwnProps, State> {

  static defaultProps = {
    id:              '',
    className:       '',
    label:           'Label',
    style:           '',
    gradient:        '',
    unit:            '',
    dim:             1,
    min:             0,
    max:             10,
    step:            0.1,
    tick:            0.1,
    exact:           false,
    loop:            false,
    wrap:            false,
    unlimited:       false,
    defer:           false,
    vernier:         false,
    keyControl:      false,
    keyframeActive:  false,
    keyframeEnabled: false,
    keyframeCurrent: false,
    value:           0,
  }

  delta: any;
  dx: any;
  dy: any;
  history: any;
  historyIdx: any;
  keys: any;
  px: any;
  py: any;
  refHandle: any;
  refSlider: any;
  refVernier: any;
  startVal: any;
  touchSupport: any;
  vernierMode: any;
  width: any;
  x: any;
  y: any;

  constructor(props: OwnProps) {
    super(props)

    const { id, step, value, tick, min, max } = props
    this.state = {
      decimalPlaces: Math.max(Math.ceil(Math.log(1 / step) / Math.log(10)), 0),
      interacting:   false,
      focusInput:    false,
      tick:          tick < step ? step : tick,
      gradient:      '',
      min,
      max,
    }

    const v = this.setValue(value)
    this.state = Object.assign(this.state, {
      value:      v.value,
      displayVal: v.displayVal,
    })

    this.vernierMode = false
    this.keys = { alt: false, shift: false, ctrl: false, meta: false }
    this.history = []

    if (id) {
      if (!history[id]) {
        history[id] = []
      }
      this.history = history[id]
    }

    this.history[this.historyIdx]
  }

  componentDidMount() {
    this.width = this.refSlider && this.refSlider.offsetWidth
  }

  componentWillUnmount() {
    this.removeListeners()
  }

  // Allow the slider to be a controlled component by keeping the internal state value consistent
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.value !== null &&
      nextProps.value !== undefined &&
      nextProps.value !== prevState.value
    ) {
      const v = prevState.interacting && nextProps.defer ? prevState.value : nextProps.value
      return setValueState(v, nextProps, prevState)
    }

    return null
  }

  menuHandler = event => {
    event.preventDefault()
  }

  startHandler = event => {
    const { id, updateHandler, interactionHandler, defer } = this.props

    if (event.type === 'mousedown' && this.touchSupport) {
      return
    }

    if (event.target.classList.contains('ui-keyframe-btn')) {
      return
    }

    if (event.metaKey || (event.buttons || event.button) === 2) {
      // undo to the item before the last state
      const lastInHistory = this.history.pop()
      if (typeof lastInHistory !== 'undefined' && updateHandler) {
        updateHandler(id, lastInHistory)
      }
      return
    }

    if (interactionHandler) {
      interactionHandler(true)
    }

    // store current value before changing it
    if (this.state.value !== this.history[this.history.length]) {
      this.history.push(this.state.value)
    }

    this.touchSupport = event.type === 'touchstart'
    const r = this.refSlider.getBoundingClientRect()
    this.width = r.right - r.left
    this.setState({ interacting: true })
    this.setKeys(event)

    const p = this.interactionPosition(event)
    this.x = this.px = p[0]
    this.y = this.py = p[1]
    this.dx = 0
    this.dy = 0
    this.startVal = this.props.value
    this.delta = 0
    this.addListeners()

    this.vernierMode = event.target === this.refVernier

    // change value to point clicked, unless we are on touch devices
    if (this.touchSupport || this.vernierMode) {
      return
    }

    const _v = this.startVal
    const v = this.jumpToValue(p[0] - r.left)

    if (v !== _v && updateHandler) {
      if (defer) {
        this.updateHandler(event)
      } else {
        updateHandler(id, v)
      }
    }
  }
  /* eslint-enable complexity */

  updateHandler = event => {
    const { id, updateHandler, defer, exact } = this.props

    if (!this.touchSupport && event.buttons === 0) {
      // special case where the mouse button was released outside the browser
      this.endHandler(event)
      return
    }

    const p = this.interactionPosition(event)
    if (event.altKey !== this.keys.altKey || event.shiftKey !== this.keys.shiftKey) {
      // change in modifier keys so reset the delta
      this.x = this.px = p[0]
      this.y = this.py = p[1]
      this.dx = 0
      this.dy = 0
      this.startVal = this.state.value
    }

    this.dx += Math.abs(p[0] - this.px)
    this.dy += Math.abs(p[1] - this.py)
    this.px = p[0]
    this.py = p[1]

    const d = p[0] - this.x
    const newState: Partial<State> = { interacting: true, deltaPx: d }
    const _v = this.state.value
    const _snap = event.shiftKey
    const _exact = exact || event.altKey

    const val = this.setValue(this.valueFromDelta(d, _exact, _snap, this.vernierMode))
    const v = val.value

    if (v !== _v && updateHandler) {
      if (defer) {
        newState.value = v
      } else {
        updateHandler(id, v)
      }
    }

    // @ts-ignore: Just allow it to force the new state
    this.setState(newState)
    this.setKeys(event)

    // if (this.touchSupport) {
    //   if (this.dx > 15) {
    //     // stop vertical scrolling if we are moving side to side
    //     event.preventDefault()
    //   } else if (this.dy > 15 && this.dx < 15) {
    //     // stop slider control as we are moving vertically
    //     this.endHandler(event)
    //   }
    // }
  }

  endHandler = event => {
    event.preventDefault()
    this.removeListeners()
    this.vernierMode = false
    this.valueHandler(this.state.value)
  }

  valueHandler = val => {
    const { id, updateHandler, interactionHandler, value } = this.props

    if (val !== value && updateHandler) {
      updateHandler(id, val)
    }

    this.setState({ interacting: false })
    if (interactionHandler) {
      interactionHandler(false)
    }
  }

  setKeys(event) {
    this.keys.altKey = !!event.altKey
    this.keys.shiftKey = !!event.shiftKey
    this.keys.ctrlKey = !!event.ctrlKey
    this.keys.metaKey = !!event.metaKey
  }

  addListeners() {
    const eventMap = this.eventHandlers(this.touchSupport)
    for (const k in eventMap) {
      document.body.addEventListener(k, eventMap[k], false)
    }
  }

  removeListeners() {
    const eventMap = this.eventHandlers(this.touchSupport)
    for (const k in eventMap) {
      document.body.removeEventListener(k, eventMap[k])
    }
    // keyController.off('keydown', this.keyHandler)
  }

  eventHandlers(touchEvents) {
    if (touchEvents) {
      return {
        touchmove:   this.updateHandler,
        touchend:    this.endHandler,
        touchcancel: this.endHandler,
      }
    } else {
      return {
        mousemove: this.updateHandler,
        mouseup:   this.endHandler,
      }
    }
  }

  interactionPosition(event) {
    const evt = (event.touches && event.touches[0]) || event

    return [evt.clientX, evt.clientY]
  }

  jumpToValue(offset) {
    const w = this.value2px()
    const f = w / this.width
    const d = offset - w

    // set threshold for jumping to the click point, further when at the range limits
    const t = f < 0.05 || f > 0.95 ? 16 : 8

    if (Math.abs(d) > t) {
      this.startVal = this.setValue(this.valueFromDelta(d, false, true)).value
    }

    return this.startVal
  }

  valueFromDelta(d: number, exact: boolean, snap: boolean, vernier = false) {
    const { step } = this.props
    const { min, max, tick } = this.state
    const r = max - min
    let s = 1

    if (vernier) {
      s = (this.width * step) / r

      if (snap) {
        s *= 100
      } else if (!exact) {
        s *= 10
      }
    } else if (exact) {
      s = (this.width * step) / r
      if (snap) {
        s *= 100
      }
    }


    const dv = r * (d / this.width) * Math.min(s, 1)
    let v = this.startVal + dv

    if (!vernier) {
      if (exact && step) {
        v = roundTo(v, step)
      } else if (snap && tick) {
        v = roundTo(v, tick)
      }
    }

    return v
  }

  value2px() {
    const { min, max } = this.state
    return this.width * ((this.startVal - min) / (max - min))
  }

  setValue(val) {
    return setValueState(val, this.props, this.state)
  }

  mouseEnterHandler = () => {
    keyController.on('keydown', this.keyHandler)
  }

  mouseLeaveHandler = () => {
    keyController.off('keydown', this.keyHandler)
  }

  keyHandler = keys => {
    const { updateHandler, id, value, exact, step } = this.props
    const { tick } = this.state
    this.startVal = value

    let d = 0
    if (keys.left || keys.down) {
      d = -1
    } else if (keys.right || keys.up) {
      d = 1
    }

    if (d) {
      let v

      if (exact && step) {
        v = this.setValue(value + d * step).value
      } else if (tick && (keys.up || keys.down)) {
        v = this.setValue(value + d * tick).value
        v = roundTo(v, tick)
      } else {
        const _snap = keys.shift
        const _exact = exact || keys.alt
        v = this.setValue(this.valueFromDelta(d, _exact, _snap)).value
      }
      updateHandler(id, v)
    }
  }

  keyframeHandler = event => {
    const { id, value, keyframeEnabled, keyframeCurrent, updateHandler } = this.props
    event.preventDefault()
    const keyframe = { enabled: keyframeEnabled, current: keyframeCurrent }
    updateHandler(id, value, keyframe)
  }

  setSliderRef = ref => (this.refSlider = ref)
  setHandleRef = ref => (this.refHandle = ref)
  setVernierRef = ref => (this.refVernier = ref)

  render() {
    const {
      id,
      wrap,
      loop,
      step,
      label,
      style,
      className,
      customStyling,
      gradient,
      unit,
      keyControl,
      keyframeActive,
      keyframeEnabled,
      keyframeCurrent,
      vernier,
    } = this.props
    const { min, max, displayVal, value, decimalPlaces, interacting, deltaPx, tick } = this.state

    const a = Math.min(Math.max((0 - min) / (max - min), 0), 1)
    const b = Math.min(Math.max((displayVal - min) / (max - min), 0), 1)
    const x1 = Number((Math.min(a, b) * 100).toFixed(3))
    const x2 = Number((Math.max(a, b) * 100).toFixed(3))
    const w = Number((x2 - x1).toFixed(3))

    const bodyStyle = {}
    const labelStyle = {}
    const valueStyle = {}

    const barStyle = {
      left:  x1 + '%',
      width: w + '%',
    }

    const handleStyle = {
      left: (displayVal < 0 ? x1 : x2) + '%',
    }

    let vernierHandle
    if (vernier) {
      const vernierHandleStyle = {
        transform: `translateX(${this.vernierMode ? deltaPx : 0}px)`,
      }
      vernierHandle = (
        <span className="ui-slider-vernier" ref={this.setVernierRef} style={vernierHandleStyle}>
          <i className="ui-slider-vernier-handle" />
        </span>
      )
    }

    if (customStyling) {
      customStyling(id, bodyStyle, barStyle, handleStyle, labelStyle, valueStyle)
    }

    const classList = classNames('ui-i ui-slider ' + className, {
      active:         interacting,
      'touch-slider': this.touchSupport,
      'mouse-slider': !this.touchSupport,
      'ctrl-red':     style === 'r',
      'ctrl-green':   style === 'g',
      'ctrl-blue':    style === 'b',
      'ui-keyframed': keyframeActive,
      gradient:       gradient,
      edited:         this.history.length,
    })


    let scale, sliderGradient
    if (gradient) {
      sliderGradient = <SliderGradient src={gradient} row={value} max={max} />
    } else if (tick) {
      scale = <SliderScale min={min} max={max} tick={tick || step} />
    }

    const props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> = {
      className:     classList,
      key:           id,
      ref:           this.setSliderRef,
      onMouseDown:   this.startHandler,
      // onTouchStart: this.startHandler
      onContextMenu: this.menuHandler,
    }

    if (keyControl) {
      props.onMouseEnter = this.mouseEnterHandler
      props.onMouseLeave = this.mouseLeaveHandler
    }

    let keyframeBtn
    if (keyframeActive) {
      keyframeBtn = (
        <KeyframeBtn
          enabled={keyframeEnabled}
          current={keyframeCurrent}
          onKeyframe={this.keyframeHandler}
        />
      )
    }

    return (
      <div {...props}>
        <div className="ui-slider-body" style={bodyStyle}>
          <div className="ui-slider-bar" style={barStyle} />
          <div className="ui-slider-handle" style={handleStyle} ref={this.setHandleRef} />
          {vernierHandle}
          <label className="ui-slider-label" style={labelStyle}>
            {label}
          </label>
          <SliderInput
            className="ui-slider-value"
            style={valueStyle}
            value={value}
            min={min}
            max={max}
            wrap={wrap}
            loop={loop}
            unit={unit}
            decimalPlaces={decimalPlaces}
            updateHandler={this.valueHandler}
          />
          {scale}
          {sliderGradient}
        </div>
        {keyframeBtn}
      </div>
    )
  }

}
