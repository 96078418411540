export const ADD_FILES_TO_QUEUE = 'imageEditor/ADD_FILES_TO_QUEUE'
export const CREATE_UPLOAD_ITEM = 'imageEditor/CREATE_UPLOAD_ITEM'
export const UPDATE_UPLOAD_ITEM = 'imageEditor/UPDATE_UPLOAD_ITEM'
export const UPLOAD_REQUEST = 'imageEditor/UPLOAD_REQUEST'
export const UPLOAD_PROGRESS = 'imageEditor/UPLOAD_PROGRESS'
export const UPLOAD_SUCCESS = 'imageEditor/UPLOAD_SUCCESS'
export const UPLOAD_FAILURE = 'imageEditor/UPLOAD_FAILURE'
export const CLEAR_QUEUE = 'imageEditor/CLEAR_QUEUE'
export const FILE_PROCESS = 'imageEditor/FILE_PROCESS'
export const FILE_PROCESS_SUCCESS = 'imageEditor/FILE_PROCESS_SUCCESS'
