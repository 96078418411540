import Easing from './easing'

export default function transitionable(options) {
  let _easeFn = Easing.ease
  let _duration = 0 // in ms
  let _delay = 0
  let _val = 0
  let _oldVal = -1
  let _startVal = 0
  let _finalVal = 0
  let _changeVal = 0
  let _startTime = 0
  let _complete = false
  let _dt = 0
  let _callback, _update
  let _raf = 0

  function setOptions(opts = {}) {
    if (typeof opts.easeFn === 'function') {
      _easeFn = opts.easeFn
    } else {
      _easeFn = Easing[opts.easeFn || 'ease']

      if (!_easeFn) {
        console.warn(
          'Invalid easing fn "' +
            opts.easeFn +
            '", choose from:\n' +
            Object.keys(Easing).sort().join(', ')
        )
        _easeFn = Easing.ease
      }
    }

    if (opts.update) {
      _update = opts.update
    } else {
      _update = null
    }

    if (opts.callback) {
      _callback = opts.callback
    } else {
      _callback = null
    }

    _duration = opts.duration || 0
    _delay = opts.delay || 0
    _complete = false

    if (typeof opts.startVal !== 'undefined') {
      _startVal = opts.startVal
    }

    if (typeof opts.finalVal !== 'undefined') {
      set(opts.finalVal)
    }
  }

  function getOptions() {
    return {
      easeFn:   _easeFn,
      update:   _update,
      callback: _callback,
      startVal: _startVal,
      finalVal: _finalVal,
      duration: _duration,
      delay:    _delay,
    }
  }

  function set(finalVal, opts) {
    if (opts) {
      setOptions(opts)
    }

    _finalVal = finalVal
    _oldVal = null

    if (_duration === 0) {
      _startVal = _finalVal
    }
    _changeVal = _finalVal - _startVal
    _startTime = window.performance.now()
    _dt = 0
    _complete = _changeVal === 0

    // console.log('set transitionable:', _startVal, _finalVal, _duration, _delay, opts);
    window.cancelAnimationFrame(_raf)
    run(_dt)
  }

  function run(dt) {
    get(dt)

    if (_dt >= _delay) {
      update()
    }

    if (_callback && _dt >= _delay + _duration) {
      _callback(_finalVal)
    }

    if (_update && _duration > 0 && _dt < _duration + _delay) {
      _raf = window.requestAnimationFrame(run)
    }
  }

  function get(dt) {
    _dt = typeof dt !== 'undefined' ? dt : window.performance.now() - _startTime

    if (_duration === 0 || _dt >= _duration + _delay) {
      _complete = true
      _val = _finalVal
    } else if (_dt < _delay) {
      _val = _startVal
    } else {
      // transition
      const t = _dt - _delay
      _val = _easeFn(t, _startVal, _changeVal, _duration)
    }

    return _val
  }

  function update() {
    if (_update && _val !== _oldVal) {
      _update(_val)
      _oldVal = _val
    }
  }

  function complete() {
    return _complete || _dt >= _delay + _duration
  }

  function cancel(final = false) {
    if (final !== false) {
      _val = final
    }

    get()
    update()
    _duration = 0
    window.cancelAnimationFrame(_raf)
  }

  // initialise
  setOptions(options)

  return Object.freeze({
    get,
    set,
    cancel,
    complete,
    options: getOptions,
    tick:    run,
  })
}
