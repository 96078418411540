import '../styles/TabColumn.css'

import React, { PureComponent } from 'react'

import { switchPanel } from '../ui-redux'
import { getIn } from '../utils/get-set-in'

type Props = {
  dispatch: (...args: Array<any>) => any,
  structure: any,
  depth: number,
  activeTab: number,
  uiPath: string,
  scope: string,
  changePanel: (...args: Array<any>) => any,
};

export default class TabColumn extends PureComponent<Props> {

  changePanels = event => {
    const { dispatch, changePanel, scope } = this.props
    const uiPath = event.target.dataset.uiPath

    if (changePanel) {
      changePanel(scope, uiPath)
    } else {
      dispatch(switchPanel(scope, uiPath, true))
    }
  }

  render() {
    const { dispatch, structure, uiPath, depth, activeTab } = this.props


    const sections: Array<JSX.Element> = []
    structure.children.forEach((child, idx) => {
      // TODO: add an option to use a custom component for the navigation links for this child
      // for instance the zoom scales for the TimelineEditorScales
      const customNav = getIn(child, ['children', '0', 'customNav']) || child.customNav

      if (customNav) {
        const element = customNav
        sections.push(
          React.createElement(element, {
            dispatch,
            uiPath,
            idx,
            key:     idx,
            onClick: this.changePanels,
          })
        )
      } else if (child.type === 'panel') {
        const activeChild = child.activeChild
        const btns: Array<JSX.Element> = []

        child.children.forEach((kid, jdx) => {
          if (kid.type === 'panel') {
            const _uiPath = uiPath
              ? `${uiPath}.children.${idx}.children.${jdx}`
              : `children.${idx}.children.${jdx}`
            let className = 'ui-tab-col-btn'

            if (activeTab === idx && jdx === activeChild) {
              className += ' active'
            }
            btns.push(
              <a
                className={className}
                key={`${idx}-${jdx}`}
                onClick={this.changePanels}
                data-ui-path={_uiPath}
              >
                {kid.label}
              </a>
            )
          }
        })

        let h4
        const label = child.label
        if (btns.length) {
          // basic header
          h4 = <h4 className="ui-tab-btn-header">{label}</h4>
        } else {
          // header with a link
          const _uiPath = uiPath ? `${uiPath}.children.${idx}` : `children.${idx}`
          let className = 'ui-tab-col-btn'

          if (activeTab === idx) {
            className += ' active'
          }

          btns.push(
            <a className={className} onClick={this.changePanels} data-ui-path={_uiPath}>
              {label}
            </a>
          )

          if (child.title) {
            h4 = <h4 className="ui-tab-btn-header">{child.title}</h4>
          }
        }

        let className = `ui-tab-group ui-group-${label.replace(/[^a-z0-9]+/gi, '-').toLowerCase()}`
        if (activeTab === idx) {
          className += ' active'
        }

        if (!child.childCount || child.childCount > 0) {
          sections.push(
            <section className={className} key={idx}>
              {h4}
              {btns}
            </section>
          )
        }
      }
    })

    if (sections.length < 1) {
      return false
    }

    const className = `ui-tabs ui-tabs-${structure.tabs} ui-tabs-depth-${depth}`
    return <nav className={className}>{sections}</nav>
  }

}
