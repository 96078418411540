import ScrollPanel from '@kpv-lab/scrollable-panel'
import { DraggablePanel } from '@kpv-lab/ui'
import React, { PureComponent } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { hideLinkEditor as hideLinkEditorAction, searchLinks as searchLinksAction } from './state/actions'
import { OwnState as LinkEditorReduxState } from './state/reducer'


// map the css class name to the tag group id
export const tagGroupIds = {
  person:   1,
  place:    2,
  event:    3,
  creation: 4,
}

export interface OwnProps {
  cancelHandler?: () => void,
  popupPanel: boolean,
}

export type AllProps = OwnProps & TypesFromRedux

interface OwnState {
  queryText: string,
  parentId: number,
}

const connector = connect(({ linkEditor: state }: { linkEditor: LinkEditorReduxState }) => {
  return {
    query:         state.query,
    itemId:        state.itemId,
    templateId:    state.templateId,
    links:         state.links,
    link:          state.link,
    pending:       state.pending,
    updateHandler: state.updateHandler,
    clearHandler:  state.clearHandler,
  }
}, {
  hideLinkEditor: hideLinkEditorAction,
  searchLinks:    searchLinksAction,
})

type TypesFromRedux = ConnectedProps<typeof connector>

export class LinkEditor extends PureComponent<AllProps, OwnState> {

  static defaultProps = {
    popupPanel: true,
  }

  timeout = 0
  refSearch: React.RefObject<any>
  state = {
    queryText: '',
    parentId:  0,
  }

  constructor(props) {
    super(props)
    const { query } = this.props

    this.state = {
      queryText: query,
      parentId:  0,
    }

    this.refSearch = React.createRef()
  }

  componentDidMount() {
    const { query, itemId, searchLinks, templateId } = this.props
    searchLinks(itemId, query, templateId)

    this.refSearch.current?.focus()
  }

  changeHandler = e => {
    const { itemId, templateId, searchLinks } = this.props
    const query = e.target.value

    this.setState({ queryText: query })

    window.clearTimeout(this.timeout)
    this.timeout = window.setTimeout(() => {
      searchLinks(itemId, query, templateId)
    }, 700)
  }

  searchButtonClicked = () => {
    const { itemId, searchLinks, templateId } = this.props
    const { queryText } = this.state

    window.clearTimeout(this.timeout)
    this.timeout = window.setTimeout(() => {
      searchLinks(itemId, queryText, templateId)
    }, 700)
  }

  acceptHandler = event => {
    const { hideLinkEditor, updateHandler } = this.props
    const el = event.target.closest('.link-editor-row, .link-editor-current')
    const { id, title, start, end, type, parentid: parentId, templateid: templateId } = el.dataset

    updateHandler && updateHandler({ id: parseInt(id), title, start, end, type, parentId, templateId })
    hideLinkEditor()
  }

  clearHandler = event => {
    const { clearHandler, hideLinkEditor, itemId } = this.props

    event.preventDefault()
    event.stopPropagation()

    clearHandler && clearHandler(itemId)
    hideLinkEditor()
  }

  cancelHandler = () => {
    const { cancelHandler, hideLinkEditor } = this.props
    if (cancelHandler) {
      cancelHandler()
    } else {
      hideLinkEditor()
    }
  }

  tagTypeHandler = event => {
    this.setState({ parentId: parseInt(event.target.value) })
  }

  render() {
    const { link, links, pending, popupPanel, itemId } = this.props
    const { queryText } = this.state

    const clearButton = (
      <button
        className="btn btn-icon link-editor-clear-btn"
        onClick={this.clearHandler}
        title="Remove this link"
      >
        <i className="material-icons md-18">remove_circle_outline</i>
      </button>
    )

    let linkRow
    if (link && (link['id'] || link['_id'])) {
      // we have a valid link or tag

      const info = <span className="link-editor-info">{link['_type']}</span>
      const title = link['name'] || link['title']
      linkRow = (
        <section
          className="link-editor-current"
          data-id={link['id'] || link['_id']}
          data-title={title}
          data-start={link['start'] || link['_start']}
          data-end={link['end'] || link['_end']}
          data-type={link['type'] || link['_type']}
          onClick={this.acceptHandler}
        >
          <strong className={'link-editor-title link-title-itemlink'}>{title}</strong>
          {info}
          {clearButton}
        </section>
      )
    }

    const rows = links.map((row, idx) => {
      const id = row['_id']
      const className = `link-editor-row ${id === itemId ? 'active' : ''}`
      return (
        <div
          className={className}
          key={idx}
          data-id={id}
          data-parentid={row['_parentId']}
          data-templateid={row['_globalTplId']}
          data-title={row['title']}
          data-start={row['_start']}
          data-end={row['_end']}
          data-type={row['_type']}
          data-cy={`link-row-${row['title']}`}
          onClick={this.acceptHandler}
        >
          <strong className="link-editor-title">{row['title']}</strong>
          <span className="link-editor-info">{row['_type']}</span>
        </div>
      )
    })

    const loading = pending ? ' loading' : ''

    const linkEditorBody = (
      <div className="link-editor-body">
        <h2 className={`ui-title ${popupPanel ? 'draggable' : ''}`}>Link Selector</h2>
        <i className="material-icons ui-close-btn" onClick={this.cancelHandler}>
          close
        </i>

        <header className={`link-editor-header ${loading}`}>
          <input
            ref={this.refSearch}
            type="text"
            onChange={this.changeHandler}
            data-cy="link-editor-input"
            className="link-editor-query"
            value={queryText}
            placeholder="Keyword search..."
          />
          <button className="btn btn-icon" onClick={this.searchButtonClicked}>
            <i className="material-icons md-18">search</i>
          </button>
          {linkRow}
        </header>

        <ScrollPanel id="link-editor-results" panelClassName="link-editor-results">
          {rows}
        </ScrollPanel>
      </div>
    )

    let panel
    if (popupPanel) {
      panel = (
        <DraggablePanel
          className="ui-floating-panel link-editor"
          name="linkEditor"
          backdrop={true}
          backdropHandler={this.cancelHandler}
        >
          {linkEditorBody}
        </DraggablePanel>
      )
    }

    return (popupPanel && panel) || linkEditorBody
  }

}

export default connector(LinkEditor)
