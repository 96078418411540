import { produce } from 'immer'

import * as types from './actions'

const initialState = {
  windowOpen:       false,
  activeChat:       -1,
  unreadMessages:   [],
  openChats:        [],
  windowDimensions: {
    width:  0,
    height: 0,
  },
}

export default function reducer() {
  return produce((draftState, action?: types.MessagingActions) => {
    if (!action) {
      return draftState
    }

    switch (action.type) {

      case types.UNREAD_MESSAGE:
        const unreadExists = draftState.unreadMessages.find((i) => i === action.data.toUserId)
        const chatExist = draftState.openChats.find((i) => i.chatId === action.data.toUserId)

        if (draftState.windowOpen && !chatExist) {
          draftState.openChats.push({
            username: action.data.username,
            chatId:   action.data.toUserId,
          })
        }
        if (!(unreadExists || action.data.toUserId === draftState.activeChat)) {
          draftState.unreadMessages.push(action.data.toUserId)
        }
        break

      case types.OPEN_CHAT_WINDOW:
        const chatExists = draftState.openChats.find((i) => i.chatId === action.data.toUserId)

        draftState.windowOpen = true
        draftState.activeChat = action.data.toUserId
        draftState.unreadMessages = draftState.unreadMessages.filter((i) =>
          i !== action.data.toUserId
        )
        if (!chatExists) {
          draftState.openChats.push({
            username: action.data.username,
            chatId:   action.data.toUserId,
          })
        }
        break

      case types.SET_CHAT_WINDOW_SIZE:
        draftState.windowDimensions.height = action.data.height
        draftState.windowDimensions.width = action.data.width
        break

      case types.SET_ACTIVE_CHAT:
        draftState.activeChat = action.data.toUserId
        draftState.unreadMessages = draftState.unreadMessages.filter((i) =>
          i !== action.data.toUserId
        )
        break

      case types.CLOSE_CHAT:
        const updatedChats = draftState.openChats.filter((chat) => {
          return chat.chatId !== action.data.toUserId
        })
        const openChat = updatedChats.length !== 0
        draftState.windowOpen = openChat
        draftState.openChats = updatedChats
        break

      case types.CLOSE_CHAT_WINDOW:
        draftState.windowOpen = false
        draftState.activeChat = -1
        break

    }
  }, initialState)

}
