import '../styles/Select.css'

import React, { PureComponent } from 'react'

import { getIn } from '../utils/get-set-in'

interface OwnProps {
  structure: any,
  dataKey: string,
  value: string | number,
  inline: boolean,
  updateHandler: (...args: Array<any>) => any,
  postRender: (...args: Array<any>) => any,
}

export default class Select extends PureComponent<OwnProps> {

  onChange = event => {
    const { structure, dataKey, value, updateHandler } = this.props
    let val = event.target.value

    if (getIn(structure, ['options', 'integer'])) {
      val = parseInt(val)
    } else if (getIn(structure, ['options', 'float'])) {
      val = parseFloat(val)
    }

    if (structure.encoder) {
      val = structure.encoder(val)
    }

    if (val !== value && updateHandler) {
      updateHandler(dataKey, val)
    }
  }

  render() {
    const { structure, dataKey, value, inline, postRender } = this.props

    const id = ('' + dataKey).replace(/[.~@!]/g, '-')
    const htmlFor = `input-${id}`
    let className = `ui-i ui-field ui-select field-${id}`
    if (inline) {
      className += ' inline'
    }

    let val = `${value}`

    if (structure.decoder) {
      val = structure.decoder(value)
    }

    const style: React.CSSProperties = {}
    if (structure.width) {
      style.width = structure.width
    }

    let selectedLabel =
      (getIn(structure, ['values', 0, 'values', 0, 'label']) ||
      getIn(structure, ['values', 0, 'label'])) as string
    let values = structure.values
    const labels = structure.labels ?? []

    if (typeof values[0].label === 'undefined') {
      // convert basic array of strings to {label, value} object array
      values = values.map((v, i) => {
        return {
          label: typeof labels[i] !== 'undefined' ? labels[i] : v,
          value: v,
        }
      })
    }

    let firstOption
    if (values[0].value === '' && values[0].label) {
      firstOption = <option value="">{values[0].label}</option>
      values.shift()
    }

    if (!values[0].values) {
      // convert object array to nested object array
      values = [
        {
          label:  structure.label,
          values: values,
        },
      ]
    }

    const optGroups = values.map((optgroup, idx) => {
      const _opts = optgroup.values.map((option, jdx) => {
        // let selected = false;

        if (option.value.toString() === val) {
          selectedLabel = option.label
          // selected = true;
        }

        return (
          <option value={option.value} key={idx + '-' + jdx}>
            {option.label}
          </option>
        )
      })

      return (
        <optgroup label={optgroup.label} key={idx}>
          {_opts}
        </optgroup>
      )
    })

    let opts = {}
    if (structure.options) {
      opts = structure.options
    }

    let label = structure.label
    if (structure.hideLabel) {
      className += ' no-label'
      label = ''
    }

    if (structure.className) {
      className += ` ${structure.className}`
    }
    // @ts-ignore: It's assuming a certain shape of options based on the field, but the options field
    // is passed into several components
    const { float, integer, ...selectProps } = opts
    const output = (
      <section className={className} style={style} key={htmlFor}>
        <select onChange={this.onChange} id={htmlFor} {...selectProps} value={val}>
          {firstOption}
          {optGroups}
        </select>
        {/* @ts-ignore: See above */}
        <label htmlFor={htmlFor} className="ui-label" title={opts.title}>
          {label}
          <strong>{selectedLabel}</strong>
        </label>
      </section>
    )

    return postRender ? postRender(structure, output) : output
  }
  /* eslint-enable complexity */

}
