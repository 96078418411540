import Logo from '@cls/Logo'
import { RootState } from '@cls/redux'
import { History } from 'history'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { resetPassword, resetUserPasswordState } from './state/actions-account'

type ResetPasswordProps = {
  dispatch: (...args: Array<any>) => any,
  location: Location,
  history: History,
  user: any,
};

type ResetPasswordState = any;

export class ResetPassword extends PureComponent<ResetPasswordProps, ResetPasswordState> {

  state = {
    // @ts-ignore We set this ourselves in LoginForm when redirecting
    email: this.props.location.state?.email || '',
    error: null,
  }

  componentWillUnmount() {
    this.props.dispatch(resetUserPasswordState())
  }

  onSubmit = (event: any) => {
    const { email } = this.state
    const { dispatch } = this.props
    event.preventDefault()

    let error
    if (!email || email.length < 7) {
      error = 'A valid email is required'
    } else {
      error = null
    }
    this.setState({ error })
    if (error) {
      return
    }
    dispatch(
      resetPassword({
        email:    this.state.email,
        location: window.location.origin,
      })
    )
  }

  formInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      email: e.target.value,
    })
  }

  render() {
    const { user } = this.props
    const { error } = this.state
    let formContent, formButton

    if (user.resetUser) {
      formContent = <p>A link to reset your password has been sent to {this.state.email}</p>
      formButton = (
        <button
          onClick={() => this.props.history.replace('/login')}
          className="btn btn-primary login-button"
        >
          Back to login
        </button>
      )
    } else {
      formContent = (
        <fieldset className="fieldset">
          <p className="form-row">
            <label htmlFor="reset-email">Email</label>
            <input
              type="email"
              placeholder="email"
              name="resetEmail"
              id="reset-email"
              className="input input-email block"
              onChange={this.formInput}
              value={this.state.email}
              autoComplete="email"
              data-cy="reset-email"
            />
          </p>
        </fieldset>
      )
      formButton = (
        <button type="submit" className="btn btn-primary login-button">
          {user.pending ? 'Loading...' : 'Request password reset'}
        </button>
      )
    }
    return (
      <div className="app page-login">
        <form onSubmit={this.onSubmit} className="form login-form center">
          <Logo />
          {error ? <p className="form-error">{error}</p> : null}
          {formContent}
          <fieldset className="fieldset">{formButton}</fieldset>
        </form>
      </div>
    )
  }

}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(ResetPassword)
