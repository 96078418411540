let store: Record<string, unknown> = {}
let localStoreKey = 'cl'

export function setItem(key: string, obj: unknown) {
  store[key] = obj
  _save(store)
  return store
}

export function getItem(key: string): unknown {
  return store[key]
}

export function initStore(initialData: Record<string, unknown>|null, storeKey = localStoreKey) {
  localStoreKey = storeKey
  let data
  try {
    data = JSON.parse(
      (window.localStorage && window.localStorage.getItem(storeKey + ':session')) || '{}'
    )
  } catch (err) {
    data = {}
  }
  store = initialData || data
  store.__localStorKey = storeKey
  return store
}

function _save(data: unknown) {
  const str = JSON.stringify(data)
  try {
    window.localStorage && window.localStorage.setItem(localStoreKey + ':session', str)
  } catch (e) {
    console.error('could not save to localStorage', e)
  }
}
