export const SHOW_REFERENCE_POPUP = 'SHOW_REFERENCE_POPUP'
export const HIDE_REFERENCE_POPUP = 'HIDE_REFERENCE_POPUP'

export interface ReferencePopupData {
  visible: boolean,
  id: number,
  page: string,
  title: string,
  dataKey?: string,
  closeHandler?: (value: any) => void,
  additionalInfo?: string,
}

export interface ShowReferencePopupAction {
  type: typeof SHOW_REFERENCE_POPUP,
  data: Omit<ReferencePopupData, 'visible'>,
}

export interface HideReferencePopupAction {
  type: typeof HIDE_REFERENCE_POPUP,
}

export type ReferencePopupAction =
  | ShowReferencePopupAction
  | HideReferencePopupAction;
