// Paragraph
import config from '../config'
;('use strict')

export function useParagraph(md) {
  md.block.ruler.push('alignParagraph', paragraph)
}

export function paragraph(state, startLine /* , endLine*/) {
  var content,
    terminate,
    i,
    l,
    token,
    oldParentType,
    nextLine = startLine + 1,
    terminatorRules = state.md.block.ruler.getRules('paragraph'),
    endLine = state.lineMax

  oldParentType = state.parentType
  state.parentType = 'paragraph'

  // jump line-by-line until empty one or EOF
  for (; nextLine < endLine && !state.isEmpty(nextLine); nextLine++) {
    // this would be a code block normally, but after paragraph
    // it's considered a lazy continuation regardless of what's there
    if (state.sCount[nextLine] - state.blkIndent > 3) {
      continue
    }

    // quirk for blockquotes, this line should already be checked by that rule
    if (state.sCount[nextLine] < 0) {
      continue
    }

    // Some tags can terminate paragraph without empty line.
    terminate = false
    for (i = 0, l = terminatorRules.length; i < l; i++) {
      if (terminatorRules[i](state, nextLine, endLine, true)) {
        terminate = true
        break
      }
    }
    if (terminate) {
      break
    }
  }

  content = state.getLines(startLine, nextLine, state.blkIndent, false).trim()

  state.line = nextLine

  token = state.push('paragraph_open', 'p', 1)
  const alignMetaData = new RegExp(`~~ (${config.alignClassPrefix}-align-[A-Za-z]*) ~~`)
  const alignMatch = content.match(alignMetaData)
  const align = alignMatch ? alignMatch[1] : `${config.alignClassPrefix}-align-left`
  token.attrs = [['align', align]]

  token.map = [startLine, state.line]

  token = state.push('inline', '', 0)

  token.content = content.replace(alignMetaData, '')
  token.map = [startLine, state.line]
  token.children = []

  token = state.push('paragraph_close', 'p', -1)

  state.parentType = oldParentType

  return true
}
