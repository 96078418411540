import styled from '@emotion/styled'

export const MediaGalleryElement = styled.div`
  label: media-gallery;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;

  .media-gallery--image {
    display: flex;
    align-items: center;
    height: 10em;
    padding: 0.5em;
    margin: 0.3em 0;
    background-color: var(--form-field-bg-color);
    box-sizing: content-box;
    justify-content: center;

    img {
      max-height: 10em;
      cursor: pointer;
    }
  }
  
  .media-gallery--audio {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--form-field-bg-color);
    padding: .5rem;
    margin-bottom: .5rem;
    cursor: pointer;
    
    audio {
      max-width: 15rem;
      padding-bottom: .5rem;
    }
  }

`
