import { produce } from 'immer'
import { Action } from 'redux'

import { ActionHandler, Reducer } from './types'

export function createReducer<S, A extends Action>(initialState: S, actionHandlers: Map<string, ActionHandler<S, A>>): Reducer<S, A> {

  return produce((draftState, action) => {
    if (!action) {
      return draftState
    }
    const handler = actionHandlers.get(action.type)
    return handler ? handler(draftState, action) : draftState
  }, initialState as any)
}
