import { settings } from '@cls/config'
import { ActionHandler, createReducer } from '@cls/redux'
import { Action } from 'redux'

const SHOW_INFO_TOOLTIP = 'editor/SHOW_INFO_TOOLTIP'
const HIDE_INFO_TOOLTIP = 'editor/HIDE_INFO_TOOLTIP'

const EDITING_VERSION_ID_SET = 'editor/EDITING_VERSION_ID_SET'
const VERSION_CHANGE_STATUS = 'editor/VERSION_CHANGE_STATUS'

const EDITING_SET_ID = 'editor/EDITING_SET_ID'

const SET_EDITOR_TEMPLATES = 'editor/SET_EDITOR_TEMPLATES'
const SET_BLURABLE = 'editor/SET_BLURABLE'

// This isn't actually an Action, we set the value directory and then do nothing with the action
// as it passes through in order to avoid triggering rerenders. However, we do need to return an
// Action from a thunk, so we'll make it look the same as all the rest.
export let blurable = 0
export function setBlurable(blurValue: number) {
  blurable = blurValue
  return <const>{
    type: SET_BLURABLE,
  }
}

export function showInfoTooltip(data: any) {
  return <const>{
    type: SHOW_INFO_TOOLTIP,
    data: data,
  }
}

export function hideInfoTooltip() {
  return <const>{
    type: HIDE_INFO_TOOLTIP,
  }
}

export function setEditingVersionId(id: number|null) {
  return <const>{ type: EDITING_VERSION_ID_SET, id: id }
}

export function versionChangeStatus(status: any) {
  return <const>{
    type: VERSION_CHANGE_STATUS,
    data: status,
  }
}

export function setEditId(id: string) {
  return <const>{ type: EDITING_SET_ID, id }
}

export function setEditorTemplates(templates: Array<any>) {
  return <const>{ type: SET_EDITOR_TEMPLATES, templates }
}

export function setActiveField(dataKey = '0') {
  return (dispatch) => {
    if (blurable <= 1) {
      setBlurable(blurable - 1)

      return dispatch(setEditId(dataKey))
    }
    return setBlurable(blurable - 1)
  }
}

type ShowInfoTooltipAction = ReturnType<typeof showInfoTooltip>
type SetEditorTemplatesAction = ReturnType<typeof setEditorTemplates>
export type EditorActions =
  | ShowInfoTooltipAction
  | ReturnType<typeof setEditingVersionId>
  | ReturnType<typeof versionChangeStatus>
  | ReturnType<typeof setEditId>
  | SetEditorTemplatesAction
  | Action<'editor/HIDE_INFO_TOOLTIP'>
  | Action<'editor/SET_BLURABLE'>


const initialState: OwnState = {
  infoTooltip: {
    visible: false,
    dataKey: '',
    value:   '',
    title:   '',
    data:    '',
  },
  templates:        [] as Array<any>,
  lang:             settings.defaultLang,
  editingVersionId: null,
  statusOverride:   null,
  editId:           '',
}

interface OwnState {
  infoTooltip: {
    visible: boolean,
    dataKey: string,
    value: string,
    title: string,
    data: string,
  },
  templates: Array<any>,
  lang: string,
  editingVersionId: number|null,
  statusOverride: null,
  editId: string,
}


const showInfoTooltipHandler: ActionHandler<OwnState, ShowInfoTooltipAction> = (draftState, action) => {
  draftState.infoTooltip = {
    ...initialState.infoTooltip,
    ...action.data,
    visible: true,
  }
}

const hideInfoTooltipHandler: ActionHandler<OwnState, ShowInfoTooltipAction> = (
  draftState
) => {
  draftState.infoTooltip = {
    ...initialState.infoTooltip,
    visible: false,
  }
}

const setEditorTemplatesHandler: ActionHandler<OwnState, SetEditorTemplatesAction> = (draftState, action) => {
  draftState.templates = action.templates
}

const actionHandlers = new Map<string, ActionHandler<OwnState, any>>([
  [SHOW_INFO_TOOLTIP, showInfoTooltipHandler],
  [HIDE_INFO_TOOLTIP, hideInfoTooltipHandler],
  [SET_EDITOR_TEMPLATES, setEditorTemplatesHandler],
  [
    EDITING_VERSION_ID_SET,
    (draftState, action) => {
      draftState.editingVersionId = action.id
    },
  ],
  [
    VERSION_CHANGE_STATUS,
    (draftState, action) => {
      draftState.statusOverride = action.data
    },
  ],
  [
    EDITING_SET_ID,
    (draftState, action) => {
      draftState.editId = action.id
    },
  ],
])

export function reducer() {
  return createReducer(initialState, actionHandlers)
}
