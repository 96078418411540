import { ApolloClient, DocumentNode } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { settings } from '@cls/config'
import { ApolloLink, split } from 'apollo-link'
import { onError } from 'apollo-link-error'
import apolloLogger from 'apollo-link-logger'
import { WebSocketLink } from 'apollo-link-ws'
import { createUploadLink } from 'apollo-upload-client'
import { getMainDefinition } from 'apollo-utilities'

import { getToken } from '../utils/token'

const isSecure = () => window.location.protocol === 'https:'
const wsUrl = `ws${isSecure() ? 's' : ''}://${settings.websocketHost}/graphql`

const isWebsocket = ({ query }: { query: DocumentNode }) => {
  const definition = getMainDefinition(query)
  if (definition.kind === 'OperationDefinition') {
    return definition.operation === 'subscription'
  }
  return false
}

const links: Array<ApolloLink> = []

// error link
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}`
      )
    )
  }

  if (networkError) {
    // @ts-ignore Log the status code if it's on the object, or an empty set of brackets is fine
    console.error(`[Network error (${networkError.statusCode})]: ${networkError}`)
  }
})

links.push(errorLink)

// logging link
if (module.hot) {
  const thruLink = new ApolloLink((operation, forward) => forward(operation))
  const loggingLink = split(isWebsocket, thruLink, apolloLogger)
  links.push(loggingLink)
}

// web links
const httpLink = createUploadLink({
  uri:         'api/graphql',
  credentials: 'same-origin',
}) as any as ApolloLink

function setHeaderAuthorization(token = getToken()) {
  if (!token) {
    console.warn('no JWT token!')
  }
  return token ? { authorization: `Bearer ${token}` } : {}
}

// Some of the fully mounted components under test get upset if there's a websocketlink
// in play. It's a symptom of server-side rendering, so while ordinarily adding a
// "test mode" isn't great, this is something we'll probably need in future.
if (typeof window === 'object' && typeof document === 'object') {
  const wsLink = new WebSocketLink({
    uri:     wsUrl,
    options: {
      connectionParams:     setHeaderAuthorization(),
      reconnect:            true,
      reconnectionAttempts: 100,
      // Reconnect after 30s, keep-alive heartbeat sent every 3s though
      timeout:              30000,
    },
  })

  const serverLink = split(isWebsocket, wsLink, httpLink)

  links.push(serverLink)
} else {
  links.push(httpLink)
}

const client = new ApolloClient({
  // @ts-ignore Some of the types seem to be incompatible. Leaving as they were in JS-land
  link:  ApolloLink.from(links),
  cache: new InMemoryCache({
    typePolicies: {
      Item: {
        fields: {
          tags: {
            merge(_existing, incoming) {
              return incoming ? incoming : _existing
            },
          },
        },
      },
      EditingUser: {
        fields: {
          editing: {
            merge(_existing, incoming) {
              // We get this from two sources, but only one is populated. Merge function for now.
              return incoming ? incoming : _existing
            },
          },
        },
      },
      TemplateField: {
        keyFields: ['parentId', 'id'],
      },
    },
  }),
})

export default client
