import { Plugin } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

export function placeholderPlugin(placeholder) {
  // TODO: move to plugins folder
  return new Plugin({
    props: {
      decorations(state) {
        const doc = state.doc
        if (doc.childCount == 1 && doc.firstChild.isTextblock && doc.firstChild.content.size == 0) {
          const el = document.createElement('span')
          el.textContent = placeholder
          el.className = 'item-field-placeholder'
          return DecorationSet.create(doc, [Decoration.widget(1, el)])
        }
      },
    },
  })
}
