import { showLinkEditor } from '@kpv-lab/link-editor'
import { lift, setBlockType, toggleMark, wrapIn } from 'prosemirror-commands'
import { Dropdown, icons as defaultIcons, MenuItem } from 'prosemirror-menu'
import { liftListItem, wrapInList } from 'prosemirror-schema-list'

import {
  showMediaPopup
} from '../../../cl-studio/src/app/components/media-manager/state/media-manager-popup-redux'
import {
  hideUnitaggerPopup,
  showUnitaggerPopup
} from '../../../cl-studio/src/app/components/unitagger-popup/state/unitagger-popup-redux'
import { showReferencePopup } from '../../src/reference-popup/state/reference-popup-actions'
import { showRichtextPopup } from '../../src/richtext-popup/state/richtext-popup-redux'
import config from '../config'
import { getMediaIdFromUrl, getMediaIdFromVideoUrl } from '../markdown/utils'
import { headerIcons } from './headerIcons'
import { MarkDetails } from './MarkDetails'
import { openPrompt, TextField } from './prompt'

function materialIcon(iconName) {
  const container = document.createElement('i')
  container.className = 'material-icons'
  container.textContent = iconName
  return container
}

export const icons = {
  ...defaultIcons,
  tag: {
    dom: materialIcon('local_offer'),
  },
  alignleft: {
    dom: materialIcon('format_align_left'),
  },
  aligncenter: {
    dom: materialIcon('format_align_center'),
  },
  alignright: {
    dom: materialIcon('format_align_right'),
  },
  alignjustify: {
    dom: materialIcon('format_align_justify'),
  },
  linkItem: {
    dom: materialIcon('link'),
  },
  media: {
    dom: materialIcon('insert_photo'),
  },
  info: {
    dom: materialIcon('help_outline'),
  },
  reference: {
    dom: materialIcon('library_books'),
  },
  clearTags: {
    dom: materialIcon('format_clear'),
  },
  audio: {
    dom: materialIcon('audiotrack'),
  },
  video: {
    dom: materialIcon('movie'),
  },
  ...headerIcons,
}

function cmdItem(cmd, options) {
  const passedOptions = {
    label: options.title,
    run:   cmd,
  }
  for (const prop in options) {
    passedOptions[prop] = options[prop]
  }
  if ((!options.enable || options.enable === true) && !options.select) {
    passedOptions[options.enable ? 'enable' : 'select'] = state => cmd(state)
  }
  return new MenuItem(passedOptions)
}

export function markActive(state, type) {
  const { from, $from, to, empty } = state.selection
  if (empty) {
    return type.isInSet(state.storedMarks || $from.marks())
  } else {
    return state.doc.rangeHasMark(from, to, type)
  }
}

export function markItem(markType, options) {
  const passedOptions = {
    active(state) {
      return markActive(state, markType)
    },
    enable: true,
  }
  for (const prop in options) {
    passedOptions[prop] = options[prop]
  }
  return cmdItem(toggleMark(markType), { ...passedOptions, ...options })
}

export function alignBlock(schema, nodeType, options) {
  return new MenuItem({
    title: `Align text ${options.align}`,
    label: options.align,
    icon:  icons[`align${options.align}`],
    enable(state) {
      const { from } = state.selection
      const node = state.doc.nodeAt(from)
      if (node && node.type.name === 'image' && options.align === 'justify') {
        // We can't justify the image alignment
        return false
      }
      return true
    },
    active(state, dispatch) {
      const { from } = state.selection
      const node = state.doc.nodeAt(from)
      if (node && node.type.name === 'image') {
        return options.align === 'left' && node.attrs.align === ''
        || node.attrs.align.replace('img-align-', '') === options.align
      } else {
        return !setBlockType(schema.nodes.paragraph, {
          align: `${config.alignClassPrefix}-align-${options.align}`,
        })(state, dispatch)
      }
    },
    run(state, dispatch) {
      const { from } = state.selection
      const node = state.doc.nodeAt(from)
      if (node && node.type.name === 'image') {
        dispatch(
          state.tr.setNodeMarkup(from, schema.nodes.image, {
            ...node.attrs,
            align: `img-align-${options.align}`,
          })
        )
      } else {
        setBlockType(schema.nodes.paragraph, {
          align: `${config.alignClassPrefix}-align-${options.align}`,
        })(state, dispatch)
      }
    },
  })
}

export function linkItem(schema, markType, props) {
  const { dispatch: reduxDispatch } = props
  return new MenuItem({
    title: 'Link to another item',
    icon:  icons.linkItem,
    active(state) {
      return new MarkDetails(state, markType).current
    },
    enable(state) {
      return new MarkDetails(state, markType).current || !state.selection.empty
    },
    run(state, dispatch, view) {
      const mark = new MarkDetails(state, markType)
      if (mark.current) {
        const { from, to } = mark.getBookends()
        const { href } = mark.current.attrs
        const templateId = parseInt(href.split('/')[2])
        const parentId = parseInt(href.split('/')[3])
        const itemId = parseInt(href.split('/')[4])
        const replacelink = (item) => {
          dispatch(
            state.tr.removeMark(from, to).addMark(
              from,
              to,
              schema.marks.linkItem.create({
                href: `itemlink://${item.templateId}/${parentId}/${item.id}`,
              })
            )
          )
        }
        reduxDispatch(
          showLinkEditor({
            itemId,
            templateId,
            query:         mark.node.textContent,
            updateHandler: replacelink,
            clearHandler:  () => { /* no op */ },
          })
        )
      } else {
        const { from, to } = state.selection
        const selection = state.doc.cut(from, to)
        const insertLink = ({ id, templateId, parentId }) => {
          toggleMark(markType, {
            href: `itemlink://${templateId}/${parentId}/${id}`,
          })(view.state, view.dispatch)
        }
        reduxDispatch(
          showLinkEditor({
            query:         selection.textContent,
            updateHandler: insertLink,
            clearHandler:  () => { /* no op */ },
          })
        )
      }
    },
  })
}

export function headingBlock(schema, nodeType, options) {
  return new MenuItem({
    label: options.label,
    icon:  icons[options.label],
    select(state) {
      const { from } = state.selection
      const node = state.doc.nodeAt(from)
      return node && node.type.name !== 'image'
    },
    enable(state, dispatch) {
      const { $from, to, node } = state.selection
      let isType = false
      if (node) {
        isType = node.hasMarkup(nodeType, options.attrs)
      } else {
        isType = to <= $from.end() && $from.parent.hasMarkup(nodeType, options.attrs)
      }
      if (isType) {
        // is the type already, enable button so we can toggle it off again
        return true
      } else {
        // We can just run the operation to tell if the button should be enabled or not
        // It'll return truthy/falsey if it manages to do it.
        return setBlockType(nodeType, options.attrs)(state, dispatch)
      }
    },
    run(state, dispatch) {
      const { $from, to, node } = state.selection
      let isType = false
      if (node) {
        isType = node.hasMarkup(nodeType, options.attrs)
      } else {
        isType = to <= $from.end() && $from.parent.hasMarkup(nodeType, options.attrs)
      }
      if (isType) {
        setBlockType(schema.nodes.paragraph)(state, dispatch)
      } else {
        setBlockType(nodeType, options.attrs)(state, dispatch)
      }
    },
    active(state) {
      const { $from, to, node } = state.selection
      if (node) {
        return node.hasMarkup(nodeType, options.attrs)
      }
      return to <= $from.end() && $from.parent.hasMarkup(nodeType, options.attrs)
    },
  })
}

export function link(markType) {
  return new MenuItem({
    title: 'Add or remove link',
    icon:  icons.link,
    active(state) {
      return markActive(state, markType)
    },
    enable(state) {
      return !state.selection.empty
    },
    run(state, dispatch, view) {
      if (markActive(state, markType)) {
        toggleMark(markType)(state, dispatch)
        return true
      }
      openPrompt({
        title:  'Link:',
        fields: {
          href: new TextField({
            label:    'http://bbc.co.uk',
            required: true,
          }),
        },
        callback(attrs) {
          toggleMark(markType, attrs)(view.state, view.dispatch)
          view.focus()
        },
      })
    },
  })
}

function insertInfoBlock(schema, props) {
  const { dispatch: reduxDispatch, structure } = props
  const infoType = schema.nodes.info
  return function (state, dispatch) {
    // const { $from } = state.selection
    // const index = $from.index()
    // if (!$from.parent.canReplaceWith(index, index, infoType)) {
    //   return false
    // }
    if (dispatch) {
      // Stops the user from being able to continue typing in the text editor and triggering errors
      document.activeElement.blur()
      reduxDispatch(
        showRichtextPopup({
          title:       'Add information or references',
          placeholder: 'Add relevant information or references...',
          checkbox:    {
            label: 'Ref',
            value: structure?.forceRef ?? false,
          },
          closeHandler: (value, isRef) => {
            const currentPos = state.tr.selection.$head.pos
            // We need to escape links if there's a ! beside them,
            // otherwise they're parsed as images
            const insertEscape = state.tr.doc.textBetween(currentPos - 1, currentPos) === '!'
            const id = Date.now()
            if (value) {
              const infoClasses = isRef ? 'info-block_ref' : 'info-block'
              dispatch(
                state.tr
                  .setMeta('attributes', {
                    type: 'insertInfoBlock',
                    id,
                    value,
                    isRef,
                  })
                  .insert(
                    state.tr.selection.$head.pos,
                    infoType.create({
                      insertEscape,
                      href: `${infoClasses}://${id}`,
                    })
                  )
              )
            }
          },
        })
      )
    }
    return true
  }
}

export function infoBlock(schema, props) {
  return new MenuItem({
    title: 'Insert Info Block',
    icon:  icons.info,
    enable(state) {
      return insertInfoBlock(schema, props)(state)
    },
    run: insertInfoBlock(schema, props),
  })
}

function insertReferenceBlock(schema, props) {
  const { dispatch: reduxDispatch } = props
  const referenceType = schema.nodes.reference

  return function (state, dispatch) {

    if (!dispatch) {
      return true
    }

    document.activeElement.blur()
    reduxDispatch(
      showReferencePopup({
        title:        'Add a reference',
        closeHandler: (referenceitem) => {
          const { refId, page, additionalInfo } = referenceitem

          if (!refId) {
            return
          }
          const currentPos = state.tr.selection.$head.pos
          const insertEscape = state.tr.doc.textBetween(currentPos - 1, currentPos) === '!'
          // Need another arbitrary ID to differentiate between multiple blocks with the same reference ID
          const blockId = Date.now()

          dispatch(
            state.tr
              .setMeta('attributes', {
                type: 'insertReferenceBlock',
                refId,
                blockId,
                page,
                additionalInfo,
              })
              .insert(
                state.tr.selection.$head.pos,
                referenceType.create({
                  insertEscape,
                  href: `ref-block://${refId}/${blockId}`,
                })
              )
          )

        },
      })
    )
  }
}

export function reference(schema, props) {
  return new MenuItem({
    title: 'Insert Reference',
    icon:  icons.reference,
    enable(state) {
      return insertReferenceBlock(schema, props)(state)
    },
    run: insertReferenceBlock(schema, props),
  })
}

export function clearTags(schema) {
  return new MenuItem({
    title: 'Clear tags',
    icon:  icons.clearTags,
    enable() {
      return true
    },
    run(state, dispatch, view) {
      const { from, to } = state.selection
      if (from === to) {
        const warning = confirm('Are you sure you want to clear all tags?')
        if (!warning) {
          return false
        }
      }
      const start = from === to ? 0 : from
      const end = from === to ? state.tr.doc.content.size : to
      let offsetCounter = 0
      let action = state.tr.removeMark(start, end, schema.marks.tag)

      function getInfoBlocks(searchNode, parentOffset) {
        searchNode.forEach((child, offset) => {
          if (child.type.name === 'info') {
            const totalOffset = parentOffset + offset + 1 - offsetCounter
            action = action.delete(totalOffset, totalOffset + 1)
            offsetCounter += 1
          }
          if (child.content.content.length) {
            getInfoBlocks(child, parentOffset + offset + 1)
          }
        })
      }

      if (from === to) {
        state.tr.doc.forEach((parentNode, offset) => {
          getInfoBlocks(parentNode, offset)
        })
        action = action.removeMark(
          0,
          state.tr.doc.content.size - offsetCounter,
          schema.marks.itemlink
        )
      } else {
        action = action.removeMark(from, to, schema.marks.itemlink)
      }

      dispatch(action)
      view.focus()
    },
  })
}

export function mediaSize(schema) {
  const r = {}
  const sizes =    ['tiny', 'small', 'medium', 'large', 'default']
  sizes.forEach(size => {
    r['mediaSize' + size] = new MenuItem({
      label:  size,
      select(state) {
        const { from } = state.selection
        const node = state.doc.nodeAt(from)
        return node && node.type.name === 'image'
      },
      active(state) {
        const { from } = state.selection
        const node = state.doc.nodeAt(from)
        return node && node.type.name === 'image' && node.attrs.src.includes(size)
      },
      run(state, dispatch) {
        const { from } = state.selection
        const node = state.doc.nodeAt(from)
        const existingMediaItem = node && node.type.name === 'image'
        let existingMediaAttrs = {}

        if (existingMediaItem) {
          existingMediaAttrs = node.attrs
        } else {
          return
        }

        let action = state.tr
        if (existingMediaItem) {
          // if we're editing a media item, delete the old one first
          action = action.delete(from, from + 1)
        }
        dispatch(action.insert(from, schema.nodes.image.create({
          ...existingMediaAttrs,
          src: existingMediaAttrs.src.replace(/#.*$/gm, '') + `#${size}`,
        })))
      },
    })
  })
  return new Dropdown([
    r.mediaSizetiny,
    r.mediaSizesmall,
    r.mediaSizemedium,
    r.mediaSizelarge]
  , { label: 'Media Size' })

}

export function mediaItem(schema, markType, props) {
  const { dispatch: reduxDispatch } = props
  return new MenuItem({
    title: 'Media',
    icon:  icons.media,
    label: 'media',
    active(state) {
      const { from } = state.selection
      const node = state.doc.nodeAt(from)
      return node && node?.type.name === 'image' || node?.type.name === 'audio' || node?.type.name === 'video'
    },
    enable() {
      return true
    },
    run(state, dispatch) {
      const { from } = state.selection

      // Checks that we're not acting on an existing media item
      const node = state.doc.nodeAt(from)
      const existingMediaItem = node && node?.type.name === 'image' || node?.type.name === 'audio' || node?.type.name === 'video'
      const mediaId = existingMediaItem ? node.attrs.mediaId : null

      // insertMedia gets called after reduxDispatch, when the popup closes
      const insertMedia = (mediaAttrs, mediaItems) => {

        if (!mediaAttrs.src) {
          return
        }

        let mediaIdToInsert = mediaAttrs?.type !== 'video'
          ? getMediaIdFromUrl(mediaAttrs.src)
          : getMediaIdFromVideoUrl(mediaAttrs.src)

        if (!mediaIdToInsert) {
          mediaIdToInsert = getMediaIdFromUrl(mediaAttrs.src)
        }

        let action = state.tr
        if (existingMediaItem) {
          // if we're editing a media item, delete the old one first
          action = action.delete(from, from + 1)
        }

        mediaAttrs.mediaId = mediaIdToInsert
        // Adding the type here so the transaction can update the event listeners
        // in MDTextEditor/EditorView
        if (mediaAttrs?.type === 'image') {
          dispatch(action
            .setMeta('attributes', {
              type: 'insertMediaItem',
            })
            .insert(from, schema.nodes.image.create(mediaAttrs))
          )
        } else if (mediaAttrs?.type === 'audio') {
          dispatch(
            action
              .setMeta('attributes', {
                type: 'insertMediaItem',
              })
              .insert(
                from,
                // Removing cache busting value - audio doesn't receive any # params from the popup
                schema.nodes.audio.create({ ...mediaAttrs, src: mediaAttrs.src.split('#')[0] })
              ))
        } else if (mediaAttrs?.type === 'video') {
          dispatch(
            action
              .setMeta('attributes', {
                type: 'insertMediaItem',
              })
              .insert(
                from,
                // Removing cache busting value - video doesn't receive any # params from the popup
                schema.nodes.video.create({ ...mediaAttrs, src: mediaAttrs.src.split('#')[0] })
              ))
        }
        /* We always want to grab the latest media url from the prosemirror content
        (since it carries size/alignment data). No point storing it in the database too. */
        delete mediaAttrs.src

        if (props.updateItem) {
          props.updateItem({
            key:   'mediaItems',
            value: {
              ...mediaItems,
              [mediaIdToInsert]: mediaAttrs,
            },
          })
        } else {
          console.error('No updateItem prop passed to RTE. Implies another field has been made media friendly')
        }
      }

      reduxDispatch(
        showMediaPopup({
          onClose:         insertMedia,
          mediaPopupAttrs: {
            align:         existingMediaItem ? node.attrs.align : null,
            src:           existingMediaItem ? node.attrs.src : null,
            itemId:        props.itemId,
            mediaId,
          },
        })
      )
    },
  })
}

export function tagItem(schema, markType, props) {
  const { dispatch: reduxDispatch, options } = props
  let tagGroupIds
  let parentTagIds
  if (!options) {
    tagGroupIds = [1, 2, 3, 4]
    parentTagIds = null
  } else {
    const tagOptions = options.split(',').filter(o => o !== 'a')
    tagGroupIds = tagOptions
      .filter(option => option.charAt(0) === 'g')
      .map(option => Number(option.substring(1)))
    parentTagIds = tagOptions
      .filter(option => option.charAt(0) !== 'g')
      .map(option => Number(option))
  }

  return new MenuItem({
    title: 'Tag',
    icon:  icons.tag,
    active(state) {
      const activeMark = new MarkDetails(state, markType)
      return activeMark.current
    },
    enable() {
      return true
    },
    run(state, dispatch, view) {
      const { $head, head, $anchor, anchor, from, to } = state.selection
      const markContainer = head >= anchor ? $head : $anchor

      if (
        markContainer.marks().length &&
        markContainer.marks().find(mark => {
          return mark.type.name === 'itemlink'
        })
      ) {
        dispatch(state.tr.removeMark(from, to))
      }

      // Stops the user from being able to continue typing in the text editor and triggering errors
      document.activeElement.blur()

      const mark = new MarkDetails(state, markType)
      if (mark.current) {
        const existingTagId = parseInt(mark.current.attrs.href.split('/')[3])
        const { start, end } = mark.getBookends()
        const replaceTag = (id, type) => {
          dispatch(
            state.tr.removeMark(start, end).addMark(
              start,
              end,
              schema.marks.tag.create({
                href: `tag://${type}/${id}`,
              })
            )
          )
          reduxDispatch(hideUnitaggerPopup())
        }
        reduxDispatch(
          showUnitaggerPopup({
            query:            mark.node.textContent,
            selectTagForLink: replaceTag,
            tagGroupIds,
            parentTagIds,
            existingTagId,
          })
        )
      } else {
        const selection = state.doc.cut(from, to)
        const markTag = (id, type) => {
          toggleMark(markType, {
            href: `tag://${type}/${id}`,
          })(view.state, view.dispatch)
          reduxDispatch(hideUnitaggerPopup())
        }
        reduxDispatch(
          showUnitaggerPopup({
            query:            selection.textContent,
            selectTagForLink: markTag,
            tagGroupIds,
            parentTagIds,
            // existingTagId:    null,
          })
        )
      }
    },
  })
}

export function wrapListItem(schema, nodeType, options) {
  return new MenuItem({
    icon: options.icon,
    active(state) {
      const { $from } = state.selection
      // lists return list_item first, then their parent is the list type
      // so we go up two
      const grandParent = $from.node(-2)
      if (grandParent) {
        return grandParent.hasMarkup(nodeType)
      }
      return false
    },
    run(state, dispatch) {
      const { $from } = state.selection
      // lists return list_item first, then their parent is the list type
      // so we go up two
      const grandParent = $from.node(-2)
      if (grandParent && grandParent.hasMarkup(nodeType)) {
        return liftListItem(schema.nodes.list_item)(state, dispatch)
      } else {
        return wrapInList(nodeType, options.attrs)(state, dispatch)
      }
    },
  })
}

export function blockQuote(schema, nodeType, options) {
  const passedOptions = {
    title: 'Wrap in block quote',
    icon:  icons.blockquote,
    active(state) {
      const { $from, $to } = state.selection
      const range = $from.blockRange($to)
      return range.parent.type.name === 'blockquote'
    },
    // if the wrapping block is already a blockquote, we just want to indent it left again
    run: (state, dispatch) => {
      const { $from, $to } = state.selection
      const range = $from.blockRange($to)
      if (range.parent.type.name === 'blockquote') {
        return lift(state, dispatch)
      }
      return wrapIn(nodeType)(state, dispatch)
    },
    select(state) {
      return wrapIn(nodeType, options.attrs instanceof Function ? null : options.attrs)(state)
    },
  }
  return new MenuItem({ ...passedOptions, ...options })
}
