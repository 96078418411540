import '../styles/KeyframeBtn.css'

import React, { Component } from 'react'

type OwnProps = {
  onKeyframe?: (...args: Array<any>) => any,
  enabled?: boolean,
  current?: boolean,
};

type Props = OwnProps & typeof KeyframeBtn.defaultProps;

export default class KeyframeBtn extends Component<Props> {

  static defaultProps = {
    enabled: false,
    active:  false,
  }

  render() {
    const { onKeyframe, current, enabled } = this.props
    const className = `ui-keyframe-btn ${enabled ? 'enabled' : ''} ${current ? 'current' : ''}`
    return <div className={className} onClick={onKeyframe} title="Keyframe" />
  }

}
