import * as ColorUtils from '@kpv-lab/color-utils'
import { produce } from 'immer'
export const SHOW = 'color-editor/SHOW'
export const HIDE = 'color-editor/HIDE'
export const RESET = 'color-editor/RESET'
export const SET_COLOR = 'color-editor/SET_COLOR'
export const SET_COLOR_CHANNEL = 'color-editor/SET_COLOR_CHANNEL'

// action dispatchers
export function setColor(color, alpha?: number) {
  return (dispatch, getState) => {
    dispatch(setColorData(color, alpha))
    applyColor(getState().colorEditor)
  }
}

export function setColorChannel(id, val) {
  return (dispatch, getState) => {
    dispatch(setColorChannelData(id, val))
    applyColor(getState().colorEditor)
  }
}

// action creators
export function showColorEditor(opts) {
  return {
    type: SHOW,
    data: opts,
  }
}

export function hideColorEditor() {
  return {
    type: HIDE,
  }
}

export function resetColor(color, alpha) {
  return {
    type: RESET,
    data: { color, alpha },
  }
}

export function setColorData(color, alpha?: number) {
  return {
    type: SET_COLOR,
    data: { color, alpha },
  }
}

export function setColorChannelData(id, val) {
  return {
    type: SET_COLOR_CHANNEL,
    data: { id, val },
  }
}

// helpers
export function applyColor(colorStore) {
  const updateFn = colorStore.updateHandler
  if (!updateFn) {
    return
  }

  if (colorStore.blank) {
    updateFn(colorStore.colorKey, '')
    return
  }

  const red = Math.round(colorStore.red)
  const green = Math.round(colorStore.green)
  const blue = Math.round(colorStore.blue)
  const alpha = colorStore.alpha

  if (colorStore.showAlpha) {
    if (colorStore.alphaKey) {
      updateFn(colorStore.colorKey, ColorUtils.rgba2hex(red, green, blue))
      updateFn(colorStore.alphaKey, alpha)
    } else {
      updateFn(colorStore.colorKey, `rgba(${red},${green},${blue},${alpha})`)
    }
  } else {
    updateFn(colorStore.colorKey, ColorUtils.rgba2hex(red, green, blue))
  }
}

export function initialState(opts: any = {}) {
  const alpha = typeof opts.alpha === 'undefined' ? 1 : opts.alpha
  const color = opts.color || '#000'
  const [r, g, b, a] = ColorUtils.col2rgba(color, alpha)
  const [h, s, l] = ColorUtils.rgb2hsl(r, g, b)

  return {
    red:           r,
    green:         g,
    blue:          b,
    alpha:         a,
    hue:           h,
    sat:           s,
    lgt:           l,
    originalColor: color,
    originalHex:   ColorUtils.rgba2hex(r, g, b),
    originalAlpha: alpha,
    colorKey:      opts.colorKey,
    alphaKey:      opts.alphaKey,
    showAlpha:     !!opts.showAlpha,
    active:        !!opts.active,
    blank:         !opts.color,
    label:         opts.label || '',
    updateHandler: opts.updateHandler,
  }
}

export default function reducer() {
  return produce((draftState, action = {}) => {
    switch (action.type) {

      case SHOW:
        return initialState(Object.assign({}, action.data, { active: true }))

      case HIDE:
        draftState.active = false
        break

      case SET_COLOR: {
        const { color, alpha } = action.data
        const [r, g, b, a] = ColorUtils.col2rgba(color || '#000', isFinite(alpha) ? alpha : 1)
        const [h, s, l] = ColorUtils.rgb2hsl(r, g, b)

        draftState.blank = !color
        draftState.hue = h
        draftState.sat = s
        draftState.lgt = l
        draftState.red = r
        draftState.green = g
        draftState.blue = b
        draftState.alpha = a

        break
      }

      case SET_COLOR_CHANNEL: {
        const { id, val } = action.data
        draftState[id] = val
        draftState.blank = false

        if (id === 'red' || id === 'blue' || id === 'green') {
          const [h, s, l] = ColorUtils.rgb2hsl(
            draftState.red,
            draftState.green,
            draftState.blue,
            false,
            true
          )
          draftState.hue = h
          draftState.sat = s
          draftState.lgt = l
        } else {
          // changing HSL
          let s = draftState.sat
          let l = draftState.lgt

          if (id === 'hue') {
            // shortcut for changing the hue when no sast or lightness
            if (s === 0 && l === 0) {
              s = 100
              draftState.sat = s
              l = 75
              draftState.lgt = l
            } else if (s === 0) {
              s = 100
              draftState.sat = s
            } else if (l === 0) {
              l = 75
              draftState.lgt = l
            }
          }

          const [r, g, b] = ColorUtils.hsl2rgb(draftState.hue, s, l, false, true)
          draftState.red = r
          draftState.green = g
          draftState.blue = b
        }
        break
      }

      default:
        return draftState

    }
  }, initialState())
}
