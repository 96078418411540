import { timeDetails, toFloatTime } from '@kpv-lab/time-utils'

export const CURRENT_TIME = 9999

function isValid(x: string|number) {
  if (typeof x === 'number') {
    return true
  }
  return x !== '' && x !== undefined && x !== null
}

interface GetDateProps {
  year: string|number,
  month: string|number,
  day: string|number,
  hour: string|number,
  minute: string|number,
  second: string|number,
  dataKey: string,
  value?: string,
  current?: boolean,
}

export function getDate({ year, month, day, hour, minute, second, dataKey, current }: GetDateProps): Record<string, string|number>|false {
  let y = year
  let M = typeof month === 'string' ? parseInt(month || '0') : month
  let d = typeof day === 'string' ? parseInt(day || '0') : day

  let val: string
  if (!isValid(y)) {
    val = ''
  } else {
    val = `${y}_${M}_${d}`
  }

  // validate date
  if (!Number.isNaN(+y) && M && d) {
    const date = timeDetails(toFloatTime(val))
    y = date.year
    M = date.month + 1
    d = date.day + 1
    val = `${y}_${M}_${d}`
  }

  // validate h/min/sec
  if (y && d && M && isValid(hour)) {
    val += `_${hour}`

    if (isValid(minute)) {
      val += `_${minute}`
    }

    if (isValid(second)) {
      val += `_${second}`
    }

    const date = timeDetails(toFloatTime(val))

    const h = date.hour
    const _minute = date.minute
    const _second = date.second

    val = `${y}_${M}_${d}_${h}`
    if (isValid(minute)) {
      val += `_${_minute}`
    }

    if (isValid(second)) {
      val += `_${_second}`
    }
  }

  if (current) {
    val = '' + CURRENT_TIME
  }

  const data: Record<string, string|number> = {}

  if (val === '' + CURRENT_TIME) {
    data[dataKey] = CURRENT_TIME
    data['_' + dataKey] = CURRENT_TIME
  } else {
    data[dataKey] = val
    data['_' + dataKey] = val ? toFloatTime('' + val) : ''
  }

  return data
}

export function parseDate(val: unknown) {
  if (!val || (typeof val === 'number' && val === CURRENT_TIME)) {
    return ['', '0', '0', '', '', '']
  }

  if (!(typeof val === 'string' || typeof val === 'number')) {
    throw new Error('Unknown type passed to function')
  }

  const f = typeof val === 'number' ? val : parseFloat(val)
  if (f.toString() === val.toString() && f % 1 !== 0) {
    const date = timeDetails(f)
    return [date.year, date.month + 1, date.day + 1, date.hour, date.minute, date.second]
  }

  let str = `${val}`.trim()
  let sign = 1
  if (str[0] === '-') {
    sign = -1
    str = str.substr(1)
  }

  const p = str.split(/[_-]/).map(n => parseInt(n || '0'))
  if (p[0]) {
    p[0] *= sign
  }

  return [p[0] || '0', p[1] || '0', p[2] || '0', p[3], p[4], p[5]]
}
