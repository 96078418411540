import { EditorState } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'

import { markdownParser } from './parser'
import { markdownSchema } from './schema'

export function MDToHTML(markdown = '') {
  const isHtml = markdown.includes('</') || markdown.includes('<b')
  if (isHtml) {
    return markdown
  }
  const proseMirrorNode = markdownParser.parse(markdown)
  const editorState = EditorState.create({
    doc:    proseMirrorNode,
    schema: markdownSchema,
  })

  const container = document.createElement('div')

  new EditorView(container, {
    state: editorState,
  })
  container.children[0].className = 'markdown-preview'
  return container.children[0].innerHTML
}
