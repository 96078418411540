import { gql } from '@apollo/client'
import { Subscription } from '@apollo/client/react/components'
import { settings } from '@cls/config'
import AppLoading from '@cls/spinner'
import { DatePopup } from '@kpv-lab/date-control'
import { ReferencePopup, RichTextPopup } from '@kpv-lab/md-text-editor'
import Modal from '@kpv-lab/modal'
import FeedbackPopup from 'cls/components/feedback-popup/FeedbackPopup'
import Flash from 'cls/components/flash/Flash'
import MapEditor from 'cls/components/map-editor/MapEditor'
import MapEventsEditor from 'cls/components/mapevents-editor/MapEventsEditor'
import MediaManagerPopup from 'cls/components/media-manager/MediaManagerPopup'
import MessageView from 'cls/components/messaging/MessageView'
import MultimediaPopup from 'cls/components/multimedia-popup/MultimediaPopup'
import UnitaggerPopup from 'cls/components/unitagger-popup/UnitaggerPopup'
import LogoutView from 'cls/views/user/LogoutView'
import PropTypes from 'prop-types'
import React, { Component, lazy, Suspense } from 'react'
import { createPortal } from 'react-dom'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import { compose } from 'redux'

import { toggleMenu as toggleMenuAction } from '../state/menu-redux'
import Logo from './Logo'
import Menu from './menu/Menu'
import NotFound from './NotFound'
import UserUpdate from './UserUpdate'

const ContentView = lazy(() => import(/* webpackChunkName: "content" */ 'cls/views/content/ContentView'))

const Overview = lazy(() => import(/* webpackChunkName: "dashboard" */ 'cls/views/overview/Overview'))
const HistoricMaps = lazy(() => import(/* webpackChunkName: "historicmaps" */ 'cls/views/maps/HistoricMaps'))
const TemplatesView = lazy(() => import(/* webpackChunkName: "templates" */ 'cls/views/templates/TemplatesView'))
const UsersView = lazy(() => import(/* webpackChunkName: "users" */ 'cls/views/user-manager/UsersView'))

const NullComponent = () => null

export class AppBody extends Component {

  static propTypes = {
    children:       PropTypes.object,
    history:        PropTypes.object,
    location:       PropTypes.object,
    popupContainer: PropTypes.instanceOf(window.Element),
    showDatePopup:  PropTypes.bool,
    showFeedback:   PropTypes.bool,
    showMultimedia: PropTypes.bool,
    showMapEditor:  PropTypes.bool,
    showMedia:      PropTypes.bool,
    showMenu:       PropTypes.bool,
    toggleMenu:     PropTypes.func,
  }

  static defaultProps = {
    popupContainer: document.body,
  }

  toggleToolbar = () => {
    this.setState({ showingToolbar: !this.state.showingToolbar })
  }

  render() {
    const {
      location,
      children,
      history,
      popupContainer,
      showFeedback,
      showMultimedia,
      toggleMenu,
      showDatePopup,
      showMapEditor,
      showMenu,
      showMedia,
    } = this.props
    const paths = (location?.pathname.split('/')) || []

    const className = `app-body ${paths[1] && `app-${paths[1]}`} ${settings.showHostWarning && 'host-warning'}`

    const richtextPopup = createPortal(<RichTextPopup />, popupContainer)
    const referencePopup = createPortal(<ReferencePopup />, popupContainer)

    return (
      <div className="app">
        <Helmet title="ChronoLogic Studio"></Helmet>
        <Subscription
          subscription={gql`
              subscription heartbeat {
                heartbeat
              }
            `}
        >
          {() => {
            // Listen for heartbeat to keep websocket alive
            return null
          }}
        </Subscription>
        <React.StrictMode>
          <main className={className}>
            {children}
            <Suspense fallback={<AppLoading />}>
              <Switch>
                <Route exact path="/" component={NullComponent} />
                <Route exact path="/logout" component={LogoutView} />
                <Route exact path="/overview" component={Overview} />
                <Route exact path="/overview/:tplId" component={Overview} />
                <Route exact path="/content" component={ContentView} />
                <Route exact path="/content/:tplId/:id/:group?/:groupIndex?" component={ContentView} />
                <Route exact path="/templates" component={TemplatesView} />
                <Route exact path="/template/:id" component={TemplatesView} />
                <Route exact path="/users" component={UsersView} />
                <Route exact path="/maps" component={HistoricMaps} />
                <Route path="/users/:userId" component={UsersView} />
                <Route component={NotFound} />
              </Switch>
            </Suspense>
          </main>
          <MessageView history={history} />
          <MapEventsEditor />
          <Modal />
          <Flash />
          {richtextPopup}
          {referencePopup}
          {showFeedback && <FeedbackPopup />}
          {showMultimedia && <MultimediaPopup />}
          {showMapEditor && <MapEditor />}
          {showDatePopup && <DatePopup />}
          <UnitaggerPopup />
          {showMedia && <MediaManagerPopup />}
          <button
            onClick={toggleMenu}
            className={`toolbar-logo ${showMenu ? 'active' : 'inactive'}`}
            data-cy="menu-btn"
          >
            <Logo />
          </button>
          <Menu location={this.props.location} hidden={!showMenu} />
          <UserUpdate location={location} />
        </React.StrictMode>
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    showDatePopup:  state.dateControl.visible,
    showFeedback:   state.feedbackPopup.visible,
    showMultimedia: state.multimediaPopup.visible,
    showMapEditor:  state.mapEditor.visible,
    showMenu:       state.menu.show,
    showMedia:      state.mediaManagerPopup.visible,
  }
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    { toggleMenu: toggleMenuAction }
  )
)(AppBody)
