import { produce } from 'immer'
import { AnyAction } from 'redux'

export interface OwnState {
  isVisible: boolean,
  message: string,
  acceptLabel: string,
  cancelLabel: string,
  acceptHandler?: (...args: Array<any>) => void,
  cancelHandler?: (...args: Array<any>) => void,
}

const initialState: OwnState = {
  isVisible:     false,
  message:       '',
  acceptLabel:   'Ok',
  cancelLabel:   'Cancel',
  acceptHandler: undefined,
  cancelHandler: undefined,
}

export default function reducer() {
  return produce((draftState?: OwnState, action?: AnyAction) => {
    if (!action) {
      return draftState
    }
    switch (action.type) {

      case 'SHOW_MODAL':
        return {
          ...draftState,
          ...action.data,
          isVisible: true,
        }

      case 'HIDE_MODAL':
        return initialState

    }
  }, initialState)

}

export const showModal = (data: Partial<Omit<OwnState, 'isVisible'>>) => {
  return <const>{
    type: 'SHOW_MODAL',
    data: {
      message:       data.message ?? initialState.message,
      acceptLabel:   data.acceptLabel ?? initialState.acceptLabel,
      cancelLabel:   data.cancelLabel ?? initialState.cancelLabel,
      acceptHandler: data.acceptHandler ?? initialState.acceptHandler,
      cancelHandler: data.cancelHandler ?? initialState.cancelHandler,
    },
  }
}

export const hideModal = () => (<const>{
  type: 'HIDE_MODAL',
})
