import { settings } from '@cls/config'
import { checkHttpStatus, parseJSON } from '@cls/utils/fetch'

import * as types from './action-types'

export function resetPassword(params = {}) {
  return async (dispatch: any) => {
    const url = `${settings.apiServer}/resetpassword`
    const options = {
      method:  'post',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body:    JSON.stringify(params),
    }
    try {
      const response = await doResetPassword(url, options, dispatch)
      return dispatch({ type: types.RESET_PASSWORD_SUCCESS, data: response.resetUser })
    } catch (error) {
      return dispatch({ type: types.RESET_PASSWORD_FAILURE, data: error.message })
    }
  }
}

export function newPassword(params = {}) {
  return async (dispatch: any) => {
    const url = `${settings.apiServer}/newpassword`
    const options = {
      method:  'post',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body:    JSON.stringify(params),
    }
    const response = await doResetPassword(url, options, dispatch)
    if (response.error) {
      return dispatch({ type: types.RESET_PASSWORD_FAILURE, data: response.error })
    } else {
      return dispatch({ type: types.RESET_PASSWORD_SUCCESS, data: response.resetUser })
    }
  }
}

export function resetUserPasswordState() {
  return (dispatch: any) => {
    dispatch({ type: types.RESET_PASSWORD_STATE })
  }
}

function doResetPassword(url: string, options: RequestInit, dispatch: any) {
  dispatch({ type: types.RESET_PASSWORD_REQUEST })
  return fetch(url, options)
    .then(checkHttpStatus)
    .then(parseJSON)
    .catch(err => {
      // dispatch failure
      console.error({ err })
    })
    .then(data => {
      if (data) {
        return data
      }
    })
}

// action creators
export function viewAccount(editing: boolean) {
  return {
    type: types.VIEW_ACCOUNT,
    data: editing,
  }
}

export function editAccountSuccess(data: any) {
  return {
    type: types.EDIT_ACCOUNT_SUCCESS,
    data,
  }
}

export function editAccountFailure(error: any) {
  const errorText =
    error && error.response && error.response.status === 406
      ? 'Invalid email or password'
      : (error && error.response && error.response.statusText) || 'Server problems! Try again...'

  return {
    type: types.EDIT_ACCOUNT_FAILURE,
    data: errorText,
  }
}
