export class MarkDetails {

  constructor(state, type) {
    this.state = state
    this.type = type
    this.current = this.atCursor()
    this.node = this.getNode()
  }

  // Will return a mark if the cursor/selection is on one
  atCursor = () => {
    const { $head, head, $anchor, anchor } = this.state.selection
    const markContainer = head >= anchor ? $head : $anchor
    if (markContainer.marks().length) {
      return markContainer.marks().find(mark => {
        return mark.type.name === this.type.name
      })
    } else {
      return null
    }
  }

  // Mark doesn't include data like text content, can grab the corresponding node for that
  getNode = () => {
    let currentNode = null
    this.state.doc.nodesBetween(0, this.state.tr.doc.content.size, node => {
      if (node.marks && node.marks.indexOf(this.atCursor()) > -1) {
        currentNode = node
        return false
      }
      return true
    })
    return currentNode
  }

  // Get the start and end position of a mark, useful when using just cursor position
  getBookends = () => {
    // We need to take into account the fact that the "selection" might be
    // a single cursor position with no text highlighted.
    const { from, to } = this.state.selection
    if (from === to) {
      const $pos = this.state.doc.resolve(this.state.selection.$head.pos)
      const start = this.state.selection.$head.pos - $pos.textOffset
      const end = start + $pos.parent.child($pos.index()).nodeSize
      return { start, end }
    } else {
      return { start: from, end: to }
    }
  }

}
