import { ActionHandler, createReducer } from '@cls/redux'
import { Action } from 'redux'

interface MediaManagerPopupState {
  visible: boolean,
  mediaPopupAttrs: {
    src: string,
    align: string | null,
    mediaId: string | null,
    itemId: string | null,
    type?: string | null,
  },
  onClose?: (...args: Array<any>) => void,
}

interface MediaPopupAttributes {
  src: string,
  align: string | null,
  mediaId: string | null,
  itemId: string | number | null,
  type?: string | null,
}

const initialState: MediaManagerPopupState = {
  visible:         false,
  mediaPopupAttrs: {
    src:     '',
    align:   null,
    mediaId: null,
    itemId:  null,
    type:    null,
  },
}

interface ShowMediaPopup extends Action<'SHOW_MEDIA_POPUP'> {
  payload: {
    onClose: () => void,
    mediaPopupAttrs: MediaPopupAttributes,
  },
}

export type MediaManagerPopupActions =
  | ShowMediaPopup
  | Action<'HIDE_MEDIA_POPUP'>

export function showMediaPopup({
  onClose,
  mediaPopupAttrs,
}: {
  onClose: (...args: any) => void,
  mediaPopupAttrs: MediaPopupAttributes,
}): MediaManagerPopupActions {
  return {
    type:    'SHOW_MEDIA_POPUP',
    payload: {
      onClose,
      mediaPopupAttrs,
    },
  }
}

export function hideMediaPopup(): MediaManagerPopupActions {
  return {
    type: 'HIDE_MEDIA_POPUP',
  }
}

const actionHandlers = new Map<string, ActionHandler<MediaManagerPopupState, any>>([
  [
    'SHOW_MEDIA_POPUP',
    (draftState, action) => {
      draftState.onClose = action.payload.onClose
      draftState.mediaPopupAttrs = action.payload.mediaPopupAttrs
      draftState.visible = true
    },
  ],
  [
    'HIDE_MEDIA_POPUP',
    (draftState) => {
      draftState.visible = false
    },
  ],
])
export default function reducer() {
  return createReducer(initialState, actionHandlers)
}
