import { ActionHandler, createReducer } from '@cls/redux'

import * as types from './action-types'
import { GridViewToggleItemTag, GridViewUpdateTagComment } from './actions'
import { TagState, tagStatus } from './updating'

export interface TagQueueItem {
  tagId: number,
  tagKey: string,
  original: TagState,
  modified: TagState,
}

interface OwnState {
  message: string,
  activeView: string,
  tagsQueue: Record<number, Array<TagQueueItem>>,
  commentsQueue: Array<any>,
  loading: boolean,
}

const initialState: OwnState = {
  message:       '',
  activeView:    'stats',
  tagsQueue:     {},
  commentsQueue: [],
  loading:       false,
}

const updateTagCommentHandler: ActionHandler<OwnState, GridViewUpdateTagComment> = (draftState, action) => {
  const { itemId, tagId, value } = action.data
  const newQueue =  draftState.commentsQueue.filter((queueItem: any) => queueItem.tagId !== tagId || queueItem.itemId !== itemId)
  newQueue.push({ itemId, tagId, value })
  draftState.commentsQueue = newQueue
}

const addItemTagToQueueHandler: ActionHandler<OwnState, GridViewToggleItemTag> = (draftState, action) => {
  const { itemId, tagId, key, currentStatus, shift = false } = action.data
  const newStatus = tagStatus(currentStatus, shift ? 2 : 1)
  const itemTagsQueue = draftState.tagsQueue[itemId]

  if (!itemTagsQueue) {
    draftState.tagsQueue[itemId] = [{ tagId, tagKey: key, original: currentStatus, modified: newStatus }]
    return
  }

  const existingTagItemIdx = itemTagsQueue.findIndex(tag => tag.tagId === tagId)

  // We're not currently storing any modifications for the tag
  if (existingTagItemIdx === -1) {
    draftState.tagsQueue[itemId] = [...itemTagsQueue, {
      tagId,
      tagKey:   key,
      original: currentStatus,
      modified: newStatus,
    }]
    return
  }

  const existingTagItem = itemTagsQueue[existingTagItemIdx]

  // Check to see if we've cycled back to the start
  if (existingTagItem.original.isTagged === newStatus.isTagged
    && existingTagItem.original.isPrimary === newStatus.isPrimary) {
    draftState.tagsQueue[itemId].splice(existingTagItemIdx)
  } else {
    draftState.tagsQueue[itemId] = itemTagsQueue.map(tag => {
      if (tag.tagId === tagId) {
        return {
          ...tag,
          modified: newStatus,
        }
      }
      return tag
    })
  }
}

const actionHandlers = new Map<string, ActionHandler<OwnState, any>>([
  [types.UPDATE_TAG_COMMENT, updateTagCommentHandler],
  [types.ADD_ITEM_TAG_TO_QUEUE, addItemTagToQueueHandler],
  [types.UPDATE_ITEM_TAG_REQUEST, (draftState: OwnState) => {
    draftState.loading = true
  }],
  [types.STOP_ITEM_TAG_LOADING, (draftState: OwnState) => {
    draftState.loading = false
  }],
  [types.CLEAR_ITEM_TAG_QUEUE, (draftState: OwnState) => {
    draftState.tagsQueue = {}
    draftState.commentsQueue = []
  }],
])

export default function reducer() {
  return createReducer(initialState, actionHandlers)
}
