/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { formatYear, parseYear } from '@kpv-lab/time-utils'
import { ActionButtons } from '@kpv-lab/ui'
import React, { PureComponent } from 'react'

import { CURRENT_TIME, getDate, parseDate } from './utils'

interface DatePopupProps {
  value: string,
  dataKey: string,
  structure: Record<string, any>,
  dateAcceptHandler: (date: Record<string|number, string|number>, isApproximateDate: boolean|undefined) => void,
  cancelHandler: () => void,
}

interface DatePopupState {
  year:   string,
  month:   string,
  day:     string,
  hour:    string,
  minute:  string,
  second:  string,
  current: boolean,
  interactingYear?: boolean,
  isApproximateDate?: boolean,
}

export class DatePopupDialog extends PureComponent<DatePopupProps, DatePopupState> {

  private refYear = React.createRef<HTMLInputElement>()
  private refHour = React.createRef<HTMLInputElement>()
  private refMinute = React.createRef<HTMLInputElement>()
  private refSecond = React.createRef<HTMLInputElement>()
  private refMonth = React.createRef<HTMLSelectElement>()
  private refDay = React.createRef<HTMLSelectElement>()

  constructor(props: DatePopupProps) {
    super(props)
    // We shouldn't need to coerce the values like this, but parseDate returns a mix of
    // numbers, strings, and undefined values, while the component is expecting only
    // strings and appears to work happily on that assumption.
    const [year, month, day, hour = '', minute = '', second = ''] = parseDate(props.value) as Array<string>

    this.state = {
      year,
      month,
      day,
      hour,
      minute,
      second,
      current:           props.value === '' + CURRENT_TIME,
      interactingYear:   false,
      isApproximateDate:   props.structure.isApproximateDate || false,
    }
  }

  componentDidMount() {
    this.refDay.current!.focus()
  }

  dateAcceptHandler = () => {
    const { dataKey, value } = this.props
    const {
      year,
      month,
      day,
      hour,
      minute,
      second,
      current,
      isApproximateDate,
    } = this.state


    const date = getDate({ year, month, day, hour, minute, second, current, dataKey, value })
    date && this.props.dateAcceptHandler(date, isApproximateDate)
  }

  resetHandler = () => {
    this.setState({
      month:             '',
      day:               '',
      hour:              '',
      minute:            '',
      second:            '',
      current:           false,
      isApproximateDate: false,
    })
  }

  yearBlurHandler = () => {
    const { year } = this.state
    this.setState({
      year:            year === '' ? year : '' + parseYear(year),
      interactingYear: false,
    })
  }

  onChange = (e: React.SyntheticEvent<HTMLElement, Event>) => {
    const yearValue = this.refYear.current!.value
    const newState: DatePopupState = {
      year:          yearValue === '' ? yearValue : String(parseYear(yearValue)),
      month:         this.refMonth.current!.value,
      day:           this.refDay.current!.value,
      hour:          this.refHour.current?.value || '',
      minute:        this.refMinute.current?.value || '',
      second:        this.refSecond.current?.value || '',
      current:       false,
    }

    const id = e.currentTarget.dataset.id
    if (id === 'year') {
      newState.interactingYear = true
    }

    this.setState(newState)
  }

  currentHandler = () => {
    this.setState({ current: !this.state.current })
  }

  approximatelyHandler = () => {
    this.setState({ isApproximateDate: !this.state.isApproximateDate })
  }

  onKeyDown = (e: React.KeyboardEvent) => {
    const enterKey = e.keyCode === 13
    const escKey = e.keyCode === 27

    if (escKey) {
      this.props.cancelHandler()
      return
    }

    if (enterKey) {
      this.dateAcceptHandler()
    }
  }

  toTitleCase = (input: string) => {
    return input.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
  }

  render() {
    const { dataKey, structure } = this.props
    const { year, month, day, hour, minute, second, current, isApproximateDate, interactingYear } = this.state

    const { showHMS } = structure
    const disabled = current

    const id = ('' + dataKey).replace('.', '-')
    const htmlFor = `input-${id}`

    let dayLabel = 'Day'
    const dayOptions = [
      <option value="0" key="0">
        -
      </option>,
    ]
    let d = 1
    while (d < 32) {
      const dd = `0${d}`.substr(-2)
      if (d === parseInt(day)) {
        dayLabel = dd
      }
      dayOptions.push(
        <option value={d} key={d}>
          {dd}
        </option>
      )
      d += 1
    }

    let monthLabel = 'Month'
    const months = [
      ['01 - Jan.', 1],
      ['02 - Feb.', 2],
      ['03 - Mar.', 3],
      ['04 - Apr.', 4],
      ['05 - May', 5],
      ['06 - June', 6],
      ['07 - Jul.', 7],
      ['08 - Aug.', 8],
      ['09 - Sept.', 9],
      ['10 - Oct.', 10],
      ['11 - Nov.', 11],
      ['12 - Dec.', 12],
    ]
    const monthOptions = [
      <option value="0" key="0">
        -
      </option>,
    ].concat(
      months.map(m => {
        if (m[1] === parseInt(month)) {
          monthLabel = (m[0] as string).split('-')[1].trim()
        }
        return (
          <option value={m[1]} key={m[1]}>
            {m[0]}
          </option>
        )
      })
    )

    // let currentCheckbox
    // if (structure?.currentCheckbox || disabled) {
    //   currentCheckbox = (
    //     <section className="ui-checkbox date-control-current">
    //       <input
    //         type="checkbox"
    //         checked={current}
    //         onChange={this.currentHandler}
    //         title="Event is still current this year"
    //         id="current"
    //       />
    //       <label htmlFor="current" title="Event is still current this year">Current</label>
    //     </section>
    //   )
    // }

    let approximatelyCheckbox
    if (structure?.showApproximateCheckbox) {
      approximatelyCheckbox = (
        <section className="ui-checkbox date-control-approximately">
          <input
            type="checkbox"
            checked={isApproximateDate || false}
            onChange={this.approximatelyHandler}
            title="Exact data is not known"
            id="approximately"
          />
          <label htmlFor="approximately" title="Exact data is not known">
            Approximately
          </label>
        </section>
      )
    }

    const dayInput = (
      <section className="ui-select ui-date-day">
        <select
          onChange={this.onChange}
          id={htmlFor + '-day'}
          ref={this.refDay}
          value={disabled || !day ? '0' : day}
          disabled={disabled}
          data-cy="date-popup-day"
        >
          <optgroup label="Day">{dayOptions}</optgroup>
        </select>
        <label htmlFor={htmlFor + '-day'} className="ui-label">
          {dayLabel}
        </label>
      </section>
    )

    const monthInput = (
      <section className="ui-select ui-date-month">
        <select
          onChange={this.onChange}
          id={htmlFor + '-month'}
          ref={this.refMonth}
          value={disabled || !month ? '0' : month}
          disabled={disabled}
          data-cy="date-popup-month"
        >
          <optgroup label="Month">{monthOptions}</optgroup>
        </select>
        <label htmlFor={htmlFor + '-month'} className="ui-label">
          {monthLabel}
        </label>
      </section>
    )

    const otherInputs = [
      {
        id:     'year',
        show:   true,
        onBlur: this.yearBlurHandler,
        props:  {
          defaultValue: interactingYear ? year : formatYear(year),
          title:        'Year formats include: AD 120, 3200BC, 1.2 ka, 10 Ma, 13.8 Ba',
        },
      },
      {
        id:    'hour',
        show:  showHMS,
        props: {
          defaultValue: hour,
          title:        '00-23',
        },
      },
      {
        id:    'minute',
        show:  showHMS,
        props: {
          defaultValue: minute,
          title:        '00-59',
        },
      },
      {
        id:    'second',
        show:  showHMS,
        props: {
          value: second,
          title: '00-59',
        },
      },
    ].map((input, idx) => {
      if (input.show) {
        return (
          <section key={`s-k-${idx}`} className={`ui-input ui-date-${input.id}`}>
            <input
              // eslint-disable-next-line
              // @ts-ignore
              ref={this[`ref${this.toTitleCase(input.id)}`]}
              placeholder={this.toTitleCase(input.id)}
              data-id={input.id}
              type="text"
              disabled={disabled}
              onChange={this.onChange}
              onBlur={input.onBlur}
              data-cy={`date-popup-${input.id}`}
              {...input.props}
            />
          </section>
        )
      }
    })

    return (
      <div className="date-popup" data-cy="date-popup" tabIndex={1} onKeyDown={this.onKeyDown}>
        <div className="date-popup-inputs">
          {dayInput}
          {monthInput}
          {otherInputs}
          <i className="material-icons md-18 reset-button" onClick={this.resetHandler}>
            close
          </i>
        </div>
        {/* {currentCheckbox} */}
        {approximatelyCheckbox}
        <ActionButtons
          acceptHandler={this.dateAcceptHandler}
          cancelHandler={this.props.cancelHandler}
          acceptLabel="Save"
          cancelLabel="Cancel"
        />
      </div>
    )
  }

}
