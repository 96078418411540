import { History } from 'history'
import React from 'react'
import nl2br from 'react-nl2br'

export function formatMessage(message: string, history: History) {
  const text = nl2br(message.trim())
  const host = window.location.hostname
  // eslint-disable-next-line
  const url = new RegExp('(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})')
  const formatter = text.map((line, index) => {
    if (typeof line !== 'string') {
      return line
    }
    return line.split(' ').map((word, i) => {
      if (url.test(word)) {
        const link = word.includes('http') ? word : 'http://' + word
        if (link.includes(host)) {
          return (
            <span className="message-text" key={`${link}-${i}`}>
              <a className="message-link" onClick={() => history.push(link.split('#')[1])}>
                {link.split('#')[1]}
              </a>
            </span>
          )
        }
        return (
          <span className="message-text" key={`${word}-${i}`}>
            <a
              className="message-link"
              target="_blank"
              rel="noopener noreferrer"
              href={link.replace(/,/g, '')}
            >
              {word}
            </a>&nbsp;
          </span>
        )
      }
      return <span className="message-text" key={`${word}-${index}-${i}`}>{word}&nbsp;</span>
    })
  })
  return formatter
}
