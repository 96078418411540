import { gql, useQuery } from '@apollo/client'
import { allMessagesQuery, onNewMessageSubscription, onNewMessageSubscriptionVariables } from 'cl-studio'
import React from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { unreadMessage as unreadMessageAction } from '../../../../components/messaging/state/actions'

const connector = connect(
  (state: any) => ({
    user: state.user,
  }),
  { addUnreadMessage: unreadMessageAction }
)
type PropsFromRedux = ConnectedProps<typeof connector>

const MessageSubscription: React.FC<PropsFromRedux> = (props) => {
  const { user, children, addUnreadMessage } = props
  const { data, subscribeToMore } = useQuery<allMessagesQuery>(ALL_MESSAGES)

  return React.cloneElement(children as React.ReactElement<any>, {
    data,
    subscribeToMessageUpdates: () =>
      subscribeToMore<onNewMessageSubscription, onNewMessageSubscriptionVariables>({
        onError:     console.error,
        document:    MESSAGE_SUBSCRIPTION,
        variables:   { groupIds: [user.profile.id] },
        updateQuery: (prev, { subscriptionData: { data: { messageAdded } } }) => {
          if (!messageAdded) {
            return prev
          }

          addUnreadMessage(
            messageAdded.from_user_id,
            messageAdded.name
          )

          return { allMessages: [ messageAdded, ...prev.allMessages ] }
        },
      }),
  })
}

export default React.memo(connector(MessageSubscription))

const MESSAGE_SUBSCRIPTION = gql`
  subscription onNewMessage($groupIds: [Int]){
    messageAdded(groupIds: $groupIds){
      id
      name
      from_user_id
      timestamp
      to_user_id
      text
    }
  }
`

export const ALL_MESSAGES = gql`
  query allMessages {
    allMessages {
      id
      name
      from_user_id
      timestamp
      to_user_id
      text
    }
  }
`
