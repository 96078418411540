export function titleFields(template) {
  const { fields } = template
  let ids = fields
    .filter(f => f.title)
    .map(f => f.id)

  if (!ids.length) {
    ids = fields
      .filter(f => f.type === 'title')
      .map(f => f.id)
  }

  if (!ids.length) {
    ids = fields
      .filter(f => /text|name/.test(f.type))
      .map(f => f.id)
  }

  if (!ids.length) {
    ids = [fields[0].id]
  }

  return ids
}
