import { settings } from '@cls/config'
import { checkHttpStatus } from '@cls/utils/fetch'
import fetch from 'isomorphic-fetch'

import * as types from './action-types'
import { login } from './actions-login'

export function signUp(params = {}) {
  return (dispatch: any) => {
    dispatch(signupRequest())

    const url = `${settings.apiServer}/secret/signup`
    const options = {
      method:  'post',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body:    JSON.stringify(params),
    }

    return fetch(url, options)
      .then(checkHttpStatus)
      .then(res => {
        if (res && res.status === 200) {
          dispatch(signupSuccess())
          dispatch(login(params))
        } else {
          dispatch(signupFailure())
          return
        }
      })
      .catch(err => {
        dispatch(signupFailure(err))
        return
      })
  }
}

export function signupRequest() {
  return { type: types.SIGNUP_REQUEST }
}

export function signupSuccess() {
  return { type: types.SIGNUP_SUCCESS }
}

export function signupFailure(error?: any) {
  const errorText =
    (error && error.response && error.response.statusText) || 'Server problems! Try again...'

  return { type: types.SIGNUP_FAILURE, data: errorText }
}
