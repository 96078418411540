import { settings } from '@cls/config'

function routeIsCloseEnoughToPoints(route, tolerance = 1000 /* m */) {
  const [fromStart, toEnd] = route

  return fromStart?.distance < tolerance && toEnd?.distance < tolerance
}


export function getRoadRoute(coords: any) {
  const cs = coords.join(';')
  const query = {
    geometries:   'geojson',
    overview:     'simplified',
    access_token: settings.mapboxToken,
  }
  const qs = Object.entries(query)
    .map(tuple => tuple.map(encodeURIComponent).join('='))
    .join('&')

  return fetch(
    `https://api.mapbox.com/directions/v5/mapbox/driving/${cs}.json?${qs}`
  )
    .then((res) => {
      if (res.ok) {
        return res.json()
      }
      throw res
    })
    .then(({ routes, waypoints }) => {
      if (!routes || !routes.length) {
        return coords
      }

      if (routeIsCloseEnoughToPoints(waypoints)) {
        return routes[0]?.geometry.coordinates
      } else {
        return coords
      }
    })
    .catch(() => {
      return coords
    })
}

export function prepareLng(lng: number, backwardLng: number, forwardLng: number) {
  const d1a = Math.abs(backwardLng - lng)
  const d1b = Math.abs(forwardLng - lng)
  const d1 = d1a + d1b

  const d2a = Math.abs(backwardLng - (lng + 360))
  const d2b = Math.abs(forwardLng - (lng + 360))
  const d2 = d2a + d2b

  const d3a = Math.abs(backwardLng - (lng - 360))
  const d3b = Math.abs(forwardLng - (lng - 360))
  const d3 = d3a + d3b

  switch (Math.min(d1, d2, d3)) {

    case d1:
      return lng
    case d2:
      return lng + 360
    case d3:
      return lng - 360

  }
}
