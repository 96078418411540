import '../styles/Checkbox.css'

import React, { PureComponent } from 'react'

type OwnProps = {
  structure: any,
  className: string,
  dataKey?: string,
  value: string | boolean,
  inline?: boolean,
  updateHandler: (...args: Array<any>) => any,
  postRender?: (...args: Array<any>) => any,
};

type Props = OwnProps & typeof Checkbox.defaultProps;

export default class Checkbox extends PureComponent<Props> {

  static defaultProps = {
    className: 'ui-i ui-field ui-checkbox',
  }

  onChange = event => {
    const { dataKey, updateHandler } = this.props
    const val = event.target.checked

    if (updateHandler) {
      updateHandler(dataKey, val)
    }
  }

  render() {
    const { className, structure, dataKey, value, inline, postRender } = this.props

    const id = ('' + (dataKey || 'generic')).replace(/[.~@!]/g, '-')
    const htmlFor = `input-${id}`
    let _className = `${className} field-${id}`

    if (inline || structure.inline) {
      _className += ' inline'
    }

    const style: React.CSSProperties = {}
    if (structure.width) {
      style.width = structure.width
    }

    let opts = {}
    if (structure.options) {
      opts = structure.options
    }

    if (structure.className) {
      _className += ` ${structure.className}`
    }

    const input = (
      <input type="checkbox" onChange={this.onChange} id={htmlFor} checked={!!value} {...opts} />
    )

    let labelText = structure.label || ' '
    if (structure.hideLabel) {
      labelText = ' '
      _className += ' no-label'
    }
    const label = (
      <label htmlFor={htmlFor} className="ui-label">
        {labelText}
      </label>
    )

    const output = (
      <section className={_className} style={style} key={htmlFor}>
        {input}
        {label}
      </section>
    )

    return postRender ? postRender(structure, output) : output
  }

}
