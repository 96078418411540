import { settings } from '@cls/config'
import Logo from '@cls/Logo'
import React, { PureComponent } from 'react'

import { signUp } from '../state/actions-signup'

type Props = {
  dispatch: (...args: Array<any>) => any,
  signupPending: boolean,
  signupError?: string,
};

type State = any;

export default class SignupForm extends PureComponent<Props, State> {

  refEmail: any;
  refName: any;
  refPassword1: any;
  refPassword2: any;

  constructor(props: Props) {
    super(props)
    this.state = { error: '', signingUp: false }
  }

  onSubmit = (event: any) => {
    const { dispatch } = this.props
    event.preventDefault()

    dispatch(
      signUp({
        email:            this.refEmail.value,
        name:             this.refName.value,
        password:         this.refPassword1.value,
        confirm_password: this.refPassword2.value,
      })
    )
  }

  validateInput = () => {
    if (this.refName.value.length < 3 || this.refName.value.length > 20) {
      this.refName.setCustomValidity('Please have the name between 3 and 20 characters')
      return
    } else {
      this.refName.setCustomValidity('')
    }

    if (this.refPassword1.value.length < 5 || this.refPassword1.value.length > 30) {
      this.refPassword1.setCustomValidity(
        'Please keep the password length between 6 and 30 characters'
      )
      return
    } else {
      this.refPassword1.setCustomValidity('')
    }

    if (this.refPassword1.value !== this.refPassword2.value) {
      this.refPassword2.setCustomValidity("Passwords don't match")
      return
    } else {
      this.refPassword2.setCustomValidity('')
    }
  }

  setRefEmail = (el: any) => this.refEmail = el
  setRefName = (el: any) => this.refName = el
  setRefPassword1 = (el: any) => this.refPassword1 = el
  setRefPassword2 = (el: any) => this.refPassword2 = el

  render() {
    const { signupPending, signupError } = this.props
    const signupURL = `${settings.apiServer}/signup`
    let errorMsg, signupButton

    if (signupError) {
      errorMsg = (
        <p className="form-error">
          {signupError}
        </p>
      )
    }

    if (signupPending) {
      signupButton = (
        <button type="submit" className="btn btn-primary login-button" disabled>
          Creating the account...
        </button>
      )
    } else {
      signupButton = (
        <button type="submit" className="btn btn-primary login-button">
          Sign up
        </button>
      )
    }

    return (
      <form
        method="post"
        action={signupURL}
        className="form login-form center"
        onSubmit={this.onSubmit}
      >
        <Logo />
        {errorMsg}
        <fieldset className="fieldset">
          <p className="form-row">
            <label htmlFor="signup-email">Email</label>
            <input
              type="email"
              placeholder="Email"
              name="email"
              id="signup-email"
              className="input input-email block"
              ref={this.setRefEmail}
              required
            />
          </p>

          <p className="form-row">
            <label htmlFor="signup-name">Email</label>
            <input
              type="text"
              placeholder="Display name"
              name="text"
              id="signup-name"
              className="input input-email block"
              ref={this.setRefName}
              onChange={this.validateInput}
              required
            />
          </p>

          <p className="form-row">
            <label htmlFor="signup-password">Password</label>
            <input
              type="password"
              placeholder="Password"
              name="password"
              id="signup-password"
              className="input input-password block"
              ref={this.setRefPassword1}
              onChange={this.validateInput}
              required
            />
          </p>

          <p className="form-row">
            <label htmlFor="signup-confirm-password">Password confirmation</label>
            <input
              type="password"
              placeholder="Password confirmation"
              name="password"
              id="signup-confirm-password"
              className="input input-password block"
              ref={this.setRefPassword2}
              onChange={this.validateInput}
              required
            />
          </p>
        </fieldset>

        <fieldset className="fieldset">
          {signupButton}
        </fieldset>
      </form>
    )
  }

}
