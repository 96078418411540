import '../styles/ColorSwatches.css'

import { rgbaString } from '@kpv-lab/color-utils'
import React, { PureComponent } from 'react'

import ColorDot from './ColorDot'

type OwnProps = {
  dispatch: (...args: Array<any>) => any,
  swatches: Array<Array<number>>,
  swatchCount: number,
  saveSwatch: (...args: Array<any>) => any,
};

type Props = OwnProps & typeof ColorSwatches.defaultProps;

export default class ColorSwatches extends PureComponent<Props> {

  static defaultProps = {
    number: 26,
  }

  render() {
    const { dispatch, swatches, saveSwatch, swatchCount } = this.props

    const dots: Array<JSX.Element> = []
    let idx = 0
    while (idx < swatchCount) {
      const c = swatches[idx]
      const value = c ? rgbaString([c[0], c[1], c[2]], 1) : ''
      const hint = c ? rgbaString([c[0], c[1], c[2]], c[3]) : ''
      const alpha = c ? c[3] : 1

      dots.push(
        <ColorDot
          dispatch={dispatch}
          saveSwatch={saveSwatch}
          idx={idx.toString()}
          key={idx}
          value={value}
          alpha={alpha}
          hint={hint}
        />
      )
      idx++
    }

    return <section className="color-swatches">{dots}</section>
  }

}
