import { History } from 'history'
import React, { useEffect }  from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { logoutUser as logoutUserAction } from './state/actions-login'

const connector = connect(undefined, { logoutUser: logoutUserAction })

type PropsFromRedux = ConnectedProps<typeof connector>

const LoginView: React.FC<PropsFromRedux & { history: History }> = ({ logoutUser, history }) => {
  useEffect(() => {
    logoutUser()
    history.replace('/login')
  }, [])
  return null
}

export default connector(LoginView)
