export type Path = string | Array<string | number>

export function objectPath(path: Path): Array<string | number> {
  return (typeof path === 'string' ? path.split('.') : path)
    .filter(k => k !== '' && k !== undefined)
    .map(k => isNumericString(k) ? parseInt(k) : k)
}

function isNumericString(value: string | number): value is string {
  return typeof value === 'string' && isFinite(Number(value))
}

export { getIn } from './get-in'
export { setIn } from './set-in'
export { hasIn } from './has-in'
export { deleteIn } from './delete-in'
