import { gql, useQuery } from '@apollo/client'
import { activeUsersQuery, onNewUserUpdateSubscription } from 'cl-studio'
import { useMemo } from 'react'

export const EDIT_SUBSCRIPTION = gql`
  subscription onNewUserUpdate {
    userUpdate {
      id
      url
      name
      editing
      lastActive
    }
  }
`

export const USERS = gql`
  query activeUsers {
    activeUsers {
      id
      url
      name
      editing
      lastActive
    }
    allUsers {
      id
      name
      lastActive
    }
  }
`

export const useLatestUsers = () => {
  const { loading, data, subscribeToMore } = useQuery<activeUsersQuery>(USERS)
  const subscribeToUpdates = useMemo(() => {
    if (!subscribeToMore) {
      return () => () => null
    }
    return () =>
      subscribeToMore<onNewUserUpdateSubscription>({
        onError:     console.error,
        document:    EDIT_SUBSCRIPTION,
        updateQuery: (prev, { subscriptionData: { data: userData } }) => {
          if (!userData) {
            return prev
          }
          return { ...prev, activeUsers: userData.userUpdate }
        },
      })
  }, [subscribeToMore])

  return { loading, data, subscribeToUpdates }
}

