import MarkdownIt from 'markdown-it'
import { defaultMarkdownParser, MarkdownParser } from 'prosemirror-markdown'

import { useParagraph } from './paragraph'
import { markdownSchema } from './schema'
import { useTag } from './tag-link'
import { getMediaIdFromVideoUrl } from './utils'

const md = new MarkdownIt('commonmark', { html: false })
md.disable('link')
  .use(useTag)
  .disable('paragraph')
  .use(useParagraph)

export { md }

// Now that we have definitions from parser, link attributes to prosemirror land objects
export const markdownParser = new MarkdownParser(markdownSchema, md, {
  ...defaultMarkdownParser.tokens,
  image: {
    node:     'image',
    getAttrs: tok => {
      return {
        align:   tok.attrGet('src').split('##')[1],
        src:     tok.attrGet('src').split('##')[0],
        title:   tok.attrGet('title') || null,
        // https://stackoverflow.com/a/36381427
        // The filename is the ID in this case so we're grabbing it so we can use it in the JSON
        mediaId: tok.attrGet('src').match(/[^/?#]+(?=$|[?#])/)[0].split('.')[0],
        alt:     (tok.children[0] && tok.children[0].content) || null,
      }
    },
  },
  paragraph: {
    block:    'paragraph',
    getAttrs: tok => {
      return {
        align: tok.attrGet('align'),
      }
    },
  },
  tag: {
    mark:     'tag',
    getAttrs: tok => {
      return {
        href: tok.attrGet('href'),
      }
    },
  },
  itemlink: {
    mark:     'itemlink',
    getAttrs: tok => {
      return {
        href: tok.attrGet('href'),
      }
    },
  },
  info: {
    node:     'info',
    getAttrs: tok => {
      return {
        href: tok.attrGet('href'),
      }
    },
  },
  reference: {
    node:     'reference',
    getAttrs: tok => {
      return {
        href: tok.attrGet('href'),
      }
    },
  },
  audio: {
    node:     'audio',
    getAttrs: tok => {
      return {
        src:     tok.attrGet('src'),
        mediaId: tok.attrGet('src').match(/[^/?#]+(?=$|[?#])/)[0].split('.')[0],
      }
    },
  },
  video: {
    node:     'video',
    getAttrs: tok => {
      return {
        src:     tok.attrGet('src'),
        mediaId: getMediaIdFromVideoUrl(tok.attrGet('src')),
      }
    },
  },
})
