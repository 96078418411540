import { gql } from '@apollo/client'
import { Mutation, Query } from '@apollo/client/react/components'
import { DraggablePanel } from '@kpv-lab/ui'
import {
  closeChat,
  closeChatWindow,
  setActiveChat,
  setChatWindowSize
} from 'cls/components/messaging/state/actions'
import PropTypes from 'prop-types'
import React, { Fragment, PureComponent } from 'react'
import { connect } from 'react-redux'
import Textarea from 'react-textarea-autosize'

import { ALL_MESSAGES } from '../../views/dashboard/components/Messages/MessageSubscription'
import MessageList from './MessageList'

export class MessageView extends PureComponent {

  static propTypes = {
    openChats:        PropTypes.array,
    dispatch:         PropTypes.func,
    user:             PropTypes.object,
    history:          PropTypes.object,
    activeChat:       PropTypes.number,
    windowDimensions: PropTypes.object,
    unreadMessages:   PropTypes.array,
    windowOpen:       PropTypes.bool,
  }

  state = {
    error:   '',
    message: null,
  }

  chatWindow = null;

  componentWillUnmount() {
    this.chatWindow = null
  }

  setInputRef = (ref) => (this.inputRef = ref);
  setDragPanelRef = (ref) => (this.dragPanelRef = ref);

  initResize = () => {
    this.chatWindow = document.getElementsByClassName('chat-panel')[0]
    window.addEventListener('mousemove', this.resize, false)
    window.addEventListener('mouseup', this.stopResize, false)
  }

  resize = (e) => {
    this.newWidth = e.clientX - this.chatWindow.offsetLeft
    this.newHeight = e.clientY - this.chatWindow.offsetTop
    if (this.newWidth >= 250) {
      this.chatWindow.style.width = this.newWidth + 'px'
    }
    if (this.newHeight >= 320) {
      this.chatWindow.style.height = this.newHeight + 'px'
    }
  }

  stopResize = () => {
    window.removeEventListener('mousemove', this.resize, false)
    window.removeEventListener('mouseup', this.stopResize, false)
    if (this.newWidth >= 250 && this.newHeight >= 320) {
      this.props.dispatch(
        setChatWindowSize(this.newWidth, this.newHeight)
      )
    }
  }

  messageInput = (e) => {
    function clearInput(target) {
      target.value = ''
    }
    const boundFunction = clearInput.bind(this, e.target)
    if (
      e.key === 'Enter' &&
             !e.shiftKey &&
             e.target.value.trim().length
    ) {
      e.preventDefault()
      this.setState(
        {
          error:   '',
          message: e.target.value,
        },
        () => {
          this.addMessage()
          boundFunction()
        }
      )
    }
  }

  setActiveChat = (toUserId) => {
    this.props.dispatch(setActiveChat(toUserId))
  }

  render() {
    const {
      openChats,
      windowDimensions,
      windowOpen,
      activeChat,
      dispatch,
      user,
      history,
      unreadMessages,
    } = this.props
    if (!windowOpen) {
      return null
    }
    return (
      <DraggablePanel
        ref={this.setDragPanelRef}
        name={'chatPanel'}
        width={windowDimensions.width}
        height={windowDimensions.height}
        className={'chat-panel ui-floating-panel message-viewer'}
      >
        <h2 className="ui-title draggable">Chat</h2>
        <i
          className="material-icons ui-close-btn"
          onClick={(e) => {
            e.stopPropagation()
            dispatch(closeChatWindow())
          }}
        >
          close
        </i>
        <div className="tabs">
          {openChats.map((chat) => {
            const active = chat.chatId === activeChat ? 'active' : ''
            const unread =
                     unreadMessages.includes(chat.chatId) &&
                     chat.chatId !== activeChat
                       ? 'unread'
                       : ''
            return (
              <div
                className={`message-tab ${active}`}
                onClick={() => this.setActiveChat(chat.chatId)}
                key={chat.chatId}
              >
                <div className={`username ${unread}`}>
                  {chat.username}
                  <span className="notification" />
                </div>

                <i
                  className="material-icons ui-close-btn close"
                  onClick={(e) => {
                    e.stopPropagation()
                    dispatch(closeChat(chat.chatId))
                  }}
                >
                         close
                </i>
              </div>
            )
          })}
        </div>
        <div className="message-container-content">
          {activeChat !== -1 && (
            <Fragment>
              <section className="ui-panel scrollable message-container">
                <Query query={ALL_MESSAGES} fetchPolicy="cache">
                  {({ loading, data: { allMessages } }) => {
                    if (loading || !allMessages) {
                      return <div />
                    }
                    const messages = allMessages.filter(
                      (m) =>
                        [user.profile.id, activeChat].includes(
                          m.to_user_id
                        ) &&
                               [user.profile.id, activeChat].includes(
                                 m.from_user_id
                               )
                    )
                    return (
                      <MessageList
                        history={history}
                        chatId={activeChat}
                        chatMessages={messages}
                      />
                    )
                  }}
                </Query>
              </section>
              <section className="ui-panel message-input-container">
                <Mutation
                  mutation={ADD_MESSAGE}
                  variables={{
                    toUserId: Number(activeChat),
                    text:     this.state.message,
                  }}
                  optimisticResponse={{
                    __typename: 'Mutation',
                    addMessage: {
                      __typename:   'Message',
                      name:         'XX',
                      from_user_id: user.profile.id,
                      to_user_id:   Number(activeChat),
                      timestamp:    Date.now(),
                      id:           Math.random(),
                      text:         this.state.message,
                    },
                  }}
                  onError={(error) => {
                    this.setState({ error: error.message })
                    this.inputRef.value = this.state.message
                  }}
                  update={(cache, { data: { addMessage } }) => {
                    const oldData = cache.readQuery({
                      query: ALL_MESSAGES,
                    })
                    cache.writeQuery({
                      query: ALL_MESSAGES,
                      data:  {
                        allMessages: [
                          addMessage,
                          ...oldData.allMessages,
                        ],
                      },
                    })
                  }}
                >
                  {(addMessage) => {
                    this.addMessage = addMessage
                    return (
                      <Fragment>
                        {this.state.error && (
                          <div className="message-error">
                            {this.state.error}
                          </div>
                        )}
                        <Textarea
                          style={{ maxRows: 10 }}
                          rows={2}
                          ref={this.setInputRef}
                          className="message-input"
                          placeholder={'Message'}
                          onKeyPress={(e) =>
                            this.messageInput(e, activeChat)
                          }
                        />
                      </Fragment>
                    )
                  }}
                </Mutation>
              </section>
            </Fragment>
          )}
        </div>
        <div
          onMouseDown={() => this.initResize()}
          className="resize-handle"
        />
      </DraggablePanel>
    )
  }

}

const mapStateToProps = ({ user, messages }) => {
  return {
    user,
    openChats:        messages.openChats,
    activeChat:       messages.activeChat,
    windowOpen:       messages.windowOpen,
    unreadMessages:   messages.unreadMessages,
    windowDimensions: messages.windowDimensions,
  }
}

export default connect(mapStateToProps)(MessageView)

const ADD_MESSAGE = gql`
  mutation addMessage($toUserId: Int!, $text: String) {
    addMessage(toUserId: $toUserId, text: $text) {
      id
      name
      from_user_id
      to_user_id
      text
      timestamp
    }
  }
`
