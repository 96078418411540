import '../styles/Panel.css'

import ScrollPanel from '@kpv-lab/scrollable-panel'
import React, { PureComponent } from 'react'

import { getIn, hasIn } from '../utils/get-set-in'
import Button from './Button'
import Checkbox from './Checkbox'
import ColorSwatch from './ColorSwatch'
import Divider from './Divider'
import Header from './Header'
import HTMLPanel from './HTMLPanel'
import ImageSwatch from './ImageSwatch'
import Input from './Input'
import NumberControl from './NumberControl'
import Radio from './Radio'
import Select from './Select'
import TabColumn from './TabColumn'
import TabRow from './TabRow'
import TabSelect from './TabSelect'

const Tabs = {
  row:    TabRow,
  column: TabColumn,
  select: TabSelect,
}

const defaultComponents = {
  float:    NumberControl,
  vec2:     NumberControl,
  vec3:     NumberControl,
  vec4:     NumberControl,
  int:      NumberControl,
  ivec2:    NumberControl,
  ivec3:    NumberControl,
  ivec4:    NumberControl,
  rotation: NumberControl,
  tabs:     Tabs,
  color:    ColorSwatch,
  bool:     Checkbox,
  checkbox: Checkbox,
  select:   Select,
  radio:    Radio,
  image:    ImageSwatch,
  html:     HTMLPanel,
  header:   Header,
  text:     Input,
  textarea: Input,
  number:   Input,
  param:    Input,
  email:    Input,
  tel:      Input,
  url:      Input,
  divider:  Divider,
  button:   Button,
}

export function createUIComponents(mainProps, className = '') {
  const {
    dispatch,
    structure,
    data,
    components,
    uiPath,
    changePanel,
    depth,
    tabbed,
    scope,
    keyPath,
    categories,
    user,
    listIdx,
    s3Upload,
  } = mainProps

  const tabsType = structure.tabs
  let hasPanel = false
  let childClassName = className

  let tabBar, childComponents
  const items = structure.children || structure.item
  if (items && items.length) {
    let activeChild = -1

    if (Tabs[tabsType]) {
      activeChild =
        structure.activeChild ||
        structure.children.findIndex(c => c.type === 'panel') ||
        0

      if (tabbed !== 'column') {
        tabBar = React.createElement(Tabs[tabsType], {
          dispatch,
          structure,
          uiPath,
          depth,
          changePanel,
          scope,
          activeTab: activeChild,
          key:       `tabs-${depth}`,
        })
      }
      childClassName += ' ui-tabbed-' + tabsType
    }


    if (structure.scrollable) {
      childClassName += ' scrollable'
    }


    // actual components
    childComponents = []
    let tabBarAdded = false
    const propTypes = [
      'data',
      'dataPath',
      'updateHandler',
      'postRender',
      'components',
      'updateUI',
      'customNav',
    ]

    const timelineIds =
      data && data.timeline ? activeTimelineControls(data.timeline) : {}

    items.forEach((child, idx) => {
      const type = child.type
      const _uiPath = uiPath ? `${uiPath}.children.${idx}` : `children.${idx}`

      // Add the tab bar in the right place in case we have non-panel components first
      if (tabBar && !tabBarAdded && type === 'panel') {
        childComponents.push(tabBar)
        tabBarAdded = true
      }

      const _id = (data && (data._id || data.id)) || ''
      const dataKey = listIdx !== undefined ? `${listIdx}.${child.id}` : child.id
      const dataPath = dataKey && dataKey.split('.')
      let value = data && dataPath && getIn(data, dataPath)
      const id = dataPath && dataPath[dataPath.length - 1]
      const comp = (components && components[type]) || defaultComponents[type]
      let params

      if (dataKey && !/panel|image|video/.test(type) && typeof value === 'undefined') {
        // fix for missing parameter in the data / videos and images handle this using getDefaultProps to create an object
        value = child.value || ''
      }

      if (comp) {
        let props: any = {}

        if (type === 'panel') {
          hasPanel = true
          props = {
            changePanel: changePanel,
            active:      activeChild === idx,
            tabbed:      tabsType,
            categories:  categories,
            keyPath:     keyPath,
            dataPath:    dataPath,
          }

          if (structure.activeList) {
            props.activeList = structure.activeList
          }
        }

        props.dispatch = dispatch

        // Redux connected comps don't expose their propTypes property so we explicity
        // define that in the ui-structure config and then assign those props that match
        let compProps, applyProps
        if (child.propTypes) {
          applyProps = child.propTypes
          compProps = applyProps.reduce((o, k) => {
            o[k] = true
            return o
          }, {})
        } else {
          compProps = comp.propTypes || {}
          applyProps = propTypes
        }

        applyProps.forEach(prop => {
          if (typeof compProps[prop] !== 'undefined') {
            props[prop] = mainProps[prop]
          }
        })

        if (typeof compProps.structure !== 'undefined') {
          props.structure = child
        }

        if (typeof compProps.id !== 'undefined') {
          props.id = dataKey
        }

        if (_id) {
          props.itemId = _id
        }

        if (keyPath && props.id) {
          props.id = `${keyPath}${props.id}`
        }

        if (timelineIds[id]) {
          props.keyframeEnabled = timelineIds[id]
        }

        if (typeof compProps.type !== 'undefined') {
          props.type = type
        }

        if (typeof compProps.uiPath !== 'undefined') {
          props.uiPath = _uiPath
        }

        if (typeof compProps.dataKey !== 'undefined') {
          props.dataKey = dataKey || _id
        }

        if (typeof compProps.active !== 'undefined') {
          props.active = activeChild === idx
        }

        if (typeof compProps.depth !== 'undefined') {
          props.depth = depth + 1
        }

        if (typeof compProps.scope !== 'undefined') {
          props.scope = scope
        }

        if (typeof compProps.value !== 'undefined') {
          props.value = value
        }

        if (type === 'category') {
          props.categories = categories
        }

        if (typeof compProps.user !== 'undefined') {
          props.user = user
        }

        if (typeof compProps.s3Upload !== 'undefined') {
          props.s3Upload = s3Upload
        }

        props.key = `${depth}-${idx}`

        // special case for alpha values with colors
        if (getIn(child, ['options', 'alphaKey'])) {
          const alpha = data
            ? getIn(data, (getIn(child, ['options', 'alphaKey']) as string).split('.'))
            : getIn(child, ['options', 'alpha'])
          props.alphaValue = alpha
        }

        let showComp = true
        if (
          child.hidden ||
          (child.active === false && !child.system) ||
          (type === 'panel' && props.active === false)
        ) {
          showComp = false
        }

        // condition check
        const condition = child.condition
        if (showComp && data && condition) {
          if (typeof condition === 'function') {
            // function condition
            params = params || (data && data.params)
            showComp = condition(params)
          } else {
            // object condition
            const condVal = `${condition.value}`.split('|')
            const show = condition.action === 'show'
            const path = `${condition.target}`.split('.')

            if (data && hasIn(data, path)) {
              const tgtVal = (getIn(data, path) as string || '').toString()
              if (condVal.includes(tgtVal)) {
                showComp = show

              } else {
                showComp = !show

              }
            } else {
              // console.warn('no condition path:', path)
              showComp = false
            }
          }
        }

        if (showComp) {
          // add dividers if required before and after the actual component
          const divider = !comp.dividerSupport && child.divider
          if (divider === -1 || divider === 2) {
            childComponents.push(<Divider key={`${props.key}-bd`} />)
          }

          childComponents.push(React.createElement(comp, props))

          if (divider > 0) {
            childComponents.push(<Divider key={`${props.key}-ad`} />)
          }
        }
      } else {
        console.warn('no component for:', type, components)
      }
    })
  }
  /* eslint-enable complexity, max-depth */

  return { childComponents, hasPanel, childClassName }
}

export function activeTimelineControls(timeline) {
  const ids = {}
  const sequences = timeline.sequences
  sequences && sequences.forEach(seq => {
    seq.params.forEach(param => {
      const [id, idx] = param.id.split('.')
      if (idx !== undefined) {
        // update multipart id index
        ids[id] = ids[id] || []
        ids[id][parseInt(idx)] = true
      } else {
        ids[id] = true
      }
    })
  })

  return ids
}

type OwnProps = {
  active?: boolean,
  activeList?: string,
  categories?: any,
  changePanel?: (...args: Array<any>) => any,
  components?: any,
  data?: any,
  dataPath?: string,
  depth?: number,
  dispatch?: (...args: Array<any>) => any,
  dropping?: boolean,
  keyPath?: string,
  postRender?: (...args: Array<any>) => any,
  s3Upload?: any,
  scope?: string,
  structure?: any,
  tabbed?: string,
  uiPath?: string,
  updateHandler?: (...args: Array<any>) => any,
  updateUI?: (...args: Array<any>) => any,
  user?: any,
};

type Props = OwnProps & typeof Panel.defaultProps;

export default class Panel extends PureComponent<Props> {

  /* eslint-enable */

  static defaultProps = {
    tabbed:  '',
    uiPath:  '',
    keyPath: '',
    scope:   '',
    depth:   0,
  }

  render() {
    const { structure, uiPath, active, depth, tabbed } = this.props

    let title
    let className = `ui-panel ui-depth-${depth}`

    if (tabbed) {
      className += active ? ' ui-panel-active' : ' ui-panel-inactive'
    } else if (structure.label) {
      title = (
        <h3 className="ui-panel-title" key="title">
          {structure.label}
        </h3>
      )
    }

    let style
    if (structure.style) {
      style = structure.style
    }

    const { childComponents, hasPanel, childClassName } = createUIComponents(this.props, className)

    className += ` ${childClassName}`

    if (structure.className) {
      className += ` ${structure.className}`
    }

    if (hasPanel || !structure.scrollable) {
      return (
        <section className={className} style={style}>
          {title}
          {childComponents}
        </section>
      )
    } else {
      return (
        <ScrollPanel
          id={`ui-${uiPath}`}
          panelClassName={className}
          hintSize={30}
          marginStart={0}
          marginEnd={0}
          overshoot={25}
        >
          {title}
          {childComponents}
        </ScrollPanel>
      )
    }
  }

}

// @ts-ignore: Use before declare workaround
defaultComponents.panel = Panel
