import React, { PureComponent } from 'react'

import { MapLocation } from '../state/reducer'

type SearchResult = {
  bbox: Array<number>,
  center: Array<number>,
  context: Array<any>,
  geometry: any,
  id: string,
  place_name: string,
  place_type: Array<string>,
  properties: any,
  relevance: number,
  text: string,
  type: string,
}

type Props = {
  placeLabel: string,
  viewOnly: boolean,
  searchResults: ReadonlyArray<SearchResult>,
  updateHandler: (newLocation: Partial<MapLocation>) => void,
  acceptHandler: () => void,
  closeHandler: () => void,
  getMapResults: (query: string) => any,
  prefillSearch: string,
  customLabelInput: boolean,
};

type OwnState = {
  query: string,
  placeLabel: string,
  searchResults: ReadonlyArray<SearchResult>,
  focus: number | null,
  loading: boolean,
  viewOnly: boolean,
  coords?: Array<number>,
  selectedResult?: SearchResult,
  country?: string,
};

export default class MapEditorControls extends PureComponent<Props, OwnState> {

  timeout: number;

  constructor(props: Props) {
    super(props)
    this.timeout = 0
    this.state = {
      query:          '',
      placeLabel:     props.placeLabel || '',
      searchResults:  [],
      focus:          null,
      loading:        false,
      viewOnly:       props.viewOnly || false,
      selectedResult: undefined,
    }
  }

  componentDidMount() {
    const { prefillSearch } = this.props

    if (prefillSearch) {
      this.updateSearch(prefillSearch)
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { searchResults, placeLabel, viewOnly } = this.props
    if (searchResults !== prevProps.searchResults) {
      this.setState({
        loading: false,
        searchResults,
      })

      if (prevProps.prefillSearch && searchResults.length) {
        this.selectResult(0, searchResults[0])
      }
    }

    if (placeLabel !== prevProps.placeLabel) {
      this.setState({ placeLabel })
    }

    if (viewOnly !== prevProps.viewOnly) {
      this.setState({ viewOnly })
    }
  }

  searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value
    this.updateSearch(query)
  }

  updateSearch = (query: string) => {
    this.setState({ query: query })

    if (query.length > 2) {
      window.clearTimeout(this.timeout)
      this.timeout = window.setTimeout(() => {
        this.setState({ loading: true })
        this.props.getMapResults(query)
      }, 100)
    } else if (query.length === 0) {
      this.setState({ searchResults: [] })
      this.setState({ focus: 0 })
    }
  }

  placeLabelHandler = () => {
    this.props.updateHandler({ placeLabel: this.state.placeLabel || '' })
  }

  handlePlaceLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ placeLabel: e.target.value })
  }

  clickHandler = (e: React.MouseEvent) => {
    // @ts-ignore We polyfill this */
    const clickedElement = e.target.closest('.map-editor-row')
    const idx = parseInt(clickedElement.dataset.key)
    this.setState({ focus: idx })
    this.selectResult(idx)
  }

  clearSearch = () => {
    this.setState({ query: '' })
  }

  clearLabel = () => {
    this.props.updateHandler({ placeLabel: '' })
  }

  keyDownHandler = (e: React.KeyboardEvent) => {
    switch (e.which) {

      // up
      case 38:
        e.preventDefault()
        this.moveFocus(-1)
        break
      // down
      case 40:
        this.moveFocus(1)
        break
      // accept
      case 13:
        e.preventDefault()
        e.stopPropagation()
        if (this.state.searchResults.length > 0 && this.state.focus == null) {
          this.selectResult(0)
        }
        this.selectResult(this.state.focus)
        break

    }
  }

  selectResult = (resultIdx: number | null, res?: SearchResult) => {
    const { searchResults } = this.state
    const result = res || (searchResults && resultIdx !== null && searchResults[resultIdx])

    if (!result) {
      return
    }

    const coords = result.center
    const placeLabel = result.place_name
    const country = placeLabel
      .split(',')
      .pop()
      ?.trim()
    this.props.updateHandler({ coords, placeLabel, country })
  }

  moveFocus = (dir: number) => {
    if (this.state.loading) {
      return
    }

    this.setState({
      focus:
        this.state.focus === null
          ? 0
          : Math.max(0, Math.min(this.state.searchResults.length - 1, this.state.focus + dir)),
    })
  }

  acceptFocus = () => {
    if (this.state.focus !== null) {
      this.setState({ selectedResult: this.state.searchResults[this.state.focus] })
    }
  }

  mapViewHandler = () => {
    const viewOnly = !this.state.viewOnly
    this.setState({ viewOnly })
    this.props.updateHandler({ viewOnly })
  }

  acceptHandler = () => {
    const { updateHandler, acceptHandler } = this.props
    const { coords, placeLabel, country, viewOnly } = this.state
    updateHandler({ coords, placeLabel, country, viewOnly })
    acceptHandler()
  }

  render() {
    const { customLabelInput, closeHandler } = this.props
    const { query, placeLabel, searchResults, focus, viewOnly } = this.state

    let rows
    if (searchResults && query.length > 0) {
      if (searchResults.length > 0) {
        rows = searchResults.map((result: SearchResult, idx: number) => (
          <div
            className={'map-editor-row' + (idx === focus ? ' focus' : '')}
            data-key={`${idx}`}
            key={idx}
            onClick={this.clickHandler}>
            <strong className="map-editor-row-title">
              {result.place_name}
            </strong>
          </div>
        ))
      } else {
        rows = (
          <div className="map-editor-row">
            <strong className="map-editor-row-title">
              Sorry, no results found for that keyword
            </strong>
          </div>
        )
      }
    }

    return (
      <form className="map-editor-controls">
        <div className="ui-input map-editor-search">
          <input
            className="search-input"
            type="text"
            value={query}
            required
            autoFocus
            onChange={this.searchHandler}
            placeholder="Search..."
            onKeyDown={this.keyDownHandler}
          />
          <i className="material-icons md-18 search-icon">search</i>
          <i
            className="material-icons md-18 clear-icon"
            onClick={this.clearSearch}
          >
            clear
          </i>
          <section className="map-editor-results">{rows}</section>
        </div>
        {!viewOnly && customLabelInput && (
          <div
            className={
              'ui-input map-editor-label' + (placeLabel ? '' : ' input-error')
            }
          >
            <input
              className="label-input"
              type="text"
              value={placeLabel || ''}
              required={false}
              onChange={this.handlePlaceLabelChange}
              onBlur={this.placeLabelHandler}
              placeholder="Label... (Required)"
            />
            <i
              className="material-icons md-18 clear-icon"
              onClick={this.clearLabel}
            >
              clear
            </i>
          </div>
        )}
        <label htmlFor="viewOnly" className="map-view-only">
          <input
            type="checkbox"
            defaultChecked={viewOnly || false}
            value={`${viewOnly || false}`}
            id="viewOnly"
            onClick={this.mapViewHandler}
          />{' '}
          Bookmark map view
        </label>
        <i
          className="material-icons md-18 map-editor-accept"
          onClick={this.acceptHandler}
        >
          done
        </i>
        <i
          className="material-icons md-18 map-editor-cancel"
          onClick={closeHandler}
        >
          clear
        </i>
      </form>
    )
  }

}
