import { extractMediaIdFromUrl, extractMediaIdsFromRichText } from './common-media-finder'

export const extractMediaIdsFromAuthorField = (fieldName: string, field: any) => {
  let mediaIds: Array<string> = []

  switch (fieldName) {

    case 'chronology':
      mediaIds = extractMediaIdsFromRichText(field.body)
      break

    case 'biography':
      mediaIds = extractMediaIdsFromRichText(field.value)
      break

    case 'bibliography':
      // A bibliography field can have both an image and an audio clip
      if (field.imageUrl) {
        const extractedMediaId = extractMediaIdFromUrl(field.imageUrl)
        if (extractedMediaId) {
          mediaIds.push(extractedMediaId)
        }
      }
      if (field.audioUrl) {
        const extractedMediaId = extractMediaIdFromUrl(field.audioUrl)
        if (extractedMediaId) {
          mediaIds.push(extractedMediaId)
        }
      }
      break

    case 'images':
      if (field.path) {
        const extractedMediaId = extractMediaIdFromUrl(field.path)
        if (extractedMediaId) {
          mediaIds.push(extractedMediaId)
        }
      }
      break

  }

  return mediaIds
}
